<template lang='pug'>
div
    div(v-show='loader')
        Loader
    div(v-if="accounts.length")
        div(class="w-card m-3 grid grid-cols-3")
            div(class="col-span-2 grid grid-cols-3")
                div
                    div.customMultiselect.w-96
                        multiselect(
                            v-model="ac"
                            :options="accounts"
                            :searchable="false"
                            :show-labels="false" 
                            :multiple="false"
                            :allow-empty="true"
                            open-direction="bottom"
                            track-by="account_id"
                            label="name"
                            placeholder="Select Account"
                            @input="mapCampaign"
                            )
                    //- select.ui.fluid.dropdown#acDrop(@change="mapCampaign",v-model="ac")
                    //-     option(value="") Select Account
                    //-     option(v-for="d in accounts",:value="d.account_id") {{ d.name }}   
                div
                    div.customMultiselect.w-96
                        multiselect(
                            v-model="ctype"
                            :options="campaignData.campaign_type"
                            :searchable="false"
                            :show-labels="false" 
                            :multiple="false"
                            :allow-empty="true"
                            open-direction="bottom"
                            placeholder="Select Campaign Type"
                            @input="setCampType"
                            )
                    //- select.ui.fluid.dropdown#cpDrop(@change="setCampType",v-model="ctype")
                    //-     option(value="") Select Campaign Type
                    //-     option(v-for="d,i in campaignData.campaign_type",:value="i") {{ i }}    
                div
                    div.customMultiselect.w-96
                        multiselect(
                            v-model="campName"
                            :options="campNames"
                            :searchable="false"
                            :show-labels="false" 
                            :multiple="false"
                            :allow-empty="true"
                            open-direction="bottom"
                            placeholder="Select Campaign Name"
                            @input="setCampName"
                            )
                    //- select.ui.fluid.dropdown(@change="setCampName",v-model="campName")  
                    //-     option(value="") Select Campaign Name
                    //-     option(v-for="d in campNames") {{ d }} 
            div
                button(type="button" class="px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white"  @click="saveMetricChecks") Save              

        div(class="w-card m-3")
            h4(class="text-base 2xl:text-lg text-primary_brand font-semibold")
                span.section(v-if="acName") Account : {{ acName }}
                span(v-if="ctype")
                    i.large.right.angle.icon.divider
                    div.active.section Campaign Type: {{ ctype }}
                span(v-if="campName")  
                    i.large.right.angle.icon.divider
                    div.active.section Campaign: {{ campName }}   
        div(class="w-card m-3")
            label(class="cursor-pointer text-primary_brand" v-for="d in metrics",v-if="d.check") {{ d.title }}
            div
                input(@keyup="searchNow",type="text",v-model="searchKey",placeholder="Search")

        div(class="w-card m-3 grid grid-cols-4")
            div(v-for="data,idx in filteredMetrics")
                div
                    label(class="cursor-pointer text-primary_brand") {{ data.title }}
                    input(type="checkbox", class="cursor-pointer rounded focus:outline-primary_brand mr-2 text-primary_brand" v-model="filteredMetrics[idx].check") 
</template>
<script>
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name: 'LinkedInMapping',
    components: {
        Loader
    },
    data() {
        return {
            loader: false,
            accounts: [],
            ac: '',
            ctype: '',
            campaignData: {},
            campName: '',
            campNames: [],
            metrics: [],
            filteredMetrics: [],
            allKPIs: [],
            acName: '',
            searchKey: '',
        };
    },
    mounted() {
        this.getAccounts();
    },
    methods: {
        searchNow() {
            this.filteredMetrics = this.metrics.filter(dataset => dataset.title.includes(this.searchKey));
        },

        async getAccounts() {
            try {
                const response = await axios.get('/api/linkedin/accounts');
                if(!Array.isArray(response.data.data)) {
                    alert("Consider checking API, response might be invalid.")
                    return;
                } 
                this.accounts = response.data.data;
            } catch (error) {
                console.error('Error', error);
            }
        },

        async mapCampaign() {
            try {
                const response = await axios.get(`/api/linkedin/campaign_map?account_id=${this.ac}`);
                this.campaignData = response.data.data;
                this.allKPIs = this.campaignData.all_kpis.sort();
                this.checkMetrics(this.campaignData.account.account_kpis);
            } catch (error) {
                console.error('Error', error);
            }
        },

        checkMetrics(checkThis) {
            this.metrics = this.allKPIs.map(dataset => ({
                title: dataset,
                check: checkThis.includes(dataset),
            }));
            this.filteredMetrics = this.metrics;
        },

        setCampType() {
            this.campName = '';
            this.campNames = [];
            if (this.ctype) {
                this.campNames = this.campaignData.campaign_type[this.ctype].campaigns;
                this.checkMetrics(this.campaignData.campaign_type[this.ctype].campaign_type_kpis);
            } else {
                this.checkMetrics(this.campaignData.account.account_kpis);
            }
        },

        setCampName() {
            if (this.campName) {
                this.checkMetrics(this.campaignData.campaigns[this.campName].campaign_kpis);
            } else {
                this.checkMetrics(this.campaignData.campaign_type[this.ctype].campaign_type_kpis);
            }
        },

        async saveMetricChecks() {
            const values = this.metrics.filter(dataset => dataset.check).map(dataset => dataset.title);
            const data = {
                account_id: this.ac,
                values,
            };

            if (this.campName) {
                data.key = 'campaign';
                data.key_id = this.campaignData.campaigns[this.campName].campaignId;
            } else {
                if (this.ctype) {
                    data.key = 'campaign_type';
                    data.key_id = this.ctype;
                } else {
                    data.key = 'account';
                    data.key_id = this.ac;
                }
            }
            axios.post('/api/linkedin/campaign_map', data).then(res => {
                alert('Success');
            }).catch(error => {
                console.log('Error', error);
                alert('Error');
            })
        },
    },
};

</script>