import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import lineChart from '@/components/charts/lineChart.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
export default {
    name: "SearchVolume",
    components: {
        Loader, lineChart, dualAxisChart
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate:  moment().subtract(6,"months").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            intervalsList: [
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'year',
                    label: 'Year'
                },
            ],
            selectedInterval: {
                key: 'month',
                label: 'Month'
            },
            selectedTopTenKeywordInterval:{
                key: 'month',
                label: 'Month'
            },
            graphDropdown:[],
            searchVolumeGraphData:{},
            isMetricDropdown:false,
            metrics : [],              
            viewAllKeywordData:'',
            viewSearchVolumeGraphData:'',
            viewTopKeywordGraphData:'',
            processedTopKeywordData:'',
            tableHeader:[],
            tableData:[],

        }
    },

    mounted() {
        // this.getSavedCreatives();
        this.getDateRange();
    },



    methods: {

        applyFilter() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.getAllKeywordTableData();
            this.getSearchVolumeTopTenKeywordGraphData();
            this.getSearchVolumeGraphData();
        },
      
       

        setInterval(selectedInterval){
            this.selectedInterval= selectedInterval
            this.getSearchVolumeGraphData();
        },

        setTopKeywordInterval(selectedInterval){
            this.selectedTopTenKeywordInterval= selectedInterval
            this.getSearchVolumeTopTenKeywordGraphData();
        },

        getDateRange(){
            this.viewAllKeywordData = '';
            const url ="/api/v1/searchvolume/enddate"
            const payload={}
            axios.post(url,payload).then(res => {
                this.dateRange.startDate = moment(res.data.end_date).subtract(2,"months").format("YYYY-MM-DD")
                this.dateRange.endDate = moment(res.data.end_date).format("YYYY-MM-DD")
                this.getAllKeywordTableData();
                this.getSearchVolumeTopTenKeywordGraphData();
                this.getSearchVolumeGraphData();
                this.loader = false;

            }).catch(err => {
                this.loader = false;
                console.log("Error in getting search volume reports", err)
            })
        },




        getAllKeywordTableData(){
            this.loader = true;
            this.viewAllKeywordData = '';
            $('#reports_table').DataTable().destroy();
            const url ="/api/v1/searchvolume/summary"
            const payload={
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate    
            }
            axios.post(url,payload).then(res => {
                this.viewAllKeywordData = res.data;
                this.$nextTick(() => {
                    this.processTable('#reports_table')
                })
                this.loader = false;
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting search volume reports", err)
            })
        },

        getSearchVolumeTopTenKeywordGraphData(){
            this.loader = true;
            this.viewSearchVolumeGraphData = ''
            const url = "/api/v1/searchvolume/bargraph"
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                interval: this.selectedTopTenKeywordInterval.key
            }
            axios.post(url, payload).then(res => {
                this.loader = false;
                this.viewTopKeywordGraphData = res.data.data
                this.processTopKeywordGraphData();
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })
        },

        processTopKeywordGraphData(){
            this.processedTopKeywordData={
                xAxis:[],
                disableYLabel:false,
                legend: true,
                seriesData:[{
                    name:'',
                    data:[]
                }]
            }

            if(this.viewTopKeywordGraphData){
                this.viewTopKeywordGraphData.forEach((data) => {
                    this.processedTopKeywordData.xAxis.push(data.key);
                    console.log("check the data", data.search_volume)
                    this.processedTopKeywordData.seriesData[0].data.push(Number(data.search_volume.replaceAll(",","")));
                })
            }
        },

        getSearchVolumeGraphData(){
            this.loader = true;
            this.viewSearchVolumeGraphData = ''
            const url = "/api/v1/searchvolume/graph"
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                interval: this.selectedInterval.key
            }
            axios.post(url, payload).then(res => {
                this.loader = false;
                this.viewSearchVolumeGraphData = res.data.data
                this.processGraphData();
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })
        },
        
        processGraphData(){
            this.searchVolumeGraphData={
                xAxis:[],
                disableYLabel:true,
                legend: true,
                seriesData:[]
            }
            let colorMapper = ["#065559","#01695C","#009688","#42D9BC","#6956E5","#8C9EFF","#1ACF85", "#759883", "#3D5AFE"]
            if(this.viewSearchVolumeGraphData){
                Object.keys(this.viewSearchVolumeGraphData[0]).forEach((data,idx)=>{
                    if(data.toLowerCase()!='timestamp'){
                        this.searchVolumeGraphData.seriesData.push({
                            name: data,
                            color:data=='boAt'?'#FFAB00':colorMapper[idx],
                            data:[]
                        })
                    }
                })
                this.viewSearchVolumeGraphData.forEach((data) => {
                    this.searchVolumeGraphData.xAxis.push(data.timestamp);
                    this.searchVolumeGraphData.seriesData.forEach((seriesObject)=>{
                        seriesObject.data.push(data[seriesObject.name])
                    })
                })
            }
        },

        processTable(id) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                scrollCollapse: true,
                lengthChange: true,
                ordering:true,
                sorting: true,
                order:[],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },

        abbreviateNumber(value) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },

        formatNumber(num) {
            if (isNaN(num)) { return num }
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}