import moment from 'moment';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name: 'EHeatMap',
    components: {
        Loader,
    },
    data() {
        return {
            allFilterOption: [],
            data: [],
            tableData: [],
            value: "",
            dataLoader: false,
            Options: [
                {
                }
            ],
            cities: [],
            r: [],
            ranges: [],
            goalsList: [],
            rangeList: [],
            city: '',
            adwords_camps: [],
            selectedAdwordsCamps: [],
            filters: ["users", "sessions", "transactions", "bounces", "Bounce Rate", "Percent New Sessions", "Avg Session Duration"],
            selectedFilter: 'sessions',
            dateRange: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment()
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.formatDateRange();
            this.getGoalsList();
            this.getCampList();
            this.getData();
        },
        formatDateRange() {
            if (this.dateRange.startDate) this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
            if (this.dateRange.endDate) this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates() {
            this.formatDateRange();
            this.getData();
        },
        roundNumber(num, dec) {
            if (isNaN(num)) return num;
            else if (num % 1 == 0) {
                num = num + '.0';
                return num.replace(/(\d)(?=(\d{3})+\.)/g, "$1,").replace('.0', '');
            } else if (dec == -1) {
                return num.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
            } else return num.toFixed(dec).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        },

        filterChange() {
            const isMatch = this.goalsList.includes(this.selectedFilter)
            if (isMatch) {
                this.adwords_camps = []
            } else {
                this.getCampList()
            }
            this.getData();
        },



        getCampList() {
            this.dataLoader = true
            let url = `/api/summary/campaigns/?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((Response) => {
                const resData = Response.data
                this.adwords_camps = resData.camps
                this.dataLoader = false;
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            });
        },
        getGoalsList() {
            this.dataLoader = true
            let url = "/api/ga/ecommerce/goals"
            axios.get(url).then((Response) => {
                const resData = Response.data
                this.goalsList = resData.data
                this.filters.push(...this.goalsList)
                this.dataLoader = false;
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            });

        },

        getData() {
            this.dataLoader = true
            let selectedCity = this.city;
            if (!selectedCity) selectedCity = 'all';
            let url = `/api/ga/ecommerce/heatmap?city=${selectedCity}&channel=${this.selectedFilter}&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            if (this.selectedAdwordsCamps.length) {
                let selectedId = '';
                for (const item of this.selectedAdwordsCamps) {
                    if (selectedId) selectedId += ', '
                    selectedId += item.id;
                }
                url += `&query_type_campaign=${true}&campaigns=${selectedId}`
            }
            console.log(this.selectedAdwordsCamps)
            axios.get(url).then((Response) => {
                const resData = Response.data
                const modifiedTableData = resData.data
                modifiedTableData.forEach((row, id) => {
                    if (id !== 0) {
                        row.forEach((col, id) => {
                            if (id !== 0) {
                                col.value = this.roundNumber(col.value, 2).replace(/,/g, '')
                            }
                        });
                    }
                });
                this.tableData = modifiedTableData
                this.rangeList = resData.ranges.map((element) => this.roundNumber(element, 2))

                if (!this.cities.length) this.cities = resData.cities
                this.dataLoader = false;

            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            })
        },
        clearCity() {
            this.city = '';
            this.getData()
        },

        clearCampaign() {
            this.selectedAdwordsCamps = [];
            this.getCampList()
        }

    },
}