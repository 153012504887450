<template lang="pug">
div
    GA4(v-if="GA4")
    GAUA(v-else) 
</template>
<script>
import GAUA from './AnalyticsSummaryUA.vue'
import GA4 from './AnalyticsSummaryGA4.vue'
export default {
    name: "AnalyticsSummary",
    components: {
        GAUA,
        GA4
    },
    data(){
        return {
            GA4: JSON.parse(localStorage.userData).menu.GA4,
            GA: JSON.parse(localStorage.userData).menu.GA4
        }
    }
}
</script>