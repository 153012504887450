import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=607d1c4c&scoped=true&lang=pug"
import script from "./Sidebar.js?vue&type=script&lang=js&external"
export * from "./Sidebar.js?vue&type=script&lang=js&external"
import style0 from "./Sidebar.sass?vue&type=style&index=0&id=607d1c4c&prod&lang=sass&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "607d1c4c",
  null
  
)

export default component.exports