<template lang="pug">
div
    div(class="text-lg font-medium text-center text-gray-500 ml-6 mb-4")
        ul(class="flex flex-wrap -mb-px")
            li
                button(id="account" class="inline-block p-4 border-b-2  rounded-t-lg" type="button" @click="current = 'account_page'") Account
            li
                button(id="campaign" class="inline-block p-4 border-b-2  rounded-t-lg" type="button" @click="current = 'campaign_page'") Campaign
    div(id="account_page")
        FlipkartDisplayAccount(v-if="current === 'account_page'")
    div(id="campaign_page")
        FlipkartDisplayCampaign(v-if="current === 'campaign_page'")
</template>
<script>
import FlipkartDisplayAccount from './DisplayAccount/FlipkartDisplayAccount.vue';
import FlipkartDisplayCampaign from './DisplayCampaign/FlipkartDisplayCampaign.vue';
import { Tabs } from 'flowbite';
export default {
    name: "FlipkartDisplay",
    components: {
        FlipkartDisplayAccount,
        FlipkartDisplayCampaign
    },
    data() {
        return {
            current: "account_page"
        }
    },
    mounted() {
        this.initilizeTabs();
    },
    methods: {
        initilizeTabs() {
            // create an array of objects with the id, trigger element (eg. button), and the content element
            const tabElements = [
                {
                    id: 'account',
                    triggerEl: document.querySelector('#account'),
                    targetEl: document.querySelector('#account_page')
                },
                {
                    id: 'campaign',
                    triggerEl: document.querySelector('#campaign'),
                    targetEl: document.querySelector('#campaign_page')
                }
            ];

            // options with default values
            const options = {
                defaultTabId: 'account',
                activeClasses: 'text-primary_brand hover:text-primary_brand border-primary_brand-80',
                inactiveClasses: 'text-gray-500 hover:text-gray-600 border-base_color-20 hover:border-gray-300 border-transparent',
            };
            this.tabObject = new Tabs(tabElements, options);
        },
    }
}
</script>
<style scoped></style>
