<template lang="pug">
div
    div(v-if="dataLoader")
        Loader
    div
        div(class="text-md font-medium text-center text-gray-500 ml-2 mb-2")
            ul(class="flex flex-wrap -mb-px")
                li
                    button(class="inline-block p-4 border-b-2 rounded-t-lg active"
                        :class="[selectedSection == 'Overall' ? 'border-primary_brand text-primary_brand' : 'hover:text-gray-600 hover:border-gray-300']"
                        @click="selectedSection = 'Overall'") Overall
                li
                    button(class="inline-block p-4 border-b-2 rounded-t-lg active"
                        :class="[selectedSection == 'Search' ? 'border-primary_brand text-primary_brand' : 'hover:text-gray-600 hover:border-gray-300']"
                        @click="()=>{selectedSection = 'Search'; getSearchCardData()}") Search
                li
                    button(class="inline-block p-4 border-b-2 rounded-t-lg active"
                        :class="[selectedSection == 'Display' ? 'border-primary_brand text-primary_brand' : 'hover:text-gray-600 hover:border-gray-300']"
                        @click="() => { selectedSection = 'Display'; getDisplayCardData()}") Display
                li
                    button(class="inline-block p-4 border-b-2 rounded-t-lg active"
                        :class="[selectedSection == 'PCA' ? 'border-primary_brand text-primary_brand' : 'hover:text-gray-600 hover:border-gray-300']"
                        @click="() => { selectedSection = 'PCA'; getDisplayCardData()}") PCA
        div(class="text-md font-medium text-center text-gray-500 ml-4 mb-3")
            ul(class="flex flex-wrap -mb-px")
                li(v-for="tabData in allSectionData[selectedSection].tabData")
                    button(class="inline-block p-4 border-b-2 rounded-t-lg active"
                        :class="[tabData.tab == allSectionData[selectedSection].selectedTab ? 'border-primary_brand text-primary_brand' : 'hover:text-gray-600 hover:border-gray-300']"
                        @click="() => {allSectionData[selectedSection].selectedTab = tabData.tab; allSectionData[selectedSection].allCardData = tabData.kpi}") {{ tabData.tab }}
        div(class="grid gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2")
            div(class="w-card w-box-shadow" v-for="cardData in shownCardData")
                div(class="text-xl text-primary_brand font-medium mb-4") {{ cardData.title }}
                div(class="flex justify-between border-solid border-base_color-40 border-b pb-3")
                    span(class="text-xl") {{ formatNumber(cardData.value) }}
                    div(class="font-semibold text-md" :class="[cardData.value_change < 0 ? 'w-red' : 'w-green']")
                        span {{ formatNumber(cardData.value_change) }}%
                        font-awesome-icon(:icon="cardData.value_change < 0 ? 'fa-down-long' : 'fa-up-long'") 
                        span ({{ formatNumber(cardData.prev_value) }})
                div(class="flex justify-between border-solid border-base_color-40 border-b mt-3 pb-3")
                    div(class="flex items-end text-lg") QTD
                    div
                        div(class="font-semibold text-sm" :class="[cardData.qtd_change < 0 ? 'w-red' : 'w-green']")
                            span {{ formatNumber(cardData.qtd_change) }} 
                            font-awesome-icon (:icon="cardData.qtd_change < 0 ? 'fa-down-long' : 'fa-up-long'") 
                            span ({{ formatNumber(cardData.prev_qtd) }})
                        span(class="text-lg") {{ formatNumber(cardData.qtd) }}
                div(class="flex justify-between border-solid border-base_color-40 border-b mt-3 pb-3")
                    div(class="flex items-end text-lg") MTD
                    div
                        div(class="font-semibold text-sm" :class="[cardData.mtd_change < 0 ? 'w-red' : 'w-green']")
                            span {{ formatNumber(cardData.mtd_change) }}% 
                            font-awesome-icon(:icon="cardData.mtd_change < 0 ? 'fa-down-long' : 'fa-up-long'") 
                            span ({{ formatNumber(cardData.prev_mtd) }})
                        span(class="text-lg") {{ formatNumber(cardData.mtd) }}
                div
                    lineChart(:data="processLineChartProp(cardData.current_timeline, cardData.prev_timeline)" :topMargin="10" :hideAxis="true" height="50%")
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import lineChart from '@/components/charts/lineChart.vue'


export default {
    name: 'AmazonReportCard',
    props: ['dateRange', 'compDateRange', 'compareFlag', 'interval'],
    components: {
        Loader,
        lineChart
    },
    data() {
        return {
            dataLoader: false,
            selectedSection: 'Overall',
            overallCardData: [],
            allSectionData: {
                'Overall': {
                    selectedTab: '',
                    allCardData: [],
                    tabData: []
                },
                'Search': {
                    selectedTab: '',
                    allCardData: [],
                    tabData: []
                },
                'Display': {
                    selectedTab: '',
                    allCardData: [],
                    tabData: []
                },
                'PCA': {
                    selectedTab: '',
                    allCardData: [],
                    tabData: []
                }
            }
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        shownCardData() {
            return this.allSectionData[this.selectedSection].allCardData
        },
        watchAllProps(){
            return `${this.dateRange}&${this.compDateRange}&${this.compareFlag}&${this.interval}`
        }
    },
    watch: {
        watchAllProps: {
            handler(){
                this.init()
            }
        }
    },
    methods: {
        init() {
            this.getOverallCardData()
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },
        processLineChartProp(timelineData, prevTimelineData){
            let xAxis, seriesData
            xAxis = timelineData.map(data => data.Date)
            seriesData = [
                {
                    name: '',
                    data: timelineData.map(data => data.value)
                }
            ]
            if(this.compareFlag) seriesData.push(
                {
                    name: '',
                    data: prevTimelineData.map(data => data.value)
                }
            )
            return {
                xAxis: xAxis,
                seriesData: seriesData
            }
        },
        getOverallCardData() {
            this.dataLoader = true
            let url = '/api/flipkart/overall/new_report'
            let payload = {
                'end_date': moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                'start_date': moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                'interval': this.interval
            }

            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.allSectionData['Overall'].tabData = resData.data
                this.allSectionData['Overall'].selectedTab = resData.data[0].tab
                this.allSectionData['Overall'].allCardData = resData.data[0].kpi
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getSearchCardData(){
            if(this.allSectionData['Search'].allCardData.length) return false
            this.dataLoader = true
            let url = '/api/flipkart/search/new_report'
            let payload = {
                'end_date': moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                'start_date': moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                'interval': this.interval
            }

            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.allSectionData['Search'].tabData = resData.data
                this.allSectionData['Search'].selectedTab = resData.data[0].tab
                this.allSectionData['Search'].allCardData = resData.data[0].kpi
                this.dataLoader = false

            }).catch(err => console.log(err))
        },
        getDisplayCardData(){
            if(this.allSectionData['Display'].allCardData.length && this.allSectionData['PCA'].allCardData.length) return false
            this.dataLoader = true
            let url = '/api/flipkart/display/new_report'
            let payload = {
                'end_date': moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                'start_date': moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                'interval': this.interval
            }

            axios.post(url, payload).then((res) => {
                const displayData = res.data.data['Display'];
                const PCAData = res.data.data['PCA'];

                this.allSectionData['Display'].tabData = displayData
                this.allSectionData['Display'].selectedTab = displayData[0].tab
                this.allSectionData['Display'].allCardData = displayData[0].kpi

                this.allSectionData['PCA'].tabData = PCAData
                this.allSectionData['PCA'].selectedTab = PCAData[0].tab
                this.allSectionData['PCA'].allCardData = PCAData[0].kpi

                this.dataLoader = false

            }).catch(err => console.log(err))
        }
    },
}
</script>