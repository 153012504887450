<template lang='pug'>
div
    div(v-if="isAdmin")
        div(v-show='loader')
            Loader
        div
            div(class="w-card m-3 flex justify-between")
                div.customMultiselect.w-96
                    multiselect(
                        v-model="fbAccount"
                        :options="fbAccounts"
                        :searchable="false"
                        :show-labels="false" 
                        :multiple="false"
                        :allow-empty="true"
                        open-direction="bottom"
                        track-by="name"
                        label="name"
                        placeholder="Select Account"
                        @input="getCampaigns"
                        )
                button(type="button" class="px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" @click="saveNow") Save  
            div(class="w-card m-3" v-if="fbAccount")
                table#campTableGaMapping(v-if="gaCampaigns.length")
                    thead
                        tr
                            th(style="width:50%") GA Campaigns
                            th(style="width:50%") FB Campaigns
                    tbody
                        tr(v-for="ga in gaCampaigns")   
                            td  {{ ga.campaign_name }}
                            td
                                div(style="width:100%")
                                    div.text-center(style="width:90%")
                                        select(class="rounded text-primary_brand-80 border-1 border-primary_brand-80" v-model="fbCamp[ga.campaign_name]")
                                            option(value="") Choose Facebook Campaign 
                                            option(v-for="fb in fbCampaigns",:value="fb") {{ fb }}
                                        
                div.p-4.mb-4.text-sm.text-red-800.rounded-lg.bg-red-50(v-else)
                    span.font-medium No Data in Table!
</template>
<script>
import Vuex from 'vuex';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';

export default {
    name: 'GAMapping',
    components: {
        Loader,
    },
    data() {
        return {
            loader: false,
            fbAccounts: [],
            fbAccount: null,
            gaCampaigns: [],
            fbCampaigns: [],
            fbCamp: {},
            isAdmin: false,
        };
    },
    computed: Vuex.mapState(['user', 'menu']),
    created() {
        const user = JSON.parse(localStorage.userData).data.username
        if (user === 'admin') {
            this.isAdmin = true;
        }
    },
    mounted() {
        $('#campTableGaMapping').DataTable();
        this.getAccounts();
    },
    methods: {
        getAccounts() {
            const self = this;
            self.loader = true;
            axios.get('/api/fb/all-accounts')
                .then((response) => {
                    self.loader = false;
                    self.fbAccounts = response.data.data.data;
                })
                .catch((error) => {
                    self.loader = false;
                    console.error('Error', error);
                });
        },
        getCampaigns() {
            const self = this;
            self.loader = true;
            $('#campTableGaMapping').DataTable().destroy();
            const url = `/api/ga/ecommerce/fb_camp_list?account_id=${self.fbAccount.name}`;
            axios.get(url)
                .then((response) => {
                    self.fbCamp = {};
                    self.loader = false;
                    self.gaCampaigns = response.data.data.ga_campaigns;
                    self.fbCampaigns = response.data.data.fb_campaigns;
                    for (const d of self.gaCampaigns) {
                        self.fbCamp[d.campaign_name] = d.campaign_fb_name;
                    }
                    self.$nextTick(() => {
                        this.processTable('#campTableGaMapping', 0)
                    });
                })
                .catch((error) => {
                    self.loader = false;
                    console.error('Error', error);
                });
        },
        saveNow() {
            const self = this;
            self.loader = true;
            const payload = {
                account_id: self.fbAccount.name,
                campaign_map: self.fbCamp,
            };
            axios.post('/api/ga/ecommerce/fb_camp_list', payload).then(() => {
                    self.loader = false;
                    console.log('Success');
                }).catch((error) => {
                    self.loader = false;
                    console.error('Error', error);
                    alert('Error');
                });
        },
        processTable(id, sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                lengthChange: true,
                sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            this.loader = false;
        },
    },
};
</script>
