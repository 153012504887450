import axios from 'axios'

export default
    name:'Toast'
    props:['message']
    data:()->
        
    mounted:()->
        this.init();
    methods:
        init:()->
            this.dataAPIs()

        dataAPIs:()->    

       