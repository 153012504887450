<template lang='pug'>
div#pilotreportadmin
    div.w-card.m-3.flex.gap-4.items-center
        div.customMultiselect.w-96
            multiselect(
                v-model="selectedAccount"
                :options="accounts"
                :searchable="false"
                :show-labels="false" 
                :multiple="false"
                :allow-empty="true"
                open-direction="bottom"
                track-by="id"
                label="name"
                placeholder="Select Account"
                )
        input(type="file" class="bg-base_color-60 mr-3 hover:bg-base_color-80 text-white rounded" @change="setFile($event.target.files)")
        button(@click="submitReport" type="button" class="px-2 py-2 w-52 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white") Submit  
</template>
<script>
import moment from 'moment';
import axios from 'axios';

export default {
    name: 'PilotReport',
    components: {

    },
    data() {
        return {
            date: {
                from: moment().subtract(30, 'days').format("YYYY-MM-DD"),
                to: moment().format("YYYY-MM-DD")
            },
            selectedAccount: null,
            accounts: [],
            file: {}
        };
    },
    mounted() {
        this.getAccounts();
    },
    methods: {
        submitReport() {
            const formData = new FormData();
            if(!this.selectedAccount || !this.file) {
                alert("Please select account and upload file")
            } else {
                formData.append("account_id", this.selectedAccount.id);
                formData.append("file", this.file);
            }

            axios.post("/api/customdata", formData).then(response => {
                    alert("Success");
                }).catch(error => {
                    alert("Error");
                });
        },
        getAccounts() {
            axios.get("/api/adword/accounts").then(response => {
                this.accounts = response.data.data;
            }).catch(error => {
                console.error("Error fetching accounts:", error);
            });
        },
        setFile(d) {
            this.file = d[0];
            console.log(this.file);
        }
    }
};


</script>