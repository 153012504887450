import axios from "axios";
import moment from "moment";
import Loader from "@/components/Loader/Loader.vue";

export default {
    name: "Flipkart Sleepwell",
    components: {
        Loader
    },
    data() {
        return {
            dataLoader: false,
            campLoader: false,
            isFilter: false,
            filterBy: "",
            dateRange: {
                startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            intervalsList: [
                {
                    key: "daily",
                    label: "Day",
                },
                {
                    key: "weekly",
                    label: "Week",
                },
                {
                    key: "monthly",
                    label: "Month",
                },
                {
                    key: "yearly",
                    label: "Year",
                },
            ],
            selectedInterval: {
                key: "daily",
                label: "Day",
            },
            selectedCampaignTypeData: "pla",
            campaignOptions: [],
            selectedNumericCampaignValue: "views",
            numericFiltersFields: [],
            selectedNumericMatricsValue: "greater than",
            numericFilterMatrics: [],
            matricsInputValue: "",
            selectedStringCampaignValue: "campaign_name",
            stringFiltersFields: [],
            selectedStringMatricsValue: "contains",
            stringFilterMatrics: [],
            StringMatricsInputValue: "",
            tableHeader: [],
            tableData: [],
            storeNumericFilterValue: [],
            storeStringFilterValue: [],
            selectedTableColumn: [],
        }
    },
    mounted() {
        this.getTargetKurlonReport();
    },
    methods: {
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format(
                "YYYY-MM-DD"
            );
            this.dateRange.endDate = moment(this.dateRange.endDate).format(
                "YYYY-MM-DD"
            );
        },
        formatDates() {
            this.formatDateRange();
            this.getTargetKurlonReport();
        },
        setInterval(selectedInterval) {
            this.selectedInterval = selectedInterval;
            this.getTargetKurlonReport();
        },
        handleCampaignType() {
            this.getTargetKurlonReport();
        },
        handleIsFilter() {
            const existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue
            );

            if (existingValue) {
                this.selectedNumericMatricsValue = existingValue[0].operation;
                this.matricsInputValue = existingValue[0].value;
            }
            this.isFilter = true;
        },
        handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
            if (data.length <= 0) {
                return false;
            }

            let existingValue = storeFilterValue.find(
                (item) => item[0].field === data
            );

            if (existingValue) {
                this[selectedMatricsValueKey] = existingValue[0].operation;
                this[matricsInputValueKey] = existingValue[0].value;
            } else {
                this[matricsInputValueKey] = "";
            }
        },
        handleNumericFilterModal(data) {
            this.handleFilterModal(data, this.storeNumericFilterValue, 'selectedNumericMatricsValue', 'matricsInputValue');
        },
        handleStringFilterModal(data) {
            this.handleFilterModal(data, this.storeStringFilterValue, 'selectedStringMatricsValue', 'StringMatricsInputValue');
        },
        clearFilter() {
            this.isFilter = false;
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
        },
        removeFilterValue(storeFilterValue, data) {
            const index = storeFilterValue
                .flat()
                .findIndex(
                    (item) =>
                        item.field === data.field &&
                        item.operation === data.operation &&
                        item.value === data.value
                );

            if (index !== -1) {
                storeFilterValue.splice(index, 1);
            }
        },
        RemoveBadgeValue(data) {
            this.removeFilterValue(this.storeNumericFilterValue, data);

            for (let i = 0; i < this.storeStringFilterValue.length; i++) {
                const itemArray = this.storeStringFilterValue[i];
                const matchIndex = itemArray.findIndex(
                    (item) =>
                        item.field === data.field &&
                        item.operation === data.operation &&
                        item.value === data.value
                );

                if (matchIndex !== -1) {
                    this.storeStringFilterValue.splice(i, 1);
                    break;
                }
            }
            this.getTargetKurlonReport();
        },
        RemoveAllBadgeValue() {
            this.storeNumericFilterValue = [];
            this.storeStringFilterValue = [];
            this.getTargetKurlonReport();
        },
        handleTableColumn() {
            this.$nextTick(() => {
                this.initDataTable('#KurlonTargetDataTable', (this.selectedTableColumn.length) ? this.selectedTableColumn : this.tableHeader, this.tableData);
            })
        },
        getTargetKurlonReport() {
            this.dataLoader = true;
            const url = "/api/fk/targated/report";
            const payload = {
                // start_date: this.dateRange.startDate,
                // end_date: this.dateRange.endDate,
                "start_date": "2024-10-01",
                "end_date": "2024-10-07",
                report_type: this.selectedInterval.key,
                campaign_type: this.selectedCampaignTypeData,
                numarical_filters: this.storeNumericFilterValue.flat(),
                string_filters: this.storeStringFilterValue.flat()
            }

            axios.post(url, payload).then((res) => {
                this.tableHeader = res.data.data.headers;
                this.tableData = res.data.data.campaign_data;
                this.campaignOptions = res.data.data.ava_campaign_type;
                this.numericFiltersFields = res.data.data.numarical_filters.fields;
                this.numericFilterMatrics = res.data.data.numarical_filters.matrics;
                this.stringFiltersFields = res.data.data.string_filters.fields;
                this.stringFilterMatrics = res.data.data.string_filters.matrics;
                this.$nextTick(() => {
                    this.initDataTable('#KurlonTargetDataTable', this.tableHeader, this.tableData);
                });
                this.dataLoader = false;
            }).catch(err => {
                console.error(err);
            })
        },
        initDataTable(id, header, data) {
            const column = header.map((header) => ({
                title: header.value,
                data: header.key,
            }));

            if ($.fn.DataTable.isDataTable(id)) {
                $(id).DataTable().clear().destroy();
            }
            $(id).empty();


            $(id).DataTable({
                data: data,
                columns: column,
                info: true,
                paging: true,
                searching: true,
                ordering: true,
                initComplete: function () {
                    $(id).wrap(
                        "<div style='overflow:auto; width:100%;position:relative;'></div>"
                    );
                },
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_",
                },
            });
            const searchIcon = require("@/assets/Icons_SVG/Search.svg");
            $(`${id}_filter > label`).append(`
              <div class='search_icon'>
                <img src='${searchIcon}' alt='Search' />
              </div>
            `);
            function adjustCloseIconPosition() {
                const screenWidth = window.innerWidth;
        
                if (screenWidth >= 1400) {
                  $(id).css({
                    width: "1750px",
                  });
                  $(`${id} .sorting_1`).css({
                    width: "60px",
                  });
                }else{
                  $(id).css({
                    width: "1300px",
                  });
                  $(`${id} .sorting_1`).css({
                    width: "60px",
                  });
                }
              }
        
              adjustCloseIconPosition();
              $(window).resize(adjustCloseIconPosition);
        },
        handlePostFilters({
            selectedCampaignValue,
            selectedMatricsValue,
            matricsInputValue,
            storeFilterValue,
            getTableDataFunction
        }) {
            let existingFilter = storeFilterValue.find(
                (item) => item[0].field === selectedCampaignValue
            );

            if (existingFilter) {
                existingFilter[0].operation = selectedMatricsValue;
                existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseInt(matricsInputValue);
            } else {
                storeFilterValue.push([
                    {
                        operation: selectedMatricsValue,
                        field: selectedCampaignValue,
                        value: isNaN(matricsInputValue) ? matricsInputValue : parseInt(matricsInputValue)
                    }
                ]);
            }

            if (typeof getTableDataFunction === "function") {
                this.getTargetKurlonReport();
            }
        },
        handlePostNumericFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedNumericCampaignValue,
                selectedMatricsValue: this.selectedNumericMatricsValue,
                matricsInputValue: this.matricsInputValue,
                storeFilterValue: this.storeNumericFilterValue,
                getTableDataFunction: this.getTargetKurlonReport
            });
            this.isFilter = false;
        },
        handlePostStringFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedStringCampaignValue,
                selectedMatricsValue: this.selectedStringMatricsValue,
                matricsInputValue: this.StringMatricsInputValue,
                storeFilterValue: this.storeStringFilterValue,
                getTableDataFunction: this.getTargetKurlonReport
            });
            this.isFilter = false;
        }
    },
}