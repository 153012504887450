import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import Header from '@/components/Header/Header.vue'
import lineChart from '@/components/charts/lineChart.vue'
import drillPieChart from '@/components/charts/drillPieChart.vue'


// api :-
// https://weavr.online/api/summary/campaigns/?start_date=2023-01-22&end_date=2023-02-21
// https://weavr.online/api/comment/
// https://weavr.online/api/summary/aggrigate/?start_date=2023-02-01&end_date=2023-02-21
// https://weavr.online/api/summary/aggrigate/?start_date=2023-01-11&end_date=2023-01-31

export default {
    name: 'Summary',
    components: {
        Header,
        Loader,
        lineChart,
        drillPieChart
    },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'week'),
                endDate: moment()
            },
            dataLoader: false,
            campaignData: [],
            selectedCampaign: { name: 'ALL' },
            summaryCardData: [],
            graphData: [],
            processedChartData: {
                impressionChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'Impressions'
                },
                clicksChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'Clicks'
                },
                costChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'Cost'
                },
                conversionsChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'Conversions'
                },
                CTRChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'CTR'
                },
                CPMChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'CPM'
                },
                CPCChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'CPC'
                },
                CostPerConversionChartData: {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    heading: 'Cost/Conversion'
                },
            },
            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'week',
                    label: 'Week'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.formatDateRange();
            this.dataAPIs();
        },
        dataAPIs() {
            this.getAggrigateData();
            this.getCampaignData();
        },
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates() {
            this.formatDateRange();
            this.dataAPIs()
        },
        setInterval(selectedInterval) {
            this.selectedInterval = selectedInterval;
            this.formatDateRange(); 
            this.getAggrigateData()
        },
        decodeHTML(html) {
            let txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value; // decoder for HTML-encoded strings.
        },
        getCampaignData() {
            this.dataLoader = true;
            let url = '/api/summary/campaigns/?'
            url += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            if (this.selectedCampaign.name !== 'ALL') url += `&camp_id=${this.selectedCampaign.id}&camp_name=${this.selectedCampaign.name}`
            axios.get(url).then((res) => {
                const resData = res.data
                resData.camps.push({ name: 'ALL' })
                this.campaignData = resData.camps
            }).catch(err => console.log(err))
        },
        getAggrigateData() {
            let url = '/api/summary/aggrigate/?'
            url += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&interval=${this.selectedInterval.key}`
            
            if (this.selectedCampaign.name !== 'ALL') url += `&camp_id=${this.selectedCampaign.id}&camp_name=${this.selectedCampaign.name}`
            
            this.graphData = [];
            this.dataLoader = true;
            
            axios.get(url).then((response) => {
                const resData = response.data;
                this.summaryCardData = resData.summary;
                this.summaryCardData.forEach(data => {
                    data.label = data.label
                    data.int_value = data.value
                    data.value = data.display
                    if (data.prefix) data.value = this.decodeHTML(data.prefix) + " " + data.value;
                    if (data.suffix) data.value = data.value + data.suffix;
                });
                this.graphData = resData.graph_data;
                this.$nextTick(() => {
                    this.processChart("impressionChartData", "Impressions")
                    this.processChart("clicksChartData", "Clicks")
                    this.processChart("costChartData", "Cost")
                    this.processChart("conversionsChartData", "Conversions")
                    this.processChart("CTRChartData", "CTR")
                    this.processChart("CPMChartData", "CPM")
                    this.processChart("CPCChartData", "CPC")
                    this.processChart("CostPerConversionChartData", "Cost/Conversion")
                    this.dataLoader = false;
                });
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            });
        },
        processChart(variable, key) {
            this.processedChartData[variable].xAxis = [];
            this.processedChartData[variable].seriesData = [];

            let dataPoints = []
            const xAxis = []
            const seriesData = []
            
            this.graphData.forEach((data) => {
                dataPoints.push(data[key])
                xAxis.push(data.Date)
            });
            
            dataPoints = dataPoints.map((data) => Math.round(data * 100) / 100)
            seriesData.push({ name: key, data: dataPoints })

            this.processedChartData[variable].xAxis = xAxis;
            this.processedChartData[variable].seriesData = seriesData;
        },
    },

}