<template lang="pug">
div
    Loader(v-if="loader")
    div(v-if="!loader") 
        div(class="xs:overflow-scroll")
            table(class="w-full border rounded-corners text-sm")
                thead
                    tr(class="text-center")
                        th(class="py-2" v-for="th of tableData.header") {{ th }}
                tbody(v-for="category of tableData.categories")
                    tr(v-for="duration, idx of tableData.rows")
                        td(class="text-center")
                            div(v-if="idx === 2") {{ category }}
                            div(class="py-2 my-0 boder--bottom" v-if="idx === 4") &nbsp;
                            div(v-else) &nbsp;
                        td(class="py-2 text-center boder--bottom") {{ duration }}
                        td(class="text-center py-2 boder--bottom" v-for="td of tableData.header.slice(2)" :title="td")
                            span(v-if="typeof (tableData.data[category][duration][td]) === 'number'") {{ numberFormat(tableData.data[category][duration][td].toFixed(2).replace(/[.,]00$/, "")) }}
                            span(v-else) {{ tableData.data[category][duration][td] }}
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name: "WeeklySummaryTable",
    components: { Loader },
    props: {
        date: {
            type: String,
            required: true
        },
        filter: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loader: false,
            tableData: {
                header: [],
                categories: [],
                rows: [],
                data: []
            }
        }
    },
    created() {
        this.getData()
    },
    beforeMount() {
        this.processTableData()
    },
    mounted() {
    },

    methods: {
        getData() {
            const url = "/api/ga/weekly/data";
            const payload = {
                end_date: moment(this.date).format("YYYY-MM-DD"),
                filter: this.filter,
                size: 10
            };
            this.loader = true;
            axios.get(url, { params: payload }).then(res => {
                this.processTableData(res.data);
                this.loader = false;
            }).catch(err => {
                console.log(err)
                this.loader = false;
            })
        },
        processTableData(data) {
            this.tableData.header = data.row;
            this.tableData.rows = data.cols;
            this.tableData.categories = Object.keys(data.data).sort();
            this.tableData.data = data.data;
        },
        numberFormat(num) {
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },
    }
}
</script>
<style scoped>
</style>