import Loader from '@/components/Loader/Loader.vue';
import axios from 'axios';
import moment from 'moment';

export default {
    name: 'MediaReport',
    components: {
        Loader
    },
    data() {
        return {
            dataLoader: false,
            dateRange: {
                startDate: moment().subtract(1, 'month'),
                endDate: moment()
            },
            current: '',
            tabsArr: [],
            allTabData: [],
            btn: "Overall",
            header: [],
            info: [],
            tab: "Monthly Campaign",
            monthlyCamp: true
        }
    },
    mounted() {
        // this.initilizeTabs();
        this.formatDateRange();
        this.getData();
        
    },
    methods: {
        // initilizeTabs() {
        //     // create an array of objects with the id, trigger element (eg. button), and the content element
        //     let tabElements = [];
        //     this.tabsArr.map(tab => {
        //         tabElements.push(
        //             {
        //                 id: tab,
        //                 triggerEl: document.getElementById(tab),
        //                 targetEl: document.getElementById(`${tab}_page`)
        //             }
        //         )
        //     })
        //     // options with default values
        //     const options = {
        //         defaultTabId: this.tabsArr[0],
        //         activeClasses: 'text-primary_brand hover:text-primary_brand border-primary_brand-80',
        //         inactiveClasses: 'text-gray-500 hover:text-gray-600 border-base_color-20 hover:border-gray-300',
        //     };
        //     this.tabObject = new Tabs(tabElements, options);
        //     console.log(this.tabObject)
        // },
        // initilizeTabs(){
        //     const tabElements=[
        //         {
        //             id: 'Monthly Campaign',
        //             triggerEl:document.querySelector('#MonthlyCampaign'),
        //             targetEl:document.querySelector('#monthlyCamp')
        //         },
        //         {
        //             id: 'Overall',
        //             triggerEl:document.querySelector('#Overall'),
        //             targetEl:document.querySelector('#section')
        //         },
        //         {
        //             id: 'GoogleAdsDiscovery',                           
        //             triggerEl:document.querySelector('#GoogleAdsDiscovery'),
        //             targetEl:document.querySelector('#section')
        //         },
        //         {
        //             id: 'GoogleAdsEcomDisplayRemarketing',
        //             triggerEl:document.querySelector('#GoogleAdsEcomDisplayRemarketing'),
        //             targetEl:document.querySelector('#section')
        //         },
        //         {
        //             id: 'GoogleAdsEcomSearch',
        //             triggerEl:document.querySelector('#GoogleAdsEcomSearch'),
        //             targetEl:document.querySelector('#section')
        //         },
        //         {
        //             id: 'GoogleAdsEcomSearchRemarketing',
        //             triggerEl:document.querySelector('#GoogleAdsEcomSearchRemarketing'),
        //             targetEl:document.querySelector('#section')
        //         },
        //         {
        //             id: 'Achieved',
        //             triggerEl:document.querySelector('#Achieved'),
        //             targetEl:document.querySelector('#section')
        //         },
        //     ];
        //     const options= {
        //         defaultTabId:'Overall',
        //         activeClasses:'text-primary_brand hover:text-primary_brand border-primary_brand-80',
        //         inactiveClasses:'text-gray-500 hover:text-gray-600 border-base_color-20 hover:border-gray-300',
        //     };
        //     this.tabObject=new Tabs(tabElements,options);
        // },
        formatNumber(num){
            if(isNaN(num)) return num
			let num1 = Number(num).toFixed(2);
			let num2 = Number(num1).toLocaleString();
			return num2
        },
        btnClick(str){
            this.btn = str
            this.processTable();
        },
        tabClick(str){
            if(str != "Monthly Campaign"){
                this.monthlyCamp = false;
                this.btn = "Achieved"
            }
            else{
                this.monthlyCamp = true;
                this.btn = "Overall";
            }
            this.tab = str;
            this.processTable();
        },
        formatDateRange(){
            if(this.dateRange.startDate) this.dateRange.startDate=moment(this.dateRange.startDate).format("YYYY-MM-DD");
            if(this.dateRange.endDate) this.dateRange.endDate=moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates(){
            this.formatDateRange();
            this.getData();
        },
        getData() {
            this.dataLoader=true;
            // this.header = [];
            // this.info = [];
            const url = "/api/summary/all_campaigns_graph/"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }
            axios.get(url, { params: payload }).then(res => {
                let resData = res.data;
                this.allTabData = resData;
                this.dataLoader = false;
                // console.log(this.header);
                // console.log("Infoo",this.info);
                // this.$nextTick(() => {
                //     this.processTable();
                // })
                this.processTable();
                // for (let tab of Object.keys(resData)) {
                //     this.tabsArr.push(tab)
                // }
                    // this.current = this.tabsArr[0]
                    // this.initilizeTabs();
            }).catch(err => {
                console.log(err);
                this.dataLoader = false;
            })
        },
        processTable(){
            if(this.info.length) $('#mediaReportTable').DataTable().destroy();
            this.header=this.allTabData.rows[this.btn];
            this.info = this.allTabData.data[this.tab][this.btn];
             let tableID = '#mediaReportTable'
             var search = require("@/assets/Icons_SVG/Search.svg");
             this.$nextTick(()=>{
                $(tableID).DataTable({
                    searching: true,
                        info: false,
                        initComplete: function(settings, json) {
                            $('.dataTables_scrollBody tfoot tr').css({visibility:'collapse'});
                            $('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
                            $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                        },
                        language: {
                            search: "",
                            searchPlaceholder: "Search...",
                            paginate: {
                                first: "",
                                previous: "<",
                                next: ">",
                                last: "",
                                
                            },
                            lengthMenu: "<h5>Show entries</h5> _MENU_",
                        },
                    })
                    $(tableID + "_filter > label").append(
                        `<div class='search_icon'><img src='${search}' /></div>`
                    );
             })
        }
    },
}