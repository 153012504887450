import axios from 'axios'
import Header from '@/components/Header/Header.vue'
import drillPieChart from '@/components/charts/drillPieChart.vue'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'

//api -:
// /api/adword/detailed?table_type=geo&start_date=2022-03-01&end_date=2022-04-01&type=city :- get Top Chart data
// /api/adword/locationwise_data?start_date=2022-09-27&end_date=2022-10-27&level=city&level_id=1007768&filter=device :- get First Drilldown Data
// /api/adword/locationwise_devicecampaignwise_data?start_date=2023-03-01&end_date=2023-03-01&level=city&level_id=1007768&filter=campaignwise_device&filter_id=1361692260 :- get Second Drilldown Data

export default{
    name:'DeviceSummary',
    components: {
        drillPieChart,
        Header,
        Loader
    },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment()
            },
            loader: false,
            filterType: 'device',
            dropdownValue1: {id: 'clicks', text: 'Clicks'},
            dropdownValue2: {id: 'clicks', text: 'Clicks'},
            dropdownOptions: [
                {id: 'impressions', text: 'Impressions'},
                {id: 'clicks', text: 'Clicks'},
                {id: 'cost', text: 'Cost (₹)'},
                {id: 'ctr', text: 'CTR (%)'},
                {id: 'cpc', text: 'CPC (Rs)'},
                {id: 'conversions', text: 'Conversions'},
                {id: 'cost_conversion', text: 'Cost/Conversion (Rs)'}
            ],
            chartData1: {
                name: '',
                seriesData: []
            },
            chartData2: {
                name: '',
                seriesData: []
            },
            topData: [],
            firstDrillData: [],
            secondDrillData: [],
            drilldownLevel: 0,
            cityDropdownOptions: [],
            selectedLevelId1: '',
            selectedLevelId2: ''
        }
    },

    mounted() {
        this.init();
    },
    computed: {
        chartHeading(){
            switch (this.drilldownLevel) {
                case 0:
                    return "Top Cities"
                case 1:
                    if(this.filterType == 'device') return "Citywise Top devices"
                    else return "Citywise Top campaigns"
                case 2:
                    if(this.filterType == 'device') return "Citywise with Device wise Top Campaigns"
                    else return "Citywise with Campaign wise Top Devices"
                default:
                    break;
            }
        }
    },

    methods: {
        init(){
            this.formatDateRange();
            this.getMainData();
        },

        formatDateRange(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates(){
            this.formatDateRange();
            this.getMainData();
        },          

        processDropdownChange(chart){
            if(this.drilldownLevel){
                this.processDropdownDrillChart(chart);
            }else{
                this.processMainChart();
            }
        },
        processCityDropdownChange(chart){
            if (this.drilldownLevel == 1) this.getfirstDrillData(chart)
        },
        processMainChart(){
            const topData1 = this.topData.sort((a, b) => b[this.dropdownValue1.id] - a[this.dropdownValue1.id]).slice(0, 10);
            const topData2 = this.topData.sort((a, b) => b[this.dropdownValue2.id] - a[this.dropdownValue2.id]).slice(0, 10);

            const seriesData1 = [];
            const seriesData2 = [];

            topData1.forEach(data => {
                seriesData1.push({name: data.level, value: data[this.dropdownValue1.id], level_id: data.level_id});
            });
            topData2.forEach(data => {
                seriesData2.push({name: data.level, value: data[this.dropdownValue2.id], level_id: data.level_id});
            });

            this.chartData1 = {
                name: this.dropdownValue1.text,
                seriesData: seriesData1,
            };
            this.chartData2 = {
                name: this.dropdownValue2.text,
                seriesData: seriesData2,
            };

            if(this.dropdownValue1.id === 'clicks')
            {
                topData1.forEach(data => {
                    this.cityDropdownOptions.push({id: data.level_id, text: data.level})
                });
            }
        },

        processDropdownDrillChart(chart){
            const seriesData1 = [];
            const seriesData2 = [];

            this.firstDrillData.forEach(data => {
                switch (chart) {
                    case 'chart1':
                        if (this.filterType === 'device'){
                            seriesData1.push({name: data.device, value: data[this.dropdownValue1.id]})
                        }else{
                            seriesData1.push({name: data.campaign_name, value: data[this.dropdownValue1.id]})
                        }
                        break;
                    case 'chart2':
                        if (this.filterType === 'device'){
                            seriesData2.push({name: data.device, value: data[this.dropdownValue2.id]})
                        }else{
                            seriesData2.push({name: data.campaign_name, value: data[this.dropdownValue2.id]})
                        }
                        break;
                }
                
            });

            switch (chart) {
                case 'chart1':
                    this.chartData1 = {
                        name: this.dropdownValue1.text,
                        seriesData: seriesData1,
                    };
                    break;
                case 'chart2':
                    this.chartData2 = {
                        name: this.dropdownValue2.text,
                        seriesData: seriesData2,
                    };
                    break;
            }
        },
        processClickDrillChart(){
            const seriesData1 = [];
            const seriesData2 = [];

            this.firstDrillData.forEach(data => {
                if (this.filterType === 'device'){
                    seriesData1.push({name: data.device, value: data[this.dropdownValue1.id], level_id: data.device})
                    seriesData2.push({name: data.device, value: data[this.dropdownValue2.id], level_id: data.device})
                }else{
                    seriesData1.push({name: data.campaign_name, value: data[this.dropdownValue1.id], level_id: data.campaign_id})
                    seriesData2.push({name: data.campaign_name, value: data[this.dropdownValue2.id], level_id: data.campaign_id})
                }
            });

            this.chartData1 = {
                name: this.dropdownValue1.text,
                seriesData: seriesData1,
            };
            this.chartData2 = {
                name: this.dropdownValue2.text,
                seriesData: seriesData2,
            };
        },
        processChartClick(val){
            switch (this.drilldownLevel) {
                case 0:
                    this.selectedLevelId1 = val;
                    this.selectedLevelId2 = val;
                    this.getfirstDrillData('');
                    break;
                case 1:
                    this.getSecondDrillData(val);
                default:
                    break;
            }
        },
        getMainData(){
            this.loader = true
            const url = `/api/adword/detailed?table_type=geo&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&type=city`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.topData = resData.data;
                this.$nextTick(() => {
                    this.processMainChart();
                    this.loader = false;
                });
            }).catch((err) => {
                console.log(err)
                this.loader = false;
            })
        },
        getfirstDrillData(chart){
            this.loader = true
            let level_id;

            if (this.drilldownLevel) {
                switch(chart) {
                    case 'chart1': 
                        level_id = this.selectedLevelId1;
                        break;
                    case 'chart2':  
                        level_id = this.selectedLevelId2;
                        break;
                }
            }else{
                level_id = this.selectedLevelId1;
            }
            
            const url = `/api/adword/locationwise_data?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&level=city&level_id=${level_id}&filter=${this.filterType}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.firstDrillData = resData.data;
                this.$nextTick(()=>{
                    if (this.drilldownLevel == 0){
                        this.processClickDrillChart();
                        this.drilldownLevel = 1;
                    }else{
                        this.processDropdownDrillChart(chart);
                    }
                    this.loader = false;
                });
            }).catch((err) => {
                console.log(err)
                this.loader = false;
            })
        },
        getSecondDrillData(filterId){
            let filter;
            if(this.filterType == 'device') filter = 'devicewise_campaign'
            else filter = 'campaignwise_device'

            let url1 = '/api/adword/locationwise_devicecampaignwise_data?'
            url1 += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            url1 += `&level=city&level_id=${this.selectedLevelId1}&filter=${filter}&filter_id=${filterId}`

            let url2 = '/api/adword/locationwise_devicecampaignwise_data?'
            url2 += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            url2 += `&level=city&level_id=${this.selectedLevelId2}&filter=${filter}&filter_id=${filterId}`

            axios.get(url1).then((Response)=>{
                const resData = Response.data;
                console.log(resData);
            }).catch((err)=>{
                console.log(err);
            });
            axios.get(url2).then((Response)=>{
                const resData = Response.data;
                console.log(resData);
            }).catch((err)=>{
                console.log(err);
            });
        }
    },
}
