import axios from 'axios'
import moment from 'moment'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import Loader from '@/components/Loader/Loader.vue'

//api :-
// TopCardsData -> '/api/amazon/display/account_bulk?'
// TableChartData -> '/api/amazon/display/histogram?'

export default{
    name: 'AmazonDisplay',
    components: {
        dualAxisChart,
        Loader
    },
    data(){
        return{
            dataLoader: false,
            dateRange:{
                startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            compDateRange: {
                startDate: moment().subtract(61, 'days').format("YYYY-MM-DD"),
                endDate: moment().subtract(31, 'days').format("YYYY-MM-DD")
            },
            compareFlag: false,
            topBoxesData: [],
            timelineData: {
                timeInterval: 'day',
                intervalOptions: ['day', 'month'],
                tableHeader: [
                    { key: "Date", label: "Date" },
                    { key: "Purchases", label: "Purchases" },
                    { key: "ATCR %", label: "ATCR (%)" },
                    { key: "ATC", label: "ATC" },
                    { key: "DPV", label: "DPV" },
                    { key: "Product Sales", label: "Product Sales" },
                    { key: "CTR %", label: "CTR (%)" },
                    { key: "Units Sold", label: "Units Sold" },
                    { key: "Total Product Sales", label: "Total Product Sales" },
                    { key: "Click Through", label: "Click Through" },
                    { key: "DPVR %", label: "DPVR (%)" },
                    { key: "Impressions", label: "Impressions" },
                    { key: "Total Cost", label: "Total Cost" }
                ],
                tableData: [],
                allChartData: {},
                chartData: {
                    xAxis: [],
                    seriesData: []
                },
                chartDropdown: {
                    options: [],
                    barChartKey: 'Purchases',
                    lineChartKey: 'ATCR %'
                }
            },
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        // Initialize
        init(){
            this.formatDateRange();
            this.dataAPIs();
        },
        // Format Dates
        formatDateRange(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.compDateRange.startDate = moment(this.compDateRange.startDate).format('YYYY-MM-DD');
            this.compDateRange.endDate = moment(this.compDateRange.endDate).format('YYYY-MM-DD');
        },
        formatNumber(num){
            if(isNaN(num)) return num
			let num1 = Number(num).toFixed(2);
			let num2 = Number(num1).toLocaleString();
			return num2
        },
        dataAPIs(){
            this.getTopBoxesData();
            this.getTimelineData();
        },
        formatDates(){
            this.formatDateRange();
            this.destroyAllTables();
            this.dataAPIs();
        },
        destroyAllTables(){
            $('#timeline-tbl').DataTable().destroy();
        },
        processTimelineTable(){
            var search = require("@/assets/Icons_SVG/Search.svg");
            const timelineTable = $('#timeline-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: true,
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#timeline-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    search: "",
                    searchPlaceholder: "Search",
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                }
            });
            $("#timeline-tbl" + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );

            // timelineTable.on('draw.dt', () => {
            //     let dateData, purchaseData, atcrData, atcData, dpvData, prodSaleData, ctrData, unitSoldData, totalProdSaleData, clickThroughData, dpvrData, impressionData, totalCostData;
            //     // $('#month-tbl_paginate').remove();
            //     // $('#month-tbl_length').remove();
            //     $('.dataTables_filter input').attr('type', 'text');
            //     dateData = [];
            //     purchaseData = []; 
            //     atcrData = []; 
            //     atcData = [];
            //     dpvData = [];
            //     prodSaleData = [];
            //     ctrData = [];
            //     unitSoldData = [];
            //     totalProdSaleData = [];
            //     clickThroughData = [];
            //     dpvrData = [];
            //     impressionData = [];
            //     totalCostData = [];
                
            //     Array.from(timelineTable.rows({filter: 'applied'}).data()).forEach((value, index) => {
            //             dateData.push(value[0]);
            //             purchaseData.push(Number(value[1].replace(/,/g, '')));
            //             atcrData.push(Number(value[2].replace(/,/g, '')));
            //             atcData.push(Number(value[3].replace(/,/g, '')));
            //             dpvData.push(Number(value[4].replace(/,/g, '')));
            //             prodSaleData.push(Number(value[5].replace(/,/g, '')))
            //             ctrData.push(Number(value[6].replace(/,/g, '')))
            //             unitSoldData.push(Number(value[7].replace(/,/g, '')))
            //             totalProdSaleData.push(Number(value[8].replace(/,/g, '')))
            //             clickThroughData.push(Number(value[9].replace(/,/g, '')))
            //             dpvrData.push(Number(value[10].replace(/,/g, '')))
            //             impressionData.push(Number(value[11].replace(/,/g, '')))
            //             totalCostData.push(Number(value[12].replace(/,/g, '')))
            //     });
            //     this.timelineData.currentTableState = {
            //         "date": dateData,
            //         "Purchases": purchaseData,
            //         "ATCR %": atcrData,
            //         "ATC": atcData,
            //         "DPV": dpvData,
            //         "Product Sales": prodSaleData,
            //         "CTR %": ctrData,
            //         "Units Sold": unitSoldData,
            //         "Total Product Sales": totalProdSaleData,
            //         "Click Through": clickThroughData,
            //         "DPVR %": dpvrData,
            //         "Impressions": impressionData,
            //         "Total Cost": totalCostData,
            //     };  
            //     this.processTimelineGraph();
            // });
            // $('#timeline-tbl').DataTable().draw();
        },
        processTimelineGraph(){
            this.timelineData.chartData = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.timelineData.allChartData.Date;

            const seriesData = [];
            const barLabel = this.timelineData.chartDropdown.barChartKey.toUpperCase()
            const lineLabel = this.timelineData.chartDropdown.lineChartKey.toUpperCase()

            seriesData.push({name: barLabel, data: this.timelineData.allChartData[this.timelineData.chartDropdown.barChartKey]})
            seriesData.push({name: lineLabel, data: this.timelineData.allChartData[this.timelineData.chartDropdown.lineChartKey]})

            this.timelineData.chartData = {
                xAxis: xaxis,
                seriesData: seriesData
            }
        },
        getTopBoxesData(){
            this.dataLoader = true;
            let url = '/api/amazon/display/account_bulk?'
            url += `first_start_date=${this.compDateRange.startDate}&first_end_date=${this.compDateRange.endDate}&last_start_date=${this.dateRange.startDate}&last_end_date=${this.dateRange.endDate}`

            axios.get(url).then((res)=>{
                const resData = res.data.data;
                resData.forEach(element => {
                    element.diff = this.formatNumber(element.diff);
                    element.value = this.formatNumber(element.value);
                    element.name = element.name.toUpperCase()
                });
                this.dataLoader = false;
                this.topBoxesData = resData;
            }).catch(err => console.log(err))
        },
        getTimelineData(){
            $('#timeline-tbl').DataTable().destroy();
            this.dataLoader = true;
            let url = '/api/amazon/display/histogram?'
            url += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&timeline=${this.timelineData.timeInterval}`

            axios.get(url).then((res)=>{
                const resData = res.data.data;
                this.timelineData.tableData = resData;
                const allKeys = Object.keys(resData[0])
                const allKeywiseData = {}

                allKeys.forEach(key => {
                    allKeywiseData[key] = resData.map(data => data[key])
                });

                this.timelineData.chartDropdown.options = allKeys
                this.timelineData.allChartData = allKeywiseData

                this.$nextTick(()=>{
                    this.processTimelineTable();
                    this.processTimelineGraph();
                    this.dataLoader = false;
                });
            }).catch(err => console.log(err))
        }
    }

}