<template lang="pug">
div
    div(v-if="loader")
        Loader 
    div(v-if="seriesData.length" :id="id")
        highcharts(:options="chartData")
    div(v-else) No data available in the chart!
</template>
<script>
import Highcharts from 'highcharts';
import Loader from '../Loader/Loader.vue';
require('highcharts/modules/funnel.js')(Highcharts);
export default {
    name: 'funnelChart',
    components: {
        Loader,
    },
    props: {
        seriesData: {
            type: Array,
            required: true
        },
        id: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            loader: false,
            chartData: {},
            // color_codes: [
            //     // "#ffd7d8",
            //     "#899efc",
            //     "#eaa9fb",
            //     "#ffa2a4",
            //     "#ddc7f8",
            //     "#ff8392",
            //     "#dbd0bf",
            //     "#ebefbf",
            //     "#d7edbb",
            //     "#9ddec5",
            //     "#ffd0b1",

            //     // "#4D6B68",
            //     // "#50ABA0",
            //     // "#AB6C50",
            //     // "#564A45",
            //     // "#2A3332",
            // ],

            color_codes: [
                "#47A694",
                "#54736D",
                "#265950",
                "#79A69E",
                "#317367",
                "#54736D",
                "#52BFAB",
                "#84B5AC",
                "#5F827C",
                "#388273",
                "#59CFB7",
            ]

        }
    },
    mounted() {
        this.initiate()
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.initiate()
            }
        }
    },
    methods: {
        initiate() {
            this.loader = true
            this.chartData = {
                chart: {
                    type: 'funnel',
                    credits: false,
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 50,
                    style: {
                        color: '#065559'
                    }
                },
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    funnel: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        colors: this.color_codes,
                        showInLegend: true
                    },
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.value:,.0f}</b><br/> ({point.name})',
                            softConnector: true
                        },
                        center: ['40%', '50%'],
                        neckWidth: '30%',
                        neckHeight: '30%',
                        width: '80%'
                    }
                },
                legend: {
                    enabled: false
                },
                series: [{
                    // name: ,
                    /*         data: [
                                ['Website visits', 15654],
                                ['Downloads', 4064],
                                ['Requested price list', 1987],
                                ['Invoice sent', 976],
                                ['Finalized', 846]
                            ] */
                    data: this.seriesData[0].data
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 200
                        },
                        chartOptions: {
                            chart: {
                                height: 200
                            },
                            subtitle: {
                                text: null
                            },
                            navigator: {
                                enabled: false
                            },
                            // plotOptions: {
                            //     series: {
                            //         dataLabels: {
                            //             inside: true
                            //         },
                            //         center: ['50%', '50%'],
                            //         width: '100%'
                            //     }
                            // }
                        }
                    }]
                }
            }
            this.loader = false
        }
    }
}
</script>