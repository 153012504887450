<template lang="pug">
div
    div(v-if="loader")
        Loader 
    div(v-if="seriesData[0].data.length" :id="id")
        highcharts(:options="chartData")
    div(v-else)
        div.text-primary_brand.text-lg.font-medium.ml-4.mb-4 {{ seriesData[0].name.toUpperCase() }}
        div.p-4.mb-4.mx-3.text-sm.text-red-800.rounded-lg.bg-red-50
            span.font-medium No Data Available in Chart!
</template>
<script>
import Loader from '../Loader/Loader.vue';
export default {
    name: 'pieChart',
    components: {
        Loader,
    },
    props: {
        seriesData: {
            type: Array,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        legendAlignment: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            loader: false,
            chartData: {},
            color_codes: [
                "#ffa2a4",
                "#899efc",
                "#eaa9fb",
                "#ffd7d8",
                "#ddc7f8",
                "#ff8392",
                "#dbd0bf",
                "#ebefbf",
                "#d7edbb",
                "#9ddec5",
                "#ffd0b1",
            ]
        }
    },
    mounted() {
        this.initiate()
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.initiate()
            }
        }
    },
    methods: {
        initiate() {
            this.loader = true
            let self = this
            this.chartData = {
                chart: {
                    plotBackgroundColor: "white",
                    plotShadow: false,
                    type: 'pie',
                    credits: false,
                    backgroundColor: '#fff',
                    borderRadius: 10
                },
                title: {
                    text: this.seriesData[0].name.toUpperCase(),
                    align: "left",
                    style: {
                        fontSize: 18,
                        color: "#065559",
                        fontWeight: '500',
                        fontFamily: "Poppins, sans-serif"
                    },
                },
                credits: {
                    enabled: false,
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                tooltip: {
                    formatter() {
                        var mytooltip = "";
                        mytooltip +=
                            '<span style="font-size:22px;color:' +
                            this.point.color +
                            '">\u25CF</span> ';
                        mytooltip += this.point.name;
                        mytooltip +=
                            " : <b> " + self.seriesData[0].prefix +' '+
                            this.point.y +' '+self.seriesData[0].suffix+
                            " (" +
                            this.point.percentage.toFixed() +
                            "%)</b> <br/>";
                        return mytooltip;
                    },
                    shape: 'rect',
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        colors: this.color_codes,
                        dataLabels: {
                            enabled: true,
                            format: '{point.percentage:.1f} %',
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 0.5
                            },
                        },
                        showInLegend: true
                    },
                    series:{
                        innerSize: '50%',

                    }
                },
                legend: {
                    align: self.legendAlignment ? self.legendAlignment : "left",
                    layout: "horizontal",
                    itemStyle: {
                        fontSize: 11,
                        fontWeight: 1,
                    },
                    maxHeight: 65,
                },
                series: this.seriesData
            }
            this.loader = false
        }
    }
}
</script>