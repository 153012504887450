import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import { generateCodeFrame } from 'vue-template-compiler';
import { handler } from 'flowbite/plugin';

export default {
    name: "AllCreatives",
    components: {
        Loader, dualAxisChart
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment().subtract(1, 'week').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'week',
                    label: 'Week'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'year',
                    label: 'Year'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            },
            selectedLineGraphMetric:'ROAS',
            selectedBarGraphMetric:'Purchase',
            graphDropdownMetric:[],
            timeLineGraphData:{},
            isMetricDropdown:false,
            metrics : [],              
            viewAllCampaignData:'',
            viewMeteCreativeTimelineGraphData:'',
            tableHeader:[],
            tableData:[]
        }
    },
    created() {
        window.addEventListener("click", this.close)
    },
    mounted() {
        // this.getSavedCreatives();
        this.dateRange.startDate = this.$store.state.filter.startDate
        this.dateRange.endDate = this.$store.state.filter.endDate,
        this.getAllCreativeData();
        this.getMeteCreativeTimelineGraphData();
    },
    watch:{
        metrics:{
            deep:true,
            handler(){
                this.selectMetric();
            }
        }
    },

    beforeDestroy() {
        window.removeEventListener("click", this.close)
    },

    methods: {
        selectMetric(metric){
            this.tableHeader = this.metrics.filter(data => data.isAvailable);
            this.$store.dispatch('setFBCreativeMetric',this.tableHeader)
            if ($.fn.DataTable.isDataTable('#reports_table')) {
                $('#reports_table').DataTable().destroy();
            }
            this.$nextTick(() => {
                this.processTable('#reports_table', 1);
            });

        },

        removeSelectedMetric(idx){
            this.metrics[idx].isAvailable = false
        },
        applyFilter() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.$store.dispatch('setDate',this.dateRange)
            this.getAllCreativeData();
            this.getMeteCreativeTimelineGraphData();
        },
      
       

        setInterval(selectedInterval){
            this.selectedInterval= selectedInterval
            this.getMeteCreativeTimelineGraphData();
        },
        toggleAdMetricDropdown() {
            if (this.isMetricDropdown) {
                this.isMetricDropdown = false
            }
            else {
                this.isMetricDropdown = true
            }
        },

        close(event) {
            if(this.metrics.length){
                if (!this.$refs.metricDropdownRef.contains(event.target)) {
                    this.isMetricDropdown = false
                }  
            }

        },
         getAllCreativeData(){
            this.loader = true;
            this.viewAllCampaignData = '';
            this.tableData = ''
            $('#reports_table').DataTable().destroy();
            const url = "/api/v1/meta/creativedatanew?start_date="+this.dateRange.startDate+'&end_date='+this.dateRange.endDate
            axios.get(url).then(res => {
                this.tableData= res.data
                this.viewAllCampaignData = this.tableData;
                this.generateMetricsForFilter();
                this.$nextTick(() => {
                    this.processTable('#reports_table')
                })
                this.loader = false;
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })
        },
        
        generateMetricsForFilter(){
            let selectedMetrics= this.$store.state.selectedFBCreativeMetric.map(data=>data.label)
            this.metrics  = this.viewAllCampaignData.headers.map(data=>{
                return {
                    isAvailable : selectedMetrics.includes(data.value)?true:false,
                    label: data.value,
                    key:data.key
                }
            })
            this.tableHeader = selectedMetrics
            this.$store.dispatch('setFBCreativeMetric',this.tableHeader)
        },

        getMeteCreativeTimelineGraphData(){
            this.loader = true;
            this.viewMeteCreativeTimelineGraphData = ''
            const url = "/api/v1/meta/creativetimleine/graph"
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                interval: this.selectedInterval.key
            }
            axios.post(url, payload).then(res => {
                this.loader = false;
                this.viewMeteCreativeTimelineGraphData = res.data.data
                this.graphDropdownMetric = Object.keys(res.data.data[0]).filter((value) => value != "Timestamp");
                this.selectedLineGraphMetric = this.graphDropdownMetric[0]
                this.selectedBarGraphMetric = this.graphDropdownMetric[1]
                this.processGraphData();
            }).catch(err => {
                this.loader = false;

                console.log("Error in getting saved reports", err)
            })
        },
        
        processGraphData(){
            this.timeLineGraphData={
                xAxis:[],
                disableYLabel:true,
                seriesData:[{
                    name: this.selectedLineGraphMetric,
                    data: []
                },
                {
                    name: this.selectedBarGraphMetric,
                    data: []
                }
                ]
            }
            if(this.viewMeteCreativeTimelineGraphData){
                this.viewMeteCreativeTimelineGraphData.forEach((data) => {
                    this.timeLineGraphData.xAxis.push(data.Timestamp);
                    this.timeLineGraphData.seriesData[0].data.push(data[this.selectedLineGraphMetric]);
                    this.timeLineGraphData.seriesData[1].data.push(data[this.selectedBarGraphMetric]);
                })
            }
        },

        processTable(id) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                scrollCollapse: true,
                lengthChange: true,
                ordering:false,
                // sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },

        abbreviateNumber(value) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },

        formatNumber(num) {
            if (isNaN(num)) { return num }
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}