<template lang='pug'>
div#customdata(style='margin-bottom:4em')
    div(v-if="is_admin") 
        div(v-if='loader',style="position:fixed")
            Loader
        div(v-else)
            div.w-card.m-3.flex.items-center.flex-col.gap-3
                h3(class="2xl:text-xl xl:text-base font-semibold text-primary_brand") Uplod Data
                div.customMultiselect.w-96
                    multiselect(
                        v-model="selectedAdv"
                        :options='advertisers'
                        :searchable="false"
                        :show-labels="false" 
                        :multiple="false"
                        :allow-empty="true"
                        open-direction="bottom"
                        track-by="id"
                        label="name"
                        placeholder="Select Advertiser"
                        )
                div
                    input(name="report",id="customdata_csvupload" type="file" class="bg-base_color-60 mr-3 hover:bg-base_color-80 text-white rounded" accept=".csv" @change="uploadFile($event)")
                div
                    button(@click="upload", type="button" class="px-2 py-1.5 mt-3 w-52 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white") Upload  
        
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue'
import Vuex from 'vuex';

export default {
    name: 'CustomData',
    computed: Vuex.mapState(['user', 'menu']),
    components: {
        Loader
    },
    data() {
        return {
            is_admin: false,
            date: {
                from: moment().subtract(31, 'days').format("YYYY-MM-DD"),
                to: moment().subtract(1, 'days').format("YYYY-MM-DD"),
                timescale: {}
            },
            advertisers: [],
            selectedAdv: null,
            file: null,
            loader: false
        };
    },
    created() {
        const user = JSON.parse(localStorage.userData).data.username
        if (user === 'admin') {
            this.is_admin = true;
        }
    },
    mounted() {
        this.getAdvertiser();
    },
    methods: {
        getAdvertiser() {
            this.loader = true;
            axios.get("/api/adword/accounts")
                .then(response => {
                    this.advertisers = response.data.data;
                    this.loader = false;
                })
                .catch(error => {
                    console.error("Error fetching advertisers:", error);
                    this.loader = false;
                });
        },
        uploadFile(event){
            this.file = event.target.files[0]
        },
        upload() {
            this.loader = true;
            setTimeout(() => {
                if (this.file && this.selectedAdv) {
                    const formData = new FormData();
                    formData.append("account_id", this.selectedAdv.id);
                    formData.append("file", file);

                    axios.post("/api/adword/custom_report", formData)
                        .then(response => {
                            console.log(response.data);
                            this.file = null;
                            alert('uploaded');
                            this.loader = false;
                        })
                        .catch(error => {
                            console.error("Error uploading:", error);
                            alert('Error uploading');
                            this.loader = false;
                        });
                } else {
                    alert('Check input');
                }
            }, 100);
        }
    }
};

</script>