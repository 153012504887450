import axios from "axios";
export default{
    name : 'UserSettings',

    data(){
        return{
            email:'',
            mobile:'',
            oldPassword:'',
            newPassword:'',
            cPassword:'',
            mobileError:false,
            confirmPasswordError:false,
            alertPopup:undefined,
        }    
    },
    mounted() {
        this.submit();
    },

    methods: {
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validNumber(number){
            var no=/^[0-9]{10}$/;
            return no.test(number);
        },
        submit(){
            let url= "/api/users/my"
                axios.get(url).then((Response) => {
                    const resData=Response.data
                    this.mobile=resData.data.mobile
                    this.email = resData.data.email
                    console.log(resData)
                }).catch(err => console.log(err))
        },
        updatePhoneNumber(){
            let url= "/api/users/my"
            let payload={
                "mobile":this.mobile,
                "email":this.email,
                
            };
            console.log("payload", payload);
            if (!this.validNumber(this.mobile) || !this.mobile) 
            {
                    this.mobileError=true
                    return;
            }
            axios.put(url,payload).then((Response) => {
                this.submit()
                alert("Phone number updated!")
            }).catch(err => {
                alert(err)
            })
        },
        changePassword(){
            let url='/api/users/'
            let payload={
                "old_password":this.oldPassword,
                "password":this.newPassword,
            };
            if(this.newPassword!==this.cPassword){
                this.confirmPasswordError=true
            }
            {
                axios.put(url,payload).then((Response) => {
                    const resData=Response.data
                    alert("Password Updated",resData);
                }).catch(err => console.log(err))
                console.log(payload);
            }      
        }

    },
}