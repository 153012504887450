import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import { Modal } from 'flowbite';


// api :-
// Rule Listing Data -> '/api/amazon/budget/rule/list'
// Campaign Performace Data -> '/api/amazon/budget/camp/sample/history?'


export default{
    name: 'AmazonBudgetRule',
    components: {
        Loader,
        Modal
    },
    data(){
        return{
            dataLoader: false,
            ruleModal: undefined,
            recomModal: undefined,
            ruleListingData: {
                tableData : [],
                tableHeader: []
            },
            campaignData: {
                dropdownOptions: ['Sponsored Product', 'Sponsored Brand'],
                dropdownVal: 'Sponsored Product',
                tableData : [],
                tableHeader: []
            },
            ruleModalData: {
                name: '',
                type: 'schedule',
                campType: 'Sponsored Products',
                duration: {
                    startDate: moment().subtract(7, 'days').format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD")
                },
                recurrence: 'Daily',
                budgetIncrease: 0,
                typeOption: ['schedule', 'performace'],
                campTypeOption: ['Sponsored Products', 'Sponsored Brands'],
                recurrenceOption: ['Daily', 'Weekly']
            },
            recomModalData: {
                tableHeader: [{ key: 'campaign_name', label: 'Campaigns' }],
                tableData: [],
                eventHeader: [{  }]
            }
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        //Initialize
        init(){
            this.initializeModal();
            this.dataAPIs();
        },
        dataAPIs(){
            this.getRuleListData();
            this.getCampaignData();
        },
        initializeModal(){
            const $targetRule = document.getElementById('rule-modal');
            const $targetRecomm = document.getElementById('recomm-modal');
            const ruleOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                
            };
            const recomOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    this.ruleModal.show()
                },
                onShow: () => {
                    this.ruleModal.hide()
                }
            };
            this.ruleModal = new Modal($targetRule, ruleOptions);
            this.recomModal = new Modal($targetRecomm, recomOptions);
        },
        processRecomTable(){
            const recomTable = $('#recomm-tbl').DataTable({
                scrollY: 300,
                info: false,
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                },
                searching: false,
                paging: false
            });
        },
        getRuleListData(){
            this.dataLoader = true
            let url = '/api/amazon/budget/rule/list'
            axios.get(url).then((res)=>{
                const resData = res.data.data.data
                this.ruleListingData.tableHeader = resData.headers
                this.ruleListingData.tableData = resData.data
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getCampaignData(){
            this.dataLoader = true
            let url = '/api/amazon/budget/camp/sample/history?'
            url += `camp_type=${this.campaignData.dropdownVal}`
            axios.get(url).then((res)=>{
                const resData = res.data.data.data;
                this.campaignData.tableHeader = resData.header
                this.campaignData.tableData = resData.data
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getDurationRecomm(){
            $('#recomm-tbl').DataTable().destroy()
            this.dataLoader = true
            let url = '/api/amazon/budget/camp/list/recomm'
            axios.get(url).then((res)=>{
                const resData = res.data.data.data
                this.recomModalData.tableData = resData
                this.$nextTick(()=>{
                    this.processRecomTable()
                    this.recomModal.show()
                    this.dataLoader = false
                })
            }).catch(err => console.log(err))
        },
        getRecommEvents(campData){
            let url = '/api/amazon/budget/recomm/events/list'
            const payload  = { 
                camp_id: campData.campaign_id,
                camp_type: campData.camp_type
             }
             axios.post(url, payload).then((res)=>{
                const resData = res.data.data.data;
                console.log(resData);
             });
        }
    },
}