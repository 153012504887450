import axios from "axios";
import Toast from 'vue-toastification'

export default{
    name : 'RecoveryPassword',

    data(){
        return{
            username:'',
            email:'',
            mobile:'',
            password:'',
            cPassword:'',
            usernameError:false,
            emailError:false,
            mobileError:false,
            confirmPasswordError:false,
            alertPopup:undefined,
            resetToken: this.$route.params.id ,
            errorMsg: true,
            passwordEmpty: false,
            passwordLength:false
        }


        
    },
    mounted() {
        this.validateToken();
    },

    methods: {
        validateToken(){
            let self = this
            let url= "/api/users/validate/"
            let payload={
                "token": self.resetToken
            }
            axios.post(url,payload).then(function(res){
                self.errorMsg = false
            }).catch(function(err){
                self.errorMsg = true
            })
        },
        inputboxReset(){
            this.passwordLength= false
            this.passwordEmpty= false
        },
        postReset(){
            let self=this
            let url= "/api/users/reset/"
            let payload={
                "token":this.resetToken,
                "password":this.password,
            };
            if(this.password==''){
                this.passwordEmpty=true
                return false
            }
            if(this.password.length<6){
                this.passwordEmpty= false
                this.passwordLength = true
                return false
            }
            if(this.password!==this.cPassword){
                this.confirmPasswordError=true
            }
            if( this.confirmPasswordError==false)
            {
                axios.post(url,payload).then((Response) => {
                    self.password=''
                    self.cPassword=''
                    self.$toast.success("Updated", {
                        position: "bottom-center",
                        timeout: 1000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                      });
                      self.$router.push('/login')

                }).catch(function(err){
                    self.$toast.error("Token Expired", {
                        position: "bottom-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                      });
                })
            }      
        }    
    },
}