import moment from 'moment';
import axios from 'axios';
import Loader from "@/components/Loader/Loader.vue";
import lineChart from '@/components/charts/lineChart.vue';
import funnelChart from '@/components/charts/funnelChart.vue';
import pieChart from '@/components/charts/pieChart.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'

export default {
    name: "OverallSummary",
    components: {
        Loader,
        lineChart,
        funnelChart,
        pieChart,
        dualAxisChart,
    },
    data() {
        return {
            firstSectionLoader: false,
            demographicDataLoader: false,
            engagementLoader: false,
            catalogueLoader: false,
            fullSocialMediaLoader: false,
            fullSocialMediaOrganicLoader: false,
            fbSocialMediaOrganicLoader: false,
            igSocialMediaOrganicLoader: false,
            fbSocialMediaPaidLoader: false,
            igSocialMediaPaidLoader: false,
            socialMediaPaidLoader: false,
            socialMediaOrganicLoader: false,
            SEOOrganicLoader: false,
            SEOKeywordGrowthLoader: false,
            emailerLoader: false,
            adwordLoader: false,
            adwordFunnelLoader: false,
            dateRange: {
                startDate: moment().startOf('month').format("YYYY-MM-DD"),
                endDate: moment().subtract(1, 'day').format("YYYY-MM-DD")
            },
            firstSectionData: [],
            leadsData: [],
            topCatalogueData: [],
            engagementData: [],
            socialMedialFull: [],
            socialMedialOrganicFull: [],
            socialMedialOrganicFB: [],
            socialMedialOrganicIG: [],
            socialMedialPaidFB: [],
            socialMedialPaidIG: [],
            socialMediaPaid: [],
            socialMediaOrganic: [],
            SEOOrganic: [],
            SEOKeywordGrowth: [],
            demographicData: [],
            emailerData: [],
            adwordData: [],
            adwordFunnelData: [],
            adwordFunnelSeriesData: {},
            funnelSeriesData: [{ name: '', data: [] }],
            genderChartData: [],
            deviceChartData: [],
            eCatalogueChartData: [],
            viewCreativePerformanceData: {},
            viewCreativePerformanceDataHeader: [],
            viewDemandGeneration: {},
            tabs: {
                googleAnalytics: true,
                userBehaviour: false,
                socialMedia: false,
                paidCampaign: false,
                // socialOrganic: false,
                SEOTab: false,
                emailer: false
            },
            fbImpVsClicks: {
                xAxis: [],
                seriesData: []
            },
            fbClicksVsLeads: {
                xAxis: [],
                seriesData: []
            },
            adwordsImpVsClicks: {
                xAxis: [],
                seriesData: []
            },
            adwordsClicksVsConv: {
                xAxis: [],
                seriesData: []
            },
            intervals: [],
            selectedInterval: "MTD",
            selectedFBComp: "Impressions vs Clicks",
            selectedAdwordComp: "Impressions vs Clicks",
            viewGoogleAdwordCardData: {}
        }
    },
    mounted() {
        this.getDateRange();
        this.formatDateRange('MTD')
        // this.dataAPIs();
    },
    methods: {
        dataAPIs() {
            this.getEngagementData();
            this.getUsersSessions();
            this.getDemographicData();
            this.getTopCatalogueData();
            // this.getDemandConversion();
            // this.getCreativePerformance();
            // this.getFullSocialMedia();
            // this.getSocialMediaPaid();
            // this.getSocialMediaOrganic();
            // this.getSEOOrganic();
            // this.getSEOKeywordGrowth();
            // this.getEmailerData();
            // this.getAdwordData();
            // this.getAdwordFunnelData();
            this.getGoogleAdwordCardData();
            // this.getSocialMediaOrganicFull();
            // this.getSocialMediaOrganicFB();
            // this.getSocialMediaOrganicIG();
            // this.getSocialMediaPaidFB();
            // this.getSocialMediaPaidIG();
        },
        formatDateRange(interval) {
            this.selectedInterval = interval
            if (interval === 'MTD') {
                let currentDate = moment().format("YYYY-MM-DD");
                if (moment(currentDate).isSame(this.dateRange.startDate)) {
                    this.dateRange.startDate = moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
                    this.dateRange.endDate = moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
                } else {
                    this.dateRange.startDate = moment().startOf('month').format("YYYY-MM-DD");
                    this.dateRange.endDate = moment().subtract(1, 'day').format("YYYY-MM-DD");
                }
            } else {
                this.dateRange.startDate = moment(this.intervals[interval].start_date).format("YYYY-MM-DD")
                this.dateRange.endDate = moment(this.intervals[interval].end_date).format("YYYY-MM-DD")
            }
            this.$nextTick(() => {
                this.dataAPIs();
            })
        },
        formateMTDDate(date) {
            return moment(date).format("DD MMM")
        },
        getDateRange() {
            this.intervals = [];
            this.selectedInterval = "";

            axios.post("/api/v1/merino/previousmonths", {
                month_count: 3
            }).then(res => {
                console.log()
                this.intervals = res.data.data;
                this.intervals = {
                    ...this.intervals,
                    "MTD": {
                        start_date: moment().startOf('month').format("YYYY-MM-DD"),
                        end_date: moment().subtract(1, 'day').format("YYYY-MM-DD")
                    }
                };
                this.selectedInterval = "MTD";
            }).catch(err => {
                console.log(err);
            })

        },

        getGoogleAdwordCardData() {
            this.firstSectionLoader = true;
            this.viewGoogleAdwordCardData = {};

            const url = "/api/v1/merino/cmosummary/googleadwordcards"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.viewGoogleAdwordCardData = res.data.data
                this.firstSectionLoader = false;
            }).catch(err => {
                console.log(err);
                this.firstSectionLoader = false
            })
        },
        getUsersSessions() {
            this.firstSectionLoader = true;
            this.firstSectionData = [];

            const url = "/api/v1/merino/cmosummary/userssessions"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "firstSectionData");
                // this.processMetricSectionData(res.data.total_leads, "leadsData");
                this.firstSectionLoader = false;
            }).catch(err => {
                console.log(err);
                this.firstSectionLoader = false
            })
        },
        getDemographicData() {
            this.demographicDataLoader = true;
            this.demographicData = [];
            this.genderChartData = [];
            this.deviceChartData = [];

            const url = "/api/v1/merino/cmosummary/genderdemogrphic";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.demographicData = res.data.data.Demographic
                this.$nextTick(() => {
                    let genderSeries = [
                        {
                            name: "Gender",
                            data: []
                        }
                    ]
                    let deviceSeries = [
                        {
                            name: "Device",
                            data: []
                        }
                    ]
                    if (res.data.data['Gender Split'].length) {
                        for (let genderDistro of res.data.data['Gender Split']) {
                            genderSeries[0].data.push({ name: genderDistro.gender, y: genderDistro.value })
                        }
                    }
                    if (res.data.data.Device.length) {
                        for (let deviceDistro of res.data.data.Device) {
                            deviceSeries[0].data.push({ name: deviceDistro.gender, y: deviceDistro.value })
                        }
                    }
                    this.genderChartData = genderSeries;
                    this.deviceChartData = deviceSeries;
                    this.demographicDataLoader = false;
                })
            }).catch(err => {
                console.log(err);
                this.demographicDataLoader = false;
            })
        },
        getEngagementData() {
            $("#enagagementTable").DataTable().destroy();
            this.engagementLoader = true;
            this.engagementData = {
                header: [],
                data: []
            };
            this.eCatalogueChartData = [];

            const url = "/api/v1/merino/cmosummary/techengagement";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.engagementData.header = res.data.data.header;
                this.engagementData.data = res.data.data.data;

                let eCatalogueSeries = [
                    {
                        name: "E-Catalogue",
                        data: []
                    }
                ]

                if (res.data.data['Ecatalogue Downloads'].length) {
                    for (let catalogueDist of res.data.data['Ecatalogue Downloads']) {
                        eCatalogueSeries[0].data.push({ name: catalogueDist.platform, y: catalogueDist.value })
                    }
                }

                this.eCatalogueChartData = eCatalogueSeries;

                this.engagementLoader = false;
                this.$nextTick(() => {
                    this.processTable("#enagagementTable")
                })
            }).catch(err => {
                console.log(err);
                this.engagementLoader = false;
            })
        },
        getTopCatalogueData() {
            this.catalogueLoader = true;
            this.topCatalogueData = []

            const url = "/api/v1/merino/cmosummary/pagecataloguetale"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.topCatalogueData = res.data.data
                this.catalogueLoader = false;
            }).catch(err => {
                console.log(err);
                this.catalogueLoader = false;
            })
        },

        getDemandConversion() {
            this.catalogueLoader = true;

            const url = "/api/v1/merino/cmosummary/paiddemand"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.viewDemandGeneration = res.data.data
                if (Object.keys(this.viewDemandGeneration)) {
                    this.processFunnelChartData("funnelSeriesData", "viewDemandGeneration");
                }
                this.catalogueLoader = false;
            }).catch(err => {
                console.log(err);
                this.catalogueLoader = false;
            })
        },

        getCreativePerformance() {
            $('#creative-performance').DataTable().destroy();
            this.demographicDataLoader = true;
            this.viewCreativePerformanceData = {};
            this.viewCreativePerformanceDataHeader = [];

            const url = "/api/v1/merino/cmosummary/paidcreative"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.viewCreativePerformanceData = res.data.data
                this.viewCreativePerformanceDataHeader = Object.keys(Object.entries(this.viewCreativePerformanceData)[0][1]);
                this.$nextTick(() => {
                    this.processTable("#creative-performance")
                })
                this.demographicDataLoader = false
            }).catch(err => {
                console.log(err);
                this.demographicDataLoader = false;
            })
        },

        getFullSocialMedia() {
            this.fullSocialMediaLoader = true;
            this.socialMedialFull = [];

            const url = "/api/v1/merino/cmosummary/socialmediafull";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMedialFull")
                this.fullSocialMediaLoader = false;
            }).catch(err => {
                console.log(err)
                this.fullSocialMediaLoader = false;
            })
        },

        getSocialMediaPaid() {
            this.socialMediaPaidLoader = true;
            this.socialMediaPaid = [];

            const url = "/api/v1/merino/cmosummary/socialmediapaid";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMediaPaid");
                this.processDualAxisChart(res.data.data.Impressions, res.data.data.Clicks, "fbImpVsClicks")
                this.processDualAxisChart(res.data.data.Clicks, res.data.data.Leads, "fbClicksVsLeads")
                this.socialMediaPaidLoader = false;
            }).catch(err => {
                console.log(err)
                this.socialMediaPaidLoader = false;
            })
        },

        getSocialMediaOrganic() {
            this.socialMediaOrganicLoader = true;
            this.socialMediaOrganic = [];

            const url = "/api/v1/merino/cmosummary/socialmediaorganic";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMediaOrganic");
                this.socialMediaOrganicLoader = false;
            }).catch(err => {
                console.log(err)
                this.socialMediaOrganicLoader = false;
            })
        },

        getSEOOrganic() {
            this.SEOOrganicLoader = true;
            this.SEOOrganic = [];

            const url = "/api/v1/merino/cmosummary/seoorganicgrowth";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "SEOOrganic");
                this.SEOOrganicLoader = false;
            }).catch(err => {
                console.log(err)
                this.SEOOrganicLoader = false;
            })
        },

        getSEOKeywordGrowth() {
            this.SEOKeywordGrowthLoader = true;
            this.SEOKeywordGrowth = []

            const url = "/api/v1/merino/cmosummary/seokeywordgrowth";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "SEOKeywordGrowth");
                this.SEOKeywordGrowthLoader = false;
            }).catch(err => {
                console.log(err)
                this.SEOKeywordGrowthLoader = false;
            })
        },

        getEmailerData() {
            this.emailerLoader = true;
            this.emailerData = [];

            const url = "/api/v1/merino/cmosummary/mailerdata";
            const payload = {
                client: "MerinoDomestic",
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.emailerData = res.data.data;
                this.emailerLoader = false;
            }).catch(err => {
                console.log(err);
                this.emailerLoader = false
            })
        },
        getAdwordData() {
            this.adwordLoader = true;
            this.adwordData = [];

            const url = "/api/v1/merino/cmosummary/googleadword";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "adwordData");
                this, this.processDualAxisChart(res.data.data.Impressions, res.data.data.Clicks, "adwordsImpVsClicks")
                this, this.processDualAxisChart(res.data.data.Clicks, res.data.data.Conversions, "adwordsClicksVsConv")
                this.adwordLoader = false;
            }).catch(err => {
                console.log(err);
                this.adwordLoader = false
            })
        },
        getAdwordFunnelData() {
            this.adwordFunnelLoader = true;
            this.adwordFunnelData = [];
            this.adwordFunnelSeriesData = {};

            const url = "/api/v1/merino/cmosummary/googleadwordfunnel";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.adwordFunnelData = res.data.data;
                for (let key of Object.keys(res.data.data)) {
                    this.adwordFunnelSeriesData = { ...this.adwordFunnelSeriesData, [key]: [] }
                }
                this.$nextTick(() => {
                    this.processFunnelChartData("adwordFunnelSeriesData", "adwordFunnelData")
                })
                this.adwordFunnelLoader = false;
            }).catch(err => {
                console.log(err);
                this.adwordFunnelLoader = false
            })
        },
        getSocialMediaOrganicFull() {
            this.fullSocialMediaOrganicLoader = true;
            this.socialMedialOrganicFull = [];

            const url = "/api/v1/merino/cmosummary/socialmediaorganicfull";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMedialOrganicFull")
                this.fullSocialMediaOrganicLoader = false;
            }).catch(err => {
                console.log(err)
                this.fullSocialMediaOrganicLoader = false;
            })
        },
        getSocialMediaOrganicFB() {
            this.fbSocialMediaOrganicLoader = true;
            this.socialMedialOrganicFB = [];

            const url = "/api/v1/merino/cmosummary/socialmediaorganicfb";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMedialOrganicFB")
                this.fbSocialMediaOrganicLoader = false;
            }).catch(err => {
                console.log(err)
                this.fbSocialMediaOrganicLoader = false;
            })
        },
        getSocialMediaOrganicIG() {
            this.igSocialMediaOrganicLoader = true;
            this.socialMedialOrganicIG = [];

            const url = "/api/v1/merino/cmosummary/socialmediaorganicig";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMedialOrganicIG")
                this.igSocialMediaOrganicLoader = false;
            }).catch(err => {
                console.log(err)
                this.igSocialMediaOrganicLoader = false;
            })
        },
        getSocialMediaPaidFB() {
            this.fbSocialMediaPaidLoader = true;
            this.socialMedialPaidFB = [];

            const url = "/api/v1/merino/cmosummary/socialmediafb";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMedialPaidFB")
                this.fbSocialMediaPaidLoader = false;
            }).catch(err => {
                console.log(err)
                this.fbSocialMediaPaidLoader = false;
            })
        },
        getSocialMediaPaidIG() {
            this.igSocialMediaPaidLoader = true;
            this.socialMedialPaidIG = [];

            const url = "/api/v1/merino/cmosummary/socialmediaig";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }

            axios.post(url, payload).then(res => {
                this.processMetricSectionData(res.data.data, "socialMedialPaidIG")
                this.igSocialMediaPaidLoader = false;
            }).catch(err => {
                console.log(err)
                this.igSocialMediaPaidLoader = false;
            })
        },

        processFunnelChartData(chartVar, dataVar) {
            if (chartVar === 'funnelSeriesData' && dataVar === 'viewDemandGeneration') {
                this[chartVar] = [{ name: 'Spends: ' + this[dataVar].Spends.prefix + ' ' + this.formatNumber(this[dataVar].Spends.value), data: [], }],
                    this[chartVar][0].data.push(
                        {
                            name: 'Impressions',
                            y: this[dataVar].Impressions.y,
                            value: this[dataVar].Impressions.value
                        },
                        {
                            name: 'Clicks',
                            y: this[dataVar].Clicks.y,
                            value: this[dataVar].Clicks.value
                        },
                        {
                            name: 'Conversions',
                            y: this[dataVar].Conversions.y,
                            value: this[dataVar].Conversions.value
                        },
                    )
            } else {
                for (let key of Object.keys(this[dataVar])) {
                    this[chartVar][key] = [{ name: 'Spends: ' + this[dataVar][key].Spends.prefix + ' ' + this.formatNumber(this[dataVar][key].Spends.value), data: [], }],
                        this[chartVar][key][0].data.push(
                            {
                                name: 'Impressions',
                                y: this[dataVar][key].Impressions.y,
                                value: this[dataVar][key].Impressions.value
                            },
                            {
                                name: 'Clicks',
                                y: this[dataVar][key].Clicks.y,
                                value: this[dataVar][key].Clicks.value
                            },
                            {
                                name: 'Conversions',
                                y: this[dataVar][key].Conversions.y,
                                value: this[dataVar][key].Conversions.value
                            },
                        )
                }
            }
        },
        processMetricSectionData(data, sectionVariable) {
            this[sectionVariable] = [];

            for (const i in data) {
                const sectionWiseData = {
                    sectionName: '',
                    id: '',
                    isSectionOpen: false,
                    boxData: []
                };

                sectionWiseData.sectionName = i;
                sectionWiseData.id = 'id' + i;


                if (Array.isArray(data[i])) {
                    for (const boxData of data[i]) {
                        const tempMetricBoxData = {
                            key: '',
                            oldDateRange: '',
                            oldValue: '',
                            percentDifference: '',
                            prefix: '',
                            suffix: '',
                            value: '',
                            dataSource: '',
                            tooltip: '',
                            graphData: [],
                            target: '',
                        };

                        tempMetricBoxData.key = boxData.key;
                        tempMetricBoxData.oldDateRange = boxData.old_date_range;
                        tempMetricBoxData.oldValue = boxData.old_value;
                        tempMetricBoxData.percentDifference = boxData.percentage_difference;
                        tempMetricBoxData.prefix = boxData.prefix;
                        tempMetricBoxData.suffix = boxData.suffix;
                        tempMetricBoxData.value = boxData.value;
                        tempMetricBoxData.dataSource = boxData.data_source ? boxData.data_source : null;
                        tempMetricBoxData.tooltip = boxData.tooltip ? boxData.tooltip : boxData.view ? boxData.view : null;
                        tempMetricBoxData.target = boxData.target ?? null

                        const tempGraphBoxData = {
                            xAxis: [],
                            ylabel: '',
                            seriesData: [
                                {
                                    name: '',
                                    data: [],
                                    checkName: ''
                                },
                                {
                                    name: '',
                                    data: [],
                                    color: "#A4C3BE",
                                    zones: [
                                        {
                                            value: 0
                                        },
                                        {
                                            dashStyle: 'Dash'
                                        }
                                    ],
                                    date: [],
                                    ylabel: false
                                }
                            ],
                            prefix: '',
                            suffix: '',
                            legend: "center",
                            legendPosition: "bottom",
                            legendMargin: 5,
                        };

                        tempGraphBoxData.seriesData[0].checkName = boxData.key;
                        tempGraphBoxData.seriesData[0].name = 'Current ';
                        tempGraphBoxData.seriesData[1].name = 'Previous ';
                        tempGraphBoxData.prefix = boxData.prefix;
                        tempGraphBoxData.suffix = boxData.suffix;

                        for (const graphData of boxData.graph) {
                            tempGraphBoxData.xAxis.push(moment(graphData.current_date).format("DD MMM"));
                            tempGraphBoxData.seriesData[0].data.push(graphData.current_value);
                            tempGraphBoxData.seriesData[1].data.push(graphData.comp_value);
                            tempGraphBoxData.seriesData[1].date.push(moment(graphData.comp_date).format("DD MMM"));
                        }

                        tempMetricBoxData.graphData.push(tempGraphBoxData);
                        sectionWiseData.boxData.push(tempMetricBoxData);
                    }
                } else {
                    const tempMetricBoxData = {
                        key: '',
                        oldDateRange: '',
                        oldValue: '',
                        percentDifference: '',
                        prefix: '',
                        suffix: '',
                        value: '',
                        dataSource: '',
                        tooltip: '',
                        graphData: [],
                        target: '',
                    };

                    tempMetricBoxData.key = data[i].key;
                    tempMetricBoxData.oldDateRange = data[i].old_date_range;
                    tempMetricBoxData.oldValue = data[i].old_value;
                    tempMetricBoxData.percentDifference = data[i].percentage_difference;
                    tempMetricBoxData.prefix = data[i].prefix;
                    tempMetricBoxData.suffix = data[i].suffix;
                    tempMetricBoxData.value = data[i].value;
                    tempMetricBoxData.dataSource = data[i].data_source ? data[i].data_source : null;
                    tempMetricBoxData.tooltip = data[i].tooltip ? data[i].tooltip : data[i].view ? data[i].view : null;
                    tempMetricBoxData.currentKeywords = data[i].curr_keyword || null
                    tempMetricBoxData.previousKeywords = data[i].prev_keyword || null
                    tempMetricBoxData.target = data[i].target ?? null

                    const tempGraphBoxData = {
                        xAxis: [],
                        ylabel: '',
                        seriesData: [
                            {
                                name: '',
                                data: [],
                                checkName: ''
                            },
                            {
                                name: '',
                                data: [],
                                color: "#A4C3BE",
                                zones: [
                                    {
                                        value: 0
                                    },
                                    {
                                        dashStyle: 'Dash'
                                    }
                                ],
                                date: [],
                                ylabel: false
                            }
                        ],
                        prefix: '',
                        suffix: '',
                        legend: "center",
                        legendPosition: "bottom",
                        legendMargin: 5
                    };

                    tempGraphBoxData.seriesData[0].checkName = data[i].key;
                    tempGraphBoxData.seriesData[0].name = 'Current ';
                    tempGraphBoxData.seriesData[1].name = 'Previous ';
                    tempGraphBoxData.prefix = data[i].prefix;
                    tempGraphBoxData.suffix = data[i].suffix;

                    for (const graphData of data[i].graph) {
                        tempGraphBoxData.xAxis.push(moment(graphData.current_date).format("DD MMM"));
                        tempGraphBoxData.seriesData[0].data.push(graphData.current_value);
                        tempGraphBoxData.seriesData[1].data.push(graphData.comp_value);
                        tempGraphBoxData.seriesData[1].date.push(moment(graphData.comp_date).format("DD MMM"));
                    }

                    tempMetricBoxData.graphData.push(tempGraphBoxData);
                    sectionWiseData.boxData.push(tempMetricBoxData);
                }

                this[sectionVariable].push(sectionWiseData);
            }
        },
        processDualAxisChart(chartData1, chartData2, chartVar) {
            this[chartVar].xAxis = [];
            this[chartVar].seriesData = [
                {
                    name: "",
                    data: []
                },
                {
                    name: "",
                    data: []
                }
            ];

            //Plotting the left axis
            this[chartVar].seriesData[0].name = chartData1.key;
            this[chartVar].seriesData[0].color = "#065559";

            chartData1.graph.forEach(datapoint => {
                this[chartVar].xAxis.push(datapoint.current_date);
                this[chartVar].seriesData[0].data.push(datapoint.current_value)
            });

            //Plotting the right axis
            this[chartVar].seriesData[1].name = chartData2.key;
            this[chartVar].seriesData[1].color = "#FCB116";

            chartData2.graph.forEach(datapoint => {
                this[chartVar].seriesData[1].data.push(datapoint.current_value)
            });

        },
        processTable(id) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                info: false,
                sorting: [[1, "asc"]],
                length: false,
                searching: false,
                lengthChange: false,
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },

            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );

        },

        //Generic Functions
        abbreviateNumber(value) {
            return new Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },
        decodeHTML(html) {
            const txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        formatNumber(num) {
            if (isNaN(num)) {
                return num;
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        },
        toggleTab(variable) {
            for (let tabKey of Object.keys(this.tabs)) {
                if (tabKey === variable) {
                    this.tabs[variable] = true
                } else {
                    this.tabs[tabKey] = false;
                }
            }
        }
    }
}