import moment from "moment";
import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";

export default {
    name: 'mediadata',
    components: {
        Loader
    },
    data() {
        return {
            account: '',
            allAccounts: [],
            mediaChannel: [],
            months: moment.months(),
            data: {
                selectedAccount: '',
                channel: '',
                month: moment().format("MMMM"),
                ctr: '',
                cpc: '',
                cts: '',
                conv_rate: '',
                ad_cost: '',
            },
            loader: false,
            tblData: {
                data: [],
                row: [],
            },
        };
    },
    mounted() {
        this.getAccounts();
    },
    methods: {
        refresh() {
            const self = this;
            if (self.data.selectedAccount.id && self.data.month) {
                self.getData();
            }
        },
        getData() {
            const self = this;
            self.tblData = [];
            axios.get(`/api/summary/media_report/?month=${moment.months().indexOf(self.data.month) + 1}&account_id=${self.data.selectedAccount.id}`).then(response => {
                self.tblData = response.data;
            }).catch(err => {
                console.log(err);
            });
        },
        getAccounts() {
            const self = this;
            axios.get('/api/summary/media_accounts/').then(response => {
                self.allAccounts = response.data.data;
                self.allAccounts.sort((a, b) => {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                });
                self.mediaChannel = response.data.labels.sort();
            }).catch(err => {
                console.log(err);
            });
        },
        SaveMedia() {
            const self = this;
            for (const k in self.data) {
                if (k === 'ctr' || k === 'cts' || k === 'cpc' || k === 'conv_rate' || k === 'ad_cost') {
                    self.data[k] = Number(self.data[k]);
                }
            }
            self.loader = true;
            axios.post('/api/summary/media_report/', self.data).then(res => {
                self.loader = false;
                self.clearForm();
                self.getData();
            }).catch(err => {
                self.loader = false;
                alert('Error', err);
            })
        },
        clearForm() {
            this.data.ctr = '';
            this.data.cpc = '';
            this.data.cts = '';
            this.data.conv_rate = '';
            this.data.ad_cost = '';
            this.data.selectedAccount = '';
            this.data.channel = '';
        },
        formatNumber(num){
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    },
};