import { render, staticRenderFns } from "./dualBarChart.vue?vue&type=template&id=6a449369&scoped=true&lang=pug"
import script from "./dualBarChart.vue?vue&type=script&lang=coffee"
export * from "./dualBarChart.vue?vue&type=script&lang=coffee"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a449369",
  null
  
)

export default component.exports