<template lang="pug">
div#advanced-filter
    div.w-card.mx-2.mb-4.relative
        //- Pills
        div(id="pills-wrapper" v-if="appliedFilters.length || searchQueries.length" class="flex flex-wrap justify-between mb-2")
            div.flex.flex-wrap(class="w-11/12")
                div(v-for="filter,idx of appliedFilters" class="mb-1")
                    div
                        span.inline-flex.items-center.gap-1.ml-2.px-2.py-1.me-2.text-sm.font-medium.text-gray-500.bg-transparent.rounded-full.border-gray-500(class="border-[1px] cursor-default") 
                            span {{ filter.table }}
                            span {{ filter.metric }}
                            span(v-if="filter.operand") 
                                span(v-if="filter.operand === 'in between'" class="inline-flex gap-1") 
                                    span {{ filter.operand }}
                                    span {{ filter.min }} , {{ filter.max }}
                                span(v-else-if="filter.operand === 'greater than'" class="inline-flex gap-1")
                                    span >
                                    span {{ filter.value }}
                                span(v-else-if="filter.operand === 'less than'" class="inline-flex gap-1")
                                    span <
                                    span {{ filter.value }}
                            span(v-else) {{ filter.value.toString() }}
                            button(type="button" @click="$emit('remove-filter', idx)" class="inline-flex items-center p-1 ms-2 text-sm text-gray-500 bg-transparent hover:bg-gray-600 hover:text-white rounded-full" aria-label="Remove Selected Campaigns")
                                svg.w-2.h-2(aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14")
                                    path(stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6")
                                span.sr-only Remove badge
                div(v-for="query,idx of searchQueries" class="mb-1")
                    div
                        span.inline-flex.items-center.gap-1.ml-2.px-2.py-1.me-2.text-sm.font-medium.text-gray-500.bg-transparent.rounded-full.border-gray-500(class="border-[1px] cursor-default") 
                            span {{ query.table }}
                            span contains
                            span {{ query.query }}
                            button(type="button" @click="$emit('remove-search-query', idx)" class="inline-flex items-center p-1 ms-2 text-sm text-gray-500 bg-transparent hover:bg-gray-600 hover:text-white rounded-full" aria-label="Remove Selected Campaigns")
                                svg.w-2.h-2(aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14")
                                    path(stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6")
                                span.sr-only Remove badge
            div
                span.px-2.py-1.text-xs.font-medium.text-gray-500.bg-transparent.rounded-full.border-gray-500(class="border-[1px] cursor-default hover:bg-gray-600")
                    button(type="button" @click="$emit('remove-all-filters')" class="inline-flex items-center p-1 text-xs text-gray-500 bg-transparent hover:text-white rounded-full" aria-label="Remove Selected Campaigns") Clear All
        div(class="border-[1px] border-gray-300 rounded-lg flex items-center")
            div.px-2.py-2.cursor-pointer.border-r-2(id="filter-button" @click="toggleDropdown($event)")
                img(src="@/assets/Icons_SVG/Filter.svg", alt="filter-icon" width="20")
            input(id="search-filter" type="search" @click="openSeachFilter" v-model="searchQuery" class="w-full" class="block w-full px-4 py-2 text-sm border-none focus:ring-0 text-gray-900 rounded-lg bg-white" placeholder="Search by Campaign Name...")
        div.absolute.start-12.border-gray-300.mt-1.rounded-md.bg-white.py-3(id="search-dropdown" class="border-[1px] z-10 max-w-md" v-if="globalSearchFilter")
            div(v-for="table, idx of tables" :key="idx" class="hover:bg-gray-200 cursor-pointer px-3 py-1" @click="addQuery(table, searchQuery)")
                span(class="text-primary_brand text-lg font-medium mx-1") {{ table }}
                span(class="text-gray-500 mx-1") contains 
                span(class="text-blue-600 mx-1 break-all") {{ searchQuery }}
        div.absolute.start-4.border-gray-300.mt-1.rounded-md.bg-white(id="filter-dropdown" class="border-[1px] z-10" v-if="Object.keys(topFilter).length && isFilterOpen")
            div.flex.justify-between.border-b-2
                div.w-72.py-2(class="border-r-2 h-72 max-h-72 overflow-y-auto filter_sidebar")
                    div(v-for="sectionValue, sectionName of topFilter" class="cursor-pointer" @click="sectionValue.isOpen = !sectionValue.isOpen" :key="sectionName") 
                        div.flex.justify-between.items-center.text-primary_brand.font-medium.text-lg(class="hover:bg-primary_brand-80 hover:text-white px-3 mx-1 hover:rounded-md")
                            span(class="my-1") {{ sectionName }}
                            font-awesome-icon(icon="fa fa-angle-up" v-if="sectionValue.isOpen")
                            font-awesome-icon(icon="fa fa-angle-down" v-if="!sectionValue.isOpen")
                        div(v-if="sectionValue.isOpen")
                            div(v-for="tableValue, tableName of sectionValue" :key="tableName")
                                div.flex.justify-between.items-center.font-medium.text-lg(class="hover:bg-primary_brand-80 hover:text-white text-primary_brand mx-4 px-3 hover:rounded-md" v-if="tableName !== 'isOpen'" @click="$event.stopPropagation();(tableValue.isOpen = !tableValue.isOpen)")
                                    span(class="py-0.5") {{ tableName }}
                                    font-awesome-icon(icon="fa fa-angle-up" v-if="tableValue.isOpen")
                                    font-awesome-icon(icon="fa fa-angle-down" v-if="!tableValue.isOpen")
                                div(v-for="metricValue, metricName of tableValue" :key="metricName" @click="selectTopFilter($event,sectionName, tableName, metricName)" class="px-8" v-if="tableValue.isOpen")
                                    div.px-2(class="my-1 py-1.5 hover:bg-gray-300 hover:rounded-3xl" v-if="metricName !== 'isOpen'" :class="{'bg-primary_brand-80 rounded-3xl text-white hover:bg-primary_brand-60': showSelection(sectionName, tableName, metricName)}") {{ metricName }}
                div.w-72.py-3.flex.flex-col.items-center.justify-between.h-72.max-h-72.overflow-y-auto.filter_sidebar
                    div.flex.flex-col.items-center.gap-2
                        div.text-primary_brand.self-start.mx-3.text-lg.font-semibold {{ selectedMetric }}:
                        //- Dropdown
                        div.customMultiselect.mx-2(class="min-w-[90%] max-w-[90%]" v-if="topFilter[selectedSection][selectedTable][selectedMetric].dropdown")
                            multiselect(
                                v-model="topFilter[selectedSection][selectedTable][selectedMetric].selectedOption"
                                :options='topFilter[selectedSection][selectedTable][selectedMetric].dropdown'
                                :searchable="false"
                                :show-labels="false" 
                                :multiple="topFilter[selectedSection][selectedTable][selectedMetric].inputType !== 'Number' ? true: false"
                                :allow-empty="true"
                                open-direction="bottom"
                                placeholder="Select"
                                :close-on-select="topFilter[selectedSection][selectedTable][selectedMetric].inputType === 'Number' ? true: false"
                            )
                                template(slot="option" slot-scope="{ option }" v-if="topFilter[selectedSection][selectedTable][selectedMetric].inputType !== 'Number'")
                                    input(type="checkbox", :value="option" v-model="topFilter[selectedSection][selectedTable][selectedMetric].selectedOption")
                                    span(class="option__title ml-3") {{ option }}
                                    div.flex.text-primary_brand.font-medium.cursor-pointer.items-end
                        //- Number and range
                        div(class="min-w-[88%] mx-2 px-1.5" v-if="topFilter[selectedSection][selectedTable][selectedMetric].inputType === 'Number'")
                            div(v-if="topFilter[selectedSection][selectedTable][selectedMetric].selectedOption === 'in between'" class="flex gap-3")
                                div
                                    label(for="min" class="text-primary_brand") From
                                    input(id="min" type="number" class="w-full h-9 border-[1px] bg-white border-primary_brand-80 text-gray-900 text-sm rounded-md focus:ring-[0.5px] focus:ring-primary_brand focus:border-primary_brand block p-2.5" placeholder="Min value" v-model="topFilter[selectedSection][selectedTable][selectedMetric].min")
                                div
                                    label(for="max" class="text-primary_brand") To
                                    input(id="max" type="number" class="w-full h-9 border-[1px] bg-white border-primary_brand-80 text-gray-900 text-sm rounded-md focus:ring-[0.5px] focus:ring-primary_brand focus:border-primary_brand block p-2.5" placeholder="Max value" v-model="topFilter[selectedSection][selectedTable][selectedMetric].max")
                            input(v-else type="number" class="w-full h-9 border-[1px] bg-white border-primary_brand-80 text-gray-900 text-sm rounded-md focus:ring-[0.5px] focus:ring-primary_brand focus:border-primary_brand block p-2.5" placeholder="Enter a value in number" v-model="topFilter[selectedSection][selectedTable][selectedMetric].inputValue")
                    button(class="bottom-0 2xl:h-9 xl:h-7 sm:h-7 xs:h-7 text-white bg-primary_brand font-medium rounded-md text-sm px-3 text-center inline-flex items-center hover:bg-primary_brand-80 hover:text-white xs:text-2xs sm:text-xs  xl:text-sm 2xl:text-sm " @click="$emit('add-filter', selectedSection, selectedTable, selectedMetric)") Add Filter
            button(class="bottom-0 2xl:h-9 xl:h-7 sm:h-7 xs:h-7 text-white float-right my-2 mr-2 bg-primary_brand font-medium rounded-md text-sm px-3 text-center inline-flex items-center hover:bg-primary_brand-80 hover:text-white xs:text-2xs sm:text-xs  xl:text-sm 2xl:text-sm " @click="$emit('apply-filter')") Apply Filters
        
</template>
<script>
/* 
Schema for this filter is at the bottom of the file
*/
export default {
    name: 'AdvancedFilter',
    data() {
        return {
            selectedSection: "",
            selectedTable: "",
            selectedMetric: "",
            searchQuery: ""
        }
    },
    props: {
        topFilter: {
            type: Object,
            required: true
        },
        selectedFields: {
            type: Object,
            required: true
        },
        isFilterOpen: {
            type: Boolean,
            required: true
        },
        appliedFilters: {
            type: Array,
            required: true
        },
        tables: {
            type: Array,
            required: true
        },
        globalSearchFilter: {
            type: Boolean,
            required: true
        },
        searchQueries: {
            type: Array,
            required: true
        }
    },
    created() {
        window.addEventListener("click", this.close)
        window.addEventListener("click", this.closeSearchDropdown)
    },
    mounted() {
        this.selectedSection = this.selectedFields.selectedSection;
        this.selectedTable = this.selectedFields.selectedTable;
        this.selectedMetric = this.selectedFields.selectedMetric;
    },
    beforeDestroy() {
        window.removeEventListener("click", this.close)
        window.removeEventListener("click", this.closeSearchDropdown)
    },
    methods: {
        addQuery(table, query) {
            this.$emit('add-search-query', table, query);
            this.$emit('toggle-search-filter', false);
            this.searchQuery = "";
        },
        selectTopFilter(e, section, table, metric) {
            e.stopPropagation();
            this.selectedSection = section;
            this.selectedTable = table;
            this.selectedMetric = metric;
        },
        showSelection(section, table, metric) {
            if (section === this.selectedSection && table === this.selectedTable && metric === this.selectedMetric) return true;
            else return false;
        },
        openSeachFilter(e) {
            e.stopPropagation();
            if(this.isFilterOpen) {
                this.$emit('toggle-dropdown', false)
            }
            this.$emit('toggle-search-filter', true)
        },
        toggleDropdown(e) {
            e.stopPropagation()
            if(this.globalSearchFilter) {
                this.$emit('toggle-search-filter', false)
            }
            this.$emit('toggle-dropdown', '')
        },
        close(event) {
            if (document.querySelector('#advanced-filter').contains(event.target)) {
                if (document.getElementById('filter-button').contains(event.target)) {
                    return;
                } else if (this.isFilterOpen && document.getElementById('filter-dropdown').contains(event.target)) {
                    return;
                } else {
                    if (this.isFilterOpen) {
                        this.$emit('toggle-dropdown', false)
                    };
                }
            } else {
                if (this.isFilterOpen) {
                    this.$emit('toggle-dropdown', false)
                }
            }
        },
        closeSearchDropdown(event) {
            if (document.querySelector('#advanced-filter').contains(event.target)) {
                if (document.querySelector('#search-filter').contains(event.target)) {
                    return;
                } else if (this.globalSearchFilter && document.querySelector('#search-dropdown').contains(event.target)) {
                    return;
                } else {
                    if (this.globalSearchFilter) {
                        this.$emit('toggle-search-filter', false)
                    }
                }
            } else {
                if (this.globalSearchFilter) {
                    this.$emit('toggle-search-filter', false)
                }
            }
        }
    }
}
</script>

<style lang="sass" scoped>
/* width */
.filter_sidebar
    &::-webkit-scrollbar
        width: 5px
        border-radius: 10px

/* Track */
.filter_sidebar
    &::-webkit-scrollbar-track
        background: var(--base_color-20)
        border-radius: 10px

/* Handle */
.filter_sidebar
    &::-webkit-scrollbar-thumb
        background: var(--base_color-40)
        border-radius: 10px

/* Handle on hover */
.filter_sidebar
    &::-webkit-scrollbar-thumb:hover
        background: var(--base_color-60)

/* Hide spinners in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button 
    -webkit-appearance: none
    margin: 0


/* Hide spinners in Firefox */
input[type="number"] 
    -moz-appearance: textfield

</style>
<!-- Schema for top filter props
    topFilter: {
        Metrics: {
            isOpen: true,
            "Campaign": {
                isOpen: true,
                Impressions: {
                    dropdown: ['in between', 'greater than', 'less than'],
                    selectedOption: 'in between',
                    inputType: Number,
                    inputValue: "",
                    min: "",
                    max: "",
                    
                },
                "Campaign ID": {
                    dropdown: ["A", "B", "C", "D"],
                    selectedOption: [],
                    inputType: "Dropdown",
                },
            },
            "Ad Products": {
                isOpen: true,
                Impressions: {
                    dropdown: ['in between', 'greater than', 'less than'],
                    selectedOption: 'in between',
                    inputType: Number,
                    inputValue: "",
                    min: "",
                    max: "",
                    
                },
                "Adset ID": {
                    dropdown: ["A", "B", "C", "D"],
                    selectedOption: [],
                    inputType: "Dropdown",
                },
            }
        }
    }

Schema for selected fields Props
    selectedFields: {
        selectedSection: "Metrics",
        selectedTable: "Campaign",
        selectedMetric: "Impressions",
    } -->
