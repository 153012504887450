<template lang='pug'>
div#reportdownload(style='margin-bottom:0em,')
    div
        div
            div.w-card.m-3(v-if="is_report_generated")
                div.cont(v-if="is_report_generated")
                    a(:href="ppt", type="button" class="px-2 py-1.5 rounded-md bg-base_color-80 hover:bg-base_color-60 text-white") Download pptx
                    a(:href="exel", type="button" class="px-2 py-1.5 rounded-md bg-base_color-80 hover:bg-base_color-60 text-white") Download exel
            div.w-card.m-3
                h2(class="2xl:text-xl xl:text-base font-semibold text-primary_brand border-b-2 border-base_color-40 mb-2 pb-2")  Report
                div
                    div(v-show="showLoader")
                        Loader
                    form.flex.justify-between.gap-2.items-center
                        date-range-picker(class="mt-5" v-model="date" 
                        opens='right'
                        :autoApply="true"
                        :locale-data="{format:'mmm dd, yyyy'}"
                        @update=""
                        )
                        div.customMultiselect.w-96
                            label(class="text-primary_brand") GA Type
                            multiselect(
                                v-model="report_params.ga_type"
                                :options="['Account', 'View']"
                                :searchable="false"
                                :show-labels="false" 
                                :multiple="false"
                                :allow-empty="true"
                                open-direction="bottom"
                                placeholder="Select GA Type"
                                )
                        div.customMultiselect.w-96
                            label(class="text-primary_brand") GA
                            multiselect(
                                v-model="report_params.ga_id"
                                :options="gaClients"
                                :searchable="false"
                                :show-labels="false" 
                                :multiple="false"
                                :allow-empty="true"
                                track-by="id"
                                label="name"
                                open-direction="bottom"
                                placeholder="Select GA"
                                )

                        div.customMultiselect.w-96
                            label(class="text-primary_brand") Fb Account Name
                            multiselect(
                                v-model="report_params.fb_iden"
                                :options="fbClients"
                                :searchable="false"
                                :show-labels="false" 
                                :multiple="false"
                                :allow-empty="true"
                                track-by="id"
                                label="name"
                                open-direction="bottom"
                                placeholder="Select Fb account name or campaign id"
                                )
                            //- select(type='text', v-model='report_params.fb_iden', placeholder='Fb account name or campaign id')
                            //-     option(v-for="i in fbClients") {{i.name}}  
                        button(@click="getReportUrls", type="button" class="mt-5 px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white") Submit


            div.w-card.m-3
                h2(class="2xl:text-xl xl:text-base font-semibold text-primary_brand border-b-2 border-base_color-40 mb-2 pb-2")  Upload Report
                div
                    form.flex.gap-3.items-center
                        //- label Fb Account Name
                        input(type='file', id="file",:value="file" class="rounded-md bg-gray-300 mr-4")   
                        button(@click="uploadExelFile", type="button" class="px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white") Submit
                        a(v-if="is_modified_report_generated", :href="modified_report_url", type="button" class="px-2 py-1.5 rounded-md bg-base_color-80 hover:bg-base_color-60 text-white") Download 



        
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';

export default {
    name: 'ReportDownload',
    template: '#reportdownload',
    components: {
        Loader
    },
    data() {
        return {
            date: {
                startDate: moment().subtract(31, 'weeks').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            },
            ga_type: '',
            fb_iden: '',
            ga_id: '',
            ppt: '',
            exel: '',
            ga_acc_selected: '',
            report_params: {
                ga_type: '',
                ga_id: '',
                fb_iden: '',
            },
            modified_report_url: '',
            is_report_generated: 0,
            is_modified_report_generated: 0,
            acc_list: [],
            gaClients: [],
            fbClients: [],
            showloader: 0,
            downStatus: 'Check Now',
            downloadLink: '',
            successMessage: '',
            pLoad: true,
            dateChange: false,
            refDateFrom: '',
            refDateTo: '',
        };
    },
    created() {
        this.getGaAccounts();
        this.downloadPptx();
    },
    watch: {
        refDateFrom() {
            this.dateChange = true;
            setTimeout(() => {
                if (!this.pLoad) {
                    this.downloadPptx();
                }
            }, 0);
        },
        refDateTo() {
            this.dateChange = true;
            setTimeout(() => {
                if (!this.pLoad) {
                    this.downloadPptx();
                }
            }, 0);
        },
    },
    methods: {
        async getGaAccounts() {
            try {
                const response = await axios.get('/api/users/clients/');
                for (const i of response.data.data) {
                    if (i.name === 'GA') {
                        this.gaClients = i.values;
                    }
                    if (i.name === 'FB') {
                        this.fbClients = i.values;
                    }
                }
            } catch (error) {
                console.error('Error', error);
            }
        },
        downloadPptx() {
            this.downStatus = 'Processing...';
            const url = `/api/summary/reports/reportstatus?start_date=${this.date.startDate}&end_date=${this.date.endDate}`;
            axios.get(url)
                .then((response) => {
                    setTimeout(() => {
                        this.pLoad = false;
                        this.dateChange = false;
                    }, 30);

                    this.is_report_generated = 1;
                    if (response.data.date.status) {
                        this.downStatus = 'Check';
                        this.downloadLink = response.data.date.ppt_file_name;
                    } else {
                        this.downStatus = 'Processing, Check Again';
                    }
                })
                .catch(() => {
                    this.is_report_generated = 0;
                    this.downStatus = 'Check';
                    if (!this.pLoad && !this.dateChange) {
                        alert('Please Submit the PPT first for the given date range');
                    } else {
                        setTimeout(() => {
                            this.pLoad = false;
                            this.dateChange = false;
                        }, 30);
                    }
                });
        },
        async getAcc() {
            try {
                const response = await axios.get('/api/fb/accounts');
                this.goals = [];
                this.acc = response.data.data;
                for (const item of response.data.data) {
                    this.acc_list.push(item.name);
                }
            } catch (error) {
                console.error('Error', error);
            }
        },
        async getReportUrls(event) {
            this.showloader = 1;
            this.downloadLink = '';
            this.is_report_generated = 0;

            this.report_params.start_date = this.date.startDate;
            this.report_params.end_date = this.date.endDate;
            this.report_params.ga_type = this.report_params.ga_type.toLowerCase();

            this.ppt = this.report_params.ga;

            for (const i of this.gaClients) {
                if (i.name === this.report_params.ga_id) {
                    this.report_params.ga_id = i.id;
                }
            }

            const query_params = new URLSearchParams(this.report_params).toString();
            const url = `/api/summary/reports/?${query_params}`;

            try {
                const response = await axios({
                    method: 'get',
                    url,
                    timeout: 600000,
                });

                this.ppt = response.data.ppt;
                this.exel = response.data.excel;
                this.is_report_generated = 1;
                this.showloader = 0;
                this.successMessage = 'Report has been submitted and preparing now..';
                setTimeout(() => {
                    this.successMessage = '';
                }, 3000);
            } catch (error) {
                alert('failed!');
                this.showloader = 0;
                console.log('Error', error);
            }

            event.preventDefault();
            return false;
        },
        uploadExelFile() {
            const formData = new FormData();
            formData.append('file', document.querySelector('#file')[0].files[0]);
            const url = '/api/upload/exel/reportsgenerate/';

            alert('starting upload..');

            axios.post(url, formData)
                .then((response) => {
                    alert('file uploaded');
                    alert(JSON.stringify(response.data));
                    this.is_modified_report_generated = 1;
                    this.modified_report_url = response.data.file_path;
                })
                .catch((error) => {
                    console.error('Error', error);
                });
        },
    },
};

</script>
<style scoped>
.selectday,
.selectoverall {
    position: relative;
    flex: 1;
}

.selectday {
    margin-right: 1.2em;
}

.selectday:last-child {
    margin-right: 0em;
}

@media only screen and (max-width:767px) {
    .selectday {
        margin-right: 0em;
    }
}

.querybox {
    height: 40px;
    vertical-align: middle;
    text-align: center;
    font-size: 13px;
    width: 30%;
    padding: 0;
    border: 1px solid #c8cdd7;
}

.querybox {
    height: 38px;
}

.cont {
    text-align: center;
    justify-content: center;
}
</style>