<template lang="pug">
div
    div(v-if="loader")
        Loader
    div(v-if="!loader")
        div.w-card.mx-3.my-4
            div.flex.justify-between
                div(class="text-primary_brand  2xl:text-xl xl:text-base font-semibold") Unapproved Users
                div.flex.justify-between.items-center.sticky.top-4.p-2.mx-3.border-2.border-primary_brand(class="h-6 rounded-md 2xl:h-8 2xl:rounded-md xl:h-6 xl:rounded-md lg:h-6 md:h-6 sm:h-6")
                    input.border-none(class="3xl:text-base 2xl:h-5 2xl:w-48 2xl:text-sm xl:h-3.5 xl:w-44 xl:text-xs lg:h-3 lg:w-40 lg:text-xs md:h-4 md:w-36 md:text-xs sm:h-4 sm:w-36 sm:text-xs h-4 w-36 text-xs" v-model="searchKey1" placeholder="Search..." required class="focus:outline-none")
                    font-awesome-icon.text-primary_brand.mr-1(icon="fa-solid fa-close" @click="clearSearchBox1", class="cursor-pointer 2xl:ml-11" v-show="searchKey1")
                    div.w-7.p-1.rounded-r-lg.flex.bg-primary_brand.userContainer(class="2xl:h-8 h-6 -mr-2.5 sm:-mr-2.3" )
                        img(src="@/assets/Icons_SVG/Search.svg")
            div.grid.grid-cols-3.gap-2.mt-6
                div.cursor-pointer.p-2(class="hover:bg-background_color hover:rounded-md" v-for="user in filteredUnapprovedUser" :key="user.user_id" @click="getIndividuals(user)")
                    span.mr-2
                        font-awesome-icon.text-primary_brand-80(icon="fa fa-user")
                    span {{ user.username }}
        div.w-card.mx-3.my-4
            div.flex.justify-between
                div(class="text-primary_brand  2xl:text-xl xl:text-base font-semibold") Approved Users
                div.flex.justify-between.items-center.sticky.top-4.p-2.mx-3.border-2.border-primary_brand(class="h-6 rounded-md 2xl:h-8 2xl:rounded-md xl:h-6 xl:rounded-md lg:h-6 md:h-6 sm:h-6")
                    input.border-none(class="3xl:text-base 2xl:h-5 2xl:w-48 2xl:text-sm xl:h-3.5 xl:w-44 xl:text-xs lg:h-3 lg:w-40 lg:text-xs md:h-4 md:w-36 md:text-xs sm:h-4 sm:w-36 sm:text-xs h-4 w-36 text-xs" v-model="searchKey2" placeholder="Search..." required class="focus:outline-none")
                    font-awesome-icon.text-primary_brand.mr-1(icon="fa-solid fa-close" @click="clearSearchBox2", class="cursor-pointer 2xl:ml-11" v-show="searchKey2")
                    div.w-7.p-1.rounded-r-lg.flex.bg-primary_brand.userContainer(class="2xl:h-8 h-6 -mr-2.5 sm:-mr-2.3" )
                        img(src="@/assets/Icons_SVG/Search.svg")
            div.grid.grid-cols-3.gap-2.mt-6
                div.cursor-pointer.p-2(class="hover:bg-background_color hover:rounded-md" v-for="user in filteredApprovedUser" :key="user.user_id" @click="getIndividuals(user)")
                    span.mr-2
                        font-awesome-icon.text-primary_brand-80(icon="fa fa-user")
                    span {{ user.username }}
    div(id="user-modal" aria-hidden="true" tabindex='-1', class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full mt-6")
        div.rounded-lg(class="bg-white 2xl:w-2/3 sm:w-2/3 pb-0")
            div.relative.bg-white.rounded-lg.h-full
                div.bg-primary_brand-20.rounded-t-lg.border-solid.border-b.flex.justify-between.px-3.py-4.mb-4
                    div.h3.capitalize.font-semibold(class="2xl:text-2xl xs:text-sm") {{ modalUsername }} {{ modalUserID ? "(" + modalUserID + ")" : "" }}
                    div.pr-4.cursor-pointer(@click="userModal.hide()") 
                        font-awesome-icon.text-base_color-60(icon="fa-solid fa-close" class="mr-1 rounded-lg hover:bg-gray-200 hover:text-gray-900 p-1.5 text-sm.ml-auto.inline-flex.items-center'")
                div.float-right.mr-3.mb-4
                    button(type="button" class="px-2 py-1 rounded-md bg-red-80 hover:bg-red-60 text-white" @click="deleteUser") Delete User 
                div.flex.justify-between.mt-3.px-4.w-full(v-if="userData") 
                    div.text-center
                        div.text-primary_brand.text-lg Email 
                        div.text-gray-600 {{ userData.email_id || "-" }}
                    div.text-center
                        div.text-primary_brand.text-lg Mobile
                        div.text-gray-600 {{ userData.mobile || "-" }}
                    div.text-center
                        div.text-primary_brand.text-lg Created On 
                        div.text-gray-600 {{ userData.created_on || "-" }}
                    div.text-center
                        div.text-primary_brand.text-lg Last Login 
                        div.text-gray-600 {{ userData.last_login || "-" }}
                div.text-primary_brand.text-xl.mx-2.mt-3.px-4.py-2.font-semibold.border-gray-300(class="border-t-[1px] w-[98%]") Access
                div.flex.justify-between.mt-2.px-4.w-full(v-if="userData")
                    div(class="hover:bg-background_color hover:rounded-md p-2 cursor-pointer")
                        input#meeting.rounded.mr-3.accent-primary_brand.text-primary_brand.cursor-pointer(type="checkbox", name="meeting" v-model="userData.meeting_access")
                        label.cursor-pointer(for="meeting") Meeting Access
                    div(class="hover:bg-background_color hover:rounded-md p-2 cursor-pointer")
                        input#monitoring.rounded.mr-3.accent-primary_brand.text-primary_brand.cursor-pointer(type="checkbox", name="monitoring" v-model="userData.monitoring_access")
                        label.cursor-pointer(for="monitoring") Monitoring Access
                    div(class="hover:bg-background_color hover:rounded-md p-2 cursor-pointer")
                        input#facebook.rounded.mr-3.accent-primary_brand.text-primary_brand.cursor-pointer(type="checkbox", name="facebook" v-model="userData.fb_access")
                        label.cursor-pointer(for="facebook") Facebook Access
                    div(class="hover:bg-background_color hover:rounded-md p-2 cursor-pointer")
                        input#dataStudio.rounded.mr-3.accent-primary_brand.text-primary_brand.cursor-pointer(type="checkbox", name="dataStudio" v-model="userData.datastudio_access")
                        label.cursor-pointer(for="dataStudio") Datastudio Access
                div.flex.justify-between.items-center.mt-8.py-2.border-gray-300.mx-2(class="border-t-[1px] w-[98%]")
                    div.text-primary_brand.text-xl.mt-3.px-4.font-semibold Clients
                    div.flex.justify-between.items-center.sticky.top-4.p-2.mx-3.border-2.border-primary_brand(class="h-6 rounded-md 2xl:h-8 2xl:rounded-md xl:h-6 xl:rounded-md lg:h-6 md:h-6 sm:h-6")
                        input.border-none(class="3xl:text-base 2xl:h-5 2xl:w-48 2xl:text-sm xl:h-3.5 xl:w-44 xl:text-xs lg:h-3 lg:w-40 lg:text-xs md:h-4 md:w-36 md:text-xs sm:h-4 sm:w-36 sm:text-xs h-4 w-36 text-xs" v-model="searchClient" placeholder="Search..." required class="focus:outline-none")
                        font-awesome-icon.text-primary_brand.mr-1(icon="fa-solid fa-close" @click="clearClientSearchBox", class="cursor-pointer 2xl:ml-11" v-show="searchClient")
                        div.w-7.p-1.rounded-r-lg.flex.bg-primary_brand.userContainer(class="2xl:h-8 h-6 -mr-2.5 sm:-mr-2.3" )
                            img(src="@/assets/Icons_SVG/Search.svg")
                div.grid.grid-cols-3.gap-2.mt-3.px-4(class="h-1/3 max-h-64 overflow-y-auto" v-if="userData")
                    div.cursor-pointer.p-2(class="hover:bg-background_color hover:rounded-md" v-for="client in filteredClients" :key="client.id" v-model="client.check")
                        input.rounded.accent-primary_brand.text-primary_brand.cursor-pointer(:id="client.name" type="checkbox", name="client" v-model="client.check")
                        label.ml-3.cursor-pointer(:for="client.name") {{ client.name }}
                div.text-center.py-3.bg-background_color.rounded-b-md
                    button(type="button" class="px-2 py-2 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" @click="updateUserDetails") Save
</template>
<script>
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import { Modal } from 'flowbite';

export default {
    name: 'Users',
    components: {
        Loader,
    },
    data() {
        return {
            loader: false,
            unApprovedUsers: [],
            approvedUsers: [],
            selectedUser: "",
            searchKey1: "",
            searchKey2: "",
            searchClient: "",
            userModal: null,
            modalUsername: "",
            modalUserID: "",
            userData: null
        }
    },
    mounted() {
        this.initializeModal();
        this.getUsers();
    },
    computed: {
        filteredUnapprovedUser() {
            return this.unApprovedUsers.filter(user => {
                return user.username.toLowerCase().includes(this.searchKey1.toLowerCase())
            })
        },
        filteredApprovedUser() {
            return this.approvedUsers.filter(user => {
                return user.username.toLowerCase().includes(this.searchKey2.toLowerCase())
            })
        },
        filteredClients() {
            return this.userData.data.filter(client => {
                return client.name.toLowerCase().includes(this.searchClient.toLowerCase())
            })
        }
    },
    methods: {
        initializeModal() {
            const $targetUserModal = document.getElementById('user-modal');
            const userModalOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    this.modalUsername = "";
                    this.modalUserID = "";
                    this.clearClientSearchBox();
                }
            };

            this.userModal = new Modal($targetUserModal, userModalOptions);
        },
        clearSearchBox1() {
            this.searchKey1 = "";
        },
        clearSearchBox2() {
            this.searchKey2 = "";
        },
        clearClientSearchBox() {
            this.searchClient = "";
        },
        getUsers() {
            this.loader = true;
            const url = "/api/users/";
            this.clearSearchBox1();
            this.clearSearchBox2();

            axios.get(url).then(res => {
                this.unApprovedUsers = res.data.data1;
                this.approvedUsers = res.data.data;
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getIndividuals(user) {
            this.loader = true;
            const url = `/api/users/list_clients/${user.user_id}/`;
            this.modalUsername = user.username;
            this.modalUserID = user.user_id;
            this.clearSearchBox1();
            this.clearSearchBox2();

            axios.get(url).then(res => {
                this.userData = res.data;
                this.$nextTick(() => {
                    this.userModal.show();
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        updateUserDetails() {
            this.loader = true;
            const url = `/api/users/list_clients/${this.modalUserID}/`;
            const payload = {
                datastudio_access: this.userData.datastudio_access,
                fb_access: this.userData.fb_access,
                meeting_access: this.userData.meeting_access,
                monitoring_access: this.userData.monitoring_access,
                clients: []
            }

            this.userData.data.map(client => {
                if (client.check) {
                    payload.clients.push(client.id)
                }
            });

            axios.post(url, payload).then(res => {
                this.userModal.hide();
                this.getUsers();

                this.$toast.success("User updated and saved!", {
                    position: "top-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            }).catch(err => {
                this.userModal.hide();
                this.getUsers();
                this.$toast.error("Error in saving user!", {
                    position: "top-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                console.log(err);
            })
        },
        deleteUser() {
            const url = `/api/users/${this.modalUserID}`;

            let confirmDeletion = confirm("Are you sure to delete the user?");

            if(confirmDeletion) {
                this.loader = true;
                axios.delete(url).then(res => {
                    this.$toast.success(`User ${this.modalUsername} deleted.`, {
                    position: "top-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.userModal.hide();
                this.getUsers();
                }).catch(err => {
                    this.$toast.error(`Error in deleting user ${this.modalUsername}` , {
                    position: "top-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                console.log(err);
                this.loader = false
                })
            } else return;
        }
    }
}
</script>
<style>
.userContainer img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(48deg) brightness(98%) contrast(108%);
}
</style>