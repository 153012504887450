import axios from 'axios'
import Header from '@/components/Header/Header.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import dualBarChart from '@/components/charts/dualBarChart.vue'
import stackChart from '@/components/charts/stackChart.vue'
import Vuetable from 'vuetable-2'
import VuetableFieldHandle from 'vuetable-2/src/components/VuetableFieldHandle.vue'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
// import MTDJson from './Summary.json'


//api -:
// https://weavr.online/api/adword/detailed?table_type=month&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=campaign&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=camp_type&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=channel&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=geo&start_date=2022-08-23&end_date=2022-09-22&type=country



export default {
    name: 'AdSummary',
    components: {
        Header,
        Vuetable,
        Loader,
        dualAxisChart,
        stackChart,
        dualBarChart
    },
    data() {
        return {
            monthDataLoad: false,
            campaignDataLoad: false,
            campaignTypeDataLoad: false,
            deviceTypeDataLoad: false,
            regionDataLoad: false,
            metricDropdown: 'clicks_impression',
            activeCompare: false,
            dateRange: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment()
            },
            monthTableData: {
                header: [],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            campaignTableData: {
                header: [],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            campaignTypeTableData: {
                header: [],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            deviceTypeTableData: {
                header: [],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            regionTableData: {
                header: [],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                },
                metricDropdown: 'country'
            },

            fields: [
                {
                    name: VuetableFieldHandle
                },
                {
                    name: 'name',
                    title: '<span class="orange glyphicon glyphicon-user"></span> Full Name',
                    sortField: 'name'
                },
                {
                    name: 'email',
                    sortField: 'email'
                },
                'birthdate',
                'nickname',
                {
                    name: 'gender',
                    formatter(value) {
                        if (value == 'M') {
                            return 'Male'
                        }
                        else
                            return 'Female'
                    }
                }
            ],
            data: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.formatDateRange();
            this.dataAPIs();
        },

        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },

        formatDates() {
            this.formatDateRange();
            this.destroyAllTables();
            this.dataAPIs();
        },


        formatNumber(num) {
            if (isNaN(num)) {
                return num
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        },

        activeCompareFn() {
            this.activeCompare = !this.activeCompare
        },

        dataAPIs() {
            this.monthDataLoad = true;
            this.campaignDataLoad = true;
            this.campaignTypeDataLoad = true;
            this.deviceTypeDataLoad = true;
            this.regionDataLoad = true;

            this.getMonthData();
            this.getCampaignData();
            this.getCampaignTypeData();
            this.getDeviceTypeData();
            this.getRegionData();

        },

        destroyAllTables() {
            $('#month-tbl').DataTable().destroy();
            $('#campaigndata-tbl').DataTable().destroy();
            $('#devicetype-tbl').DataTable().destroy();
            $('#campaigntype-tbl').DataTable().destroy();
        },

        reinitializeRegionDropdown() {
            this.destroyAllTables();
            this.dataAPIs();
        },

        processAllGraphs() {
            this.processMonthGraph();
            this.processCampaignDataGraph();
            this.processCampaignTypeGraph();
            this.processDeviceTypeGraph();
            this.processRegionGraph();
        },


        processMonthGraph() {
            this.monthTableData.chart = {
                xAxis: [],
                seriesData: [],
            }
            const xaxis = this.monthTableData.currentTableState.monthData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression') {
                seriesData.push({ name: 'Clicks', data: this.monthTableData.currentTableState.clickData, });
                seriesData.push({ name: 'Impressions', data: this.monthTableData.currentTableState.impressionData, color: "#FCB116" })
            } else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({ name: 'Cost (₹)', data: this.monthTableData.currentTableState.costData });
                seriesData.push({ name: 'Cost/Conversion (₹)', data: this.monthTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            } else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({ name: 'Cost (₹)', data: this.monthTableData.currentTableState.costData });
                seriesData.push({ name: 'Revenue (₹)', data: this.monthTableData.currentTableState.revenueData, color: "#FCB116" });
            }

            this.monthTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData,
                disableYLabel: true
            }
            // this.monthDataLoad = false;
        },
        processCampaignDataGraph() {
            this.campaignTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.campaignTableData.currentTableState.campaignNameData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression') {
                seriesData.push({ name: 'Clicks', data: this.campaignTableData.currentTableState.clickData });
                seriesData.push({ name: 'Impressions', data: this.campaignTableData.currentTableState.impressionData })
            } else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({ name: 'Cost (₹)', data: this.campaignTableData.currentTableState.costData });
                seriesData.push({ name: 'Cost/Conversion (₹)', data: this.campaignTableData.currentTableState.cost_conversionData });
            } else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({ name: 'Cost (₹)', data: this.campaignTableData.currentTableState.costData });
                seriesData.push({ name: 'Revenue (₹)', data: this.campaignTableData.currentTableState.revenueData });
            }

            this.campaignTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData,
            }
            this.campaignDataLoad = false;
        },
        processCampaignTypeGraph() {
            this.campaignTypeTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.campaignTypeTableData.currentTableState.networkData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression') {
                seriesData.push({ name: 'Clicks', data: this.campaignTypeTableData.currentTableState.clickData });
                seriesData.push({ name: 'Impressions', data: this.campaignTypeTableData.currentTableState.impressionData, color: "#FCB116" })
            } else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({ name: 'Cost (₹)', data: this.campaignTypeTableData.currentTableState.costData });
                seriesData.push({ name: 'Cost/Conversion (₹)', data: this.campaignTypeTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            } else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({ name: 'Cost (₹)', data: this.campaignTypeTableData.currentTableState.costData });
                seriesData.push({ name: 'Revenue (₹)', data: this.campaignTypeTableData.currentTableState.revenueData, color: "#FCB116" });
            }

            this.campaignTypeTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData,
                disableYLabel: true
            }
            this.campaignTypeDataLoad = false;
        },
        processDeviceTypeGraph() {
            this.deviceTypeTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.deviceTypeTableData.currentTableState.deviceData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression') {
                seriesData.push({ name: 'Clicks', data: this.deviceTypeTableData.currentTableState.clickData });
                seriesData.push({ name: 'Impressions', data: this.deviceTypeTableData.currentTableState.impressionData, color: "#FCB116" })
            } else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({ name: 'Cost (₹)', data: this.deviceTypeTableData.currentTableState.costData });
                seriesData.push({ name: 'Cost/Conversion (₹)', data: this.deviceTypeTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            } else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({ name: 'Cost (₹)', data: this.deviceTypeTableData.currentTableState.costData });
                seriesData.push({ name: 'Revenue (₹)', data: this.deviceTypeTableData.currentTableState.revenueData, color: "#FCB116" });
            }

            this.deviceTypeTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData,
                disableYLabel: true
            }
            this.deviceTypeDataLoad = false;
        },
        processRegionGraph() {
            this.regionTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.regionTableData.currentTableState.regionData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression') {
                seriesData.push({ name: 'Clicks', data: this.regionTableData.currentTableState.clickData });
                seriesData.push({ name: 'Impressions', data: this.regionTableData.currentTableState.impressionData, color: "#FCB116" })
            } else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({ name: 'Cost (₹)', data: this.regionTableData.currentTableState.costData });
                seriesData.push({ name: 'Cost/Conversion (₹)', data: this.regionTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            } else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({ name: 'Cost (₹)', data: this.regionTableData.currentTableState.costData });
                seriesData.push({ name: 'Revenue (₹)', data: this.regionTableData.currentTableState.revenueData, color: "#FCB116" });
            }

            this.regionTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData,
                disableYLabel: true
            }
            this.regionDataLoad = false;
        },


        processMonthTable(sortCol, dateFormat) {
            $.fn.dataTable.moment(dateFormat);
            var search = require("@/assets/Icons_SVG/Search.svg");
            const monthTable = $('#month-tbl').DataTable({
                info: false,
                sorting: [[sortCol, "asc"]],
                searching: true,
                lengthChange: true,
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#month-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            });
            $("#month-tbl" + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            monthTable.on('draw.dt', () => {
                var monthData, costData, cost_conversionData, revenueData, impressionData, clickData;
                monthData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];
                clickData = [];

                Array.from(monthTable.rows({ filter: 'applied' }).data()).forEach((value, index) => {
                    if (index < 10) {
                        monthData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[5].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });
                this.monthTableData.currentTableState = {
                    monthData: monthData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processMonthGraph();
            });
            $('#month-tbl').DataTable().draw();
        },
        processCampaignDataTable(sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            const campaignTable = $('#campaigndata-tbl').DataTable({
                info: false,
                sorting: [[sortCol, "desc"]],
                searching: true,
                lengthChange: true,
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#campaigndata-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            });

            $("#campaigndata-tbl" + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );

            campaignTable.on('draw.dt', () => {
                var campaignNameData, costData, cost_conversionData, revenueData, impressionData, clickData;

                campaignNameData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];
                clickData = [];
                Array.from(campaignTable.rows({ filter: 'applied' }).data()).forEach((value, index) => {
                    if (index < 10) {
                        campaignNameData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[5].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });

                this.campaignTableData.currentTableState = {
                    campaignNameData: campaignNameData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processCampaignDataGraph();
            });
            $('#campaigndata-tbl').DataTable().draw();

        },
        processCampaignTypeTable(sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            const campaignTypeTable = $('#campaigntype-tbl').DataTable({
                info: false,
                sorting: [[sortCol, "desc"]],
                searching: true,
                lengthChange: true,
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#campaigntype-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            });

            $("#campaigntype-tbl" + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );

            campaignTypeTable.on('draw.dt', () => {
                var networkData, costData, cost_conversionData, revenueData, impressionData, clickData;

                networkData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];
                clickData = [];
                Array.from(campaignTypeTable.rows({ filter: 'applied' }).data()).forEach((value, index) => {
                    if (index < 10) {
                        networkData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[5].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')));
                    }
                });
                this.campaignTypeTableData.currentTableState = {
                    networkData: networkData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processCampaignTypeGraph();
            });
            $('#campaigntype-tbl').DataTable().draw();
        },
        processDeviceTypeTable(sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            const deviceTypeTable = $('#devicetype-tbl').DataTable({
                info: false,
                sorting: [[sortCol, "desc"]],
                searching: true,
                lengthChange: true,
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#devicetype-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            });

            $("#devicetype-tbl" + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );

            deviceTypeTable.on('draw.dt', () => {
                var deviceData, costData, cost_conversionData, revenueData, impressionData, clickData;

                deviceData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];
                clickData = [];
                Array.from(deviceTypeTable.rows({ filter: 'applied' }).data()).forEach((value, index) => {
                    if (index < 10) {
                        deviceData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[5].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });
                this.deviceTypeTableData.currentTableState = {
                    deviceData: deviceData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processDeviceTypeGraph();
            });
            $('#devicetype-tbl').DataTable().draw();
        },
        processRegionTable(sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            const regionTable = $('#region-tbl').DataTable({
                info: false,
                sorting: [[sortCol, "desc"]],
                searching: true,
                lengthChange: true,
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#region-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            });

            $("#region-tbl" + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );

            regionTable.on('draw.dt', () => {
                var regionData, costData, cost_conversionData, revenueData, impressionData, clickData;

                regionData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];
                clickData = [];

                Array.from(regionTable.rows({ filter: 'applied' }).data()).forEach((value, index) => {
                    if (index < 10) {
                        regionData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[5].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });
                this.regionTableData.currentTableState = {
                    regionData: regionData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processRegionGraph();
            });
            $('#region-tbl').DataTable().draw();
        },

        //fetching API's
        getMonthData() {
            this.monthDataLoad = true;
            const url = `/api/adword/detailed?table_type=month&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.monthTableData.header = resData.headers;
                this.monthTableData.data = resData.data;
                this.$nextTick(() => {
                    this.processMonthTable(resData.sort_col.sort_column, resData.sort_col.format);
                });
                this.monthDataLoad = false;
            }).catch((err) => {
                console.log(err);
                this.monthDataLoad = false;
            })
        },
        getCampaignData() {
            this.campaignDataLoad = true;
            const url = `/api/adword/detailed?table_type=campaign&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.campaignTableData.header = resData.headers;
                this.campaignTableData.data = resData.data;
                this.$nextTick(() => {
                    this.processCampaignDataTable(resData.sort_col.sort_column);
                });
                this.campaignDataLoad = false;
            }).catch((err) => {
                console.log(err);
                this.campaignDataLoad = false;
            })
        },
        getCampaignTypeData() {
            this.campaignTypeDataLoad = true;
            const url = `/api/adword/detailed?table_type=camp_type&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.campaignTypeTableData.header = resData.headers;
                this.campaignTypeTableData.data = resData.data;
                this.$nextTick(() => {
                    this.processCampaignTypeTable(resData.sort_col.sort_column);
                });
                this.campaignTypeDataLoad = false;
            }).catch((err) => {
                console.log(err)
                this.campaignTypeDataLoad = false;
            })
        },
        getDeviceTypeData() {
            this.deviceTypeDataLoad = true;
            const url = `/api/adword/detailed?table_type=channel&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.deviceTypeTableData.header = resData.headers;
                this.deviceTypeTableData.data = resData.data;
                this.$nextTick(() => {
                    this.processDeviceTypeTable(resData.sort_col.sort_column);
                });
                this.deviceTypeDataLoad = false;
            }).catch((err) => {
                console.log(err)
                this.deviceTypeDataLoad = false;
            })
        },
        getRegionData() {
            this.regionDataLoad = true;
            this.regionTableData.header = [];
            this.regionTableData.data = [];
            $('#region-tbl').DataTable().destroy();
            const type = this.regionTableData.metricDropdown;

            const url = `/api/adword/detailed?table_type=geo&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&type=${type}`;
            axios.get(url).then((res) => {
                const resData = res.data;
                this.regionTableData.header = resData.headers;
                this.regionTableData.data = resData.data;
                this.$nextTick(() => {
                    this.processRegionTable(resData.sort_col.sort_column);
                });
                this.regionDataLoad = false;
            }).catch((err) => {
                console.log(err)
                this.regionDataLoad = false;
            })
        },
    },
}
