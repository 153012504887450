import moment from "moment";
import axios from "axios";
import Vuex from 'vuex';
import Loader from "@/components/Loader/Loader.vue";
import Targeting from "./Targeting.vue";
import { Modal } from "flowbite";
export default {
    name: "ProgrammaticData",
    components: {
        Loader,
        Targeting
    },
    data() {
        return {
            is_admin: false,
            loader: false,
            clientsList: [],
            selectedClient: null,
            campaignsList: [],
            selectedCampaign: null,
            lineItemsList: [],
            selectedLineItem: null,
            gaDateRange: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            gaFilterDateRange: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            source: "Display",
            medium: "BannerAds",
            click: "GA",
            gaCampaigns: [],
            selectedGACampaigns: [],
            allCampsSelected: false,
            mapCampaignText: "Map Now",
            autoUpdate: false,
            targetList: [
                { label: "Device", key: 'device' },
                { label: "Age", key: 'age' },
                { label: "Gender", key: 'gender' },
                { label: "Publisher", key: 'publisher' }
            ],
            selectedTarget: null,
            targetItems: {
                device: [
                    { label: "mobile", key: "mobile" },
                    { label: "desktop", key: "desktop" },
                    { label: "tablet", key: "tablet" }
                ],
                age: [
                    { label: "18-24", key: "age1824" },
                    { label: "25-34", key: "age2534" },
                    { label: "35-44", key: "age3544" },
                    { label: "45-54", key: "age4554" },
                    { label: "55-64", key: "age5564" },
                    { label: "65+", key: "age65plus" }
                ],
                gender: [
                    { label: "male", key: "male" },
                    { label: "female", key: "female" },
                    { label: "other", key: "other" }
                ],
                publisher: [
                    { label: "Small", key: "small" },
                    { label: "Medium", key: "medium" },
                    { label: "Large", key: "large" }
                ]
            },
            clickManually: false,
            showDataPrv: false,
            targetingDates: {
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().subtract(10, 'days').format('YYYY-MM-DD')
            },
            tblKeys: [],
            ctrCPCDecided: [],
            lastLogin: "",
            nonEditableList: {},
            isVideoView: true,
            minMaxRatios: null,
            renderFields: true,
            emptyVideo: ['video_25', 'video_50', 'video_75', 'video_100'],
            deleteModalName: "",
            addEditModalName: "",
            deleteModal: null,
            addEditModal: null,
            allDelete: false,
            deleteDateRange: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            addEditDateRange: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            deleteLevel: null,
            addEditLevel: null,
            newItemName: "",
            newItemBudget: "",
            customMetrices: [],
            allMetrices: {},
            newCustomMetric: '',
            uploadedCSV: null,
            finalUploadedData: [],
            uploadOrder: {},
            prvModal: null,
            finalTargetingError: '',
            showDetail: false,
            targetingPrvData: [],
            errorData: [],
            cpcCtrModal: null,
            rerender: true,
            modifierPrvTotal: {}
        }
    },
    computed: Vuex.mapState(['user', 'menu']),
    created() {
        const user = JSON.parse(localStorage.userData).data.username
        if (user === 'admin') {
            this.is_admin = true;
        }
    },
    mounted() {
        this.initializeModal();
        this.getClients();
    },
    methods: {
        initializeModal() {
            //Delete pop-up
            const $targetDeleteModal = document.getElementById("delete-modal");
            const deleteModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {
                    this.deleteModalName = "";
                    this.deleteLevel = "";
                },
            };

            //Add-Edit pop-up
            const $targetAddEditModal = document.getElementById("add-edit-modal");
            const addEditModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {
                    this.addEditModalName = "";
                    this.addEditLevel = "";
                    this.newItemName = "";
                    this.newItemBudget = "";
                    this.addEditDateRange.startDate = moment().format("YYYY-MM-DD");
                    this.addEditDateRange.endDate = moment().format("YYYY-MM-DD");
                    this.allMetrices = {};
                    this.newCustomMetric = "";
                    this.customMetrices = [];
                },
            };

            //Preview pop-up
            const $targetPreviewModal = document.getElementById("prv-modal");
            const previewModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {

                },
            };

            //CPC CTR pop-up
            const $targetcpcCtrModal = document.getElementById("cpcCtr-modal");
            const previewcpcCtrOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {

                },
            };

            //assigning modals
            this.deleteModal = new Modal($targetDeleteModal, deleteModalOptions);
            this.addEditModal = new Modal($targetAddEditModal, addEditModalOptions);
            this.prvModal = new Modal($targetPreviewModal, previewModalOptions);
            this.cpcCtrModal = new Modal($targetcpcCtrModal, previewcpcCtrOptions);
        },
        formatDate(date) {
            const incomingDate = moment(date);
            if(incomingDate.isValid()) {
                return incomingDate.format('DD MMM YYYY')
            } 
            return "Invalid Date"
        },
        getClients() {
            this.loader = true;
            this.selectedClient = null;
            axios.get("/api/users/").then(res => {
                this.clientsList = res.data.clients;
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false
            })
        },
        showDeleteModal(level) {
            this.deleteLevel = level;

            if (level == 'account') {
                if (!this.selectedClient) {
                    alert("Select Account");
                    return;
                }
                this.deleteModalName = this.selectedClient.name
            }
            else if (level == 'campaign') {
                if (!this.selectedCampaign) {
                    alert("Select Campaign");
                    return;
                }
                this.deleteModalName = this.selectedCampaign.name
            }
            else if (level == 'lineItem') {
                if (!this.selectedLineItem) {
                    alert("Select Line Item");
                    return;
                }
                this.deleteModalName = this.selectedLineItem.name
            }
            this.deleteModal.show()
        },
        showAddEditModal(level, action) {
            this.addEditLevel = level;

            if (level == 'campaign') {
                this.addEditModalName = `Add Campaign`;
                if (action === 'Edit' && this.selectedCampaign) {
                    this.addEditModalName = `Edit "${this.selectedCampaign.name}"`
                    this.editCampaign();
                    this.addEditModal.show();
                } else if (action === 'Add') {
                    this.addEditModal.show();
                } else {
                    alert("Select Campaign");
                    return;
                }
            } else if (level == 'lineItem') {
                this.addEditModalName = `Add Line Item`;
                this.lineitemDefaultModalData();

                if (action === 'Edit' && this.selectedLineItem) {
                    this.addEditModalName = `Edit "${this.selectedLineItem.name}"`
                    this.editLineitem();
                    this.addEditModal.show();
                } else if (action === 'Add') {
                    this.addEditModal.show();
                } else {
                    alert("Select Line Item");
                    return;
                }
            }

        },
        editCampaign() {
            this.loader = true;
            this.newItemName = "";
            this.newItemBudget = "";
            this.addEditDateRange.startDate = moment().format("YYYY-MM-DD")
            this.addEditDateRange.endDate = moment().format("YYYY-MM-DD")

            const url = `/api/report/campaign?campaign_id=${this.selectedCampaign.id}`;

            axios.get(url).then(response => {
                let data = response.data.data;
                this.newItemName = data.camp_name;
                this.newItemBudget = data.camp_budget;
                this.addEditDateRange.startDate = moment(data.start_date).format("YYYY-MM-DD");
                this.addEditDateRange.endDate = moment(data.end_date).format("YYYY-MM-DD");
                this.loader = false;
            }).catch(error => {
                console.error('Error fetching campaign data:', error);
                this.loader = false;
            });
        },
        editLineitem() {
            this.loader = true;
            this.lineitemDefaultModalData();

            const url = `/api/report/lineitem?lineitem_id=${this.selectedLineItem.id}`;

            axios.get(url).then(response => {
                let data = response.data.data;
                this.newItemName = data.lineitem_name;
                this.newItemBudget = data.lineitem_budget;

                for (let i = 0; i < data.active_fields.length; i++) {
                    var d = data.active_fields[i];
                    if (this.allMetrices[d]) {
                        this.allMetrices[d].value = true;
                    } else {
                        this.customMetrics.push(d);
                    }
                }

                this.loader = false;
            }).catch(error => {
                console.error('Error fetching lineitem data:', error);
                this.loader = false;
            });
        },

        getCampaigns() {
            this.loader = true;
            this.campaignsList = [];
            this.lineItemsList = [];
            this.selectedCampaign = null;
            this.selectedLineItem = null;
            axios.get(`/api/report/campaign?account_id=${this.selectedClient.id}`).then(res => {
                this.campaignsList = res.data.data
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        getLineItems() {
            this.loader = true;
            this.selectedLineItem = null;
            axios.get(`/api/report/lineitem?campaign_id=${this.selectedCampaign.id}`).then(res => {
                this.lineItemsList = res.data.data;
                this.gaDateRange.startDate = moment(res.data.dates.start_date).format("YYYY-MM-DD");
                this.gaDateRange.endDate = moment(res.data.dates.end_date).format("YYYY-MM-DD");
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getGACampaignsList() {
            this.loader = true;
            axios.get(`/api/getgacampaignlist?account_name=${this.selectedClient.name}&account_id=${this.selectedClient.id}&start_date=${this.gaDateRange.startDate}&end_date=${this.gaDateRange.endDate}&source_medium=${this.source + " / " + this.medium}`).then(res => {
                this.gaCampaigns = res.data.data;
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        saveGACampaign() {
            let sourceMedium = null;

            if (this.source && this.medium) {
                sourceMedium = `${this.source} / ${this.medium}`;
            }

            this.loader = true;
            const url = '/api/report/utmsource';
            const data = {
                lineitem_id: this.lineitem_id,
                source_utm: sourceMedium,
                adword_camp_id: this.allCampsSelected ? ['all'] : this.selectedGACampaigns.id,
            };

            axios.post(url, data).then((response) => {
                this.loader = false;
                this.mapCampaignText = 'Mapped';
                setTimeout(() => {
                    this.mapCampaignText = 'Map Again';
                }, 5000);
                alert('Mapping Saved');
            }).catch(() => {
                alert('Error');
            });
        },
        lastSavedRatios() {
            this.loader = true;
            this.tblKeys = [];
            this.ctrCPCDecided = [];
            const url = `/api/report/uploadmodifer?lineitem_id=${this.selectedLineItem.id}&start_date=${moment("2020-12-01").format("YYYY-MM-DD")}&end_date=${moment("2020-12-31").format("YYYY-MM-DD")}`;
            axios.get(url).then((response) => {
                this.loader = false;
                if (response.data.success) {
                    this.ctrCPCDecided = response.data.data;
                    this.lastLogin = response.data.last_login;
                    this.nonEditableList = {};

                    response.data.data.forEach((d) => {
                        if (moment(d.date) <= moment(response.data.last_login)) {
                            this.nonEditableList[d.date] = true;
                        }
                    });

                    this.tblKeys = response.data.order;
                    this.calculateTotal();

                    this.$nextTick(() => {
                        this.cpcCtrModal.show()
                    })
                } else {
                    alert(response.data.summary);
                }
            }).catch((error) => {
                self.loader = false;
                console.error('Error fetching last saved ratios:', error);
            });

        },
        makeEditable(date) {
            const self = this;

            if (self.nonEditableList[date]) {
                if (confirm(`Last login at ${moment(self.lastLogin).format('DD MMM YYYY')}`)) {
                    if (confirm('Are you sure to edit')) {
                        for (const [d, i] of Object.entries(self.ctrCPCDecided)) {
                            self.nonEditableList[d.date] = false;
                        }
                        self.rerender = false;
                        self.rerender = true;
                        this.cpcCtrModal.hide();
                        this.cpcCtrModal.show()
                    }
                }
            }
        },

        calculateTotal() {
            const self = this;
            const total = {};

            self.tblKeys.forEach((v) => {
                total[v.key] = 0;
            });

            self.ctrCPCDecided.forEach((d) => {
                self.tblKeys.forEach((v) => {
                    if (v.key !== 'ratio' && v.key !== 'ctr' && v.key !== 'cpc') {
                        total[v.key] += Number(d[v.key]);
                    }
                });
            });

            total['ratio'] = (total.clicks / total.sessions) * 100;
            total['ctr'] = (total.clicks / total.impressions) * 100;
            total['cpc'] = total.cost / total.clicks;

            self.modifierPrvTotal = total;
        },

        saveRatiosNow() {
            const self = this;

            if ($('#minMaxForm').form('is valid')) {
                self.loader = true;
                const url = "/api/report/uploadmodifer";
                const data = {};
                self.sum = {};
                self.ctrCPCDecided = [];
                self.tblKeys = [];

                self.minMaxRatios.forEach((d) => {
                    if (d.min) {
                        data[`${d.key}_min`] = d.min;
                    } else {
                        data[`${d.key}_min`] = null;
                    }

                    if (d.max) {
                        data[`${d.key}_max`] = d.max;
                    } else {
                        data[`${d.key}_max`] = null;
                    }
                });

                data["lineitem_id"] = self.selectedLineItem.id;
                data["start_date"] = self.gaFilterDateRange.startDate;
                data["end_date"] = self.gaFilterDateRange.endDate;
                data["auto_update"] = self.autoUpdate;

                axios.post(url, data).then((response) => {
                    self.loader = false;

                    if (response.data.success) {
                        this.cpcCtrModal.show();
                        self.lastLogin = response.data.last_login;

                        if (response.data.data.length) {
                            self.ctrCPCDecided = response.data.data;
                            self.tblKeys = response.data.order;
                            self.calculateTotal();
                            self.nonEditableList = {};

                            response.data.data.forEach((d) => {
                                if (moment(d.date) <= moment(response.data.last_login)) {
                                    self.nonEditableList[d.date] = true;
                                }
                            });
                        }

                        self.$nextTick(() => {
                            this.cpcCtrModal.hide();
                            this.cpcCtrModal.show();
                        });
                    } else {
                        alert(response.data.summary);
                    }
                }).catch((error) => {
                    self.loader = false;
                    console.error('Error saving ratios:', error);
                });
            }
        },

        chooseLineItem() {
            const self = this;
            self.createMinMax();

            for (const d of self.lineItemsList) {
                if (d.id === self.selectedLineItem.id) {
                    if (d.active_fields.indexOf('videoviews') !== -1) {
                        self.isVideoViews = true;
                    } else {
                        self.isVideoViews = false;
                    }
                }
            }
        },
        createMinMax() {
            self = this
            self.minMaxRatios = [
                { label: 'Sessions-Clicks Ratio', key: 'ratio', min: '', max: '', video: false, disable: false },
                { label: 'CTR Ratio', key: 'ctr', min: '', max: '', video: false, disable: false },
                { label: 'CPC Ratio', key: 'cpc', min: '', max: '', video: false, disable: false },
                { label: 'Impression-Video Ratio', key: 'imp_to_videoview', min: '', max: '', video: false, disable: false },
                { label: 'Video 25% played', key: 'video_25', min: '', max: '', video: true, disable: false },
                { label: 'Video 50% played', key: 'video_50', min: '', max: '', video: true, disable: false },
                { label: 'Video 75% played', key: 'video_75', min: '', max: '', video: true, disable: false },
                { label: 'Video 100% played', key: 'video_100', min: '', max: '', video: true, disable: false }
            ]
        },
        minMaxKeyup(d, type) {
            const self = this;

            if (d.video) {
                if (d.min || d.max) {
                    if (self.emptyVideo.indexOf(d.key) !== -1) {
                        self.emptyVideo.splice(self.emptyVideo.indexOf(d.key), 1);
                    }
                } else {
                    if (self.emptyVideo.indexOf(d.key) === -1) {
                        self.emptyVideo.push(d.key);
                    }
                }

                if (self.emptyVideo.length === 1) {
                    for (const dt of self.minMaxRatios) {
                        if (dt.key === self.emptyVideo[0]) {
                            dt.disable = true;
                        }
                    }
                } else {
                    for (const dt of self.minMaxRatios) {
                        if (dt.key === self.emptyVideo[0]) {
                            dt.disable = false;
                        }
                    }
                }

                self.renderFields = false;
                self.renderFields = true;
            }
        },

        uploadFile(e) {
            this.uploadedCSV = e.target.files[0];
        },
        uploadData() {
            if (!this.uploadedCSV) {
                alert('Please choose a file to upload');
                return;
            }

            if (this.finalUploadedData.length) {
                $('#prvTable').DataTable().destroy();
            }

            this.loader = true;
            this.uploadOrder = {};

            const data = new FormData();
            data.append('data', this.uploadedCSV);
            data.append('lineitem_id', this.selectedLineItem.id);
            data.append('click_manually', this.clickManually);
            data.append('account_id', this.selectedClient.id);
            data.append('account_name', this.selectedClient.name);

            axios.post('/api/report/reportdata', data).then(response => {
                this.loader = false;

                if (response.data.success) {
                    this.showDataPrv = true;
                    this.finalUploadedData = response.data.data;

                    for (const v of response.data.order) {
                        this.uploadOrder[Object.keys(v)[0]] = v[Object.keys(v)[0]];
                    }

                    this.$nextTick(() => {
                        $('#prvTable').DataTable();
                    });
                } else {
                    alert(response.data.message);
                }
            }).catch(err => {
                alert('Error', err);
            });
        },
        saveCSVData() {
            this.loader = true;

            const url = `/api/report/savedata?lineitem_id=${this.selectedLineItem.id}`;
            const data = {
                data: this.finalUploadedData,
            };

            axios.post(url, data).then((response) => {
                alert('Saved');
                this.loader = false;
                console.log('saved');
            }).catch((error) => {
                alert('Error');
            });
        },

        SaveTargeting(targetItems, from_ga, dates) {
            const self = this;
            self.loader = true;
            self.targetingPrvData = [];
            const url = `/api/report/${self.selectedTarget.key}targeting`;
            const data = {
                lineitem_id: self.lineitem_id,
                from_ga: from_ga,
                start_date: dates.start_date,
                end_date: dates.end_date,
            };

            if (!from_ga) {
                targetItems.forEach((d) => {
                    data[`${d.key}_min`] = d.min;
                    data[`${d.key}_max`] = d.max;
                });
            }

            self.sum = {};
            self.showDetail = false;

            axios.post(url, data).then((response) => {
                self.loader = false;

                if (response.data.success) {
                    self.targetingPrvData = response.data.data;

                    response.data.data.forEach((d, i) => {
                        Object.entries(d).forEach(([k, v]) => {
                            if (k !== 'date') {
                                if (!self.sum[k]) {
                                    self.sum[k] = 0;
                                }
                                self.sum[k] += Number(v);
                            }
                        });

                        if (i === response.data.data.length - 1) {
                            Object.entries(self.sum).forEach(([k2, v2]) => {
                                self.sum[k2] = v2 / response.data.data.length;
                            });
                        }
                    });

                    console.log('saved');

                    if (self.finalTargetingError) {
                        $('#errorDatesTable').DataTable().destroy();
                        self.errorData = [];
                        self.finalTargetingError = '';
                    }
                    this.$nextTick(() => {
                        this.prvModal.show()
                    })
                } else {
                    alert(response.data.summary);
                }
            }).catch((error) => {
                self.loader = false;
                console.error('Error saving targeting:', error);
            });
        },
        finalSave: () => {
            this.loader = true;

            if (this.finalTargetingError) {
                $('#errorDatesTable').DataTable().destroy();
                this.errorData = [];
                this.finalTargetingError = '';
            }

            const url = `/api/report/save${this.selectedTarget.key}?lineitem_id=${this.selectedLineItem.id}`;
            const requestData = {
                data: this.targetingPrvData
            };

            axios.post(url, requestData).then((response) => {
                this.loader = false;
                if (response.data.success) {
                    alert('Saved');
                    this.prvModal.hide();
                } else {
                    this.finalTargetingError = response.data.summary;
                    this.errorData = response.data.data;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            $('#errorDatesTable').DataTable({ filter: false, bSort: false, pageLength: 50 });
                            $('.dataTables_info').remove();
                            $('.dataTables_length').remove();
                        }, 0);
                    });
                }
            })
                .catch((error) => {
                    alert('Error', error);
                });
        },
        finalSaveCost() {
            this.loader = true;
            const url = '/api/report/savemodifier';
            const data = {
                lineitem_id: this.selectedLineItem.id,
                data: this.ctrCPCDecided,
                auto_update: this.autoUpdate,
            };

            axios.post(url, data).then((res) => {
                alert('Saved');
                this.loader = false;
            }).catch((err) => {
                console.log('error');
                this.loader = false;
            });
        },

        changeModifierData: (d, key) => {
            const self = this;

            if (!d[key]) {
                d[key] = 0;
            } else {
                d[key] = Number(d[key]);
            }

            if (isNaN(d[key])) {
                alert('Please enter only numbers');
                d[key] = 0;
            } else {
                if (key === 'impressions') {
                    d.ctr = (d.clicks / d.impressions) * 100;
                    d.imp_to_videoview = d.videoviews / d.impressions;
                } else if (key === 'clicks') {
                    d.cpc = d.cost / d.clicks;
                    d.ctr = (d.clicks / d.impressions) * 100;
                    d.ratio = (d.clicks / d.sessions) * 100;
                } else if (key === 'cost') {
                    d.cpc = d.cost / d.clicks;
                } else if (key === 'videoviews') {
                    d.video_25 = (d.videoviews * d.videoview_25_perc) / 100;
                    d.video_50 = (d.videoviews * d.videoview_50_perc) / 100;
                    d.video_75 = (d.videoviews * d.videoview_75_perc) / 100;
                    d.video_100 = (d.videoviews * d.videoview_100_perc) / 100;
                    d.imp_to_videoview = d.videoviews / d.impressions;
                }
                self.calculateTotal();
            }
        },

        saveFilteredPublisher(publishers) {
            const self = this;
            self.loader = true;
            const url = "/api/report/publisherfilter";
            const data = {
                lineitem_id: self.lineitem_id,
                filter: publishers
            };

            axios.post(url, data).then((response) => {
                alert('Saved');
                self.loader = false;
            }).catch((error) => {
                console.error('Error saving filtered publisher:', error);
                self.loader = false;
            });
        },
        getText(disabled, text) {
            return disabled === true ? "Remaining" : text
        },
        deleteNow() {
            const self = this;
            if (confirm('Are you sure?')) {
                self.loader = true;
                let levelId = '';

                if (self.deleteLevel === 'account') {
                    levelId = self.selectedClient.id;
                } else if (self.deleteLevel === 'campaign') {
                    levelId = self.selectedCampaign.id;
                } else if (self.deleteLevel === 'lineitem') {
                    levelId = self.selectedLineItem.id;
                }

                const url = "/api/report/deletedata";
                const data = {
                    level_type: self.deleteLevel,
                    level_id: levelId,
                };

                if (!self.allDelete) {
                    data.start_date = moment(self.deleteDateRange.startDate).format('YYYY-MM-DD');
                    data.end_date = moment(self.deleteDateRange.endDate).format('YYYY-MM-DD');
                }

                axios.post(url, data).then((response) => {
                    alert('Success');
                    self.loader = false;
                    if (self.deleteLevel != 'lineitem') self.getCampaigns();
                    else self.getLineItems();
                    this.deleteModal.hide()
                }).catch((error) => {
                    alert('Failed');
                    self.loader = false;
                    console.error('Error:', error);
                });
            }
        },
        lineitemDefaultModalData() {
            this.newCustomMetric = '';
            this.customMetrices = [];
            this.allMetrices = {
                clicks: { label: 'Clicks', isDisabled: true, value: true },
                cost: { label: 'Cost', isDisabled: true, value: true },
                impressions: { label: 'Impressions', isDisabled: true, value: true },
                sessions: { label: 'Sessions', isDisabled: true, value: true },
                ctr: { label: 'CTR', isDisabled: true, value: true },
                cpc: { label: 'CPC', isDisabled: true, value: true },
                pageviews: { label: 'Page Views', isDisabled: false, value: false },
                videoviews: { label: 'Video Views', isDisabled: false, value: false },
                cpm: { label: 'CPM', isDisabled: false, value: false }
            };
        },
        addNewCustomMetric() {
            if (this.customMetrices.indexOf(this.newCustomMetric) == -1) {
                this.customMetrices.push(this.newCustomMetric)
                this.newCustomMetric = ''
            } else alert('Metric name already exist')
        },

        delCustomMetric(i) {
            this.customMetrices.splice(i, 1)
        }



    }
}