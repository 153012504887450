<template lang='pug'>
div
    div(v-if='loader')
        Loader
    div.font-medium.text-center.text-gray-500.mb-4(v-else class="m-3 xl:text-base 2xl:text-lg")
        ul.flex.flex-wrap.-mb-px
            li
                button.inline-block.p-4.border-b-2.rounded-t-lg.text-base(type='button' :class="{'border-primary_brand-80 text-primary_brand-80': tabs.upload}" @click="toggleTab('upload')") Upload Creatives 
            li
                button.inline-block.p-4.border-b-2.rounded-t-lg.text-base(type='button' :class="{'border-primary_brand-80 text-primary_brand-80': tabs.capture}" @click="toggleTab('capture')") Capture
            li
                button.inline-block.p-4.border-b-2.rounded-t-lg.text-base(type='button' :class="{'border-primary_brand-80 text-primary_brand-80': tabs.multiCapture}" @click="toggleTab('multiCapture')") Multi URL Capture
    div.w-card.m-3
        div(v-show="tabs.upload")
            div.flex.gap-4.my-2
                input(type='file', id="uploadCreative",@change="uploadCreative($event.target.files)" class="rounded-md bg-gray-300 ")
                button(@click="uploadNow" type="button" class="px-2 py-2 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white") Upload Now

            div.mt-4
                div.flex.justify-between.border-b-2.border-base_color-40.pb-2
                    h3(class="2xl:text-xl xl:text-base font-semibold text-primary_brand") Uploaded Creatives 
                    button(@click="multiDelete" type="button" class="px-2 py-2 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white") Delete Marked
                table.mt-3.rounded-corners
                    thead
                        tr
                            th Mark
                            th Dimension
                            th Creatives
                    tbody.bordered
                        tr(v-for="d,i in creatives")
                            td.text-center
                                input(type="checkbox", class="cursor-pointer rounded focus:outline-primary_brand mt-3 text-primary_brand" v-model="creativeCheck[i]", @click="markCreative")
                            td(style="width:30%") {{ i }}  
                            td(style="width:70%")
                                div(style="height:200px;overflow-y:scroll")
                                    div(v-for="img,j in d",@click="viewImg(img)")
                                        img(:src="img.image_url")                                
        div(v-show="tabs.capture") 
            div.flex.gap-3
                div
                div.customMultiselect.w-96
                    multiselect(
                        v-model="device"
                        :options="[{name: 'Desktop', value: 'desktop'}, {name: 'Mobile', value: 'mobile'}]"
                        :searchable="false"
                        :show-labels="false" 
                        :multiple="false"
                        :allow-empty="true"
                        track-by="value"
                        label="name"
                        open-direction="bottom"
                        placeholder="Select Device"
                        @input="deviceChange"
                        )
                div.customMultiselect.w-96
                    multiselect(
                        v-model="resolution"
                        :options="resolutions"
                        :searchable="false"
                        :show-labels="false" 
                        :multiple="false"
                        :allow-empty="true"
                        track-by="value"
                        label="name"
                        open-direction="bottom"
                        placeholder="Select Resolution"
                        )
                div.ui.four.wide.field
                    input(type="text", class="rounded border-[1px] h-[2.2rem] border-primary_brand-80 focus:outline-none focus:ring-primary_brand-80 focus:border-[0px] text-sm text-primary_brand-80 mr-2 w-96" v-model="siteUrl",placeholder="Enter URL") 
                div.field
                    button(type="button" class="px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" @click="getAds") Submit     
                div.field(v-if="orignalImage")
                    button(type="button" class="px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" @click="downOriginal") Download Original   
            table.mt-3.rounded-corners.w-full
                thead 
                    tr
                        th(style="width:7%") Dimension
                        th(style="width:18%") Google Ads
                        th(style="width:80%") Creatives
                tbody.bordered
                    tr(v-for="d in allAds")
                        td {{ d.width + 'x' +  d.height }}
                        td
                            div(style="display:inline-block")
                                div(style="float:left")
                                    img(:src='d.image_url',@click="viewImg(d)")            
                        td.relative
                            button.compact.small.label.pink.ui.clearradio(@click="clearRadio(d.image_id)")  Clear
                            div.creativeList(v-if="creatives[d.width + 'x' +  d.height]")  
                                div(v-for="e,i in creatives[d.width + 'x' +  d.height]")
                                    img(:src='e.image_url',style="margin:10px;margin-top:0",@click="viewImg(e)") 
                                    div.ui.form                        
                                        .field
                                            .ui.radio.checkbox( @click="select(d.image_id,e.creative_id)")
                                                input.allradio.hidden(:class="d.image_id",type='radio', :name="'imgs' + d.image_id")
                            div(v-else)  
                                div No Creative                  

            button(type="button" class="mt-4 px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" @click="captureNow") Capture Now        
        div(v-show="tabs.multiCapture") 
            div
                div(v-if="blockSubmit")
                    Loader 
                div.grid.grid-cols-4.gap-3
                    div.customMultiselect
                        multiselect(
                            v-model="device"
                            :options="[{name: 'Desktop', value: 'desktop'}, {name: 'Mobile', value: 'mobile'}]"
                            :searchable="false"
                            :show-labels="false" 
                            :multiple="false"
                            :allow-empty="true"
                            track-by="value"
                            label="name"
                            open-direction="bottom"
                            placeholder="Select Device"
                            @input="deviceChange"
                            )
                    div.customMultiselect
                        multiselect(
                            v-model="resolution"
                            :options="resolutions"
                            :searchable="false"
                            :show-labels="false" 
                            :multiple="false"
                            :allow-empty="true"
                            track-by="value"
                            label="name"
                            open-direction="bottom"
                            placeholder="Select Resolution"
                            )

                    div(v-for="d,i in urls") 
                        input(type="text", class="rounded border-[1px] h-[2.2rem] border-primary_brand-80 focus:outline-none focus:ring-primary_brand-80 focus:border-[0px] text-sm text-primary_brand-80 mr-2 w-full",v-model="urls[i]",placeholder="Enter URL")   
                div.flex.gap-3
                    div
                        button(type="button" class="mt-4 px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" @click="urls.push('')") Add More        
                    div 
                        button(type="button" class="mt-4 px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" @click="getMultiAds") Submit     
            div(v-if="siteZips.length")
                div
                    h3(class="2xl:text-xl xl:text-base font-semibold text-primary_brand") Screenshots
                div.grid.grid-cols-2.gap-3
                    div.field(v-for="d in siteZips")
                        div.zip
                            div
                                div {{ d.site_name }}
                            div
                                div(v-if="!d.file_path")
                                    Loader
                                button(type="button" class="mt-4 px-2 py-1.5 rounded-md bg-primary_brand hover:bg-primary_brand-80 text-white" v-if="d.file_path",@click="multiDownloadZip(d.file_path)") Download ZIP

    div(id="img-modal" aria-hidden="true" tabindex='-1', class="fixed top-10 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full")
        div.rounded-lg(class="bg-white 2xl:w-1/2 sm:w-1/2 pb-0")
            div.relative.bg-white.rounded-lg.h-full
                div.bg-primary_brand-20.rounded-t-lg.border-solid.border-b.flex.justify-between.px-3.py-4.mb-2
                    div.h3.capitalize.font-semibold(class="2xl:text-2xl xs:text-sm") Creative Preview 
                    div.pr-4.cursor-pointer(@click="imgModal.hide()") 
                        font-awesome-icon.text-base_color-60(icon="fa-solid fa-close" class="mr-1 rounded-lg hover:bg-gray-200 hover:text-gray-900 p-1.5 text-sm.ml-auto.inline-flex.items-center'")
                div.m-3
                    div.flex.gap-3.items-center.justify-end.pb-2.border-b-2
                        button(@click="processDelete", type="button" class="h-4/5 px-2 py-2 bg-red-80 hover:bg-red-60 rounded-md text-white") Delete
                    div.flex.justify-center.items-center.m-2.p-2
                        img(:src="imgprv.image_url")
    
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import { Modal } from "flowbite";

export default {
    name: 'AdScreenshot',
    components: {
        Loader
    },
    data() {
        return {
            data: [],
            siteUrl: '',
            allAds: [],
            files: [],
            creatives: [],
            imgprv: {},
            loader: '',
            images: {},
            siteZips: [],
            count: 0,
            urls: ['', ''],
            siteIds: [],
            siteId: '',
            device: { name: 'Desktop', value: 'desktop' },
            resolution: { name: "Auto", value: 'auto' },
            creativeCheck: {},
            deleteIds: [],
            resolutions: [
                { name: "Auto", value: 'auto' },
            ],
            orignalImage: null,
            blockSubmit: false,
            tabs: {
                upload: true,
                capture: false,
                multiCapture: false
            },
            imgModal: null
        };
    },
    mounted() {
        const self = this;
        this.initializeModal();
        this.resolutions = [
                { name: "Auto", value: 'auto' }
            ];

            if (this.device.value === 'mobile') {
                this.resolutions.push(
                    { value: '240,320', name: '240x320' },
                    { value: '320,480', name: '320x480' },
                    { value: '480,800', name: '480x800' },
                    { value: '720,1280', name: '720x1280' },
                    { value: '1080,1920', name: '1080x1920' },
                )
            } else if (this.device.value === 'desktop') {
                this.resolutions.push(
                    { value: '1920,1080', name: '1920x1080' },
                    { value: '1366,768', name: '1366x768' },
                    { value: '1280,720', name: '1280x720' },
                    { value: '1024,768', name: '1024x768' },
                )
            }
            this.resolution = this.resolutions[0];
        self.getCreatives();
    },
    methods: {
        initializeModal() {
            //Image pop-up
            const $targetImgModal = document.getElementById("img-modal");
            const imgModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {
                    this.imgprv = {};
                }
            };

            //assigning modals
            this.imgModal = new Modal($targetImgModal, imgModalOptions);
        },

        multiDownloadZip(zipurl) {
            window.location = zipurl;
        },
        checkMultiData() {
            const self = this;
            const data = {};
            self.count = 0;
            data.site_list = self.siteIds;
            const url = '/api/customads/autorelpace/status';
            axios.post(url, JSON.stringify(data), {
                headers: { 'Content-type': 'application/json' },
            })
                .then((response) => {
                    self.siteZips = response.data.data;
                    response.data.data.forEach((d) => {
                        if (d.file_path) {
                            self.count++;
                        }
                    });
                    if (self.count !== self.siteIds.length) {
                        setTimeout(() => {
                            self.checkMultiData();
                        }, 10000);
                    } else {
                        self.blockSubmit = false;
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                });
        },
        getMultiAds() {
            const self = this;
            const siteUrls = self.urls.filter(Boolean);
            if (siteUrls.length) {
                const urlRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
                for (const d of siteUrls) {
                    if (!urlRegExp.test(d)) {
                        alert(`'${d}' is not a valid url`);
                        return;
                    }
                }
                self.loader = true;
                self.blockSubmit = true;
                let winSize = '';
                if (self.resolution.value !== 'auto') {
                    winSize = self.resolution.value;
                } else {
                    winSize = window.screen.width;
                    winSize += `,${window.innerHeight}`;
                }
                const url = '/api/customads/autoreplace';
                const data = {
                    window_size: winSize,
                    urls: siteUrls,
                };
                axios.post(url, data).then((response) => {
                    self.loader = false;
                    self.siteIds = response.data.data;
                    self.checkMultiData();
                }).catch((error) => {
                    console.log('errr', error);
                });
            }
        },
        multiDelete() {
            const self = this;
            if (self.deleteIds.length) {
                self.deleteCreative(self.deleteIds);
            } else {
                alert('Please mark to delete');
            }
        },
        processDelete() {
            const self = this;
            const arr = [];
            arr.push(self.imgprv.creative_id);
            self.deleteCreative(arr);
        },
        deleteCreative(arr) {
            const self = this;
            if (confirm('Are you sure')) {
                const data = {
                    creative_images: arr,
                };
                axios.delete('/api/report/creative', { data: data }).then(() => {
                    // $('#imgModal').modal('hide');
                    self.creativeCheck = [];
                    self.deleteIds = [];
                    self.getCreatives();
                })
                    .catch(() => {
                        alert('Error');
                    });
            }
        },
        markCreative() {
            const self = this;
            setTimeout(() => {
                self.deleteIds = [];
                Object.entries(self.creativeCheck).forEach(([idx, dataset]) => {
                    if (dataset) {
                        self.creatives[idx].forEach((dataset2) => {
                            self.deleteIds.push(dataset2.creative_id);
                        });
                    }
                });
            }, 0);
        },
        deviceChange() {
            this.resolutions = [
                { name: "Auto", value: 'auto' }
            ];

            if (this.device.value === 'mobile') {
                this.resolutions.push(
                    { value: '240,320', name: '240x320' },
                    { value: '320,480', name: '320x480' },
                    { value: '480,800', name: '480x800' },
                    { value: '720,1280', name: '720x1280' },
                    { value: '1080,1920', name: '1080x1920' },
                )
            } else if (this.device.value === 'desktop') {
                this.resolutions.push(
                    { value: '1920,1080', name: '1920x1080' },
                    { value: '1366,768', name: '1366x768' },
                    { value: '1280,720', name: '1280x720' },
                    { value: '1024,768', name: '1024x768' },
                )
            }
            this.resolution = this.resolutions[0];
        },
        clearRadio(id) {
            const self = this;
            delete self.images[id];
            $(`.${id}`).prop('checked', false);
        },
        select(d, e) {
            const self = this;
            self.images[d] = e;
        },
        captureNow() {
            const self = this;
            self.loader = true;
            const img = [];
            Object.entries(self.images).forEach(([idx, dataset]) => {
                const obj = {};
                obj.image_id = idx;
                obj.creative_id = dataset;
                img.push(obj);
            });
            const data = {
                site_id: self.siteId,
                ad_images: img,
            };
            axios.post('/api/customads', JSON.stringify(data), {
                headers: { 'Content-type': 'application/json' },
            })
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = response.data.data.image_url;
                    link.download = `screenshot_${moment().format('DDMMMYYYYHHmmss')}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    self.loader = false;
                })
                .catch(() => {
                    alert('Error');
                });
        },
        downOriginal() {
            const self = this;
            const link = document.createElement('a');
            link.href = self.orignalImage;
            link.download = `original_screenshot_${moment().format('DDMMMYYYYHHmmss')}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        checkData() {
            const self = this;
            setTimeout(() => {
                axios.get(`/api/customadsstatus?site_id=${self.siteId}`)
                    .then((response) => {
                        if (!response.data.data.image_status) {
                            self.checkData();
                        } else {
                            self.allAds = response.data.data.ads_data;
                            self.orignalImage = response.data.data.site_image_url;
                            self.$nextTick(() => {
                                self.loader = false;
                                $('.ui.radio.checkbox').checkbox();
                            });
                        }
                    })
                    .catch(() => {
                        console.log('Error');
                    });
            }, 5000);
        },
        getAds() {
            const self = this;
            self.images = {};
            $('.allradio').prop('checked', false);
            let winSize = '';
            if (self.resolution.value !== 'auto') {
                winSize = self.resolution.value;
            } else {
                winSize = window.screen.width;
                winSize += `,${window.innerHeight}`;
            }
            if (self.siteUrl) {
                const urlRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
                if (!urlRegExp.test(self.siteUrl)) {
                    alert(`'${self.siteUrl}' is not a valid url`);
                    return;
                }
                self.loader = true;
                const url = `/api/customads?url=${self.siteUrl}&window_size=${winSize}`;
                axios.get(url)
                    .then((response) => {
                        self.siteId = response.data.data.site_id;
                        self.checkData();
                    })
                    .catch(() => {
                        self.loader = false;
                        alert('Error');
                    });
            }
        },
        viewImg(img) {
            const self = this;
            self.imgprv = img;
            self.$nextTick(() => {
                this.imgModal.show()
            });
        },
        getCreatives() {
            const self = this;
            const url = '/api/report/creative';
            axios.get(url)
                .then((response) => {
                    self.creatives = response.data.data;
                })
                .catch(() => {
                    alert('Error');
                });
        },
        uploadCreative(file) {
            this.files = file;
        },
        uploadNow() {
            const self = this;
            const arr = [];
            if (self.files.length) {
                const data = new FormData();
                self.files.forEach((file) => {
                    data.append('file', file);
                });
                axios.post('/api/report/creative', data, {
                    cache: false,
                    processData: false,
                    contentType: false,
                })
                    .then(() => {
                        $('#uploadCreative').val('');
                        self.getCreatives();
                    })
                    .catch(() => {
                        alert('error');
                    });
            }
        },
        toggleTab(variable) {
            for (let tabKey of Object.keys(this.tabs)) {
                if (tabKey === variable) {
                    this.tabs[variable] = true
                } else {
                    this.tabs[tabKey] = false;
                }
            }
        },
    },
};

</script>
<style scoped>
.ui.fields {
    justify-content: center
}

/* #adChanger .button,
input {
    height: 32px !important;
} */

.captureTbl img {
    width: 150px;
    height: 100px;
    border: 2px solid silver;
}

.creativeTbl img {
    width: 40%;
    border: 1px solid grey !important;
    margin: 2px;
}

.creativeList {
    display: flex;
    overflow-x: scroll;
    width: 856px;
    text-align: center;
    height: 184px;
}

td {
    vertical-align: top
}

#imgModal img {
    max-width: 100% !important;
}

.zip {
    background: whitesmoke;
    text-align: center;
    padding: 10px;
    border: 1px solid silver;
    height: 100%;
}

.ui.active.loader {
    position: relative;
    margin-top: 30px;
}

.clearradio {
    position: absolute;
    right: 0;
    cursor: pointer;
}

.relative {
    position: relative;
}
</style>