import moment from "moment";
import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";
import lineChart from "@/components/charts/lineChart.vue";

export default {
    name: "KPISummary",
    components: {
        Loader,
        lineChart
    },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'month').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            gaLoader: false,
            paidCampaignLoader: false,
            SMMLoader: false,
            seoLoader: false,
            emailerLoader: false,
            isGAOpen: true,
            isPaidCampaignOpen: false,
            isSMMOpen: false,
            isSeoOpen: false,
            isEmailerOpen: false,
            gaData: {},
            paidCampaignData: {},
            SMMData: {},
            seoData: {},
            emailerData: {},
            intervals: ["Day", "Week", "Month", "Year"],
            selectedInterval: "Day",
            color_codes: [
                "#47A694",
                "#fcb116",
                "#eea39f",
                "#c8beea",
                "#79A69E",
                "#54736D",
                "#265950",
                "#79A69E",
                "#317367",
                "#54736D",
                "#52BFAB",
                "#84B5AC",
                "#5F827C",
                "#388273",
                "#59CFB7",
            ],
        }
    },
    mounted() {
        this.dataAPIs()
    },
    methods: {
        dataAPIs() {
            this.getGAData();
            this.getPaidCampaigns();
            this.getSMMData();
            this.getSeoData();
            this.getEmailerData();
        },
        getGAData() {
            this.gaLoader = true;
            const url = "/api/v1/merino/businesssummary"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                level_type: "account",
                interval: this.selectedInterval.toLowerCase()
            }

            axios.post(url, payload).then(res => {
                this.gaLoader = false;
                this.processChart(res.data.data, "gaData");
            }).catch(err => {
                this.gaLoader = false;
                console.log(err);
            })
        },
        getPaidCampaigns() {
            this.paidCampaignLoader = true;
            this.paidCampaignData = {};
            const url = "/api/v1/merino/kpisummary/paid";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                level_type: "account",
                interval: this.selectedInterval.toLowerCase()
            }

            axios.post(url, payload).then(res => {
                this.processChart(res.data.data, "paidCampaignData");
                this.paidCampaignLoader = false;
            }).catch(err => {
                console.log(err);
                this.paidCampaignLoader = false;
            })
        },

        getSMMData() {
            this.SMMLoader = true;
            this.SMMData = {};
            const url = "/api/v1/merino/kpisummary/smm"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                level_type: "account",
                interval: this.selectedInterval.toLowerCase()
            }

            axios.post(url, payload).then(res => {
                this.$nextTick(() => {
                    this.processChart(res.data.data, "SMMData");
                });
                this.SMMLoader = false;
            }).catch(err => {
                console.log(err);
                this.SMMLoader = false;
            })
        },

        getSeoData() {
            this.seoLoader = true;
            this.seoData = {};
            const url = "/api/v1/merino/kpisummary/seo"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                level_type: "account",
                interval: this.selectedInterval.toLowerCase()
            }

            axios.post(url, payload).then(res => {
                let self = this;
                this.processChart(res.data.data, "seoData");
                this.seoLoader = false;
            }).catch(err => {
                console.log(err);
                this.seoLoader = false;
            })
        },
        getEmailerData() {
            this.emailerLoader = true;
            this.emailerData = {};
            const url = "/api/v1/merino/kpisummary/emailer"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                client:"MerinoDomestic"
            }

            axios.post(url, payload).then(res => {
                this.processChart(res.data.data, "emailerData");
                this.emailerLoader = false;
            }).catch(err => {
                console.log(err);
                this.emailerLoader = false;
            })
        },

        processChart(data, targetVar){
            let self = this;
            Object.keys(data).forEach(key => {
                this[targetVar] = Object.assign({}, this[targetVar], {
                    [key]: {
                        title: data[key].title,
                        data: {
                            xAxis: function () {
                                let temp = [];
                                data[key].data.forEach(datapoint => {
                                    temp.push(datapoint.date)
                                })
                                return temp
                            }(),
                            seriesData: function () {
                                let temp = [];
                                data[key].headers.forEach((seriesLevel, index) => {
                                    temp.push({ data: [], additionalTooltip: [] })
                                    data[key].data.forEach(datapoint => {
                                        temp[index].data.push(datapoint[seriesLevel]);
                                        if(datapoint.keywords) {
                                            temp[index].additionalTooltip.push(datapoint.keywords)
                                        } else {
                                            delete temp[index].additionalTooltip;
                                        }
                                    })
                                    temp[index].name = seriesLevel;
                                    temp[index].color = self.color_codes[index];
                                    temp[index].yAxis = index === 0 ? 0 : 1;
                                })
                                return temp;
                            }(),
                            disableYLabel: data[key].headers.length === 1 ? true : false,
                            prefix: data[key].data[0].prefix,
                            sufix: data[key].data[0].suffix,
                            legend: true,
                        },
                        type: data[key].default_view,
                        toggle: data[key].toggle_graph,
                        tooltip: data[key].tooltip ? data[key].tooltip : null
                    }
                })
            })
        },

        toggleSection(sectionName) {
            this[`is${sectionName}Open`] = !this[`is${sectionName}Open`]
        },
    }
}