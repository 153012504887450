<template lang="pug">
div(class="w-card")
    //- div(v-if="!allData.length")
    //-     Loader
    div(class="w-40 customMultiselect" v-if="allData.length")
        multiselect(v-model="selectedChart" :options="chartMetrics" :searchable="false" :close-on-select="true"
            :show-labels="false" :multiple="false" :allow-empty="false" track-by="key" label="label"
            :preselect-first="true" @select="componentRender += 1")
    div(class="mt-8" v-if="allData.length")
        highcharts(:options="chartData")
    div.p-4.mb-4.text-sm.text-red-800.rounded-lg.bg-red-50(v-else)
            span.font-medium No data available in the chart!
</template>
<script>
import Highcharts from 'highcharts';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name: "NonEcomLineChart",
    components: { Loader },
    props: {
        allData: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chartMetrics: [
                {
                    label: "Sessions",
                    key: "sessions"
                },
                {
                    label: "New Users",
                    key: "newUsers"
                },
                {
                    label: "Bounce Rate (%)",
                    key: "bounceRate"
                },
                {
                    label: "Page Views",
                    key: "pageviews"
                },
            ],
            selectedChart: "",
            processedXAxis: [],
            processedSeriesData: [],
            chartData: {},
            componentRender: 0,
        }
    },
    mounted() {
        this.processChart();
        this.componentRender = 0;
    },
    watch: {
        componentRender(){
            this.processChart();
        }
    },
    methods: {
        processChart(){
            this.processedXAxis = [];
            this.processedSeriesData = [];
            this.allData.map(dataset => {
                this.processedXAxis.push(dataset.month.replaceAll(" ", " / "))
                if(this.selectedChart.key === 'bounceRate'){
                    this.processedSeriesData.push(Number(dataset["bounceRate"].toFixed(2)))
                } else {
                    this .processedSeriesData.push(dataset[this.selectedChart.key])
                }
            })               
            Highcharts.setOptions({
                lang: {
                    decimalPoint: '.',
                    thousandsSep: ','
                }
            });
            this.chartData = {
                chart: {
                    type: 'line',
                    backgroundColor:'transparent'
                },
                title: {
                    text: '',
                },
                subtitle: {
                    text: '',
                },
                credits: {
                    enabled: false
                },
                yAxis: {
                    title: {
                        text: this.selectedChart.label
                    }
                },
                xAxis: [{
                    categories: this.processedXAxis,
                    crosshair: true
                }],
                tooltip: {
                    formatter: function () {
                        return this.x + ' : <b>' + (this.y).toLocaleString("en-US") + '</b>';
                    },
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                },           
                legend: {
                    itemStyle: {
                        fontSize: "0.8vw"
                    },
                    enabled: false,
                    alignColumns: true,
                    align: "right",
                    y: 0,
                    x: 0,
                    squareSymbol: true,
                    verticalAlign: "top",
                    itemDistance: 35,
                    margin: 0,
                    padding: 0,
                    symbolHeight: 14,
                    symbolWidth: 14,
                    symbolRadius: 4
                },
                series: [{
                    data: this.processedSeriesData,
                    name: this.selectedChart.label,
                    color: '#47A694'
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'top'
                            },
                            chart: {
                                style:{
                                    fontSize: '0.5rem'
                                }
                            }
                        }
                    }]
                }
            }
        }
    }
}
</script>
<style></style>