<template lang="pug">
div
  div(v-if="resData")
    highcharts(:options="resData")
  div(v-else class="mt-4 ml-2") No data available in chart!
</template>

  
  
<script>
import Highcharts from "highcharts";
require('highcharts/highcharts-more.js')(Highcharts);
export default {
  name: 'columnRange chart',
  data() {
    return {
      resData: {},

    }
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    drawChart() {
      this.resData = {
        chart: {
          type: 'columnrange',
          inverted: false
        },

        accessibility: {
          description: ''
        },

        title: {
          text: ''
        },

        subtitle: {
          text: ''
        },

        xAxis: {
          categories: ['BAU', 'Oct Event', 'Nov Event', 'Dec Event']
        },

        yAxis: {
          title: {
            text: 'Increase / Decrease'
          }
        },

        tooltip: {
          valueSuffix: 'M'
        },

        plotOptions: {
          columnrange: {

            dataLabels: {
              enabled: true,
              format: '₹ {y} M'
            }
          }
        },

        legend: {
          enabled: false
        },

        series: [{
          name: 'Sum of 14 Day Total sales',
          data: [
            [13.9, 5.2],
            [-16.7, 10.6],
            [-4.7, 11.6],
            [-4.4, 16.8],

          ]
        }]
      }
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
