import axios from 'axios'
import Vuetable from 'vuetable-2'
import VuetableFieldHandle from 'vuetable-2/src/components/VuetableFieldHandle.vue'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import lineChart from '@/components/charts/lineChart.vue'
import { Chart } from 'highcharts-vue'
import { Tabs } from 'flowbite';



//api -:
//// Search Section
// Campaign Type and name dropdown list - '/api/amazon/camp_details?start_date='+self.dateRange.startDate+'&end_date='+self.dateRange.endDate
// Top Boxes Data - '/api/amazon/account_bulk?'
// Timeline graph and Table Data - '/api/amazon/timeline?'
// campaign level table data - '/api/amazon/camp-type/all-bulk?'
//// Report Section
// Top Boxes Data - '/api/amazon/report/top/metrices?'
// Highlight Table Data - '/api/amazon/report/highlights?'
// all Top Line Chart - '/api/amazon/report/graphs?'
// Sponsored Tables Data - '/api/amazon/report/camp-type?'
// Daywise Report Data - '/api/amazon/report/histogram?'


export default {
    name: 'AmazonSearch',
    components: {
        Vuetable,
        Loader,
        VuetableFieldHandle,
        dualAxisChart,
        lineChart,
        Chart
    },
    data() {
        return {
            dataLoader: false,
            searchDates: {
                dateRange: {
                    startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD")
                },
                compDateRange: {
                    startDate: moment().subtract(61, 'days').format("YYYY-MM-DD"),
                    endDate: moment().subtract(31, 'days').format("YYYY-MM-DD")
                },
            },
            reportDates: {
                dateRange: {
                    startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD")
                },
                compDateRange: {
                    startDate: moment().subtract(61, 'days').format("YYYY-MM-DD"),
                    endDate: moment().subtract(31, 'days').format("YYYY-MM-DD")
                },
            },
            timelineData: {
                timeInterval: 'day',
                intervalOptions: ['day', 'month'],
                tableHeader: [
                    { key: "Date", label: "Date" },
                    { key: "Impressions", label: "Impressions" },
                    { key: "CTR", label: "CTR (%)" },
                    { key: "Clicks", label: "Clicks" },
                    { key: "CPC", label: "CPC (₹)" },
                    { key: "Cost", label: "Cost (₹)" },
                    { key: "ACOS", label: "ACOS" },
                    { key: "Orders", label: "Orders" },
                    { key: "Revenue", label: "Revenue" },
                    { key: "CPM", label: "CPM (₹)" },
                    { key: "ROAS", label: "ROAS" }
                ],
                tableData: [],
                allChartData: {},
                chartData: {
                    xAxis: [],
                    seriesData: []
                },
                chartDropdown: {
                    options: ['impressions', 'ctr', 'clicks', 'cpc', 'cost', 'acos', 'order', 'revenue', 'cpm'],
                    barChartKey: 'Impressions',
                    lineChartKey: 'Clicks'
                }
            },
            campgnLevelData: {
                tableHeader: [
                    { key: "campaign_type", label: "Campaign Type" },
                    { key: "Impressions", label: "Impressions" },
                    { key: "CTR", label: "CTR (%)" },
                    { key: "Clicks", label: "Clicks" },
                    { key: "CPC", label: "CPC (₹)" },
                    { key: "Cost", label: "Cost (₹)" },
                    { key: "ACOS", label: "ACOS" },
                    { key: "Orders", label: "Orders" },
                    { key: "Revenue", label: "Revenue" },
                    { key: "CPM", label: "CPM (₹)" },
                    { key: "ROAS", label: "ROAS" }
                ],
                tableData: []
            },
            highlightData: {
                tableHeader: [],
                tableData: []
            },
            sponsoredData: {
                'Sponsored Products': {
                    tableHeader: [],
                    tableData: []
                },
                'Sponsored Brands': {
                    tableHeader: [],
                    tableData: []
                }
            },
            compareFlag: false,
            tabObject: undefined,
            summaryDropdownData: {
                campTypeOption: [],
                campNameOption: [],
                campaignTypeData: {},
                campaignMapping: {},
                selectedCampType: 'All Types',
                selectedCampName: 'All Campaigns'
            },
            viewTopBoxesData: [],
            reportTopBoxesData: [],
            reportTopGraphData: [],
            awarenessGraphData: {
                xAxis: [],
                seriesData: [],
                ylabel: '',
                description: ''
            },
            considerationGraphsData: [
                {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    description: ''
                },
            ],
            purchaseGraphsData: [
                {
                    xAxis: [],
                    seriesData: [],
                    ylabel: '',
                    description: ''
                },
            ],
            daywiseReportGraphData: {
                xAxis: [],
                seriesData: [],
                ylabel: '',
                description: ''
            },
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        // Initialize
        init() {
            this.initilizeTabs();
            this.dataAPIs();
        },
        // Format
        formatDateRange() {
            this.searchDates.dateRange.startDate = moment(this.searchDates.dateRange.startDate).format('YYYY-MM-DD');
            this.searchDates.dateRange.endDate = moment(this.searchDates.dateRange.endDate).format('YYYY-MM-DD');

            this.searchDates.compDateRange.startDate = moment(this.searchDates.compDateRange.startDate).format('YYYY-MM-DD');
            this.searchDates.compDateRange.endDate = moment(this.searchDates.compDateRange.endDate).format('YYYY-MM-DD');

            this.reportDates.dateRange.startDate = moment(this.reportDates.dateRange.startDate).format('YYYY-MM-DD');
            this.reportDates.dateRange.endDate = moment(this.reportDates.dateRange.endDate).format('YYYY-MM-DD');

            this.reportDates.compDateRange.startDate = moment(this.reportDates.compDateRange.startDate).format('YYYY-MM-DD');
            this.reportDates.compDateRange.endDate = moment(this.reportDates.compDateRange.endDate).format('YYYY-MM-DD');
        },

        formatDates() {
            this.formatDateRange();
            this.destroyAllTables()
            this.dataAPIs();
        },
        refreshSearchCamp() {
            const levelId = this.summaryDropdownData.campaignMapping[this.summaryDropdownData.selectedCampName]

            this.getTopBoxesData('campaign', levelId)
            this.getTimelineData('campaign', levelId)
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },
        // APIs
        dataAPIs() {
            this.formatDateRange();
            this.destroyAllTables()
            this.getCampDropdowns();
            this.getTopBoxesData('account', '')
            this.getTimelineData('account', '')
            this.getCampaignLevelData()
        },
        reportAPIs() {
            this.formatDateRange();
            this.getReportBoxData()
            this.getHighlightData()
            this.getAllTopGraphData()
            this.getSponsoredData('Sponsored Products')
            this.getSponsoredData('Sponsored Brands')
            this.getDaywiseReportData()
        },
        initilizeTabs() {
            const that = this
            // create an array of objects with the id, trigger element (eg. button), and the content element
            const tabElements = [
                {
                    id: 'summary',
                    triggerEl: document.querySelector('#summary'),
                    targetEl: document.querySelector('#summary-section')
                },
                {
                    id: 'report',
                    triggerEl: document.querySelector('#report'),
                    targetEl: document.querySelector('#report-section')
                }
            ];

            // options with default values
            const options = {
                defaultTabId: 'summary',
                activeClasses: 'text-primary_brand hover:text-primary_brand border-primary_brand-80',
                inactiveClasses: 'text-gray-500 hover:text-gray-600 border-base_color-20 border-transparent hover:border-gray-300',
                onShow: () => {
                    that.$forceUpdate();
                }
            };
            this.tabObject = new Tabs(tabElements, options);
        },
        destroyAllTables() {
            $('#campgnlevel-tbl').DataTable().destroy();
            $('#timeline-tbl').DataTable().destroy();
        },
        processSummaryDropdown() {
            if (this.summaryDropdownData.selectedCampType !== 'All Types') {
                this.summaryDropdownData.campNameOption = this.summaryDropdownData.campaignTypeData[this.summaryDropdownData.selectedCampType]
                this.summaryDropdownData.campNameOption.push('All Campaigns')
            }
            this.getTopBoxesData('campaign_type', this.summaryDropdownData.selectedCampType)
            this.getTimelineData('campaign_type', this.summaryDropdownData.selectedCampType)
        },
        processCampgnTable() {
            const campgnTable = $('#campgnlevel-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#campgnlevel-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    search: "",
                    searchPlaceholder: "Search",
                    lengthMenu: "<h5>Show entries</h5> _MENU_",
                }
            });
        },
        processTimelineTable() {
            const timelineTable = $('#timeline-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#timeline-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    search: "",
                    searchPlaceholder: "Search",
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                }
            });

            // timelineTable.on('draw.dt', () => {
            //     let dateData, costData, cpcData, cpmData, acosData, ctrData, orderData, revenueData, impressionData, clickData;
            //     // $('#month-tbl_paginate').remove();
            //     // $('#month-tbl_length').remove();
            //     $('.dataTables_filter input').attr('type', 'text');
            //     dateData = [];
            //     impressionData = []; 
            //     ctrData = []; 
            //     clickData = [];
            //     cpcData = [];
            //     costData = [];
            //     acosData = [];
            //     orderData = [];
            //     revenueData = [];
            //     cpmData = [];

            //     Array.from(timelineTable.rows({filter: 'applied'}).data()).forEach((value, index) => {
            //             dateData.push(value[0]);
            //             impressionData.push(Number(value[1].replace(/,/g, '')));
            //             ctrData.push(Number(value[2].replace(/,/g, '')));
            //             clickData.push(Number(value[3].replace(/,/g, '')));
            //             cpcData.push(Number(value[4].replace(/,/g, '')));
            //             costData.push(Number(value[5].replace(/,/g, '')))
            //             acosData.push(Number(value[6].replace(/,/g, '')))
            //             orderData.push(Number(value[7].replace(/,/g, '')))
            //             revenueData.push(Number(value[8].replace(/,/g, '')))
            //             cpmData.push(Number(value[9].replace(/,/g, '')))
            //     });
            //     this.timelineData.allChartData = {
            //         date: dateData,
            //         impressions: impressionData,
            //         ctr: ctrData,
            //         clicks: clickData,
            //         cpc: cpcData,
            //         cost: costData,
            //         acos: acosData,
            //         order: orderData,
            //         revenue: revenueData,
            //         cpm: cpmData
            //     };  
            //     this.processTimelineGraph();
            // });
            // $('#timeline-tbl').DataTable().draw();
        },
        processTimelineGraph() {
            this.timelineData.chartData = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.timelineData.allChartData.Date;

            const seriesData = [];
            const barLabel = this.timelineData.chartDropdown.barChartKey.toUpperCase()
            const lineLabel = this.timelineData.chartDropdown.lineChartKey.toUpperCase()

            seriesData.push({ name: barLabel, data: this.timelineData.allChartData[this.timelineData.chartDropdown.barChartKey] })
            seriesData.push({ name: lineLabel, data: this.timelineData.allChartData[this.timelineData.chartDropdown.lineChartKey], color: "#FCB116" })

            this.timelineData.chartData = {
                xAxis: xaxis,
                seriesData: seriesData,
            }
        },
        // Report Section Graphs
        processAwarenessGraph() {
            let awarenessData, xAxis, seriesData
            this.reportTopGraphData.forEach((data) => {
                if (data.title == 'Awareness') awarenessData = data.keys;
            });

            xAxis = []
            seriesData = []
            awarenessData.forEach((data, i) => {
                if (i == 0) {
                    xAxis.push(data.start_range)
                    xAxis.push(data.end_range)
                }
                seriesData.push({ name: data.key, data: [data.start_range_data, data.end_range_data] })
            });
            this.awarenessGraphData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: '',
                description: awarenessData[0].description,
                legend: true
            }
        },
        processConsiderationGraphs() {
            let considerationData
            this.reportTopGraphData.forEach((data) => {
                if (data.title == 'Consideration') considerationData = data.keys;
            });

            const allGraphData = []
            considerationData.forEach((dataPoint) => {
                const graphData = {
                    xAxis: [dataPoint.start_range, dataPoint.end_range],
                    seriesData: [{ name: dataPoint.key, data: [dataPoint.start_range_data, dataPoint.end_range_data] }],
                    ylabel: '',
                    description: dataPoint.description
                };
                allGraphData.push(graphData)
            });
            this.considerationGraphsData = allGraphData;
        },
        processPurchaseGraphs() {
            let purchaseData
            this.reportTopGraphData.forEach((data) => {
                if (data.title == 'Purchase') purchaseData = data.keys;
            });

            const allGraphData = []
            purchaseData.forEach((dataPoint) => {
                const graphData = {
                    xAxis: [dataPoint.start_range, dataPoint.end_range],
                    seriesData: [{ name: dataPoint.key, data: [dataPoint.start_range_data, dataPoint.end_range_data] }],
                    ylabel: '',
                    description: dataPoint.description
                };
                allGraphData.push(graphData)
            });
            this.purchaseGraphsData = allGraphData;
        },
        processDaywiseReportGraph(graphData) {
            const xAxis = []
            const salesData = []
            const spendData = []

            graphData.forEach((data) => {
                xAxis.push(data.date);
                salesData.push(data.sales);
                spendData.push(data.spend);
            });

            this.daywiseReportGraphData = {
                xAxis: xAxis,
                seriesData: [{ name: 'Sales', data: salesData }, { name: 'Spend', data: spendData }],
                ylabel: '',
                legend: true
            }
        },
        // Data Api (Summary Section)
        getCampDropdowns() {
            this.dataLoader = true;
            this.campData = {}
            let url = '/api/amazon/camp_details?start_date=' + this.searchDates.dateRange.startDate + '&end_date=' + this.searchDates.dateRange.endDate
            axios.get(url).then((res) => {
                const resData = res.data.data;
                this.summaryDropdownData.campaignTypeData = resData.campaign_type
                this.summaryDropdownData.campaignMapping = resData.campaigns
                this.summaryDropdownData.campTypeOption = []
                this.summaryDropdownData.campTypeOption.push('All Types')
                Object.entries(resData.campaign_type).forEach(entry => {
                    const [key, value] = entry;
                    this.summaryDropdownData.campTypeOption.push(key)
                });

                this.dataLoader = false;
            }).catch((err) => console.log(err))
        },
        getTopBoxesData(key, keystring) {
            this.dataLoader = true;
            this.campData = {}
            let url = "/api/amazon/account_bulk?key=" + key + '&first_start_date=' + this.searchDates.compDateRange.startDate + '&first_end_date=' + this.searchDates.compDateRange.endDate
            url += "&last_start_date=" + this.searchDates.dateRange.startDate + "&last_end_date=" + this.searchDates.dateRange.endDate + "&key_id=" + keystring
            axios.get(url).then((res) => {
                const resData = res.data.data
                resData.forEach(element => {
                    element.diff = this.formatNumber(element.diff);
                    element.value = this.formatNumber(element.value);
                    element.name = element.name.toUpperCase()
                });
                this.viewTopBoxesData = resData
                this.dataLoader = false;
            }).catch((err) => console.log(err))
        },
        getTimelineData(key, keystring) {
            this.dataLoader = true;
            $('#timeline-tbl').DataTable().destroy();
            let url = '/api/amazon/timeline?'
            url += `level=${key}&start_date=${this.searchDates.dateRange.startDate}&end_date=${this.searchDates.dateRange.endDate}&timeline=${this.timelineData.timeInterval}&level_id=${keystring}`

            axios.get(url).then((res) => {
                const resData = res.data.data
                this.timelineData.tableData = resData;
                const allKeys = Object.keys(resData[0])
                const allKeywiseData = {}

                allKeys.forEach(key => {
                    allKeywiseData[key] = resData.map(data => data[key])
                });

                this.timelineData.chartDropdown.options = allKeys.filter(key => key !== 'Date')
                this.timelineData.allChartData = allKeywiseData

                this.$nextTick(() => {
                    this.processTimelineTable();
                    this.processTimelineGraph()
                    this.dataLoader = false;
                });
            }).catch((err) => console.log(err));
        },
        getCampaignLevelData() {
            this.dataLoader = true;
            let url = '/api/amazon/camp-type/all-bulk?'
            url += `start_date=${this.searchDates.dateRange.startDate}&end_date=${this.searchDates.dateRange.endDate}`

            axios.get(url).then((res) => {
                const resData = res.data.data;
                const modifiedData = []
                Object.entries(resData).forEach(entry => {
                    try {
                        const [key, value] = entry;
                        value.campaign_type = key
                        modifiedData.push(value)
                    } catch (error) {
                        // api is throwing weird data keys in some clients blocking the logic.
                        console.error(error);
                    }
                });
                this.campgnLevelData.tableData = modifiedData;
                this.$nextTick(() => {
                    this.processCampgnTable();
                    this.dataLoader = false;
                });
            }).catch((err) => console.log(err));
        },
        // Data Api - (Report Section)
        getReportBoxData() {
            this.dataLoader = true;
            let url = '/api/amazon/report/top/metrices?'
            url += `first_start_date=${this.reportDates.compDateRange.startDate}&first_end_date=${this.reportDates.compDateRange.endDate}&last_start_date=${this.reportDates.dateRange.startDate}&last_end_date=${this.reportDates.dateRange.endDate}`

            axios.get(url).then((res) => {
                const resData = res.data.data;
                resData.forEach(element => {
                    element.diff = this.formatNumber(element.diff);
                    element.value = this.formatNumber(element.value);
                    element.name = element.name.replace('_', ' ').toUpperCase()
                });
                this.reportTopBoxesData = resData;
                this.dataLoader = false;
            }).catch(err => console.log(err))
        },
        getHighlightData() {
            this.dataLoader = true;
            let url = '/api/amazon/report/highlights?'
            url += `first_start_date=${this.reportDates.dateRange.startDate}&first_end_date=${this.reportDates.dateRange.endDate}&last_start_date=${this.reportDates.compDateRange.startDate}&last_end_date=${this.reportDates.compDateRange.endDate}`

            axios.get(url).then((res) => {
                const resData = res.data.data;
                this.highlightData.tableData = resData.data;
                this.highlightData.tableHeader = resData.row;
                this.dataLoader = false;
            }).catch(err => console.log(err))
        },
        getAllTopGraphData() {
            this.dataLoader = true;
            let url = '/api/amazon/report/graphs?'
            url += `first_start_date=${this.reportDates.dateRange.startDate}&first_end_date=${this.reportDates.dateRange.endDate}&last_start_date=${this.reportDates.compDateRange.startDate}&last_end_date=${this.reportDates.compDateRange.endDate}`

            axios.get(url).then((res) => {
                const resData = res.data.data;
                this.reportTopGraphData = resData;
                this.$nextTick(() => {
                    this.processAwarenessGraph();
                    this.processConsiderationGraphs();
                    this.processPurchaseGraphs();
                    this.dataLoader = false;
                });
            }).catch(err => console.log(err))
        },
        getSponsoredData(sponsType) {
            this.dataLoader = true;
            let url = "/api/amazon/report/camp-type?"
            url += `camp_type=${sponsType}&start_date=${this.reportDates.dateRange.startDate}&end_date=${this.reportDates.dateRange.endDate}`

            axios.get(url).then((res) => {
                const resData = res.data.data;
                this.sponsoredData[sponsType].tableHeader = resData.row;
                this.sponsoredData[sponsType].tableData = resData.data;
                this.dataLoader = false;
            }).catch(err => console.log(err))
        },
        getDaywiseReportData() {
            this.dataLoader = true;
            let url = "/api/amazon/report/histogram?"
            url += `start_date=${this.reportDates.dateRange.startDate}&end_date=${this.reportDates.dateRange.endDate}`

            axios.get(url).then((res) => {
                const resData = res.data.data;
                this.processDaywiseReportGraph(resData);
                this.dataLoader = false;
            });
        }
    }
}