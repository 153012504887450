<template lang="pug">
div
    div.font-medium.text-center.text-gray-500.ml-6.ml-4(class=" lg: text-sm xl:text-base 2xl:text-lg")
        ul.flex.flex-wrap.-mb-px
            li
                button#account.inline-block.p-4.border-b-2.rounded-t-lg(type='button', @click="metricDropdown = 'account_page'") Account 
            li
                button#campaign.inline-block.p-4.border-b-2.rounded-t-lg(type='button', @click="metricDropdown = 'campaign_page'") Campaign
    div#account_page
        facebook-account-summary(v-if="metricDropdown === 'account_page'" @change-metric-dropdown='ChangeMetricDropdown')
    div#campaign_page    
        facebook-campaign-summary(v-if="metricDropdown === 'campaign_page'" @change-metric-dropdown='ChangeMetricDropdown')

</template>

<script>
    import FacebookAccountSummary from "./FBAccountSummary/FacebookAccountSummary.vue";
    import FacebookCampaignSummary from "./FBCampaignSummary/FacebookCampaignSummary.vue";
    import { Tabs } from 'flowbite';
    export default{
        name:'FacebookSummary',
        components: {
            FacebookAccountSummary,
            FacebookCampaignSummary
        },
        data(){
            return{
                metricDropdown : 'account_page',
            }
        },
        mounted(){
            this.initilizeTabs();
        },
        methods: {
            ChangeMetricDropdown(value){
                console.log("flag1", value)
                this.metricDropdown = value
            },
            initilizeTabs() {
                // create an array of objects with the id, trigger element (eg. button), and the content element
                const tabElements = [
                    {
                        id: 'account',
                        triggerEl: document.querySelector('#account'),
                        targetEl: document.querySelector('#account_page')
                    },
                    {
                        id: 'campaign',
                        triggerEl: document.querySelector('#campaign'),
                        targetEl: document.querySelector('#campaign_page')
                    }
                ];
    
                // options with default values
                const options = {
                    defaultTabId: 'account',
                    activeClasses: 'text-primary_brand hover:text-primary_brand border-primary_brand-80',
                    inactiveClasses: 'text-gray-500 hover:text-gray-600 border-base_color-20 hover:border-gray-300 border-transparent',
                };
                this.tabObject = new Tabs(tabElements, options);
            },
        },
    }
</script>