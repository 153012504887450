import moment from "moment";
import lineChart from "@/components/charts/lineChart.vue";
import pieChart from "@/components/charts/pieChart.vue";
import dualBarChart from "@/components/charts/dualBarChart.vue";
import columnRangeChart from "@/components/charts/columnRangeChart.vue";
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import axios from "axios";
import Loader from '@/components/Loader/Loader.vue'
export default {
  name: "Adset Summary",
  components: {
    lineChart,
    pieChart,
    dualBarChart,
    columnRangeChart,
    dualAxisChart,
    Loader
  },

  data() {
    return {
      mainInterval: "sb",
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      loader: false,
      pieChartData: [],
      customRanges: [],
      viewAdsetSummaryData:'',
      CTRAndCVRData:'',
      SpendsSalesAndACOSData:'',
      spendsGraphData:'',
      unitsGraphData:'',
      selectedSpendsSalesACOSInterval :'monthly_data',
      selectedCTRCVRInterval:'monthly_data'
    };
  },
  mounted() {
    this.getAdsetSummaryData();
  },
  created() {
    const today = new Date();
    const yesterday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );
    const monthStartDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate() + 0
    );
    const thisMonthStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const thisMonthEndDate = new Date();
    const lastMonthStartDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
    const thisYearStartDate = new Date(today.getFullYear(), 0, 1);
    const thisYearEndDate = new Date(today.getFullYear(), 11, 31);
    const lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1);
    const lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31);
    const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
    const quarterStartDate = new Date(
      today.getFullYear(),
      quarterStartMonth,
      1
    );

    // Calculate the first day of the current week (Sunday)
    const firstDayOfCurrentWeek = new Date(today);
    firstDayOfCurrentWeek.setDate(today.getDate() - today.getDay());

    // Calculate the last day of the previous week (Saturday)
    const lastDayOfLastWeek = new Date(firstDayOfCurrentWeek);
    lastDayOfLastWeek.setDate(firstDayOfCurrentWeek.getDate() - 1);

    // Calculate the first day of last week (Sunday)
    const firstDayOfLastWeek = new Date(lastDayOfLastWeek);
    firstDayOfLastWeek.setDate(lastDayOfLastWeek.getDate() - 6);

    this.customRanges = {
      Today: [today, today],
      Yesterday: [yesterday, yesterday],
      Month: [monthStartDate, thisMonthEndDate],
      "Last Week": [firstDayOfLastWeek, lastDayOfLastWeek],
      "Last month": [lastMonthStartDate, lastMonthEndDate],
      "Last Year": [lastYearStartDate, lastYearEndDate],
      MTD: [thisMonthStartDate, today],
      QTD: [quarterStartDate, today],
      YTD: [thisYearStartDate, today],
    };
  },

  methods: {
    formateDateRange(timestamp) {
      return moment(timestamp).format("lll");
    },

    formatDateRange() {
      this.dateRange.startDate = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.dateRange.endDate = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );
    },

    formatDates() {
      this.formatDateRange();
      this.getAdsetSummaryData();
    },

    abbreviateNumber(value){
      return Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 2
      }).format(value);
    },

    formatNumber(num) {
      if (isNaN(num)) return num
      let num1 = Number(num).toFixed(2);
      let num2 = Number(num1).toLocaleString();
      return num2
    },
    
    intervalChange(interval){
      this.mainInterval = interval;
      this.getAdsetSummaryData();
    },

    changeGraphInterval(flag,interval){
      if(flag=='CTR'){
        this.selectedCTRCVRInterval = interval
        this.CTRAndCVRData = this.processLineChart(this.viewAdsetSummaryData,['CTR','CVR'],this.selectedCTRCVRInterval)
      }
      if(flag=='SpendsSale'){
        this.selectedSpendsSalesACOSInterval = interval
        this.SpendsSalesAndACOSData = this.processLineChart(this.viewAdsetSummaryData,['Spends','ACOS','Sales'],this.selectedSpendsSalesACOSInterval)
      }
      
    },

    getAdsetSummaryData(){
      this.loader = true;
      this.viewAdsetSummaryData = ''
      const url = "/api/module/amazon/adset_summary_data";
      let payload = {
          account_id: "3803619040964368",
          start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
          end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
          ad_type: this.mainInterval
      }
      // this.adsetsList = ["All Adsets"]
      axios.post(url, payload).then(res => {
          this.viewAdsetSummaryData = res.data.data
          this.$nextTick(() => {
            this.processTable('#campaignTable-tbl',2);
        })
          this.processPieChart(this.viewAdsetSummaryData.Spend_by_category)
          this.processSpendsBarGraph(this.viewAdsetSummaryData.campaign_data.Spends)
          this.processUnitsColumnGraph(this.viewAdsetSummaryData.campaign_data.Units)
          this.CTRAndCVRData = this.processLineChart(this.viewAdsetSummaryData,['CTR','CVR'], this.selectedCTRCVRInterval)
          this.SpendsSalesAndACOSData = this.processLineChart(this.viewAdsetSummaryData,['Spends','ACOS','Sales'], this.selectedSpendsSalesACOSInterval)
          this.loader = false;
      }).catch(err => {
          console.log(err);
          this.loader = false;
      });
    },

    processLineChart(data,graphMetric,interval){
      let graphData = {
        xAxis:[],
        legend: true,
        seriesData:[]
      }
      for(let metric of graphMetric){
        let temp = {
          name : metric +' ('+data[interval][0][metric]['prefix']+ data[interval][0][metric]['suffix']+')',
          data: []
        }
        if(metric == 'ACOS'){
          temp.yAxis = 1
        }
        for(let d of data[interval]){
          graphData.xAxis.push(interval=='monthly_data'?moment(d.interval).format("MMM-YY"):moment(d.interval).format("DD-MMM"))
          temp.data.push(d[metric].value)
        }
        graphData.seriesData.push(temp)
      }
      return graphData
    },
    processUnitsColumnGraph(data){
      this.unitsGraphData = ''
      let seriesData = {
        xAxis:[],
        seriesData:[{
          name:'Units',
          prefix:'',
          suffix:'',
          data: []
          }
      ]}

      for(let d of data){
        seriesData.xAxis.push(d.campaign_name)
        seriesData.seriesData[0].data.push(d.value)
      }
      seriesData.seriesData[0].data.sort((a,b)=>{
        return b-a;
      })
      this.unitsGraphData= seriesData
    },

    processSpendsBarGraph(data){
      this.spendsGraphData = ''
      let seriesData = {
        xAxis:[],
        seriesData:[{
          name: 'Spends ('+data[0].prefix + data[0].suffix+')',
          prefix:'',
          suffix:'',
          data: []
          }
      ]}

      for(let d of data){
        seriesData.xAxis.push(d.campaign_name)
        seriesData.seriesData[0].data.push(d.value)
      }
      seriesData.seriesData[0].data.sort((a,b)=>{
        return b-a;
      })
      this.spendsGraphData= seriesData
    },
   
    processPieChart(data) {
      this.pieChartData = [
        {
          name:'',
          prefix: data[0].prefix,
          suffix: data[0].suffix,
          data: [],
        },
        ];
      for(let d of data){
        this.pieChartData[0].data.push({name:d.category_name,y:d.value})
      }
    },

    processTable(id, sortCol = 0) {
      $(id).DataTable().destroy();
      var search = require("@/assets/Icons_SVG/Search.svg");
      $(id).DataTable({
        searching: true,
        info: false,
        sorting: [[sortCol, "desc"]],
        initComplete: function (settings, json) {
          $(id).wrap(
            "<div style='overflow:auto; width:100%;position:relative;'></div>"
          );
        },
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            first: "",
            previous: "<",
            next: ">",
            last: "",
          },
          lengthMenu: "<h5>Show entries</h5> _MENU_",
        },
      });
      $(id + "_filter > label").append(
        `<div class='search_icon'><img src='${search}' /></div>`
      );
    },
  },
};
