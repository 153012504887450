export default {
    name: 'Sidebar',
    data() {
        return {
            userData: '',
            isSidebarOpen: true,
            currentPath: '',
        }
    },

    created() {
        this.$store.dispatch('setUser')
        this.$store.dispatch('setMenu')
        this.activeCurrentPageMenuItem()
    },
    computed: {
        menu() {
            return this.$store.state.menu;
        },
        floatingNav() {
            if (window.screen.width < 1024) {
                return true;
            } else return;
        },
    },
    watch: {
        "$route"() {
            this.activeCurrentPageMenuItem()
        }
    },
    methods: {
        activeCurrentPageMenuItem() {
            this.currentPath = this.$route.path
        },
        toggleSidebar() {
            if(this.floatingNav){
                this.isSidebarOpen = false;
            } else {
                this.isSidebarOpen = !this.isSidebarOpen;
            }
        },
        setMenuIndex(menuItem, index) {
            for (let routeItems of Object.values(menuItem.submenuItems)) {
                if (routeItems.link == this.$router.currentRoute.fullPath) {
                    localStorage.setItem('selectedMenu', index)
                }
            }
        },
        openedMenu(menuItem) {
            if (!this.isSidebarOpen) return;
            else if (this.isSidebarOpen) {
                this.$store.dispatch('toggleExpansion', menuItem.title)
            }
        }
    }
}