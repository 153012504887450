import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'

export default {
    name: "Ad Detials",
    components: {
        Loader,dualAxisChart
    },
    data() {
        return {
            loader: false,
            savedCreatives: [],
            dateRange: {
                startDate: moment().subtract(6, 'days').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            selectedMetrices:['ROAS','ACOS'],
            excludeList:['GA Purchase','Meta Purchases','Hook Rate','Hold Rate','Reach', 'CPC','CTR',],
            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'week',
                    label: 'Week'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'year',
                    label: 'Year'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            },
            selectedLineGraphMetric:'ROAS',
            selectedBarGraphMetric:'Purchase',
            metrices:['ROAS','Purchase'],
            timeLineGraphData:'',
            isMetricDropdown:false,
            checked:false,
            selectedMetric:'ACOS',
            selectedMetrices:['ACOS','ROAS'],
            metrices:['ROAS','Purchase','ACOS','Purchase1','ACOS1','Purchase2','ACOS2'],
            viewAllCampaignData:'',
            viewCreativeCardData:'',
            viewAllCreativeData:'',
            adId: this.$route.params.adId,
            viewAllCampaignData:'',
            viewMeteCreativeTimelineGraphData:'',
            tableHeader:'',
            tableData:'',
            isMetricDropdown:false,
            metrics : [], 
        }
    },
    created() {
        this.dateRange.startDate = this.$store.state.startDate
        this.dateRange.endDate = this.$store.state.endDate
        window.addEventListener("click", this.close)

    },
    watch:{
        metrics:{
            deep:true,
            handler(){
                this.selectMetric();
            }
        }
    },
    mounted() {
        // this.getSavedCreatives();
        this.dateRange.startDate = this.$store.state.filter.startDate
        this.dateRange.endDate = this.$store.state.filter.endDate
        this.getAllCreativeData()
        this.getAllTimelineData()
    },
    beforeDestroy() {
        window.removeEventListener("click", this.close)
    },
    methods: {
        close(event) {
            if (!this.$refs.metricDropdown.contains(event.target)) {
                this.isMetricDropdown = false
            }
        },
        selectMetric(){
            this.viewAllCampaignData = { ...this.tableData };  
            this.tableHeader = this.metrics.filter(data => data.isAvailable);
            this.$store.dispatch('setFBCreativeMetric',this.tableHeader)
        },

        removeSelectedMetric(idx){
            this.metrics[idx].isAvailable = false
        },
        applyFilter(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.$store.dispatch('setDate',this.dateRange)
            this.getAllCreativeData();
            this.getAllTimelineData();
        },
        
        selectDeselectMetric(metric){
            console.log("check the metric",metric,this.checked)
        },
        setInterval(selectedInterval){
            this.selectedInterval= selectedInterval
            this.getAllTimelineData();
        },
        toggleAdMetricDropdown(){
            if(this.isMetricDropdown){
                this.isMetricDropdown = false
            }
            else{
                this.isMetricDropdown = true
            }
        },
        updateDate() {
            // if (this.appliedFilterMetrices.length) {
            //     this.filterCreativeData()
            // } else this.getCreativesData();
        },
        
        getAllCreativeData(){
            this.loader = true;
            this.viewAllCampaignData = [];
            this.viewAllCreativeData='';
            const url = "/api/v1/meta/creativedatanew?start_date="+this.dateRange.startDate+'&end_date='+this.dateRange.endDate+'&ad_id='+this.adId
            // const url = "/api/v1/meta/creativedata?start_date=2024-10-09&end_date=2024-10-15"
            axios.get(url).then(res => {
                this.loader = false;
                this.viewAllCampaignData = res.data;
                this.viewCreativeCardData = res.data.data[0]
                this.viewAllCreativeData= res.data,
                this.generateMetricsForFilter();
                // this.$nextTick(() => {
                //     this.processTable('#reports_table', 2)
                // })
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })
        },
        generateMetricsForFilter(){
            let selectedMetrics= this.$store.state.selectedFBCreativeMetric.map(data=>data.label)
            this.metrics  = this.viewAllCampaignData.headers.map(data=>{
                return {
                    isAvailable : selectedMetrics.includes(data.value)?true:false,
                    label: data.value,
                    key:data.key
                }
            })
            this.tableHeader = selectedMetrics
            this.$store.dispatch('setFBCreativeMetric',this.tableHeader)
        },

        getAllTimelineData(){
            this.loader = true;
            this.viewMeteCreativeTimelineGraphData = '';
            this.graphDropdownMetric=''
            const url = "/api/v1/meta/creativetimleine/graph"
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                interval: this.selectedInterval.key,
                ad_id: this.adId
            }
            axios.post(url,payload).then(res => {
                this.loader = false;
                if(!res.data.data) return false
                this.viewMeteCreativeTimelineGraphData = res.data.data
                this.graphDropdownMetric = Object.keys(res.data.data[0]).filter((value)=>value!="Timestamp");
                this.selectedLineGraphMetric = this.graphDropdownMetric[0]
                this.selectedBarGraphMetric = this.graphDropdownMetric[1]
                this.processGraphData();
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })
        },
        processGraphData(){
            this.timeLineGraphData={
                xAxis:[],
                disableYLabel:true,
                seriesData:[{
                    name: this.selectedLineGraphMetric,
                    data:[]
                    },
                    {
                        name: this.selectedBarGraphMetric,
                        data:[] 
                    }    
                ]
            }
            if(this.viewMeteCreativeTimelineGraphData){
                this.viewMeteCreativeTimelineGraphData.forEach((data)=>{
                    this.timeLineGraphData.xAxis.push(data.Timestamp);
                    this.timeLineGraphData.seriesData[0].data.push(data[this.selectedLineGraphMetric]);
                    this.timeLineGraphData.seriesData[1].data.push(data[this.selectedBarGraphMetric]);
                })
            }
        },
        
      

     
        abbreviateNumber(value){
			return Intl.NumberFormat('en-US', {
				notation: "compact",
				maximumFractionDigits: 2
			}).format(value);
        },

        formatNumber(num) {
            if (isNaN(num)) {return num}
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}