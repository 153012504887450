import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import lineChart from '@/components/charts/lineChart.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import dualBarChart from '@/components/charts/dualBarChart.vue'
import FlipkartReportEvent from '../Report/FlipkartReportEvent.vue'
import FlipkartReportCard from '../Report/FlipkartReportCard'


export default {
    name: 'FlipkartReport',
    components: {
        Loader,
        lineChart,
        dualAxisChart,
        dualBarChart,
        FlipkartReportEvent,
        FlipkartReportCard
    },
    data(){
        return {
            dataLoader: false,
            dateRange: {
                startDate:  moment().subtract(1, 'months').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            compDateRange: {
                startDate: moment().subtract(2, 'months').format("YYYY-MM-DD"),
                endDate: moment().subtract(1, 'months').format("YYYY-MM-DD")
            },
            compareFlag: false,
            mainInterval: 'day',
            topChartData: {
                allData: [],
                typeDropdown: {
                    option: ['overall', 'display', 'search', 'dsp'],
                    value: 'overall',
                },
                chartDropdown:{
                    option: [],
                    firstValue: '',
                    secondValue: ''
                },
                chartData: {
                    xAxis: [],
                    seriesData: []
                }
            },
            searchTabObject: undefined,
            searchReportData: {
                selectedTab: 'graph',
                reportTable: {
                    tableHeader: [],
                    tableData: []
                },
                categoryTable: {
                    tableHeader: [],
                    tableData: []
                },
                dropdownOption: [],
                dropdownVal: 'Units Sold',
                reportChartData: {
                    xAxis: [],
                    seriesData: []
                },
                categoryChartData: {
                    xAxis: [],
                    seriesData: []
                }
            },
            displayTabObject: undefined,
            displayReportData: {
                selectedTab: 'graph',
                reportTable: {
                    tableHeader: [],
                    tableData: []
                },
                categoryTable: {
                    tableHeader: [],
                    tableData: []
                },
                dropdownOption: [],
                dropdownVal: 'Units Sold',
                reportChartData: {
                    xAxis: [],
                    seriesData: []
                },
                categoryChartData: {
                    xAxis: [],
                    seriesData: []
                }
            },
            fsnData: {
                search: {
                    tableHeader: [],
                    tableData: []
                },
                display: {
                    tableHeader: [],
                    tableData: []
                }
            }
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        init(){
            this.initilizeReportTabs();
            this.formatDateRange();
            this.dataAPIs();
        },
        dataAPIs(){
            this.getTopChartData()
            this.getSearchReportData()
            this.getDisplayReportData()
            this.getFsnSearchData()
            this.getFsnDisplayData()
        },
        formatDateRange(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.compDateRange.startDate = moment(this.compDateRange.startDate).format('YYYY-MM-DD');
            this.compDateRange.endDate = moment(this.compDateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates(){
            this.formatDateRange();
            this.destroyAllTables()
			this.dataAPIs();
        },
        intervalChange(){
            this.getTopChartData()
        },
        destroyAllTables(){
            $('#search_report-tbl').DataTable().destroy()
            $('#search_category-tbl').DataTable().destroy()
            $('#display_report-tbl').DataTable().destroy()
            $('#display_category-tbl').DataTable().destroy()
            $('#fsn-search').DataTable().destroy()
            $('#fsn-display').DataTable().destroy()
        },
        formatNumber(num){
            if(isNaN(num)) return num
			let num1 = Number(num).toFixed(2);
			let num2 = Number(num1).toLocaleString();
			return num2
        },
        initilizeReportTabs(){
            // create an array of objects with the id, trigger element (eg. button), and the content element
            const searchTabElements = [
                {
                    id: 'graph',
                    triggerEl: document.querySelector('#search-graph'),
                    targetEl: document.querySelector('#search-graph-section')
                },
                {
                    id: 'table',
                    triggerEl: document.querySelector('#search-table'),
                    targetEl: document.querySelector('#search-table-section')
                }
            ];
            const displayTabElements = [
                {
                    id: 'graph',
                    triggerEl: document.querySelector('#display-graph'),
                    targetEl: document.querySelector('#display-graph-section')
                },
                {
                    id: 'table',
                    triggerEl: document.querySelector('#display-table'),
                    targetEl: document.querySelector('#display-table-section')
                }
            ];
            
            // options with default values
            const searchOptions = {
                defaultTabId: 'graph',
                activeClasses: 'bg-primary_brand text-white',
                inactiveClasses: 'hover:bg-primary_brand-20 text-primary_brand',
            };
            const displayOptions = {
                defaultTabId: 'graph',
                activeClasses: 'bg-primary_brand text-white',
                inactiveClasses: 'hover:bg-primary_brand-20 text-primary_brand',
            };
            this.searchTabObject = new Tabs(searchTabElements, searchOptions);
            this.displayTabObject = new Tabs(displayTabElements, displayOptions);
        },
        processTopChart(){
            const allData = this.topChartData.allData
            const xAxis = []
            const firstLineData = []
            const secondLineData = []

            allData.forEach((point)=>{
                xAxis.push(point.Date)
                firstLineData.push(point[this.topChartData.chartDropdown.firstValue])
                secondLineData.push(point[this.topChartData.chartDropdown.secondValue])
            });
            const seriesData = [
                { name:  this.topChartData.chartDropdown.firstValue, data: firstLineData },
                { name: this.topChartData.chartDropdown.secondValue, data: secondLineData }
            ]
            this.topChartData.chartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: '',
                legend: true
            }
        },
        processDataTable(tableId, scroll){
            var search = require("@/assets/Icons_SVG/Search.svg");
            const table = $('#'+tableId).DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: true,
                initComplete: function(settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({visibility:'collapse'});
                    $('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
                    $('#'+tableId).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                language: {
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    search: "",
                    searchPlaceholder: "Search",
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                }
            });
            $("#"+ tableId + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },
        processSearchGraphs(){
            let reportAxis, reportData, catAxis, catData

            reportAxis = this.searchReportData.reportTable.tableData.map(data => data['FSN'])
            reportData = [{name: this.searchReportData.dropdownVal, data: this.searchReportData.reportTable.tableData.map(data => data[this.searchReportData.dropdownVal])}]

            catAxis = this.searchReportData.categoryTable.tableData.map(data => data['Category'] == '' ? 'N/A' : data['Category'])
            catData = [{name: this.searchReportData.dropdownVal, data: this.searchReportData.categoryTable.tableData.map(data => data[this.searchReportData.dropdownVal])}]

            this.searchReportData.reportChartData = {
                xAxis: reportAxis,
                seriesData: reportData
            }
            this.searchReportData.categoryChartData = {
                xAxis: catAxis,
                seriesData: catData
            }
        },
        processDisplayGraphs(){
            let reportAxis, reportData, catAxis, catData

            reportAxis = this.displayReportData.reportTable.tableData.map(data => data['FSN'])
            reportData = [{name: this.displayReportData.dropdownVal, data: this.displayReportData.reportTable.tableData.map(data => data[this.displayReportData.dropdownVal])}]

            catAxis = this.displayReportData.categoryTable.tableData.map(data => data['Category'] == '' ? 'N/A' : data['Category'])
            catData = [{name: this.displayReportData.dropdownVal, data: this.displayReportData.categoryTable.tableData.map(data => data[this.displayReportData.dropdownVal])}]

            this.displayReportData.reportChartData = {
                xAxis: reportAxis,
                seriesData: reportData
            }
            this.displayReportData.categoryChartData = {
                xAxis: catAxis,
                seriesData: catData
            }
        },
        // data apis
        getTopChartData(){
            this.dataLoader = true
            let url = '/api/flipkart/report/compare/graph'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate,
                'interval': this.mainInterval,
                'type': this.topChartData.typeDropdown.value
            }
            axios.post(url, payload).then((res)=>{
                const resData = res.data.data;
                this.topChartData.allData = resData;
                //dropdown options based on keys
                this.topChartData.chartDropdown.option = Object.keys(resData[0]).filter(key => key !== 'Date')
                this.topChartData.chartDropdown.firstValue = this.topChartData.chartDropdown.option[0]
                this.topChartData.chartDropdown.secondValue = this.topChartData.chartDropdown.option[1]
                this.processTopChart()
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getSearchReportData(){
            this.dataLoader = true
            let url = '/api/flipkart/search/fsn'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate
            }
            axios.post(url, payload).then((res)=>{
                const resData = res.data.data
                this.searchReportData.reportTable.tableHeader = resData.headers
                this.searchReportData.reportTable.tableData = resData.data

                // calculating for category table
                const categoryTypes = [...new Set(resData.data.map(item => item.Category))]
                const tableData = []
                categoryTypes.forEach((cat)=>{
                    const keys = resData.headers.filter((head)=> head !== 'FSN' && head !== 'Category')
                    const allData = resData.data.filter((obj)=> obj.Category == cat)

                    const rowObj = {}
                    rowObj.Category = cat
                    keys.forEach((key)=>{
                        rowObj[key] = allData.map((data)=> data[key]).reduce((accumulator, currentValue) => accumulator + currentValue)
                    });
                   tableData.push(rowObj);
                });
                this.searchReportData.dropdownOption = resData.headers.filter((head)=> head !== 'FSN' && head !== 'Category')
                this.searchReportData.dropdownVal = resData.headers.filter((head)=> head !== 'FSN' && head !== 'Category')[0]
                this.searchReportData.categoryTable.tableHeader = resData.headers.filter((head)=> head !== 'FSN')
                this.searchReportData.categoryTable.tableData = tableData

                this.$nextTick(()=>{
                    this.processDataTable("search_report-tbl", true)
                    this.processDataTable("search_category-tbl", true)
                    this.processSearchGraphs()
                    this.dataLoader = false
                });
            }).catch(err => console.log(err))
        },
        getDisplayReportData(){
            this.dataLoader = true
            let url = '/api/flipkart/display/fsn'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate
            }
            axios.post(url, payload).then((res)=>{
                const resData = res.data.data
                this.displayReportData.reportTable.tableHeader = resData.headers
                this.displayReportData.reportTable.tableData = resData.data

                // calculating for category table
                const categoryTypes = [...new Set(resData.data.map(item => item.Category))]
                const tableData = []
                categoryTypes.forEach((cat)=>{
                    const keys = resData.headers.filter((head)=> head !== 'FSN' && head !== 'Category')
                    const allData = resData.data.filter((obj)=> obj.Category == cat)

                    const rowObj = {}
                    rowObj.Category = cat
                    keys.forEach((key)=>{
                        rowObj[key] = allData.map((data)=> data[key]).reduce((accumulator, currentValue) => accumulator + currentValue)
                    });
                   tableData.push(rowObj);
                });
                this.displayReportData.dropdownOption = resData.headers.filter((head)=> head !== 'FSN' && head !== 'Category')
                this.displayReportData.dropdownVal = resData.headers.filter((head)=> head !== 'FSN' && head !== 'Category')[0]
                this.displayReportData.categoryTable.tableHeader = resData.headers.filter((head)=> head !== 'FSN')
                this.displayReportData.categoryTable.tableData = tableData

                this.$nextTick(()=>{
                    this.processDataTable("display_report-tbl", false)
                    this.processDataTable("display_category-tbl", false)
                    this.processDisplayGraphs()
                    this.dataLoader = false
                });
            }).catch(err => console.log(err))
        },
        getFsnSearchData(){
            this.dataLoader = true
            let url = '/api/flipkart/search/fsn'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate
            }

            axios.post(url, payload).then((res)=>{
                const resData = res.data.data
                this.fsnData.search.tableData = resData.data
                this.fsnData.search.tableHeader = resData.headers
                this.$nextTick(()=>{
                    this.processDataTable("fsn-search", true)
                    this.dataLoader = false
                });
                this.dataLoader = false                
            }).catch(err => console.log(err))
        },
        getFsnDisplayData(){
            this.dataLoader = true
            let url = '/api/flipkart/display/fsn'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate
            }

            axios.post(url, payload).then((res)=>{
                const resData = res.data.data
                this.fsnData.display.tableData = resData.data
                this.fsnData.display.tableHeader = resData.headers
                this.$nextTick(()=>{
                    this.processDataTable("fsn-display", false)
                    this.dataLoader = false
                });
                this.dataLoader = false                
            }).catch(err => console.log(err))
        }
    },
}