<template>
    <div>
        <div v-if="dataLoader">
            <Loader></Loader>
        </div>
        <div class="grid gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2">
            <div class="w-card w-box-shadow lg:col-span-1">
                <!-- Dropdown menu -->
                <div class="bg-white rounded-lg">
                    <div class="p-3">
                        <label for="input-group-search" class="sr-only">Search</label>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-5 h-5 text-primary_brand-80" aria-hidden="true" fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <input type="text" v-model="searchVal" id="input-group-search"
                                class="bg-gray-50 border border-solid border-primary_brand-80 text-gray-900 text-base rounded-lg focus:ring-0 focus:border-primary_brand block w-full pl-8 p-2"
                                placeholder="Search user">
                        </div>
                    </div>
                    <ul class="h-96 px-3 pb-3 overflow-y-auto text-sm text-gray-700 overflow-y-scroll">
                        <li v-for="eventData in eventList">
                            <div class="h-10 flex items-center p-2 rounded hover:bg-primary_brand-10">
                                <input id="checkbox-item-11" type="checkbox" @click="checkboxhandler($event, eventData.event_name)"
                                    class="w-4 h-4 text-primary_brand-80 bg-gray-100 border-gray-300 rounded focus:ring-0">
                                <label for="checkbox-item-11"
                                    class="w-full ml-2 text-base font-medium text-gray-900 rounded">{{ eventData.event_name }}</label>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        <div class="lg:col-span-2">
            <div class="grid gap-4 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2  flex-row overflow-x-scroll">
                <div v-for="eventData in selectedEventData">
                    <div class="grid gap-3 flex-col">
                        <div class="w-card w-box-shadow bg-primary_brand-80 text-white">
                            <span class="text-base">{{ eventData['Order name'] }}</span>
                        </div>
                        <div v-for="([key, val]) in Object.entries(eventData)" v-if="key != 'Order name'" class="w-card w-box-shadow py-1">
                            <div class="flex justify-between my-2 text-md font-medium">
                                <div>{{ key }}</div>
                                <div class="font-normal">{{ formatNumber(val) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></template>
<script>
import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'

export default {
    name: 'AmazonReportEvent',
    components: {
        Loader
    },
    data() {
        return {
            dataLoader: false,
            searchVal: '',
            allEventList: [],
            selectedEvents: [],
            selectedEventData: []
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        eventList(){
           return this.allEventList.filter((eventData) => eventData.event_name.toLowerCase().includes(this.searchVal.toLowerCase())) 
        }
    },
    methods: {
        init() {
            this.getEventListData()
        },
        formatNumber(num){
            if(isNaN(num)) return num
			let num1 = Number(num).toFixed(2);
			let num2 = Number(num1).toLocaleString();
			return num2
        },
        checkboxhandler(event, eventName){
            if(event.target.checked) this.selectedEvents.push(eventName)
            if(!event.target.checked) this.selectedEvents.splice(this.selectedEvents.indexOf(eventName), 1)
            this.getSelectedEventData()
        },
        getSelectedEventData(){
            this.dataLoader = true
            let url = '/api/amazon/events/data'
            let payload = {
                events_list: this.selectedEvents
            }
            axios.post(url, payload).then((res)=> {
                const resData = res.data.data
                this.selectedEventData = resData
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getEventListData() {
            this.dataLoader = true
            let url = '/api/amazon/events/list'

            axios.post(url, {}).then((res) => {
                const resData = res.data.data
                this.allEventList = resData
                this.dataLoader = false
            }).catch(err => console.log(err))
        }
    },
}
</script>