import moment from 'moment';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';

export default{
    name : 'CampaignReport',
    components: {
        Loader
    },

    data(){
        return{
            dataLoader: false,
            dateRange: {
                startDate: moment().subtract(1,'months'),
                endDate: moment()
            },
            tableData:[],
            btn: "Summary",
            header: [],
            info: [],
        }

    },
    mounted() {
        this.formatDateRange();
        this.getData();
        this.initilizeTabs()
        
    },
    methods: {
        btnClick(str){
            this.btn = str
            this.processTable();
        },
        formatDateRange(){
            if(this.dateRange.startDate) this.dateRange.startDate=moment(this.dateRange.startDate).format("YYYY-MM-DD");
            if(this.dateRange.endDate) this.dateRange.endDate=moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates(){
            this.formatDateRange();
            this.getData();
        },
        initilizeTabs(){
            const tabElements=[
                {
                    id: 'summary',
                    triggerEl:document.querySelector('#summary'),
                    targetEl:document.querySelector('#summary-section')
                },
                {
                    id: 'adwordSearch',
                    triggerEl:document.querySelector('#adwordSearch'),
                    targetEl:document.querySelector('#summary-section')
                },
                {
                    id: 'adwordVideo',
                    triggerEl:document.querySelector('#adwordVideo'),
                    targetEl:document.querySelector('#summary-section')
                },
                {
                    id: 'adwordDisplay',
                    triggerEl:document.querySelector('#adwordDisplay'),
                    targetEl:document.querySelector('#summary-section')
                },
                {
                    id: 'adwordDiscovery',
                    triggerEl:document.querySelector('#adwordDiscovery'),
                    targetEl:document.querySelector('#summary-section')
                },
                {
                    id: 'adwordPerformanceMax',
                    triggerEl:document.querySelector('#adwordPerformanceMax'),
                    targetEl:document.querySelector('#summary-section')
                },

                

                
            ];
            const options= {
                defaultTabId:'summary',
                activeClasses:'border-primary_brand-80 text-primary_brand hover:text-primary_brand',
                inactiveClasses:'border-transparent text-gray-500 hover:text-gray-600 hover:border-base_color-20',
            };
            this.tabObject=new Tabs(tabElements,options);
        },
        formatNumber(num){
            if(isNaN(num)) return num
			let num1 = Number(num).toFixed(2);
			let num2 = Number(num1).toLocaleString();
			return num2
        },
        getData(){
            this.dataLoader=true;
            let url= `/api/adword/dbm_details?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((Response) => {
                const resData=Response.data;
                // console.log("resdata",resData);
                this.tableData=resData;
                this.dataLoader = false;
                this.processTable();
                }).catch((err) => {
                    console.log(err)
                    this.dataLoader = false
                });

        },
        processTable(){
            if(this.info.length)
            $('#campaignReportTable').DataTable().destroy();
            this.header = this.tableData.rows[this.btn];
            this.info = this.tableData.data[this.btn];
            let tableID = '#campaignReportTable'
            var search = require("@/assets/Icons_SVG/Search.svg");
            this.$nextTick(()=>{
                $(tableID).DataTable({
                    searching: true,
                        info: false,
                        // sorting: [[0, "desc"]],
                        initComplete: function(settings, json) {
                            $('.dataTables_scrollBody tfoot tr').css({visibility:'collapse'});
                            $('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
                            $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                        },
                        language: {
                            search: "",
                            searchPlaceholder: "Search...",
                            paginate: {
                                first: "",
                                previous: "<",
                                next: ">",
                                last: "",
                                
                            },
                            lengthMenu: "<h5>Show entries</h5> _MENU_",
                        },
                    })
                    $(tableID + "_filter > label").append(
                        `<div class='search_icon'><img src='${search}' /></div>`
                    );
             })
        }
    },
}