import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import lineChart from '@/components/charts/lineChart.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import dualBarChart from '@/components/charts/dualBarChart.vue'
import AmazonReportEvent from '../Report/AmazonReportEvent.vue'
import AmazonReportCard from '../Report/AmazonReportCard.vue'
import { Tabs } from 'flowbite';

// api :- 
// first Top Line Charts -> '/api/amazon/search/graph'
// second Top Line Charts -> '/api/amazon/new/compare/graph'
// report Table Section -> '/api/amazon/search/report'
// Asin Report Section -> '/api/amazon/asin/data'

export default{
    name: 'AmazonReport',
    components:{
        Loader,
        lineChart,
        dualAxisChart,
        dualBarChart,
        AmazonReportEvent,
        AmazonReportCard
    },
    data() {
        return {
            dataLoader: false,
            dateRange: {
                startDate:  moment().subtract(1, 'months').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            compDateRange: {
                startDate: moment().subtract(2, 'months').format("YYYY-MM-DD"),
                endDate: moment().subtract(1, 'months').format("YYYY-MM-DD")
            },
            compareFlag: false,
            mainInterval: 'day',
            firstTopChartData: {
                dropdownOption:[],
                dropdownVal:'',
                allData: [],
                chartData: {
                    xAxis: [],
                    seriesData: []
                }
            },
            secondTopChartData: {
                allData: [],
                typeDropdown: {
                    option: ['overall', 'display', 'search', 'dsp'],
                    value: 'overall',
                },
                chartDropdown:{
                    option: [],
                    firstValue: '',
                    secondValue: ''
                },
                chartData: {
                    xAxis: [],
                    seriesData: []
                }
            },
            reportTabObject: undefined,
            reportTableData: {
                tabSection: {
                    selectedSection: '',
                    selectedTab: '',
                    selectedOrder: '',
                    orderData: [],
                    tabData: [],
                },
                tableHeader: [],
                tableData: [],
                allData: []
            },
            asinTabObject: undefined,
            asinData: {
                selectedTab: 'graph',
                reportTable: {
                    tableHeader: [],
                    tableData: []
                },
                categoryTable: {
                    tableHeader: [],
                    tableData: []
                },
                dropdownOption: [],
                dropdownVal: 'Units Sold',
                reportChartData: {
                    xAxis: [],
                    seriesData: []
                },
                categoryChartData: {
                    xAxis: [],
                    seriesData: []
                }
            },
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            this.initializeReportTabs();
            this.initilizeAsinTabs();
            this.formatDateRange();
            this.dataAPIs();
        },
        dataAPIs(){
            this.getFirstTopChart()
            this.getSecondTopChart()
            this.getReportTableData()
            this.getAsinReportData()
        },
        formatDateRange(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.compDateRange.startDate = moment(this.compDateRange.startDate).format('YYYY-MM-DD');
            this.compDateRange.endDate = moment(this.compDateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates(){
            this.formatDateRange();
            this.destroyAllTables()
			this.dataAPIs();
        },
        intervalChange(){
            this.getFirstTopChart()
            this.getSecondTopChart()
        },
        formatNumber(num){
            if(isNaN(num)) return num
			let num1 = Number(num).toFixed(2);
			let num2 = Number(num1).toLocaleString();
			return num2
        },
        initilizeAsinTabs(){
            // create an array of objects with the id, trigger element (eg. button), and the content element
            const tabElements = [
                {
                    id: 'graph',
                    triggerEl: document.querySelector('#graph'),
                    targetEl: document.querySelector('#graph-section')
                },
                {
                    id: 'table',
                    triggerEl: document.querySelector('#table'),
                    targetEl: document.querySelector('#table-section')
                }
            ];
            
            // options with default values
            const options = {
                defaultTabId: 'graph',
                activeClasses: 'bg-primary_brand text-white',
                inactiveClasses: 'hover:bg-primary_brand-20 text-primary_brand',
            };
            this.asinTabObject = new Tabs(tabElements, options);
        },
        initializeReportTabs(){
            // create an array of objects with the id, trigger element (eg. button), and the content element
            const tabElements = [
                {
                    id: 'graph',
                    triggerEl: document.querySelector('#report-graph'),
                    targetEl: document.querySelector('#report-graph-section')
                },
                {
                    id: 'table',
                    triggerEl: document.querySelector('#report-table'),
                    targetEl: document.querySelector('#report-table-section')
                }
            ];
            
            // options with default values
            const options = {
                defaultTabId: 'graph',
                activeClasses: 'bg-primary_brand text-white',
                inactiveClasses: 'hover:bg-primary_brand-20 text-primary_brand',
            };
            this.reportTabObject = new Tabs(tabElements, options);
        },
        destroyAllTables(){
            $('#asin_report-tbl').DataTable().destroy()
            $('#asin_category-tbl').DataTable().destroy()
        },
        processFirstTopChart(){
            const allData = this.firstTopChartData.allData
            let xAxis, spData, sbData, sdData

            allData.forEach(dataPoint => {
                if(dataPoint.key == this.firstTopChartData.dropdownVal){
                    xAxis = dataPoint.data.map(point => point.date)
                    spData = dataPoint.data.map(point => point.sp)
                    sbData = dataPoint.data.map(point => point.sb)
                    sdData = dataPoint.data.map(point => point.sd)
                }
            });
            const seriesData = [
                { name: 'sp', data: spData },
                { name: 'sb', data: sbData },
                { name:'sd', data: sdData }
            ]
            this.firstTopChartData.chartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: '',
                legend: true
            }
        },
        processSecondTopChart(){
            const allData = this.secondTopChartData.allData
            const xAxis = []
            const firstLineData = []
            const secondLineData = []

            allData.forEach((point)=>{
                xAxis.push(point.Date)
                firstLineData.push(point[this.secondTopChartData.chartDropdown.firstValue])
                secondLineData.push(point[this.secondTopChartData.chartDropdown.secondValue])
            });
            const seriesData = [
                { name:  this.secondTopChartData.chartDropdown.firstValue, data: firstLineData },
                { name: this.secondTopChartData.chartDropdown.secondValue, data: secondLineData }
            ]
            this.secondTopChartData.chartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: '',
                legend: true
            }
        },
        processReportTable(){
            let tableHeader, tableData

            if(this.reportTableData.tabSection.selectedSection == 'Summary'){
                this.reportTableData.tabSection.tabData.forEach(data => {
                    if(data.key == this.reportTableData.tabSection.selectedTab) tableHeader = data.data;
                })
                tableData = this.reportTableData.allData[this.reportTableData.tabSection.selectedSection][this.reportTableData.tabSection.selectedTab]

            }else if (this.reportTableData.tabSection.selectedSection == 'Overall') {
                this.reportTableData.tabSection.tabData.forEach(tabData => {
                    if(tabData.key == this.reportTableData.tabSection.selectedTab) tabData.data.forEach(orderData => {
                        if(orderData.key == this.reportTableData.tabSection.selectedOrder) tableHeader = orderData.data;
                    })
                    tableData = this.reportTableData.allData[this.reportTableData.tabSection.selectedSection][this.reportTableData.tabSection.selectedTab][this.reportTableData.tabSection.selectedOrder]
                })
            }
            this.reportTableData.tableHeader = tableHeader
            this.reportTableData.tableData = tableData
        },
        processDataTable(tableId){
            var search = require("@/assets/Icons_SVG/Search.svg");
            const table = $('#'+tableId).DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: true,
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#'+tableId).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    search: "",
                    searchPlaceholder: "Search",
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                }
            });
            $("#"+ tableId + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },
        processAsinGraphs(){
            let reportAxis, reportData, catAxis, catData

            reportAxis = this.asinData.reportTable.tableData.map(data => data['ASIN'])
            reportData = [{name: this.asinData.dropdownVal, data: this.asinData.reportTable.tableData.map(data => data[this.asinData.dropdownVal])}]

            catAxis = this.asinData.categoryTable.tableData.map(data => data['Category'] == '' ? 'N/A' : data['Category'])
            catData = [{name: this.asinData.dropdownVal, data: this.asinData.categoryTable.tableData.map(data => data[this.asinData.dropdownVal])}]

            this.asinData.reportChartData = {
                xAxis: reportAxis,
                seriesData: reportData
            }
            this.asinData.categoryChartData = {
                xAxis: catAxis,
                seriesData: catData
            }
        },
        // data api
        getFirstTopChart(){
            this.dataLoader = true
            let url = '/api/amazon/search/graph'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate,
                'interval': this.mainInterval
            }
            axios.post(url, payload).then((res)=>{
                const resData = res.data.data;
                this.firstTopChartData.allData = resData;
                this.firstTopChartData.dropdownOption = resData.map(dataPoint => dataPoint.key)
                this.firstTopChartData.dropdownVal = this.firstTopChartData.dropdownOption[0]
                this.processFirstTopChart()
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getSecondTopChart(){
            this.dataLoader = true
            let url = '/api/amazon/new/compare/graph'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate,
                'interval': this.mainInterval,
                'type': this.secondTopChartData.typeDropdown.value
            }
            axios.post(url, payload).then((res)=>{
                const resData = res.data.data;
                this.secondTopChartData.allData = resData;
                //dropdown options based on keys
                this.secondTopChartData.chartDropdown.option = Object.keys(resData[0]).filter(key => key !== 'Date')
                this.secondTopChartData.chartDropdown.firstValue = this.secondTopChartData.chartDropdown.option[0]
                this.secondTopChartData.chartDropdown.secondValue = this.secondTopChartData.chartDropdown.option[1]
                this.processSecondTopChart()
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getReportTableData(){
            this.dataLoader = true
            let url = '/api/amazon/search/report'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate 
            }
            axios.post(url, payload).then((res)=>{
                const resData = res.data.data;
                this.reportTableData.tabSection.allData = resData.rows
                this.reportTableData.tabSection.selectedSection = resData.rows[0].key
                this.reportTableData.tabSection.tabData = resData.rows[0].data
                this.reportTableData.tabSection.selectedTab = resData.rows[0].data[0].key
                this.reportTableData.tabSection.orderData = resData.order
                this.reportTableData.tabSection.selectedOrder = resData.order[0]
                
                this.reportTableData.allData = resData.data
                this.processReportTable()
                this.dataLoader = false
            }).catch(err => console.log(err))
        },
        getAsinReportData(){
            this.dataLoader = true
            let url = '/api/amazon/asin/data'
            let payload = {
                'end_date': this.dateRange.endDate,
                'start_date': this.dateRange.startDate 
            }
            axios.post(url, payload).then((res)=>{
                const resData = res.data.data
                this.asinData.reportTable.tableHeader = resData.headers
                this.asinData.reportTable.tableData = resData.data

                // calculating for category table
                const categoryTypes = [...new Set(resData.data.map(item => item.Category))]
                const tableData = []
                categoryTypes.forEach((cat)=>{
                    const keys = resData.headers.filter((head)=> head !== 'ASIN' && head !== 'Category')
                    const allData = resData.data.filter((obj)=> obj.Category == cat)

                    const rowObj = {}
                    rowObj.Category = cat
                    keys.forEach((key)=>{
                        rowObj[key] = allData.map((data)=> data[key]).reduce((accumulator, currentValue) => accumulator + currentValue)
                    });
                   tableData.push(rowObj);
                });
                this.asinData.dropdownOption = resData.headers.filter((head)=> head !== 'ASIN' && head !== 'Category')
                this.asinData.dropdownVal = resData.headers.filter((head)=> head !== 'ASIN' && head !== 'Category')[0]
                this.asinData.categoryTable.tableHeader = resData.headers.filter((head)=> head !== 'ASIN')
                this.asinData.categoryTable.tableData = tableData

                this.$nextTick(()=>{
                    this.processDataTable("asin_report-tbl")
                    this.processDataTable("asin_category-tbl")
                    this.processAsinGraphs()
                    this.dataLoader = false
                });
            }).catch(err => console.log(err))
        },
        
    }
}