import axios from "axios";
import moment from "moment";
import Loader from "@/components/Loader/Loader.vue";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { watch } from "vue";

export default {
  name: "Sleepwell",
  components: {
    Loader,
    Treeselect
  },
  data() {
    return {
      dataLoader: false,
      campLoader: false,
      isFilter: false,
      filterBy: "",
      asinFilterBy: "",
      isAsinFilter: false,
      asinFilterBy: "",
      treeSelectData: [],
      selectedNumericCampaignValue: "TopOfSearch(IS)",
      selectedStringCampaignValue: "Campaign",
      selectedNumericMatricsValue: "greater than",
      selectedStringMatricsValue: "contains",
      selectedAsinNumericalMatricsValue: "greater than",
      asinMatricsNumericalInputValue: "",
      selectedAsinNumericalCampaignValue: "Impressions",
      selectedAsinStringMatricsValue: "contains",
      asinMatricsStringInputValue: "",
      selectedAsinStringCampaignValue: "ASIN",
      storeNumericFilterValue: [],
      storeStringFilterValue: [],
      storeAsinFilterValue: [],
      storeAsinMetricsValue: [],
      allAsinCategory: [],
      activeCampaign: true,
      matricsInputValue: "",
      StringMatricsInputValue: "",
      sleepwellTableData: [],
      sleepwellTableHeader: [],
      asinTableData: [],
      asinTableHeader: [],
      numericFiltersFields: [],
      stringFiltersFields: [],
      numericFilterTableMatrics: [],
      stringFilterTableMatrics: [],
      asinMatric: [],
      asinFields: [],
      asinMetricFields: [],
      selectedValue: [],
      selectedTableColumn: [],
      selectedAsinTableColumn: [],
      asinCategoryValue: null,
      dateRange: {
        startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      intervalsList: [
        {
          key: "daily",
          label: "Day",
        },
        {
          key: "weekly",
          label: "Week",
        },
        {
          key: "monthly",
          label: "Month",
        },
      ],
      selectedInterval: {
        key: "daily",
        label: "Day",
      },
      selectedCampaignTypeData: [
        {
          key: "Sponsored Products",
          label: "sp",
        },
      ],
      campaignOptions: [
        {
          key: "Sponsored Products",
          label: "sp",
        },
        {
          key: "Sponsored Brands",
          label: "sb",
        },
        {
          key: "Sponsored Display",
          label: "sd",
        },
      ],
      selectedCategory: {},
      isDefaultSelectedCategory: false,
    };
  },
  mounted() {
    this.isDefaultSelectedCategory = true;
    this.getTableData();
    this.getAsinReportData();

    this.$watch('allAsinCategory', (newVal) => {
      if (newVal && Object.keys(newVal).length) {
        this.treeSelectData = this.transformToTreeSelect(newVal);
      }
    }, { immediate: true });
  },
  // computed: {
  //   allOptions() {
  //     return [{ key: 'selectAll', value: 'Select All' }, ...this.sleepwellTableHeader];
  //   }
  // },
  methods: {
    formatDateRange() {
      this.dateRange.startDate = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.dateRange.endDate = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );
    },
    formatDates() {
      this.formatDateRange();
      this.getTableData();
      this.getAsinReportData();
    },
    getTableData() {
      this.campLoader = true;
      const url = "/api/amazon/targated/campaigns";
      const payload = {
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        camp_state: this.activeCampaign ? "enabled" : "All",
        ad_type: this.selectedCampaignTypeData.map((data) => {
          return data.key;
        }),
        // fields_to_filter: this.selectedTableColumn.map((data) => {
        //   return data.key;
        // }),
        numarical_filters: this.storeNumericFilterValue.flat(),
        string_filters: this.storeStringFilterValue.flat(),
        report_type: this.selectedInterval.key,
      };

      axios
        .post(url, payload)
        .then((res) => {
          this.sleepwellTableHeader = res.data.data.campaign_header;
          this.selectedTableColumn = this.sleepwellTableHeader;
          this.sleepwellTableData = res.data.data.campaign_data;
          this.numericFiltersFields = res.data.data.numarical_filters.fields;
          this.numericFilterTableMatrics = res.data.data.numarical_filters.matrics;
          this.stringFiltersFields = res.data.data.string_filters.fields;
          this.stringFilterTableMatrics = res.data.data.string_filters.matrics;
          this.$nextTick(() => {
            this.initDataTable('#campaignDataTable', this.sleepwellTableHeader, this.sleepwellTableData);
          })
          this.campLoader = false;
        })
        .catch((err) => {
          this.campLoader = false;
          console.log(err);
        });
    },
    getAsinReportData() {
      this.dataLoader = true;
      this.asinTableHeader = '';
      this.asinTableData = '';
      this.asinMatric = '';
      this.asinFields = '';
      this.allAsinCategory = '';

      let url = "/api/amazon/targeted/asinreport";
      const payload = {
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        interval: this.selectedInterval.key,
        numarical_filters: this.storeAsinFilterValue.flat(),
        // fields_to_filter: this.selectedTableColumn.map((data) => {
        //   return data.key;
        // }),
        category_filters: this.selectedCategory,
        asin_string_filters: this.storeAsinMetricsValue.flat(),
      }
      axios.post(url, payload).then((res) => {
        this.asinTableHeader = res.data.data.headers;
        this.selectedAsinTableColumn = this.asinTableHeader;
        this.asinTableData = res.data.data.data;
        this.asinMatric = res.data.data.metric;
        this.asinFields = res.data.data.fileds;
        this.allAsinCategory = res.data.data.category;
        this.asinMetricFields = res.data.data.asin_string_metric;
        this.treeSelectData = this.transformToTreeSelect(res.data.data.category);
        if (this.treeSelectData && this.treeSelectData.length > 0 && this.isDefaultSelectedCategory) {
          this.asinCategoryValue = [this.treeSelectData[0].id];
          this.handleTreeSelect(this.treeSelectData[0]);
        }
        this.isDefaultSelectedCategory = false;
        this.$nextTick(() => {
          this.initDataTable('#asinDataTable', this.asinTableHeader, this.asinTableData);
        });
        this.dataLoader = false;
      }).catch((err) => {
        console.error(err);
        this.dataLoader = false;
      })
    },
    initDataTable(id, header, data) {
      const column = header.map((header) => ({
        title: header.value,
        data: header.key,
      }));

      if ($.fn.DataTable.isDataTable(id)) {
        $(id).DataTable().clear().destroy();
      }
      $(id).empty();

      $(id).DataTable({
        data: data,
        columns: column,
        info: true,
        paging: true,
        searching: true,
        ordering: true,
        order: [],
        initComplete: function () {
          $(id).wrap(
            "<div style='overflow:auto; width:100%;position:relative;'></div>"
          );

        },
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            first: "",
            previous: "<",
            next: ">",
            last: "",
          },
          lengthMenu: "<h5>Show entries</h5> _MENU_",
        },
      });
      const searchIcon = require("@/assets/Icons_SVG/Search.svg");
      $(`${id}_filter > label`).append(`
        <div class='search_icon'>
          <img src='${searchIcon}' alt='Search' />
        </div>
      `);
      function adjustCloseIconPosition() {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1400) {
          $(id).css({
            width: "1750px",
          });
          $(`${id} .sorting_1`).css({
            width: "80px",
          });
        } else {
          $(id).css({
            width: "1300px",
          });
          $(`${id} td:first-child`).css({
            width: "80px",
          });
        }
      }
      adjustCloseIconPosition();
      $(window).resize(adjustCloseIconPosition);
    },
    handleDownloadTable() {
      let url = "/api/data/downloadtoxlsx";

      // const payload = {
      //   report_title: "sleepwellReport",
      //   headers: this.selectedTableColumn,
      //   data: this.sleepwellTableData
      // }

      const payload = {
        report_title: "sleepwellReport",
        headers: this.selectedTableColumn,
        data: this.sleepwellTableData.map((data) => {
          const filteredData = {};
          this.selectedTableColumn.forEach((column) => {
            if (data.hasOwnProperty(column.key)) {
              filteredData[column.key] = data[column.key];
            }
          });
          return filteredData;
        })
      };

      axios.post(url, payload).then((res) => {
        window.location = res.data.url;
      }).catch((err) => {
        console.error(err);

      })
    },
    handleDownloadAsinTable() {
      let url = "/api/data/downloadtoxlsx";

      const payload = {
        report_title: "asinReport",
        headers: this.selectedAsinTableColumn,
        data: this.asinTableData.map((data) => {
          const filteredData = {};
          this.selectedAsinTableColumn.forEach((column) => {
            if (data.hasOwnProperty(column.key)) {
              filteredData[column.key] = data[column.key];
            }
          });
          return filteredData;
        })
      }

      axios.post(url, payload).then((res) => {
        window.location = res.data.url;
      }).catch((err) => {
        console.error(err);

      })
    },
    transformToTreeSelect(data) {
      return Object.keys(data).map((category, idx) => {
        return {
          id: idx + category,
          label: category,
          parent: category,
          children: Object.keys(data[category]).map((type, idx) => {
            return {
              id: idx + category + type,
              parentCat: category,
              label: type,
              children: data[category][type].map((size, idx) => ({
                id: idx + category + type + size,
                cat: category,
                sub: type,
                label: size,
              })),
            };
          }),
        };
      });
    },
    setInterval(selectedInterval) {
      this.selectedInterval = selectedInterval;
      this.getTableData();
      this.getAsinReportData();
    },
    handleTableColumn() {
      // const selectAllOption = this.selectedTableColumn.find(option => option.key === 'selectAll');

      // if (selectAllOption) {
      //   this.selectedTableColumn = [...this.sleepwellTableHeader];
      // } else {
      const dateColumn = this.sleepwellTableHeader.find(item => item.key === 'Date');

      if (!this.selectedTableColumn.some(col => col.key === 'Date')) {
        this.selectedTableColumn.unshift(dateColumn);
      }
      // }
      this.$nextTick(() => {
        this.initDataTable('#campaignDataTable', this.selectedTableColumn, this.sleepwellTableData);
      })
    },

    handleAsinTableColumn() {
      const dateColumn = this.sleepwellTableHeader.find(item => item.key === 'Date');

      if (!this.selectedAsinTableColumn.some(col => col.key === 'Date')) {
        this.selectedAsinTableColumn.unshift(dateColumn);
      }
      this.$nextTick(() => {
        this.initDataTable('#asinDataTable', this.selectedAsinTableColumn, this.asinTableData);
      });
    },
    handleCampaignType() {
      this.getTableData();
    },
    applyEnabledFilters() {
      this.getTableData();
    },
    handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
      if (data.length <= 0) {
        return false;
      }

      let existingValue = storeFilterValue.find(
        (item) => item[0].field === data && item[0].operation === this[selectedMatricsValueKey]
      );

      if (existingValue) {
        // this[selectedMatricsValueKey] = existingValue[0].operation;
        this[matricsInputValueKey] = existingValue[0].value;
      } else {
        this[matricsInputValueKey] = "";
      }
    },
    handleNumericFilterModal(data) {
      this.handleFilterModal(
        data,
        this.storeNumericFilterValue,
        'selectedNumericMatricsValue',
        'matricsInputValue'
      );
    },
    handleNumericMatricFilterModal(data) {
      let existingValue = this.storeNumericFilterValue.find(
        (item) => item[0].field === this.selectedNumericCampaignValue && item[0].operation === data)
      if (existingValue) {
        this.matricsInputValue = existingValue[0].value;
      } else {
        this.matricsInputValue = ""
      }
    },
    handleStringFilterModal(data) {
      this.handleFilterModal(
        data,
        this.storeStringFilterValue,
        'selectedStringMatricsValue',
        'StringMatricsInputValue'
      );
    },
    handleAsinStringFilterModal(data) {
      this.handleFilterModal(
        data,
        this.storeAsinFilterValue,
        'selectedAsinStringMatricsValue',
        'asinMatricsStringInputValue'
      );
    },
    handleStringMatricsFilterModal(data) {
      let existingValue = this.storeStringFilterValue.find(
        (item) => item[0].field === this.selectedStringCampaignValue && item[0].operation === data)
      if (existingValue) {
        this.StringMatricsInputValue = existingValue[0].value;
      } else {
        this.StringMatricsInputValue = ""
      }
    },
    handleAsinFilterModal(data) {
      this.handleFilterModal(
        data,
        this.storeAsinFilterValue,
        'selectedAsinNumericalMatricsValue',
        'asinMatricsNumericalInputValue'
      );
    },
    handleAsinMatricsFilterModal(data) {
      let existingValue = this.storeAsinFilterValue.find(
        (item) => item[0].field === this.selectedAsinNumericalCampaignValue && item[0].operation === data)
      if (existingValue) {
        this.asinMatricsNumericalInputValue = existingValue[0].value;
      } else {
        this.asinMatricsNumericalInputValue = ""
      }
    },
    handleAsinStringMatricsFilterModal(data) {
      let existingValue = this.storeAsinMetricsValue.find(
        (item) => item[0].field === this.selectedAsinStringCampaignValue && item[0].operation === data)
      if (existingValue) {
        this.asinMatricsStringInputValue = existingValue[0].value;
      } else {
        this.asinMatricsStringInputValue = ""
      }
    },
    handleIsFilter() {
      const existingValue = this.storeNumericFilterValue.find(
        (item) => item[0].field === this.selectedNumericCampaignValue
      );

      if (existingValue) {
        this.selectedNumericMatricsValue = existingValue[0].operation;
        this.matricsInputValue = existingValue[0].value;
      }
      this.isFilter = true;
    },
    handleIsAsinFilter() {
      const existingValue = this.storeAsinFilterValue.find(
        (item) => item[0].field === this.selectedAsinNumericalCampaignValue
      );

      if (existingValue) {
        this.selectedAsinNumericalMatricsValue = existingValue[0].operation;
        this.asinMatricsNumericalInputValue = existingValue[0].value;
      }
      this.isAsinFilter = true;
    },
    clearFilter() {
      this.isFilter = false;
      this.matricsInputValue = "";
    },
    clearAsinFilter() {
      this.isAsinFilter = false;
    },
    removeFilterValue(storeFilterValue, data) {
      const index = storeFilterValue
        .flat()
        .findIndex(
          (item) =>
            item.field === data.field &&
            item.operation === data.operation &&
            item.value === data.value
        );

      if (index !== -1) {
        storeFilterValue.splice(index, 1);
      }
    },
    RemoveBadgeValue(data) {
      this.removeFilterValue(this.storeNumericFilterValue, data);

      for (let i = 0; i < this.storeStringFilterValue.length; i++) {
        const itemArray = this.storeStringFilterValue[i];
        const matchIndex = itemArray.findIndex(
          (item) =>
            item.field === data.field &&
            item.operation === data.operation &&
            item.value === data.value
        );

        if (matchIndex !== -1) {
          this.storeStringFilterValue.splice(i, 1);
          break;
        }
      }
      this.removeFilterValue(this.storeAsinFilterValue, data);
      this.removeFilterValue(this.storeAsinMetricsValue, data);
      this.getTableData();
      this.getAsinReportData();
    },
    RemoveAllBadgeValue() {
      this.storeNumericFilterValue = [];
      this.storeStringFilterValue = [];
      this.getTableData();
    },
    ResetAsinFilter() {
      this.storeAsinFilterValue = [];
      this.storeAsinMetricsValue = [];
      this.getAsinReportData();
    },
    handlePostFilters({
      selectedCampaignValue,
      selectedMatricsValue,
      matricsInputValue,
      storeFilterValue,
      getTableDataFunction
    }) {
      let existingFilter = storeFilterValue.find(
        (item) => item[0].field === selectedCampaignValue && item[0].operation === selectedMatricsValue
      );

      if (existingFilter) {
        // existingFilter[0].operation = selectedMatricsValue;
        existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseInt(matricsInputValue);
      } else {
        storeFilterValue.push([
          {
            operation: selectedMatricsValue,
            field: selectedCampaignValue,
            value: isNaN(matricsInputValue) ? matricsInputValue : parseInt(matricsInputValue)
          }
        ]);
      }

      if (typeof getTableDataFunction === "function") {
        getTableDataFunction();
      }
    },
    postNumericalFilters() {
      this.handlePostFilters({
        selectedCampaignValue: this.selectedNumericCampaignValue,
        selectedMatricsValue: this.selectedNumericMatricsValue,
        matricsInputValue: this.matricsInputValue,
        storeFilterValue: this.storeNumericFilterValue,
        getTableDataFunction: this.getTableData
      });
      this.isFilter = false;
    },

    handlePostStringFilters() {
      this.storeStringFilterValue.push([
        {
          operation: this.selectedStringMatricsValue,
          field: this.selectedStringCampaignValue,
          value: this.StringMatricsInputValue
        }
      ])
      this.getTableData();
      this.isFilter = false;
    },

    postAsinFilters() {
      this.handlePostFilters({
        selectedCampaignValue: this.selectedAsinNumericalCampaignValue,
        selectedMatricsValue: this.selectedAsinNumericalMatricsValue,
        matricsInputValue: this.asinMatricsNumericalInputValue,
        storeFilterValue: this.storeAsinFilterValue,
        getTableDataFunction: this.getAsinReportData
      });
      this.isAsinFilter = false;
    },

    postAsinMetricsFilters() {
      this.storeAsinMetricsValue.push([
        {
          operation: this.selectedAsinStringMatricsValue,
          field: this.selectedAsinStringCampaignValue,
          value: this.asinMatricsStringInputValue
        }
      ])
      this.getAsinReportData();
      this.isAsinFilter = false;
    },

    handleTreeSelect(node) {
      if (this.selectedCategory) {
        if (node.parent) {
          this.selectedCategory[node.label] = {}
        }
        else if (node.parentCat) {
          if (this.selectedCategory[node.parentCat]) {
            this.selectedCategory[node.parentCat][node.label] = []
          }
          else {
            this.selectedCategory[node.parentCat] = {}
            this.selectedCategory[node.parentCat][node.label] = []
          }
        }
        else if (node.sub) {
          if (!this.selectedCategory[node.cat]) {
            this.selectedCategory[node.cat] = {};
          }

          if (!this.selectedCategory[node.cat][node.sub]) {
            this.selectedCategory[node.cat][node.sub] = [];
          }

          this.selectedCategory[node.cat][node.sub].push(node.label);
        }
        this.getAsinReportData();
      }
    },
    handleTreeDeSelect(node) {
      if (this.selectedCategory) {
        if (node.parent) {
          delete this.selectedCategory[node.label];
        }
        else if (node.parentCat) {
          if (this.selectedCategory[node.parentCat]) {
            delete this.selectedCategory[node.parentCat][node.label];

            if (Object.keys(this.selectedCategory[node.parentCat]).length === 0) {
              delete this.selectedCategory[node.parentCat];
            }
          }
        }
        else if (node.sub) {
          if (this.selectedCategory[node.cat] && this.selectedCategory[node.cat][node.sub]) {
            const index = this.selectedCategory[node.cat][node.sub].indexOf(node.label);
            if (index > -1) {
              this.selectedCategory[node.cat][node.sub].splice(index, 1);
            }

            if (this.selectedCategory[node.cat][node.sub].length === 0) {
              delete this.selectedCategory[node.cat][node.sub];
            }

            if (Object.keys(this.selectedCategory[node.cat]).length === 0) {
              delete this.selectedCategory[node.cat];
            }
          }
        }

        this.getAsinReportData();
      }
    },
    // handleTreeDeSelect(node) {
    //   if (this.selectedCategory) {
    //     if (node.parentCat) {
    //       if (this.selectedCategory[node.parentCat]) {
    //         delete this.selectedCategory[node.parentCat][node.label];

    //         if (Object.keys(this.selectedCategory[node.parentCat]).length === 0) {
    //           delete this.selectedCategory[node.parentCat];
    //         }
    //       }
    //     } else if (node.cat && node.sub) {
    //       if (this.selectedCategory[node.cat] && this.selectedCategory[node.cat][node.sub]) {
    //         const index = this.selectedCategory[node.cat][node.sub].indexOf(node.label);

    //         if (index > -1) {
    //           this.selectedCategory[node.cat][node.sub].splice(index, 1);
    //         }

    //         if (this.selectedCategory[node.cat][node.sub].length === 0) {
    //           delete this.selectedCategory[node.cat][node.sub];

    //           if (Object.keys(this.selectedCategory[node.cat]).length === 0) {
    //             delete this.selectedCategory[node.cat];
    //           }
    //         }
    //       }
    //     }
    //     this.getAsinReportData();
    //   }
    // },
    handleClear(newValue) {
      if (!newValue || newValue.length === 0) {
        this.selectedCategory = {};
        this.getAsinReportData();
      }
    }
  }
};
