<template lang="pug">
div.w-full#id
    highcharts(:options="chartData")
</template>

<!-- props format :-
 data: {
   xAxis: array,
   seriesData: [{name: string, data: array, color: 'color code (optional),zones:'set style for particular line (optional)', yAxis: 0 or 1 (optional), additionalTooltip: 2D-Array(optional, show keywords for merino on tooltip)}, ...],
   ylabel: string (optional),
   description: string (optional),
   legend: boolean (optional) 
 }
 topMargin: Number (optional)
 legendYPos: Number (optional)
 hideAxis: boolean (optional)
 height: Number (optional)
 tooltipCustom: boolean in string (optional),
 chartType: String (optional)
 -->

<script>
import Highcharts from 'highcharts';
import moment from 'moment';
export default {
    name: 'lineChart',
    props: ['data', 'topMargin', 'legendYPos', 'hideAxis', 'xAxisGap', 'height', 'id', 'marker', 'tooltipCustom', 'chartType', 'endOnTick', 'disableXAxisFormatting'],
    data() {
        return {
            chartData: {},
            color_codes: [
                "#47A694",
                "#eea39f",
                "#c8beea",
                "#79A69E",
                "#54736D",
                "#265950",
                "#79A69E",
                "#317367",
                "#54736D",
                "#52BFAB",
                "#84B5AC",
                "#5F827C",
                "#388273",
                "#59CFB7",
            ],
            updatedGraphData: {
                name: '',
                currentValue: '',
                wasValue: '',
                date: '',
                compDate: ''
            },
            updateData: '',
        }
    },
    watch: {
        data: {
            handler() {
                this.initiate();
            },
            deep: true
        },
        chartType() {
            this.initiate();
        }

    },
    mounted() {
        this.initiate();
    },
    methods: {
        chartYLabel() {
            if (this.data.disableYLabel) return;
            else if (this.data.hasOwnProperty('ylabel')) return this.data.ylabel;
            else return this.data.seriesData[0].name;
        },
        secondaryAxisLabel() {
            if (this.data.seriesData[1]) {
                if (this.data.seriesData[1].ylabel) return this.data.seriesData[1].name;
                else if (!this.data.seriesData[1].ylabel) return;
                return this.data.seriesData[1].name;
            }
            else return;
        },
        chartDescription() {
            if (this.data.hasOwnProperty('description')) return this.data.description;
            else return '';
        },
        chartMarginTop() {
            if (this.topMargin) return this.topMargin;
            else return 60;
        },
        chartYLegend() {
            if (this.legendYPos) return this.legendYPos;
            else return 0;
        },

        formatXAxis(dateArray) {
            if(this.disableXAxisFormatting) return dateArray;

            const resultArray = [];
            for (const dateString of dateArray) {
                const dateObj1 = moment(dateString, 'DD MMM YYYY', true);
                const dateObj2 = moment(dateString, 'YYYY-MM-DD', true);
                if (dateObj1.isValid()) {
                    resultArray.push(dateObj1.format('DD MMM, YY'));
                } else if (dateObj2.isValid()) {
                    resultArray.push(dateObj2.format('DD MMM, YY'));
                } else {
                    resultArray.push(dateString);
                }
            }
            return resultArray;
        },

        yTitleSize() {
            if (window.screen.width < 600) return 9
            else return
        },

        legendFontSize() {
            if (window.screen.width < 600) return "2vw"
            else return "0.8vw"
        },

        legendPosition() {
            if(this.data.legendPosition) return "center"
            if (window.screen.width < 600) return "center"
            else return "right"

        },

        symbolHeight() {
            if (window.screen.width < 600) return 7
            else return 14
        },

        symbolRadius() {
            if (window.screen.width < 600) return 2
            else return 4
        },

        lineMarker() {
            if (window.screen.width < 600) {
                return {
                    enabled: this.marker,
                    radius: 1.5,
                    states: {
                        hover: {
                            radiusPlus: 1,
                            lineWidthPlus: 1
                        }
                    }

                }
            } else {
                return {
                    enabled: this.marker,
                    // radius: 2.5,
                    states: {
                        hover: {
                            radiusPlus: 5,
                            lineWidthPlus: 3,
                        }
                    }
                }

            }

        },

        labelFontSize() {
            if (window.screen.width < 600) return 9
            else return 11;

        },

        plotLegend() {
            if (this.data.legend) {
                return {
                    itemStyle: {
                        fontSize: this.legendFontSize()
                    },
                    enabled: true,
                    alignColumns: true,
                    align: this.legendPosition(),
                    y: this.chartYLegend(),
                    x: 0,
                    squareSymbol: true,
                    verticalAlign: this.data.legendPosition ? this.data.legendPosition : "top",
                    itemDistance: 35,
                    margin: this.data.legendMargin ? this.data.legendMargin : 0,
                    padding: 0,
                    symbolHeight: this.symbolHeight(),
                    symbolWidth: this.symbolHeight(),
                    symbolRadius: this.symbolRadius(),
                }
            } else if (!this.data.legend) {
                return {
                    enabled: false,
                }
            }
        },
        enableAxis() {
            if (this.hideAxis) return false;
            else return true
        },
        chartHeight() {
            if (this.height) return this.height;
            else return null
        },
        customTickInterval() {
            if (this.xAxisGap)
                if (this.data.xAxis.length <= 6)
                    return 1
                else if (this.data.xAxis.length == 29) {
                    return 7
                }
                else {
                    let tempInterval = ~~(this.data.xAxis.length / 5) //  get the quotient of a division
                    return tempInterval;
                }
            else
                return 1;

        },
        initiate() {
            let self = this
            if (!this.data.seriesData.length) return;
            const seriesData = [];
            // looping over the seriesData provided such that there could be as many lines as provided.
            this.data.seriesData.forEach((element, idx) => {
                seriesData.push({
                    name: element.name,
                    type: this.chartType ? this.chartType : 'line',
                    color: element.color ? element.color : this.color_codes[idx],
                    data: element.data,
                    zones: element.zones ? element.zones : '',
                    date: element.date ? element.date : '',
                    yAxis: element.yAxis ? element.yAxis : 0,
                    additionalTooltip: element.additionalTooltip ? element.additionalTooltip : null
                })
            });

            Highcharts.setOptions({
                lang: {
                    decimalPoint: '.',
                    thousandsSep: ','
                }
            });
            this.chartData = {
                chart: {
                    marginTop: this.chartMarginTop(),
                    // width: '100vw',
                    height: this.chartHeight(),
                    backgroundColor: 'transparent',
                },
                plotOptions: {
                    line: {
                        marker: this.lineMarker()
                    },
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: this.chartHeight()
                        },
                        chartOptions: {
                            chart: {
                                height: 300
                            },
                            subtitle: {
                                text: null
                            },
                            navigator: {
                                enabled: false
                            }
                        }
                    }]
                },
                title: {
                    text: '',
                    align: 'center'
                },
                subtitle: {
                    text: this.chartDescription(),
                    align: 'left',
                    y: 0,
                    marginLeft: -10,
                    style: {
                        fontSize: this.legendFontSize()
                    },
                },
                credits: {
                    enabled: false
                },

                yAxis: [
                    {
                        title: {
                            text: this.chartYLabel()
                        },
                        lineWidth: 0,
                        gridLineWidth: 1,
                        labels: {
                            // format: this.data.prefix + ' {value} ' + "%",
                            style: {
                                // color: '#2C2C2C',
                                fontSize: this.labelFontSize(),
                                // fontWeight: '400',
                                // fontFamily: 'Poppins',
                                // wordWrap: "break-word"

                                }
                        },
                    },
                    {
                        title: {
                            text: this.secondaryAxisLabel()
                        },
                        visible: true,
                        lineWidth: 0,
                        gridLineWidth: 1,
                        labels: {
                            // format: this.data.prefix + ' {value} ' + "%",
                            style: {
                                // color: '#2C2C2C',
                                fontSize: this.labelFontSize(),
                                // fontWeight: '400',
                                // fontFamily: 'Poppins',
                                // wordWrap: "break-word"

                                }
                        },
                        opposite: true,
                    }

                ],
                xAxis: [{
                    categories: this.formatXAxis(this.data.xAxis),
                    crosshair: true,
                    visible: this.enableAxis(),
                    tickWidth: 1,
                    tickmarkPlacement: 'on',
                    tickPositioner: function () {
                        if (this.dataMax <= 5) return;
                        let tick = Math.floor(this.dataMin);
                        const positions = [],
                            increment = Math.ceil((this.dataMax - this.dataMin) / 5);

                        if (this.dataMax !== null && this.dataMin !== null) {
                            for (tick;
                                tick - increment <= this.dataMax;
                                tick += increment) {
                                positions.push(tick);
                            }
                        }
                        positions[positions.length - 1] = this.dataMax
                        return positions;
                    },
                    labels: {
                        style: {
                            fontSize: this.labelFontSize(),
                        },
                        rotation: false,
                    }
                }],
                legend: this.plotLegend(),
                series: seriesData,
                tooltip: {
                    useHTML: true,
                    stickOnContact: true,
                    formatter: function (tooltip) {
                        if (seriesData.length === 1) {
                            let currentIdx = seriesData[0].data.indexOf(this.points[0].y)
                            if (self.data.prefix) {

                                if (seriesData.additionalTooltip) {
                                    return `<div class='customTooltip' style='white-space: normal; height: 185px; width: 150px; overflow-y: scroll; max-height: 100px;'> 
                                        ${this.x}: <b> ${self.data.prefix} + ' ' + ${(this.y).toLocaleString("en-US")}</b><br/>
                                        <b>Keywords: </b> ${seriesData[0].additionalTooltip[currentIdx].toString()}</div>`;
                                }
                                return this.x + ' : <b>' + self.data.prefix + ' ' + (this.y).toLocaleString("en-US") + '</b>';
                            }

                            if (self.data.sufix) {
                                if (seriesData.additionalTooltip) {
                                    return `<div class='customTooltip' style='white-space: normal; height: 185px; width: 150px; overflow-y: scroll; max-height: 100px;'> 
                                        ${this.x}: <b> ${(this.y).toLocaleString("en-US")} ${self.data.sufix} </b><br/>
                                        <b>Keywords: </b> ${seriesData[0].additionalTooltip[currentIdx].toString()}</div>`;
                                }
                                return this.x + ' : <b>' + (this.y).toLocaleString("en-US") + self.data.sufix + '</b>';
                            }

                            if (!(self.data.prefix && self.data.sufix)) {
                                if (seriesData[0].additionalTooltip) {
                                    return `<div class='customTooltip' style='white-space: normal; height: 185px; width: 150px; overflow-y: scroll; max-height: 100px;'> 
                                        ${this.x}: <b> ${(this.y).toLocaleString("en-US")}</b><br/>
                                        <b>Keywords: </b> ${seriesData[0].additionalTooltip[currentIdx].toString()}</div>`;
                                }
                                return this.x + ' : <b>' + (this.y).toLocaleString("en-US") + '</b>';
                            }
                        } else {

                            if (self.tooltipCustom) {
                                let currentIdx = seriesData[0].data.indexOf(this.points[0].y)
                                if (self.data.prefix) {
                                    return this.x + ' : <b>' + self.data.prefix + ' ' + (this.points[0].y).toLocaleString("en-US") +
                                        '</b> <br>' + seriesData[1].date[currentIdx] + ': <b>' + self.data.prefix + ' ' + this.points[1].y.toLocaleString("en-US") + '</b>';
                                }

                                if (self.data.sufix) {
                                    return this.x + ' : <b>' + (this.points[0].y).toLocaleString("en-US") + self.data.sufix +
                                        '</b> <br>' + seriesData[1].date[currentIdx] + ': <b>' + this.points[1].y.toLocaleString("en-US") + self.data.sufix + '</b>'
                                }

                                if (!(self.data.prefix && self.data.sufix)) {
                                    return this.x + ' : <b>' + (this.points[0].y).toLocaleString("en-US") +
                                        '</b> <br>' + seriesData[1].date[currentIdx] + ': <b>' + this.points[1].y.toLocaleString("en-US") + '</b>'
                                }
                            }
                            else
                                return tooltip.defaultFormatter.call(this, tooltip);
                        }
                    },
                    // enabled: self.tooltipDisabled,
                    shape: 'rect',
                    valuePrefix: self.data.prefix,
                    valueSuffix: self.data.sufix,
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    shared: true,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                }
            }
        }
    }
}
</script>

<style></style>