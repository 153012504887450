import moment from 'moment';
import axios from 'axios';

export default {
    name: 'targeting',
    props: ['targets', 'targetType', 'isManual', 'tstart_date', 'tend_date'],
    data() {
        return {
            from_ga: false,
            targetItems: [],
            filledItems: [],
            treeData: [],
            targetDateRange: {
                startDate: this.tstart_date,
                endDate: this.tend_date,
            },
            publisherData: {},
            currentCat_levelType: '',
            text_level_id: '',
            rerender: true,
            current_level: 0,
            selectedCat: [],
            checkedPublisher: {},
            mappedPublisher: [],
            squareNode: [],
            allCatCheck: false,
        };
    },
    watch: {
        targetType() {
            const self = this;
            self.filledItems = [];
            self.targetItems = [];
            self.$nextTick(() => {
                self.setTargetItems();
                if (self.targetType === 'publisher') {
                    self.publisherData = [];
                    self.currentCat_levelType = '';
                    self.text_level_id = '';
                    self.current_level = 0;
                    self.from_ga = false;
                    self.getPublisherData();
                }
            });
        },
        tstart_date() {
            this.setDate();
        },
        tend_date() {
            this.setDate();
        },
    },
    mounted() {
        const self = this;
        self.setDate();
        self.setTargetItems();
        if (self.targetType === 'publisher') {
            self.from_ga = false;
            self.getPublisherData();
        }
    },
    methods: {
        toggleAllCheck() {
            const self = this;
            self.squareNode = [];
            if (self.allCatCheck) {
                for (const k in self.checkedPublisher) {
                    self.checkedPublisher[k] = false;
                }
            } else {
                for (const k in self.checkedPublisher) {
                    self.checkedPublisher[k] = true;
                }
            }
            self.refreshCheck();
        },
        setDate() {
            const self = this;
            self.targetDateRange.startDate = self.tstart_date;
            self.targetDateRange.endDate = self.tend_date;
        },
        getName(k) {
            const self = this;
            const parent = self.getParent(k);
            if (parent) {
                if (!self.checkedPublisher[parent]) {
                    return k;
                } else {
                    return self.getName(parent);
                }
            } else {
                return k;
            }
        },
        existInMappedData(name) {
            const self = this;
            let exist = false;
            for (const d of self.mappedPublisher) {
                if (d.name === name) {
                    exist = true;
                    break;
                }
            }
            return exist;
        },
        submitPublisher() {
            const self = this;
            self.mappedPublisher = [];
            for (const k in self.checkedPublisher) {
                if (self.checkedPublisher[k]) {
                    const name = self.getName(k);
                    const category = self.getCategory(name);
                    if (!self.existInMappedData(name)) {
                        const obj = {};
                        obj['name'] = name;
                        obj['level_id'] = category;
                        self.mappedPublisher.push(obj);
                    }
                }
            }
            console.log(self.mappedPublisher);
            self.$emit('emitFilteredPublisher', self.mappedPublisher);
        },
        refreshCheck() {
            const self = this;
            self.rerender = false;
            self.$nextTick(() => {
                self.rerender = true;
            });
        },
        existInTreeData(d) {
            const self = this;
            let exist = false;
            for (const node of self.treeData) {
                if (node.name === d) {
                    exist = true;
                    break;
                }
            }
            return exist;
        },
        getCategory(d) {
            const self = this;
            let category = '';
            for (const node of self.treeData) {
                for (const eachChild of node.data) {
                    if (eachChild === d) {
                        category = node.key;
                        break;
                    }
                }
            }
            return 'category' + category;
        },
        getChildren(d) {
            const self = this;
            let children = [];
            for (const node of self.treeData) {
                if (node.name === d) {
                    children = node.data;
                    break;
                }
            }
            return children;
        },
        getParent(d) {
            const self = this;
            let parent = '';
            for (const node of self.treeData) {
                for (const eachChild of node.data) {
                    if (d === eachChild) {
                        parent = node.name;
                        break;
                    }
                }
            }
            return parent;
        },
        tickChildren(d, check) {
            const self = this;
            const children = self.getChildren(d);
            for (const eachChild of children) {
                if (self.checkedPublisher.hasOwnProperty(eachChild)) {
                    if (check === 'check') {
                        if (self.squareNode.indexOf(eachChild) !== -1) {
                            self.squareNode.splice(self.squareNode.indexOf(eachChild), 1);
                        }
                        self.checkedPublisher[eachChild] = true;
                    } else {
                        self.checkedPublisher[eachChild] = false;
                    }
                    self.refreshCheck();
                    self.tickChildren(eachChild, check);
                }
            }
        },
        tickParent(d) {
            const self = this;
            const parent = self.getParent(d);
            if (parent) {
                const siblings = self.getChildren(parent);
                let countTick = 0;
                for (const eachChild of siblings) {
                    if (self.checkedPublisher[eachChild]) {
                        countTick++;
                    }
                }

                if (countTick === siblings.length) {
                    self.checkedPublisher[parent] = true;
                    if (self.squareNode.indexOf(parent) !== -1) {
                        self.squareNode.splice(self.squareNode.indexOf(parent), 1);
                    }
                } else {
                    self.checkedPublisher[parent] = false;
                    if (countTick > 0) {
                        if (self.squareNode.indexOf(parent) === -1) {
                            self.squareNode.push(parent);
                        }
                    }
                    if (countTick === 0) {
                        if (self.squareNode.indexOf(parent) !== -1) {
                            self.squareNode.splice(self.squareNode.indexOf(parent), 1);
                        }
                    }
                    let squareExist = false;
                    for (const eachChild of siblings) {
                        if (
                            self.squareNode.indexOf(eachChild) !== -1 ||
                            self.checkedPublisher[eachChild]
                        ) {
                            if (countTick !== siblings.length) {
                                squareExist = true;
                                break;
                            }
                        }
                    }
                    if (squareExist) {
                        if (self.squareNode.indexOf(parent) === -1) {
                            self.squareNode.push(parent);
                        }
                    } else {
                        if (self.squareNode.indexOf(parent) !== -1) {
                            self.squareNode.splice(self.squareNode.indexOf(parent), 1);
                        }
                    }
                    self.refreshCheck();
                    self.tickParent(parent);
                }
            }
        },
        tickPublisher(d) {
            const self = this;
            self.$nextTick(() => {
                if (self.squareNode.indexOf(d) !== -1) {
                    self.squareNode.splice(self.squareNode.indexOf(d), 1);
                }
                self.tickParent(d);
                if (self.checkedPublisher[d]) {
                    self.tickChildren(d, 'check');
                } else {
                    self.tickChildren(d, 'uncheck');
                }
            });
        },
        getPublisherData() {
            const self = this;
            const nextLevel = self.current_level + 1;
            const url = `/api/report/categorydata?level_type=${self.currentCat_levelType}&level_id=${encodeURIComponent(self.text_level_id)}&required_category=category${nextLevel}`;

            axios.get(url).then(res => {
                if (res.data.data.length) {
                    const obj = {};
                    obj['key'] = nextLevel;
                    obj['data'] = res.data.data;
                    obj['name'] = self.text_level_id;
                    self.treeData.push(obj);
                    let exist = false;
                    if (self.text_level_id && self.checkedPublisher[self.text_level_id]) {
                        exist = true;
                    }
                    for (const d of res.data.data) {
                        if (exist) {
                            self.checkedPublisher[d] = true;
                        } else {
                            self.checkedPublisher[d] = false;
                        }
                    }
                } else {
                    console.log('no more sub category');
                }
            }).catch(err => {
                console.log(err)
            })
        },
        getChild(d, k) {
            const self = this;
            if (self.selectedCat.indexOf(d) === -1) {
                self.selectedCat.push(d);
                if (!self.existInTreeData(d)) {
                    self.text_level_id = d;
                    self.current_level = k;
                    self.currentCat_levelType = 'category' + self.current_level;
                    self.getPublisherData();
                }
            } else {
                self.selectedCat.splice(self.selectedCat.indexOf(d), 1);
            }
        },
        formatStartDate() {
            this.targetDateRange.startDate = moment(this.start_date).format('YYYY-MM-DD');
        },
        formatEndDate() {
            this.targetDateRange.endDate = moment(this.end_date).format('YYYY-MM-DD');
        },
        toggleDisableNow() {
            const self = this;
            for (const d of self.targetItems) {
                if (self.targets.length - self.filledItems.length === 1) {
                    if (self.filledItems.indexOf(d.name) === -1) {
                        d.disable = true;
                    }
                } else {
                    d.disable = false;
                }
            }
        },
        toggleDisable(name, num, num2) {
            const self = this;
            if (!isNaN(num) && num !== 'e' && num !== '-') {
                if (!num) {
                    if (self.filledItems.indexOf(name) !== -1 && !num2) {
                        self.filledItems.splice(self.filledItems.indexOf(name), 1);
                    }
                } else {
                    if (self.filledItems.indexOf(name) === -1) {
                        self.filledItems.push(name);
                    }
                }
                self.$nextTick(() => {
                    self.toggleDisableNow();
                });
            }
        },
        saveTargetData() {
            const self = this;
            const dates = {
                start_date: self.targetDateRange.startDate,
                end_date: self.targetDateRange.endDate,
            };
            if (!self.from_ga) {
                const emptyItems = [];
                let minTotal = 0;
                let maxTotal = 0;
                for (const d of self.targetItems) {
                    if (d.min === '') {
                        d.min = null;
                    }
                    if (d.max === '') {
                        d.max = null;
                    }
                    if (d.min === null && d.max === null) {
                        emptyItems.push(d.name);
                    }
                }
                if (emptyItems.length > 1) {
                    alert('Please enter targeting values');
                    return false;
                }
                for (const d of self.targetItems) {
                    if (d.min !== null && d.max !== null) {
                        d.min = Number(d.min);
                        d.max = Number(d.max);
                    }
                    if (d.min !== null && d.max === null) {
                        alert(d.name + ' max is required');
                        return;
                    }
                    if (d.max !== null && d.min === null) {
                        alert(d.name + ' min is required');
                        return;
                    }
                    if (d.min > d.max) {
                        alert(d.name + ' min cant be greater than ' + d.name + ' max');
                        return;
                    }
                    if (d.min > 100 || d.min < 0) {
                        alert(d.name + ' min value is not in range 0 to 100');
                        return;
                    }
                    if (d.max > 100 || d.max < 0) {
                        alert(d.name + ' max value is not in range 0 to 100');
                        return;
                    }
                    if (d.min) {
                        minTotal += Number(d.min);
                    }
                    if (d.max) {
                        maxTotal += Number(d.max);
                    }
                }
                if (minTotal > 100) {
                    alert("Min total can't be greater than 100");
                    return;
                }
                if (maxTotal > 100) {
                    alert("Max total can't be greater than 100");
                    return;
                }
            }
            self.$emit('callback', self.targetItems, self.from_ga, dates);
        },
        setTargetItems() {
            const self = this;
            self.targetItems = [];
            for (const d of self.targets) {
                self.targetItems.push({
                    name: d.label,
                    key: d.key,
                    min: '',
                    max: '',
                    disable: false,
                });
            }
        },
        getText(disabled, text) {
            if (disabled) {
                return 'Remaining';
            } else {
                return text;
            }
        },
    },
};
