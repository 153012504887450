import moment from "moment";
import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";

export default {
    name: 'KPITracker',
    components: {
        Loader
    },
    data() {
        return {
            loader: false,
            accounts: [],
            spends: '',
            revenue: '',
            kpiData: [],
            accountGet: '',
            kpiID: '',
            breakSpend: false,
            fbSpend: '',
            adwordSpend: '',
            isMonthly: false,
            modalName: '',
            acMaper: [],
            acName: '',
            errorMsg: 'No account selected',
            KPIModal: null,
            dateRange: {
                startDate: moment().startOf('month').format('YYYY-MM-DD'),
                endDate: moment().endOf('month').format('YYYY-MM-DD')
            }
        };
    },
    watch: {
        isMonthly() {
            if (this.isMonthly) {
                this.dateRange.startDate = moment().startOf('month').format('YYYY-MM-DD');
                this.dateRange.endDate = moment().endOf('month').format('YYYY-MM-DD');
            }
        },
        adwordSpend() {
            if (isNaN(Number(this.adwordSpend))) {
                this.adwordSpend = 0;
            }
            if (isNaN(Number(this.fbSpend))) {
                this.fbSpend = 0;
            }
            this.spends = Number(this.adwordSpend) + Number(this.fbSpend);
        },
        fbSpend() {
            if (isNaN(Number(this.adwordSpend))) {
                this.adwordSpend = 0;
            }
            if (isNaN(Number(this.fbSpend))) {
                this.fbSpend = 0;
            }
            this.spends = Number(this.adwordSpend) + Number(this.fbSpend);
        }
    },
    mounted() {
        this.getAccounts();
        this.initializeModal();
    },
    methods: {
        initializeModal() {
            const $targetKPIModal = document.getElementById("KPIModal");
            const KPIModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {
                    this.modalName = "";
                },
            };

            //assigning modals
            this.KPIModal = new Modal($targetKPIModal, KPIModalOptions);
        },
        toggleSpend() {
            this.breakSpend = !this.breakSpend;
            if (!this.adwordSpend) {
                this.adwordSpend = this.spends / 2;
            }
            if (!this.fbSpend) {
                this.fbSpend = this.spends / 2;
            }
        },
        getKPIData() {
            this.kpiData = [];
            let url = `/api/adword/kpitracker?account_id=${this.accountGet.id}`
            this.loader = true;
            axios.get(url).then(res => {
                if (res.data.data.length) {
                    this.kpiData = res.data.data;
                    this.errorMsg = '';
                } else {
                    if (this.accountGet) {
                        this.errorMsg = 'No tracker found for selected account';
                    } else {
                        this.errorMsg = 'No account selected';
                    }
                }
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        openKPIModal() {
            const self = this;
            self.modalName = 'Add';
            self.spends = '';
            self.revenue = '';
            self.breakSpend = false;
            self.dateRange = {
                startDate: moment().startOf('month').format('YYYY-MM-DD'),
                endDate: moment().endOf('month').format('YYYY-MM-DD')
            };
            for (const d of self.accounts) {
                if (d.id === self.accountGet.id) {
                    self.acName = d.name;
                }
            }
            this.KPIModal.show()
        },
        saveKPI() {
            const self = this;
            const kpis = {};
            kpis.spends = {};
            kpis.revenue = {};

            if (moment(self.dateRange.endDate).diff(self.dateRange.startDate, 'days') < 0) {
                alert('Incorrect Date');
                return;
            }

            if (self.spends) {
                kpis.spends.total = self.spends.toString();
            } else {
                kpis.spends.total = null;
            }
            if (self.revenue) {
                kpis.revenue.total = self.revenue.toString();
            } else {
                kpis.revenue.total = null;
            }

            if (self.adwordSpend && self.spends && self.breakSpend) {
                kpis.spends.Adwords = self.adwordSpend.toString();
            } else {
                kpis.spends.Adwords = null;
            }
            if (self.fbSpend && self.spends && self.breakSpend) {
                kpis.spends.Facebook = self.fbSpend.toString();
            } else {
                kpis.spends.Facebook = null;
            }

            const payload = {
                kpis: kpis,
                start_date: moment(self.dateRange.startDate).format('YYYY-MM-DD'),
                end_date: moment(self.dateRange.endDate).format('YYYY-MM-DD'),
                account_id: self.accountGet
            };

            const url = '/api/adword/kpitracker'

            this.loader = true;

            if (self.modalName === 'Add') {
                axios.post(url, payload).then(res => {
                    this.KPIModal.hide();
                    this.getKPIData();
                    this.loader = false;
                }).catch(err => {
                    this.loader = false;
                    alert('Already exist');
                });
            } else {
                payload.kpi_id = self.kpiID;
                axios.put(url, payload).then(res => {
                    this.KPIModal.hide();
                    this.getKPIData();
                    this.loader = false;
                }).catch(err => {
                    alert("Error");
                    this.loader = false;
                });
            }
        },
        deleteKPI(d) {
            if (confirm("Sure to Delete")) {
                const payload = {
                    kpi_id: d.kpi_id
                };
                const url = '/api/adword/kpitracker';
                this.loader = true;

                axios.delete(url, {data: payload}).then(res => {
                    this.KPIModal.hide();
                    this.getKPIData();
                    this.loader = false;
                }).catch(err => {
                    alert("Error");
                    this.loader = false;
                });
            }
        },
        editKPI(d) {
            const self = this;
            for (const ac of self.accounts) {
                if (ac.id === self.accountGet) {
                    self.acName = ac.name;
                }
            }
            self.modalName = 'Update';
            self.isMonthly = false;
            self.kpiID = d.kpi_id;
            this.breakSpend = false;
            self.dateRange.startDate = d.start_date;
            self.dateRange.endDate = d.end_date;
            if (d.kpis.spends) {
                if (d.kpis.spends.Adwords) {
                    this.adwordSpend = d.kpis.spends.Adwords;
                } else {
                    this.adwordSpend = d.kpis.spends.total / 2;
                }
                if (d.kpis.spends.Facebook) {
                    this.fbSpend = d.kpis.spends.Facebook;
                } else {
                    this.fbSpend = d.kpis.spends.total / 2;
                }
                self.spends = d.kpis.spends.total;
            }
            if (d.kpis.revenue) {
                self.revenue = d.kpis.revenue.total;
            }
            this.KPIModal.show();
        },
        getAccounts() {
            const self = this;
            self.acMaper = [];
            this.loader = true;
            axios.get('/api/adword/accounts').then(response => {
                self.accounts = response.data.data;
                self.accounts.sort((a, b) => {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                });
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        formatNumber(num){
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
};