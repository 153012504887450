import moment from 'moment';
import axios from 'axios';
import Loader from "@/components/Loader/Loader.vue";
import lineChart from '@/components/charts/lineChart.vue';
import funnelChart from '@/components/charts/funnelChart.vue';
import pieChart from '@/components/charts/pieChart.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'

export default {
    name: "OverallSummary",
    components: {
        Loader,
        lineChart,
        funnelChart,
        pieChart,
        dualAxisChart,
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment("2023-10-01").format("YYYY-MM-DD"),
                endDate: moment("2023-10-31").format("YYYY-MM-DD")
            },
            metricData: [],
            processedMetricData: [],
            viewAllTableData: {},
            selectedTab: 'campaign',
            firstModal: undefined,
            secondModal: undefined,
            firstModalHeader:'',
            secondModalHeader:'',
            viewBulkDataForModal: {
                header:[],
                body:[]
            },
            viewBulkDataForSecondModal:{
                header:[],
                body:[],
            },
            mappingForModalAPI:{
                campaign:'adsquad',
                adsquad:'ads'
            }
        }
    },
    mounted() {
        this.dataAPIs();
    },
    methods: {

        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.dataAPIs();
        },

        dataAPIs() {
            this.getTopBoxesBulkData();
            this.getAllTableData();
            this.initializeModal();
        },

        initializeModal() {
            const $targetMoving = document.getElementById('modalForALL');
            const movingOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
            };
            this.firstModal = new Modal($targetMoving, movingOptions);
            const $targetSecondModal = document.getElementById('modalForSecond');
            const secondModalOption = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
            };
            this.secondModal = new Modal($targetSecondModal, secondModalOption);

        },

        hideMovingAvgModal() {
            this.firstModal.hide();
        },
        hideSecondModal(){
            this.secondModal.hide();
        },
        toggleTab(tab) {
            this.selectedTab = tab
            this.getAllTableData()
        },

        openModal(name,id) {
            this.firstModal.show();
            this.firstModalHeader = name
           this.getAllModalTableData(id);
        },
        
        openSecondModal(id,name){
            this.secondModalHeader= name
            this.secondModal.show();
            this.getSecondModalTableData(id);
        },
        getTopBoxesBulkData() {
            this.loader = true;
            const url = "/api/v1/snapchat/bulkdata"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                // account_id:"61c6333b-f36e-4219-abb5-72f4c1b240f9",
            }

            axios.post(url, payload).then(res => {
                this.loader = false;
                this.metricData = res.data.data
                this.processMetricSectionData(this.metricData);
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },

        getAllTableData() {
            this.viewAllTableData = ''
            this.loader = true;
            const url = "/api/v1/snapchat/bulktable"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                // account_id:"61c6333b-f36e-4219-abb5-72f4c1b240f9",
                level: this.selectedTab
            }
            axios.post(url, payload).then(res => {
                this.viewAllTableData = res.data
                this.$nextTick(() => {
                    this.processTable('#campaignTable');
                })
                this.loader = false;

            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },

        getAllModalTableData(id) {
            this.loader = true;
            this.viewBulkDataForModal =''
            const url = "/api/v1/snapchat/bulktable"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                // account_id:"61c6333b-f36e-4219-abb5-72f4c1b240f9",
                level: this.mappingForModalAPI[this.selectedTab],
                level_id: id
            }
            axios.post(url, payload).then(res => {
                this.viewBulkDataForModal = res.data
                this.$nextTick(() => {
                    this.processTable('#campaignTableModal');
                })
                this.loader = false;

            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },


        getSecondModalTableData(id) {
            this.loader = true;
            this.viewBulkDataForSecondModal =''
            const url = "/api/v1/snapchat/bulktable"
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                // account_id:"61c6333b-f36e-4219-abb5-72f4c1b240f9",
                level: 'ads',
                level_id: id
            }
            axios.post(url, payload).then(res => {
                this.viewBulkDataForSecondModal = res.data
                this.$nextTick(() => {
                    this.processTable('#campaignTableSecondModal');
                })
                this.loader = false;

            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        processMetricSectionData(data) {
            this.loader = true
            this.processedMetricData = []
            for (const boxData of data) {
                const tempMetricBoxData = {
                    key: '',
                    oldDateRange: '',
                    oldValue: '',
                    percentDifference: '',
                    prefix: '',
                    suffix: '',
                    value: '',
                    dataSource: '',
                    tooltip: '',
                    graphData: [],
                    target: ''
                };

                tempMetricBoxData.key = boxData.key;
                tempMetricBoxData.oldDateRange = boxData.old_date_range;
                tempMetricBoxData.oldValue = boxData.old_value;
                tempMetricBoxData.percentDifference = boxData.percentage_difference;
                tempMetricBoxData.prefix = boxData.prefix;
                tempMetricBoxData.suffix = boxData.suffix;
                tempMetricBoxData.value = boxData.value;
                tempMetricBoxData.dataSource = boxData.data_source ? boxData.data_source : null;
                tempMetricBoxData.tooltip = boxData.tooltip ? boxData.tooltip : boxData.view ? boxData.view : null;
                tempMetricBoxData.target = boxData.target ?? null

                const tempGraphBoxData = {
                    xAxis: [],
                    ylabel: '',
                    seriesData: [
                        {
                            name: '',
                            data: []
                        },
                        {
                            name: '',
                            data: [],
                            color: "#A4C3BE",
                            zones: [
                                {
                                    value: 0
                                },
                                {
                                    dashStyle: 'Dash'
                                }
                            ],
                            date: []
                        }
                    ],
                    prefix: '',
                    suffix: ''
                };
                tempGraphBoxData.seriesData[0].name = boxData.key;
                tempGraphBoxData.seriesData[1].name = 'Was ';
                tempGraphBoxData.prefix = boxData.prefix;
                tempGraphBoxData.suffix = boxData.suffix;

                for (const graphData of boxData.graph) {
                    tempGraphBoxData.xAxis.push(moment(graphData.date).format("DD MMM"));
                    tempGraphBoxData.seriesData[0].data.push(graphData.value);
                    tempGraphBoxData.seriesData[1].data.push(graphData.comp_value);
                    tempGraphBoxData.seriesData[1].date.push(moment(graphData.comp_date).format("DD MMM"));
                }

                tempMetricBoxData.graphData.push(tempGraphBoxData);
                this.processedMetricData.push(tempMetricBoxData);

            }
            this.loader = false
        },

        processTable(id, sortCol = 0) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                sorting: [[sortCol, "desc"]],
                initComplete: function (settings, json) {
                    $(id).wrap(
                        "<div style='overflow:auto; width:100%;position:relative;'></div>"
                    );
                },
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_",
                },
            });
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },

        //Generic Functions
        abbreviateNumber(value) {
            return new Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },
        decodeHTML(html) {
            const txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        formatNumber(num) {
            if (isNaN(num)) {
                return num;
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        },
    }
}