import moment from 'moment';
import Vuex from 'vuex';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';

export default {
    name: 'FBData',
    components: {
        Loader
    },
    computed: Vuex.mapState(['user', 'menu']),
    data() {
        return {
            is_admin: false,
            dateRange: {
                startDate: moment().subtract(31, 'days').format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            },
            goals: [],
            goal: '',
            acc: [],
            camps: [],
            camp_data: [],
            camp: '',
            loading: false,
            camp_id: '',
            file: '',
            account_id: '',
            final_data: [],
            header: [],
            data_submit: [],
            show_btn: false,
            start_date: '',
            end_date: '',
            data_cid: '',
            data_aid: '',
            show_table: true
        };
    },
    created() {
        this.getAcc();
        const user = JSON.parse(localStorage.userData).data.username
        if (user === 'admin') {
            this.is_admin = true;
        }
    },
    mounted() {
        this.dateRange.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    },
    methods: {
        getData(val) {
            const self = this;
            self.idc = self.camps.indexOf(self.camp);
            self.camp_id = self.camp_data[self.idc]['campaign_id'];
            const url = `/api/fb/reports?camp_id=${self.camp_id}&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&version=${val}`;
            self.loading = true;

            axios.get(url).then(res => {
                this.$router.push(res.data.data);
                this.loading = false;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });

        },
        setChannel() {
            const self = this;
            self.idc = self.camps.indexOf(self.camp);
            self.camp_id = self.camp_data[self.idc].id;
        },
        getCamps() {
            const self = this;
            const url = `/api/fb/campaigns?account=${this.goal.name}`;

            axios.get(url).then(res => {
                self.camps = [];
                self.camp_data = res.data.data;
                for (const item of self.camp_data) {
                    self.camps.push(item.campaign_name);
                }
                if (res.data.initial_date) {
                    self.dateRange.startDate = res.data.initial_date;
                }
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });

        },
        getChannel() {
            const self = this;
            self.id = self.goals.indexOf(self.goal);
            self.account_id = self.acc[self.id].id;
            const url = `/api/fb/campaigns?account_id=${self.account_id}`;

            axios.get(url).then(res => {
                self.camps = [];
                self.camp_data = res.data.data;
                for (const item of self.camp_data) {
                    self.camps.push(item.name);
                }
                if (res.data.initial_date) {
                    self.dateRange.startDate = res.data.initial_date;
                }
            }).catch(err => {
                console.log(err);
            })
        },
        getAcc() {
            const self = this;
            axios.get('/api/fb/accounts').then(res => {
                self.goals = [];
                self.acc = res.data.data;
                for (const item of res.data.data) {
                    self.goals.push(item);
                }
            }).catch(err => {
                console.log(err);
            })
        },
        upload() {
            const self = this;
            const formData = new FormData();
            formData.append('file', document.querySelector('#file')[0].files[0]);
            self.idc = self.camps.indexOf(self.camp);
            self.camp_id = self.camp_data[self.idc].campaign_id;
            const url = `/api/fb/upload?camp_name=${self.camp}&camp_id=${self.camp_id}&account_name=${self.goal}&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`;
            axios.post(url, formData).then(res => {
                self.header = res.data.first_row;
                self.final_data = [];
                self.data_submit = res.data.data;
                self.start_date = res.data.start_date;
                self.end_date = res.data.end_date;
                self.data_cid = res.data.campaignID;
                self.camp_name = res.data.campaign_name;
                self.data_acc_name = res.data.account_name;
                for (const item of self.data_submit) {
                    const vals = [];
                    for (const key of self.header) {
                        if (key === 'Date') {
                            vals.push(item.timestamp);
                        } else {
                            vals.push(item[key]);
                        }
                    }
                    self.final_data.push(vals);
                }
                self.show_btn = true;
            }).catch(err => {
                console.log(err);
            });
        },
        post_data() {
            const self = this;
            const data = {};
            data.data = self.data_submit;
            data.start_date = self.start_date;
            data.end_date = self.end_date;
            data.account_name = self.data_acc_name;
            data.camp_id = self.data_cid;
            data.campaign_name = self.camp_name;
            const url = '/api/fb/post';
            axios.post(url, data).then(res => {
                self.show_btn = false;
                self.show_table = false;
                alert('saved');
            }).catch(err => {
                console.log(err);
            });
        }
    }
};
