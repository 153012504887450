import moment from 'moment';
import Loader from '@/components/Loader/Loader.vue';
import axios from 'axios';
import pieChart from '@/components/charts/pieChart.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue';
import { Modal } from 'flowbite';
export default {
  name: 'AmazonAccountSummary',
  components: { 
    Loader,
    pieChart, 
    dualAxisChart,
  },
  data() {
    return {
      isCompare: false,
      loader: false,
      
      interval: 'day',
      widthMapper: {
        'full': 'w-full',
        'half': 'w-1/2'
      },
      dateRange: {
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
      },
      quartersList: [],
      selectedQuarter: "",
      allTablesData: [],
      regex: /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/i,
      graphArray: [],
      specialChar: '',
      current: JSON.parse(localStorage.userData).clients.current,
      clientId: JSON.parse(localStorage.userData).data.client_id,
      checkselectedDropdown: [],
      selectedEventsData: [],
      pieChartData: [],
      barChartData: [],
      dualAxisChart: [],
      boschDropdown:'',
      selectedSection:'Summary',

      //modal 
      ruleModal: undefined,
      Creative:[],
      Categories:[],
      EventList:[],
      ruleModalData: {
        category: '',
        search_keys:[],
        headKey:'',
        categoryHeadKey:'',
        event_name:'',
        type:[],
        creative_name:'',
        search_keys:[],
    },    
    items:[{}],
    loop:[{}],
    data:[{}],
    EventDateRange: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment()
  },
    creativeDateRange: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment()
  },
  categoryDateRange: {
    startDate: moment().subtract(1, 'months'),
    endDate: moment()
},
    }
  },
  mounted() {
    this.getTableData()
    this.initializeModal()  
  },

  methods: {
    
    dateChange() {
      for (let [d, i] in this.allTablesData) {
        $('#table_dynamic_report_' + i).DataTable().destroy()
      }
      this.getTableData()
    },

    downloadAccountReport() {
      self = this
      self.loader = true
      let url = "/api/report/downloadaccountreport"
      let payload ={
        data: self.allTablesData
      }
      axios.post(url,payload).then(res=>{
          self.loader = false
          window.location = res.data.data
      }).catch(err=>{
        console.log("err")
        self.loader = false
      })
    },

    checkLink(value) {
      this.regex.test(value)
    },

    getTableData() {
      this.loader = true
      this.allTablesData = []
      this.dualAxisChart = []
      this.checkselectedDropdown= []
      this.selectedEventsData= []
      let url = "/api/module/amazon_report"
      let payload = {
        'start_date': moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        'end_date': moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        "account_id": this.current
      }
      if (payload.account_id == 'RR Kabel') {
        payload.account_id = 'RR kables'
      }
      if (payload.account_id == 'Bosch Power Tools') {
        payload.account_id = 'BOSCH'
        payload.section= this.selectedSection
      }
      if(this.current === 'AMD'){
        if(this.selectedQuarter !== ''){
          payload['quarter'] = this.selectedQuarter.split(' ')[0].toLowerCase();
        };
      }
      axios.post(url, payload).then(res => {
        this.allTablesData = res.data.data;
        this.loader = false;
        if(this.current=='Bosch Power Tools'){
          this.boschDropdown = res.data.data[0].section_list
        }
        this.$nextTick(() => {
          for (let idx in this.allTablesData) {
            $('#table_dynamic_report_' + idx).dataTable()
            $('#table_dynamic_report_' + idx).DataTable().destroy()
            $('#table_dynamic_report_' + idx).DataTable({
              pageLength: 10,
              searching: false,
              initComplete: function (settings, json) {
                $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                $('#table_dynamic_report_' + idx).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
              lengthChange: false,
              info: false,
            })
          }
        })
        if (this.current == 'AMD') {
          this.quartersList = res.data.data[0].quarters;
          if(this.selectedQuarter === ''){
            this.selectedQuarter = this.quartersList[0]
          }
          this.$nextTick(() => {
            $('#table_dynamic_report_event').dataTable()
            $('#table_dynamic_report_event').DataTable().destroy()
            $('#table_dynamic_report_event').DataTable({
              searching: false,
              info: false,
              initComplete: function (settings, json) {
                $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                $('#table_dynamic_report_event').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
              language: {
                search: "", searchPlaceholder: "Search...", paginate: {
                    first: "",
                    previous: "<",
                    next: ">",
                    last: "",
                },
                lengthMenu: "<h5>Show entries</h5> _MENU_"
            },
            })
            $('#Qtd_report').dataTable()
            $('#Qtd_report').DataTable().destroy()
            $('#Qtd_report').DataTable({
              info: false,
              paging: false,
              searching: false,
              language: {
                search: "", searchPlaceholder: "Search...", paginate: {
                    first: "",
                    previous: "<",
                    next: ">",
                    last: "",
                },
                lengthMenu: "<h5>Show entries</h5> _MENU_"
            },
            })
            $('#table_dynamic_report_category').dataTable()
            $('#table_dynamic_report_category').DataTable().destroy()
            $('#table_dynamic_report_category').DataTable({
              info: false,
              paging: false,
              searching: false,
              initComplete: function (settings, json) {
                $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                $('#table_dynamic_report_category').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
              language: {
                search: "", searchPlaceholder: "Search...", paginate: {
                    first: "",
                    previous: "<",
                    next: ">",
                    last: "",
                },
                lengthMenu: "<h5>Show entries</h5> _MENU_"
            },
            })
          })
        }
        this.processDualGraphData('allData')
        this.$nextTick(() => {
          for (let i in this.dualAxisChart) {
            $('#table_dynamic_report_amazon' + i).dataTable()
            $('#table_dynamic_report_amazon' + i).DataTable().destroy()
            $('#table_dynamic_report_amazon' + i).DataTable({
              searching: false,
              sorting: false,
              lengthMenu: [6, 15, 25, 50, 100],
              info: false,
              initComplete: function (settings, json) {
                $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                $('#table_dynamic_report_amazon' + i).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
              language: {
                paginate: {
                    first: "",
                    previous: "<",
                    next: ">",
                    last: "",
                },
                lengthMenu: "<h5>Show entries</h5> _MENU_"
            },
            })
          }
        })
        this.processPieChart()
        this.processBarChartData()
      }).catch(err => {
        console.log(err)
        self.loader = false;
      })
    },

    processPieChart() {
      this.pieChartData = []
      for (let key in this.allTablesData[1].graph[0]) {
        let series = [{
          name: key,
          data: []
        }]
        for (let d2 of this.allTablesData[1].graph[0][key]) {
          series[0].data.push({ name: d2.key, y: d2.value })
        }
        this.pieChartData.push(series)
      }
    },

    processBarChartData() {
      let barChartDataTemp = []
      this.barChartData = []
      let barGraphData = {}
      barChartDataTemp.push(this.allTablesData[2])
      barChartDataTemp.push(this.allTablesData[3])
      for (let data of barChartDataTemp) {
        barGraphData = {
          xAxis: [],
          seriesData: [{
            name: data.title,
            data: []
          }]
        }
        for (let d of data.graph) {
          barGraphData.xAxis.push(d.key)
          barGraphData.seriesData[0].data.push(d.value)
        }
        this.barChartData.push(barGraphData)
      }
    },

    processDualGraphData(flag) {
      this.graphArray = [];
      let oneSectionGraph = [];
      let graphData;
      // this.dualAxisChart = [];
      if (flag == 'allData') {
        this.dualAxisChart.push(this.allTablesData[5], this.allTablesData[6], this.allTablesData[7])
      }
      for (let data of this.dualAxisChart) {
        this.checkselectedDropdown.push({
          checkSelectedMonth: [],
          checkSelectedWeek: [],
          selectedWeekData: [],
          weekDropdown: []
        })
        if (data.graph) {
          if (data.graph.length) {
            oneSectionGraph = []
            graphData = {
              xAxis: [],
              seriesData: [{
                name: 'Orders',
                data: [],
                prefix: '',
                suffix: ''
              },
              {
                name: 'Spends',
                data: [],
                prefix: '',
                suffix: '',
                color: '#FCB116'
              }
              ],
            }
            for (let data2 of data.graph) {
              graphData.seriesData[0].data.push(data2['Orders'].value)
              graphData.xAxis.push(data2.Week.value)
              graphData.seriesData[0].prefix = data2["Orders"].prefix
              graphData.seriesData[0].suffix = data2["Orders"].suffix
              graphData.seriesData[1].data.push(data2["Spends"].value)
              graphData.seriesData[1].prefix = data2["Spends"].prefix
              graphData.seriesData[1].suffix = data2["Spends"].suffix
            }
            this.graphArray.push(graphData)
          }
        }
        else {
          let oneSectionGraph = []
          this.graphArray.push(oneSectionGraph)
        }
      }
    },
    updateParticularGraphAndTable(title, idx, flag) {
      let url = "/api/module/amazon_report"
      let payload;
      if (flag == 'event') {
        this.loader = true
        $('#table_dynamic_report_event').DataTable().destroy()
        payload = {
          "account_id": 'AMD',
          "title": title,
          "events": this.selectedEventsData
        }
        if(this.selectedQuarter !== ''){
          payload['quarter'] = this.selectedQuarter.split(' ')[0].toLowerCase();
        };
      } else {
        this.loader = true
        $('#table_dynamic_report_amazon' + idx).DataTable().destroy()
        payload = {
          "account_id": 'AMD',
          "month": this.checkselectedDropdown[idx].checkSelectedMonth,
          "title": title,
          "weeks": this.checkselectedDropdown[idx].selectedWeekData
        }
        if(this.selectedQuarter !== ''){
          payload['quarter'] = this.selectedQuarter.split(' ')[0].toLowerCase();
        };
      }
      axios.post(url, payload).then(res => {
        this.loader = false
        if (flag == 'event') {
          this.allTablesData[idx] = res.data.data[0]
          $('#table_dynamic_report_event').dataTable()
          $('#table_dynamic_report_event').DataTable().destroy()
          $('#table_dynamic_report_event').DataTable({
            searching: false,
            lengthMenu: [6, 15, 25, 50, 100],
            info: false,
            initComplete: function(settings, json) {
              $('.dataTables_scrollBody tfoot tr').css({visibility:'collapse'});
              $('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
              $('#table_dynamic_report_event').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
          },
            language: {
              search: "", searchPlaceholder: "Search...", paginate: {
                  first: "",
                  previous: "<",
                  next: ">",
                  last: "",
              },
              lengthMenu: "<h5>Show entries</h5> _MENU_"
          },
          })
        } else {
          this.dualAxisChart[idx] = res.data.data[0]
          this.processDualGraphData('single')
          this.$nextTick(() => {
            $('#table_dynamic_report_amazon' + idx).dataTable()
            $('#table_dynamic_report_amazon' + idx).DataTable().destroy()
            $('#table_dynamic_report_amazon' + idx).DataTable({
              searching: false,
              sorting: false,
              lengthMenu: [6, 15, 25, 50, 100],
              info: false,
              language: {
                search: "", searchPlaceholder: "Search...", paginate: {
                    first: "",
                    previous: "<",
                    next: ">",
                    last: "",
                },
                initComplete: function(settings, json) {
                  $('.dataTables_scrollBody tfoot tr').css({visibility:'collapse'});
                  $('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
                  $('#table_dynamic_report_amazon' + idx).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
              },
                lengthMenu: "<h5>Show entries</h5> _MENU_"
            },
            })
          })
        }
        this.loader = false
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })
    },
    processMonthandWeek(title, idx, flag, data) {
      if (flag == 'month') {
        if (!this.checkselectedDropdown[idx].checkSelectedMonth.length) {
          this.checkselectedDropdown[idx].selectedWeekData = []
          this.checkselectedDropdown[idx].weekDropdown = []
          this.checkselectedDropdown[idx].checkSelectedWeek = []
        }
        if (this.checkselectedDropdown[idx].checkSelectedMonth.length) {
          this.checkselectedDropdown[idx].weekDropdown = [];
          for (let month of this.checkselectedDropdown[idx].checkSelectedMonth) {
            for (let week of this.dualAxisChart[idx].weeks[month])
              this.checkselectedDropdown[idx].weekDropdown.push(week)
          }
        }
        this.updateParticularGraphAndTable(title, idx)
      }

      if (flag == 'week') {
        this.checkselectedDropdown[idx].selectedWeekData = []
        for (let d of this.checkselectedDropdown[idx].checkSelectedWeek) {
          this.checkselectedDropdown[idx].selectedWeekData.push([
            d.start_date, d.end_date
          ])
        }
        this.updateParticularGraphAndTable(title, idx)
      }

      if (flag == "event") {
        this.updateParticularGraphAndTable(title, idx, 'event')
      }
    },
    //modal 
    initializeModal(){
      const $targetRule = document.getElementById('rule-modal');
      const ruleOptions = {
          placement: 'center',
          backdrop: 'dynamic',
          backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
          closable: true,
        //   onHide: () => {
        //     this.ruleModal.show()
        // },
        // onShow: () => {
        //     this.ruleModal.hide()
        // }
      };
      this.ruleModal = new Modal($targetRule, ruleOptions);
      
  },
  addSearchKey(headKey,creativeIndex){
    this.Creative[creativeIndex].search_keys.push(headKey)
    this.headKey='';
  },
  addCategorySearchKey(categoryHeadKey,index){
    this.Categories[index].search_keys.push(categoryHeadKey)
    // console.log('fghgfg',this.Categories[index].search_keys);
    this.categoryHeadKey='';
  },
  
  removeCategorySearchKey(index,categorySearchKeyIndex){
    this.Categories[index].search_keys.splice(categorySearchKeyIndex,1)
  },
  removeSearchKey(creativeIndex,creativeSearchKeyIndex){ this.Creative[creativeIndex].search_keys.splice(creativeSearchKeyIndex,1)
  },
  addButton(){
    this.ruleModal.show();
    this.getAccountData();

  },
  // formatDates(){
  //   this.formatEventDates()
  // },
  addItem() {
    this.Categories.push({
      date_range:{
        startDate:moment().subtract(1,'months').format("YYYY-MM-DD"),
        endDate:moment().format("YYYY-MM-DD"),
      },
      category:'',
      search_keys:[],
    })
  },
  addEventItem() {
    this.EventList.push({
      date_range:{
        startDate:moment().subtract(1,'months').format("YYYY-MM-DD"),
        endDate:moment().format("YYYY-MM-DD"),
      },
      event_name:'',
      type:'',

    })
  },
  addCreativesItem() {
    this.Creative.push({
      date_range:{
        startDate:moment().subtract(1,'months').format("YYYY-MM-DD"),
        endDate:moment().format("YYYY-MM-DD"),
      },
      creative_name:'',
      search_keys:[],
    })
  },
  eventFormatDate(eventIndex){
    this.EventList[eventIndex].date_range.startDate=moment(this.EventList[eventIndex].date_range.startDate).format("YYYY-MM-DD");
    this.EventList[eventIndex].date_range.endDate=moment(this.EventList[eventIndex].date_range.endDate).format("YYYY-MM-DD");


  },
  categoryFormatDate(index){
this.Categories[index].date_range.startDate=moment(this.Categories[index].date_range.startDate).format("YYYY-MM-DD");
    this.Categories[index].date_range.endDate=moment(this.Categories[index].date_range.endDate).format("YYYY-MM-DD");
  },
  creativeFormatDate(creativeIndex){
    this.Creative[creativeIndex].date_range.startDate=moment(this.Creative[creativeIndex].date_range.startDate).format("YYYY-MM-DD");
    this.Creative[creativeIndex].date_range.endDate=moment(this.Creative[creativeIndex].date_range.endDate).format("YYYY-MM-DD");


  },
  
  removeItem(index) {
    this.Categories.splice(index,1)
  },
  removeEventItem(eventIndex) {
    this.EventList.splice(eventIndex,1)
  },
  removeCreativesItem(creativeIndex) {
    this.Creative.splice(creativeIndex,1)
  },
  
  
//   formatEventDates() {
//     if (this.categoryDateRange.startDate) this.categoryDateRange.startDate = moment(this.categoryDateRange.startDate).format("YYYY-MM-DD");
//     if (this.categoryDateRange.endDate) this.categoryDateRange.endDate = moment(this.categoryDateRange.endDate).format('YYYY-MM-DD');

//     if (this.EventDateRange.startDate) this.EventDateRange.startDate = moment(this.EventDateRange.startDate).format("YYYY-MM-DD");
//     if (this.EventDateRange.endDate) this.EventDateRange.endDate = moment(this.EventDateRange.endDate).format('YYYY-MM-DD');

//     if (this.creativeDateRange.startDate) this.creativeDateRange.startDate = moment(this.creativeDateRange.startDate).format("YYYY-MM-DD");
//     if (this.creativeDateRange.endDate) this.creativeDateRange.endDate = moment(this.creativeDateRange.endDate).format('YYYY-MM-DD');
// },
  getAccountData(){
    this.loader = true
    let url="/api/amazon/save_modify/account_data"
    axios.get(url).then(res => {
      const resData=res.data;
      this.Categories=resData.data.categories;
      this.Creative=resData.data.creatives;
      this.EventList=resData.data.event_list;
      this.loader=false
    }).catch(err => {
      console.log(err);
      this.loader = false
  })
  },
  saveAccountData(){
    let url="api/amazon/save_modify/account_data"
    let payload={

      // "account_id":this.current,
      // "categories":[{
      // "start_date":this.categoryDateRange.startDate,
      // "end_date":this.categoryDateRange.endDate,
      // "category":this.ruleModalData.category,
      // "search_keys":this.ruleModalData.search_keys
      // }],
      data:{
      "categories":this.Categories,
      "event_list":this.EventList,
      "creatives":this.Creative,
      }
      
    }
    console.log("payload",payload);
    axios.post(url,payload).then((res) => {
      const resData=res.data;
      console.log("save data",this.resData);
    })
  }

  }
}
