import axios from 'axios';
import moment from 'moment';
import Loader from '@/components/Loader/Loader.vue';
import lineChart from '@/components/charts/lineChart.vue';
import { Modal } from 'flowbite';

export default {
    name: 'GroupData',
    components: {
        Loader,
        lineChart
    },
    data() {
        return {
            keywordLoader: false,
            areaGraphLoader: false,
            groupDataLoader: false,
            groupModalLoader: false,
            dateRange: {
                startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            has_ecom: JSON.parse(localStorage.userData).menu.Ecomm,
            mapper: {
                "Total Cost (Rs)": "cost",
                "Keyword Count": "count",
                "Keyword Count(%)": "count_per",
                "Total Cost(%)": "cost_per",
                "No Of Orders": "conv",
                "Orders(%)": "conv_per",
                "Avg Position": "avg_pos",
                "Avg Quality Score": "avg_score",
                "Avg Cost Per Order (Rs)": "cost_conv",
                "Avg Impression Share(%)": "imp_share",
                "Avg Cost Per Lead (Rs)": "cost_conv",
                "No Of Leads": "conv",
                "Leads(%)": "conv_per",
                "CPC Bid (Rs)": "cpc_bid"
            },
            groupDataTableHeader: [],
            limit: {
                cpl: [500, 700],
                conv: [1, 3]
            },
            rules: {},
            table_data: [],
            group_data: [],
            trend_options: ['Avg Cost Per Order (Rs)', 'Avg Impression Share(%)', 'Avg Position', 'Avg Quality Score', 'Keyword Count', 'Keyword Count(%)', 'No Of Orders', 'Orders(%)', 'Total Cost (Rs)', 'Total Cost(%)'],
            options: {
                headings: [
                    // { key: 'final_group', value: "Group", width: "48.8125px" },
                    { key: 'campaign_name', value: "Campaign Name", width: "105.8125px" },
                    { key: 'adgroup_name', value: "Adgroup Name", width: "75.8125px" },
                    { key: 'keyword', value: "Keyword", width: "96.8125px" },
                    // { key: 'avg_pos', value: "Avg Position", width: "55.8125px" },
                    { key: 'cpl', value: "Adjusted CPL", width: "65.8125px" },
                    { key: 'cost', value: "Cost (₹)", width: "55.8125px" },
                    { key: 'imp', value: "Impressions", width: "74.8125px" },
                    { key: 'imp_share', value: "Impression Share", width: "66.8125px" },
                    { key: 'clicks', value: "Clicks", width: "41.8125px" },
                    { key: 'cost_click', value: "Cost/Clicks (₹)", width: "72.8125px" },
                    { key: 'conv', value: "Conversions", width: "73.8125px" },
                    { key: 'cost_conversion', value: "Cost/Conversions (₹)", width: "101.8125px" },
                    { key: 'score', value: "Quality Score", width: "50.8125px" },
                    // { key: 'best_group', value: "Best Group", width: "47.8125px" },
                ]
            },
            download_text: "Download",
            metric: '',
            choice: 'Total Cost (Rs)',
            group_trends: {},
            color_codes: [
                "#ffa2a4",
                "#899efc",
                "#eaa9fb",
                "#ffd7d8",
                "#ddc7f8",
                "#ff8392",
                "#dbd0bf",
                "#ebefbf",
                "#d7edbb",
                "#9ddec5",
                "#ffd0b1",
            ],
            plotAreaChartData: {},
            modalChartData: [],
            modalChart: {
                xAxis: [],
                seriesData: []
            },
            keywordModalChart: {
                xAxis: [],
                seriesData: []
            },
            ecomModalMultiselect: [
                {
                    metric: "cost_conv",
                    label: "Avg Cost Per Order (Rs.)"
                },
                {
                    metric: "imp_share",
                    label: "Avg Impression Share (%)"
                },
                {
                    metric: "avg_pos",
                    label: "Avg Position"
                },
                {
                    metric: "avg_score",
                    label: "Avg Quality Score"
                },
                {
                    metric: "count",
                    label: "Keyword Count"
                },
                {
                    metric: "count_per",
                    label: "Keyword Count (%)"
                },
                {
                    metric: "conv",
                    label: "No. of Orders"
                },
                {
                    metric: "conv_per",
                    label: "Orders (%)"
                },
                {
                    metric: "cost",
                    label: "Total Cost (Rs.)"
                },
                {
                    metric: "cost_per",
                    label: "Total Cost (%)"
                },
                {
                    metric: "cpc_bid",
                    label: "CPC Bid (Rs.)"
                },
            ],
            nonEcomModalMultiselect: [
                {
                    metric: "cost_conv",
                    label: "Avg Cost Per Lead (Rs.)"
                },
                {
                    metric: "imp_share",
                    label: "Avg Impression Share (%)"
                },
                {
                    metric: "avg_pos",
                    label: "Avg Position"
                },
                {
                    metric: "avg_score",
                    label: "Avg Quality Score"
                },
                {
                    metric: "count",
                    label: "Keyword Count"
                },
                {
                    metric: "count_per",
                    label: "Keyword Count (%)"
                },
                {
                    metric: "conv",
                    label: "No. of Leads"
                },
                {
                    metric: "conv_per",
                    label: "Leads (%)"
                },
                {
                    metric: "cost",
                    label: "Total Cost (Rs.)"
                },
                {
                    metric: "cost_per",
                    label: "Total Cost (%)"
                },
                {
                    metric: "cpc_bid",
                    label: "CPC Bid (Rs.)"
                },
            ],
            groupModalMetric: [],
            selectedGroupModalMetric: '',
            groupModalHeading: "",
            groupDataModal: null,
            keywordModal: null,
            compareModal: null,
            selectedKeywordGroup: 'All',
            particularKeywordData: [],
            keywordModalFilter: [
                {
                    key: 'avg_pos',
                    label: 'Avg Position'
                },
                {
                    key: 'clicks',
                    label: 'Clicks'
                },
                {
                    key: 'conv',
                    label: 'Conversions'
                },
                {
                    key: 'cost_conversion',
                    label: 'Cost Conversions (Rs.)'
                },
                {
                    key: 'cost_click',
                    label: 'Cost/Click (Rs.)'
                },
                {
                    key: 'cpl',
                    label: 'CPL (Rs.)'
                },
                {
                    key: 'imp',
                    label: 'Impressions'
                },
                {
                    key: 'imp_share',
                    label: 'Impression Share (%)'
                },
                {
                    key: 'cost',
                    label: 'Total Cost (Rs.)'
                },
                {
                    key: 'score',
                    label: 'Quality Score'
                },
                {
                    key: 'cpc_bid',
                    label: 'CPC Bid (Rs.)'
                },
            ],
            selectedKeywordFilter: null,
            checkboxIndices: [],
            compareModalRes: [],
            compareModalChart: {
                xAxis: [],
                seriesData: [],
                ylabel: ''
            },
            dataReady: false
        }
    },
    mounted() {
        this.has_ecom ? this.metric = 'CPO' : this.metric = 'CPL';
        this.has_ecom ? this.groupModalMetric = this.ecomModalMultiselect : this.groupModalMetric = this.nonEcomModalMultiselect;
        this.selectedGroupModalMetric = this.groupModalMetric[0];
        this.selectedKeywordFilter = this.keywordModalFilter[0];
        this.initializeModal();
        this.plotGroupDataTableHeader();
        this.getKeyword(false);
        // this.getGraphData();
    },
    computed: {
        trendsHasData() {
            return Object.keys(this.group_trends).length
        }
    },
    methods: {
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        downloadKey_table() {
            const url = "/api/adword/download_report";
            this.keywordLoader = true;
            const payload = this.table_data;

            axios.post(url, payload).then(res => {
                console.log(res);
                window.location = res.data.data;
                this.keywordLoader = false
            }).catch(err => {
                console.log(err);
                this.keywordLoader = false;
            });
        },

        initializeModal() {
            //Group Data Table Popup
            const $targetGroup = document.querySelector('#groupModal');
            const groupOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    console.log('modal is hidden');
                    this.groupModalHeading = "";
                },
                onShow: () => {
                    console.log('modal is shown');
                },
                onToggle: () => {
                    console.log('modal has been toggled');
                }
            };

            //Single Keyword Popup
            const $targetKeyword = document.querySelector('#keywordModal');
            const keywordOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    console.log('modal is hidden');
                    this.selectedKeywordFilter = this.keywordModalFilter[0];
                },
                onShow: () => {
                    console.log('modal is shown');
                },
                onToggle: () => {
                    console.log('modal has been toggled');
                }
            };

            //Compare Keyword Popup
            const $targetcompare = document.querySelector('#compareModal');
            const compareOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    console.log('modal is hidden');
                    this.selectedKeywordFilter = this.keywordModalFilter[0];
                },
                onShow: () => {
                    console.log('modal is shown');
                },
                onToggle: () => {
                    console.log('modal has been toggled');
                }
            };

            this.groupDataModal = new Modal($targetGroup, groupOptions);
            this.keywordModal = new Modal($targetKeyword, keywordOptions);
            this.compareModal = new Modal($targetcompare, compareOptions);
        },
        getKeyword(flag) {
            this.formatDateRange();
            const url = "/api/users/keyword/";
            this.keywordLoader = true;
            axios.get(url).then(res => {
                this.limit.conv[0] = res.data.data["conv1"];
                this.limit.conv[1] = res.data.data["conv2"];
                this.limit.cpl[0] = res.data.data["cpl1"];
                this.limit.cpl[1] = res.data.data["cpl2"];
                this.getGroupData();
                this.keywordLoader = false;
            }).catch(err => {
                console.log(err);
                this.keywordLoader = false;
            })
        },
        getGraphData() {
            const url = `/api/adword/grouptrends?metric=${this.mapper[this.choice]}&months=11`;
            this.areaGraphLoader = true;
            axios.get(url).then(res => {
                this.group_trends = res.data.group_trends;
                this.$nextTick(() => {
                    this.plotChart();
                });
                this.areaGraphLoader = false;
            }).catch(err => {
                console.log(err);
                this.areaGraphLoader = false;
            })
        },
        getGroupData() {
            const url = `/api/adword/?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&conv_list=${this.limit.conv}&cpl_list=${this.limit.cpl}`;
            this.group_data = [];
            this.rules = [];
            this.table_data = [];
            $('#group_table').DataTable().destroy();
            $('#keyword_table').DataTable().destroy();
            this.groupDataLoader = true;
            axios.get(url).then(res => {
                this.group_data = res.data.group;
                this.rules = res.data.rules;
                this.table_data = res.data.table.sort((a, b) => parseInt(a.final_group) - parseInt(b.final_group)) || [];
                this.$nextTick(() => {
                    // this.processTable('#group_table', 0);
                    this.processTable('#keyword_table', 1);
                    this.groupDataLoader = false;
                });
            }).catch(err => {
                console.log(err);
                this.groupDataLoader = false;
            })
        },
        filterKeywordTable() {
            var dtkey = $('#keyword_table').DataTable();
            if (this.selectedKeywordGroup !== 'All') {
                dtkey.columns(1).search(this.selectedKeywordGroup).draw()
            } else {
                dtkey.columns(1).search('').draw()
            }
        },
        openGroupModal(group) {
            const url = `/api/adword/monthly_group_report?group=${group}`;
            this.groupModalLoader = true;
            this.groupModalHeading = `Group ${group}`;
            axios.get(url).then(res => {
                this.modalChartData = res.data.data;
                this.$nextTick(() => {
                    this.plotGroupModalChart();
                    this.groupDataModal.show();
                });
                this.groupModalLoader = false;
            }).catch(err => {
                console.log(err);
                this.groupModalLoader = false;
            })
        },
        openKeywordModal(row) {
            const url = `/api/adword/monthly_keyword_report`;
            this.groupModalLoader = true;
            let payload = {
                adgroup: row.adgroup_id,
                campaign: row.campaign_id,
                keyword: row.keyword,
                keyword_type: row.keyword_type
            }
            axios.get(url, { params: payload }).then(res => {
                this.particularKeywordData = res.data.data;
                this.$nextTick(() => {
                    this.keywordModal.show();
                    this.plotKeywordModalChart()
                });
                this.groupModalLoader = false;
            }).catch(err => {
                console.log(err);
                this.groupModalLoader = false;
            })
        },
        async openCompareModal() {
            let endpoints = [];
            this.groupModalLoader = true;
            this.compareModalRes = [];
            this.checkboxIndices.forEach(element => {
                if (typeof (element) === 'object') {
                    endpoints.push(`/api/adword/monthly_keyword_report?adgroup=${element.adgroup_id}&campaign=${element.campaign_id}&keyword=${encodeURIComponent(element.keyword)}&keyword_type=${element.keyword_type}`)
                }
            })

            const requests = endpoints.map(endpoint => axios.get(endpoint));
            try {
                const responses = await Promise.all(requests);
                const resData = responses.map(response => response.data.data[0]);

                this.compareModalRes = resData;
                this.groupModalLoader = false;
                this.dataReady = true;
            } catch (error) {
                console.error('Error occurred:', error);
                this.groupModalLoader = false;
            }

            this.plotCompareModalChart();
            this.compareModal.show();
        },

        checkThreshold(group, key, value) {
            if (this.rules[group][key] !== undefined) {
                let expression = `${value} ${this.rules[group][key]['op']} ${this.rules[group][key]['value']}`
                return eval(expression) ? 'bg-red-10' : ''
            }
        },
        setIndex(index, row) {
            if (this.checkboxIndices.includes(index)) {
                this.checkboxIndices.splice(this.checkboxIndices.indexOf(index), 2);
            } else this.checkboxIndices.push(index, row);

        },
        processTable(id, sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                lengthChange: true,
                sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            this.loader = false;
        },
        plotChart() {
            let seriesName = Object.keys(this.group_trends);
            let chartData = Object.values(this.group_trends);
            let xAxisData = [];
            let seriesData = [];

            //mapping data for xAxis
            chartData[0].map(element => {
                xAxisData.push(moment(element.month).format("MMM-YYYY"));
            });

            //mapping data for series 
            chartData.map((eachChart, index) => {
                seriesData.push({
                    marker: {
                        symbol: 'circle',
                        color: this.color_codes[index]
                    },
                    name: seriesName[index],
                    color: this.color_codes[index],
                    data: []
                });
                eachChart.forEach(chartSeries => {
                    seriesData[index]['data'].push(Number(chartSeries.metric.toFixed(2)))
                });
            });

            //plotting chart
            this.plotAreaChartData = {
                chart: {
                    type: 'area'
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    min: 0,
                    categories: xAxisData,
                    tickmarkPlacement: 'on',
                    title: {
                        text: 'Duration'
                    }
                },
                yAxis: {
                    lineWidth: 1,
                    gridLineWidth: 0,
                    title: {
                        text: this.choice
                    }
                },
                tooltip: {
                    shape: 'rect',
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    shared: true,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                },
                plotOptions: {
                    area: {
                        lineWidth: 0.7,
                        lineColor: 'grey',
                        marker: {
                            radius: 1,
                            lineWidth: 2,
                            lineColor: 'grey'
                        }
                    }
                },
                series: seriesData
            }
        },
        plotGroupModalChart() {
            let xAxis = [];
            let seriesData = [
                {
                    name: "",
                    data: []
                }
            ];
            seriesData[0].name = this.selectedGroupModalMetric.label;
            this.modalChartData.map(datapoint => {
                xAxis.push(moment(datapoint.month).format("MMM YYYY"));
                seriesData[0].data.push(Number(datapoint[this.selectedGroupModalMetric.metric].toFixed(2)));
            });
            this.modalChart.xAxis = xAxis;
            this.modalChart.seriesData = seriesData;
        },
        plotKeywordModalChart() {
            let xAxis = [];
            let seriesData = [
                {
                    name: "",
                    data: []
                }
            ];
            xAxis.push(moment(this.particularKeywordData[0].month).format("MMM YYYY"));
            this.particularKeywordData.map((datapoint, index) => {
                seriesData[index].name = this.selectedKeywordFilter.label;
                seriesData[index].data.push(Number(datapoint[this.selectedKeywordFilter.key].toFixed(2)));
            });
            this.keywordModalChart.xAxis = xAxis;
            this.keywordModalChart.seriesData = seriesData;
        },
        plotCompareModalChart() {
            let xAxis = [];
            let seriesData = []

            this.compareModalRes.map(datapoint => {
                if (datapoint !== undefined) {
                    xAxis.push(datapoint.month);
                    seriesData.push({
                        name: datapoint.keyword,
                        data: [Number(datapoint[this.selectedKeywordFilter.key].toFixed(2))]
                    })
                }
            })

            this.compareModalChart.xAxis = xAxis;
            this.compareModalChart.seriesData = seriesData;
            this.compareModalChart.ylabel = this.selectedKeywordFilter.label;

        },
        plotGroupDataTableHeader() {
            if (this.has_ecom) {
                this.groupDataTableHeader = [
                    {
                        key: 'group_count',
                        label: 'Group'
                    },
                    {
                        key: 'count',
                        label: 'Keyword Count',
                        change: 'count_%'
                    },
                    {
                        key: 'count_per',
                        label: 'Count (%)',
                        change: 'count_per_%'
                    },
                    {
                        key: 'cost',
                        label: 'Total Cost',
                        change: 'cost_%'
                    },
                    {
                        key: 'cost_per',
                        label: 'Cost (%)',
                        change: 'cost_per_%'
                    },
                    {
                        key: 'conv',
                        label: 'No. of Orders',
                        change: 'conv_%'
                    },
                    {
                        key: 'conv_per',
                        label: 'Orders (%)',
                        change: 'conv_per_%'
                    },
                    {
                        key: 'avg_pos',
                        label: 'Avg Position',
                        change: 'avg_pos_%'
                    },
                    {
                        key: 'avg_score',
                        label: 'Avg Quality Score',
                        change: 'avg_score_%'
                    },
                    {
                        key: 'cost_conv',
                        label: 'Avg CPO',
                        change: 'cost_conv_%'
                    },
                    {
                        key: 'imp_share',
                        label: 'Avg Impression Share',
                        change: 'imp_share_%'
                    },
                ]
            } else if (!this.has_ecom) {
                this.groupDataTableHeader = [
                    {
                        key: 'group_count',
                        label: 'Group'
                    },
                    {
                        key: 'count',
                        label: 'Keyword Count',
                        change: 'count_%'
                    },
                    {
                        key: 'count_per',
                        label: 'Count (%)',
                        change: 'count_per_%'
                    },
                    {
                        key: 'cost',
                        label: 'Total Cost',
                        change: 'cost_%'
                    },
                    {
                        key: 'cost_per',
                        label: 'Cost (%)',
                        change: 'cost_per_%'
                    },
                    {
                        key: 'conv',
                        label: 'No. of Leads',
                        change: 'conv_%'
                    },
                    {
                        key: 'conv_per',
                        label: 'Leads (%)',
                        change: 'conv_per_%'
                    },
                    {
                        key: 'avg_pos',
                        label: 'Avg Position',
                        change: 'avg_pos_%'
                    },
                    {
                        key: 'avg_score',
                        label: 'Avg Quality Score',
                        change: 'avg_score_%'
                    },
                    {
                        key: 'cost_conv',
                        label: 'Avg CPL',
                        change: 'cost_conv_%'
                    },
                    {
                        key: 'imp_share',
                        label: 'Avg Impression Share',
                        change: 'imp_share_%'
                    },
                ]
            }
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },
    }
}
