<template lang="pug">
div
    div(v-if="loader")
        Loader
    div(class="flex justify-between mb-2")
        h2(class="2xl:text-xl xl:text-base font-semibold text-primary_brand my-1") Totals & Trends
        div(class="flex")
            div(class="customMultiselect mr-4 2xl:w-48 xl:w-36" style="font-size: 10px !important;")
                multiselect(v-model="selectedTimeline" :options="timelineArr" :searchable="false"
                    :close-on-select="true" :show-labels="false" :multiple="false" :allow-empty="false" track-by="value"
                    label="label" :preselectFirst="true" @select="componentRender += 1")
            div(class="flex customMultiselect 2xl:w-48 xl:w-44")
                multiselect(v-model="selectedChannel" :options="channel" :searchable="false" :close-on-select="true"
                    :show-labels="false" :multiple="false" :allow-empty="false" :preselectFirst="true"
                    @select="componentRender += 1")
    div(v-if="!loader")
        div(v-for="chart of plotReadyData" class="py-4 border-solid border-t-2 border-base_color-20")
            AreaChartRender(:chartData="chart" :xData="chartXaxisData" :tickData="tickPositionData"
                :key="chart.title + componentRender")
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import AreaChartRender from './AreaChartRender.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
    name: 'AreaChartProcess',
    components: { AreaChartRender, Loader },
    props: {
        dateRange: {
            type: Object,
            required: true
        },
        channel: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loader: false,
            timelineArr: [
                { label: "Day", value: 'day' },
                { label: "Month", value: 'month' },
                { label: "Week", value: 'week' },
            ],
            selectedTimeline: "",
            selectedChannel: "",
            tickPositionData: [],
            usersChartData: {},
            ASPChartData: {},
            AOVChartData: {},
            revenueChartData: {},
            transactionRateChartData: {},
            transactionsChartData: {},
            chartXaxisData: [],
            plotReadyData: [],
            componentRender: 0,
            has_ecom: JSON.parse(localStorage.userData).menu.Ecomm,
            nonEcomUsersChartData: {},
            pageViewsChartData: {},
            sessionsChartData: {},
            newUsersChartData: {},
            bounceRateChartData: {},
            bouncesChartData: {}
        }
    },
    mounted() {
        if (this.has_ecom) {
            this.getChartData();
            this.componentRender = 0;
        } else if (!this.has_ecom) {
            this.getNonEcomChartData();
            this.componentRender = 0;
        }
    },
    watch: {
        componentRender() {
            this.plotReadyData = [];
            if(this.has_ecom){
                this.getChartData();
            } else if(!this.has_ecom){
                this.getNonEcomChartData();
            }
        }
    },
    methods: {
        getChartData() {
            const url = "/api/ga/ecommerce";
            this.loader = true;
            let payload = {
                filter: this.selectedTimeline.value,
                channel: this.selectedChannel,
                magento: false,
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                week_start: "monday",
                week_end: "sunday"
            };
            if (this.selectedChannel === 'All') {
                payload.channel = 'all'
            }
            axios.get(url, { params: payload }).then(res => {
                this.processEcomChartData(res.data.data)
                this.loader = false;
            }).catch(err => {
                console.log(err)
                this.loader = false;
            })
        },
        getNonEcomChartData() {
            const url = "/api/ga/non-ecom/reports";
            this.loader = true;
            let payload = {
                filter: "timelineByChannel",
                level_type: "view",
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                timeline_type: this.selectedTimeline.value,
                channel: this.selectedChannel
            }
            if (this.selectedChannel === 'All') {
                payload.channel = 'all'
            }
            if (this.selectedTimeline.value === 'day') {
                payload.timeline_type = "date"
            }
            axios.get(url, { params: payload }).then(res => {
                this.processNonEcomChartData(res.data.data)
                this.loader = false;
            }).catch(err => {
                console.log(err)
                this.loader = false;
            })
        },
        processEcomChartData(data) {
            this.tickPositionData = [];
            this.usersChartData = {
                title: "Users",
                xLabel: false,
                color: "#47A694",
                prefix: null,
                suffix: null,
                seriesData: []
            };
            this.ASPChartData = {
                title: "Avg Selling Price",
                xLabel: false,
                color: "#54736D",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.AOVChartData = {
                title: "Avg. Order Value",
                xLabel: false,
                color: "#265950",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.revenueChartData = {
                title: "Revenue",
                xLabel: false,
                color: "#79A69E",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.transactionRateChartData = {
                title: "Transaction Rate",
                xLabel: false,
                color: "#317367",
                prefix: null,
                suffix: "%",
                seriesData: []
            };
            this.transactionsChartData = {
                title: "Transactions",
                xLabel: true,
                color: "#54736D",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.chartXaxisData = [];

            if ((data.length >= 6) && (data.length <= 10)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 2), data.length - 1];
            }
            else if ((data.length >= 11) && (data.length <= 19)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 4), parseInt((data.length - 1) * 3 / 4), data.length - 1];
            }
            else if ((data.length >= 20) || (data.length <= 5)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 4), parseInt((data.length - 1) / 2), parseInt((data.length - 1) * 3 / 4), data.length - 1];
            }

            data.map(dataset => {
                this.chartXaxisData.push(dataset[this.selectedTimeline.value])
                this.usersChartData.seriesData.push(dataset["Users"])
                this.ASPChartData.seriesData.push(Math.ceil(dataset["Avg Selling Price"]))
                this.AOVChartData.seriesData.push(Math.ceil(dataset["Avg. Order Value"]))
                this.revenueChartData.seriesData.push(Math.ceil(dataset["Revenue"]))
                this.transactionRateChartData.seriesData.push(Math.ceil(dataset["Transaction Rate"]))
                this.transactionsChartData.seriesData.push(Math.ceil(dataset["Transactions"]))
            })
            this.plotReadyData.push(this.usersChartData, this.ASPChartData, this.AOVChartData, this.revenueChartData, this.transactionRateChartData, this.transactionsChartData)

        },
        processNonEcomChartData(data) {
            this.tickPositionData = [];
            this.nonEcomUsersChartData = {
                title: "Users",
                xLabel: false,
                color: "#47A694",
                prefix: null,
                suffix: null,
                seriesData: []
            };
            this.pageViewsChartData = {
                title: "Page Views",
                xLabel: false,
                color: "#54736D",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.sessionsChartData = {
                title: "Sessions",
                xLabel: false,
                color: "#265950",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.newUsersChartData = {
                title: "New Users",
                xLabel: false,
                color: "#79A69E",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.bounceRateChartData = {
                title: "Bounce Rate",
                xLabel: false,
                color: "#317367",
                prefix: null,
                suffix: "%",
                seriesData: []
            };
            this.bouncesChartData = {
                title: "Bounces",
                xLabel: true,
                color: "#54736D",
                prefix: "₹",
                suffix: null,
                seriesData: []
            };
            this.chartXaxisData = [];

            if ((data.length >= 6) && (data.length <= 10)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 2), data.length - 1];
            }
            else if ((data.length >= 11) && (data.length <= 19)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 4), parseInt((data.length - 1) * 3 / 4), data.length - 1];
            }
            else if ((data.length >= 20) || (data.length <= 5)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 4), parseInt((data.length - 1) / 2), parseInt((data.length - 1) * 3 / 4), data.length - 1];
            }

            data.map(dataset => {
                this.chartXaxisData.push(dataset[this.selectedTimeline.value])
                this.nonEcomUsersChartData.seriesData.push(dataset["users"])
                this.pageViewsChartData.seriesData.push(Math.ceil(dataset["pageviews"]))
                this.sessionsChartData.seriesData.push(Math.ceil(dataset["sessions"]))
                this.newUsersChartData.seriesData.push(Math.ceil(dataset["newUsers"]))
                this.bounceRateChartData.seriesData.push(Math.ceil(dataset["bounceRate"]))
                this.bouncesChartData.seriesData.push(Math.ceil(dataset["bounces"]))
            })
            this.plotReadyData.push(this.nonEcomUsersChartData, this.pageViewsChartData, this.sessionsChartData, this.newUsersChartData, this.bounceRateChartData, this.bouncesChartData)
        }
    }
}
</script>
<style></style>