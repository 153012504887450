import Vuex from 'vuex';
import moment from 'moment';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';

export default {
    name: 'DBMData',
    computed: Vuex.mapState(['user', 'menu']),
    components: {
        Loader
    },
    data() {
        return {
            is_admin: false,
            dateRange: {
                startDate: moment().subtract(31, 'days').format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            },
            advertisers: [],
            selectedAdvertiser: '',
            header: [],
            csvdata: {},
            show_table: false,
            loader: false
        };
    },
    created() {
        const user = JSON.parse(localStorage.userData).data.username
        if (user === 'admin') {
            this.is_admin = true;
        }
    },
    mounted() {
        this.getAdvertiser();
    },
    methods: {
        getAdvertiser() {
            this.loader = true;
            axios.get('/api/adword/dbm_advertiser_list').then(res => {
                this.advertisers = res.data.data;
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        getData(version) {
            const self = this;
            self.loader = true;
            if (self.selectedAdvertiser) {
                let url = `/api/adword/dbm_download?start_date=${self.date.from}&end_date=${self.date.to}`;
                url += `&version=${version}&advertiser_id=${self.selectedAdvertiser.id}`;
                $.getJSON(url, data => {
                    window.location = data.data;
                    self.loader = false;
                });
            } else {
                alert('Please Select Advertiser');
            }
        },
        upload() {
            const self = this;
            const formData = new FormData();
            self.loader = true;
            const url = '/api/adword/dbm_upload'
            formData.append('file', $('#csvtoupload')[0].files[0]);
            axios.post(url, formData).then(res => {
                console.log(res);
                self.csvdata = res.data.data;
                self.header = res.data.first_row;
                self.show_table = true;
                self.loader = false;
                alert('uploaded');
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })

        },
        save_data() {
            const self = this;
            const payload = {};
            const url = '/api/adword/dbm_save';
            self.loader = true;
            payload['start_date'] = self.date.from;
            payload['end_date'] = self.date.to;
            payload['advertiser_id'] = self.selectedAdv;
            payload['advertiser_name'] = self.selectedAdvText;
            payload['data'] = self.csvdata;

            axios.post(url, payload).then(res => {
                console.log(res);
                self.loader = false;
                alert('saved');
            }).catch(err => {
                console.log(err);
                this.loader = false
            })
        }
    }
};
