import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import Header from '@/components/Header/Header.vue'
import { Modal } from 'flowbite';


//api :-
// https://weavr.online/api/adword/summary?start_date=2023-01-07&end_date=2023-02-06&cstart_date=2022-12-07&cend_date=2023-01-06
// campaign-modal-api :-
// https://weavr.online/api/adword/adgroup_campaign?start_date=2023-01-10&end_date=2023-02-09&cstart_date=2022-12-10&cend_date=2023-01-09&campaign_id=16806590822
// https://weavr.online/api/adword/campaign_type_device?campaign_id=16806590822&start_date=2022-12-10&end_date=2023-02-09
//adgroup-modal-api :-
// https://weavr.online/api/adword/adgroup_type_device?adgroup_id=133930531566&start_date=2022-12-15&end_date=2023-02-14
// https://weavr.online/api/adword/adset_adgroup?start_date=2023-01-15&end_date=2023-02-14&cstart_date=2022-12-15&cend_date=2023-01-14&adgroup_id=133930531566 => Adset Dropdown
// https://weavr.online/api/adword/keyword_adgroup?start_date=2023-01-15&end_date=2023-02-14&cstart_date=2022-12-15&cend_date=2023-01-14&adgroup_id=133930531566 => Keyword Dropdown
//keyword-modal-api :-
// https://weavr.online/api/adword/searchterm_keyword?start_date=2023-01-15&end_date=2023-02-14&cstart_date=2022-12-15&cend_date=2023-01-14&keyword=%2Bbody%20%2Bshop%20%2Bvitamin%20%2Be%20%2Blip%20%2Bbalm&keyword_type=Broad&adgroup_id=92886637864


export default {
    name: 'CampaignSummary',
    components: {
        Loader,
        Header
    },
    data() {
        return {
            dataLoader: false,
            firstDateRange: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment()
            },
            secondDateRange: {
                startDate: moment().subtract(2, 'months'),
                endDate: moment().subtract(1, 'months').subtract(1, 'days')
            },
            activeCompare: false,
            totalSummaryData: [],
            campaignTableData: {
                header: [
                    /* { key: "campaign_name", label: "Campaign Name" },
                    { key: "clicks", label: "Clicks" },
                    { key: "impressions", label: "Impressions" },
                    { key: "ctr", label: "CTR" },
                    { key: "cpc", label: "CPC" },
                    { key: "cost", label: "Cost (₹)" },
                    { key: "conversions", label: "Conversions" },
                    { key: "cost_conversion", label: "Cost/Conv (₹)" },
                    { key: "impression_share", label: "Imp.Share (%)" },
                    { key: "lost_is", label: "LostIS (%)" },
                    { key: "revenue", label: "Revenue (₹)" }, */
                    // { key: "revenue_cost", label: "Revenue/Cost" },
                ],
                data: [],
                allData: []
            },
            campaignModalData: {
                title: '',
                device: {
                    header: [
                        { key: "key", label: "Devices" },
                        { key: "clicks", label: "Clicks" },
                        { key: "impressions", label: "Impressions" },
                        { key: "cost", label: "Cost" },
                        { key: "conversions", label: "Conversions" }
                    ],
                    data: []
                },
                adnetwork: {
                    header: [
                        { key: "key", label: "Ad Network" },
                        { key: "clicks", label: "Clicks" },
                        { key: "impressions", label: "Impressions" },
                        { key: "cost", label: "Cost" },
                        { key: "conversions", label: "Conversions" },
                    ],
                    data: []
                },
                adgroup: {
                    header: [
                        { key: "adgroup_name", label: "Adgroup" },
                        { key: "clicks", label: "Clicks" },
                        { key: "impressions", label: "Impressions" },
                        { key: "ctr", label: "CTR" },
                        { key: "cpc", label: "CPC" },
                        { key: "cost", label: "Cost (₹)" },
                        { key: "conversions", label: "Conversions" },
                        { key: "cost_conversion", label: "Cost/Conv (₹)" },
                        { key: "impression_share", label: "Imp.Share (%)" },
                        { key: "lost_is", label: "LostIS (%)" },
                        { key: "revenue", label: "Revenue (₹)" },
                        { key: "revenue_cost", label: "Revenue/Cost" },
                    ],
                    data: []
                }
            },
            adgroupModalData: {
                title: '',
                dropDownValue: 'keyword',
                device: {
                    header: [
                        { key: "key", label: "Devices" },
                        { key: "clicks", label: "Clicks" },
                        { key: "impressions", label: "Impressions" },
                        { key: "cost", label: "Cost" },
                        { key: "conversions", label: "Conversions" }
                    ],
                    data: []
                },
                adnetwork: {
                    header: [
                        { key: "key", label: "Ad Network" },
                        { key: "clicks", label: "Clicks" },
                        { key: "impressions", label: "Impressions" },
                        { key: "cost", label: "Cost" },
                        { key: "conversions", label: "Conversions" },
                    ],
                    data: []
                },
                adset: {
                    header: [
                        { key: "headline", label: "Adset" },
                        { key: "clicks", label: "Clicks" },
                        { key: "impressions", label: "Impressions" },
                        { key: "ctr", label: "CTR" },
                        { key: "cpc", label: "CPC" },
                        { key: "cost", label: "Cost (₹)" },
                        { key: "conversions", label: "Conversions" },
                        { key: "cost_conversion", label: "Cost/Conv (₹)" }
                    ],
                    data: []
                },
                keyword: {
                    header: [
                        { key: "keyword_name", label: "Keyword" },
                        { key: "keyword_type", label: "Type" },
                        { key: "clicks", label: "Clicks" },
                        { key: "impressions", label: "Impressions" },
                        { key: "ctr", label: "CTR" },
                        { key: "cpc", label: "CPC" },
                        { key: "cost", label: "Cost (₹)" },
                        { key: "conversions", label: "Conversions" },
                        { key: "cost_conversion", label: "Cost/Conv (₹)" },
                        { key: "impression_share", label: "Imp.Share (%)" },
                        { key: "lost_is", label: "LostIS (%)" }
                    ],
                    data: []
                }
            },
            compareOptions: [
                { key: '', label: 'None' },
                { key: '>', label: 'Greater Than' },
                { key: '>=', label: 'Greater Equal' },
                { key: '==', label: 'Equal' },
                { key: '<=', label: 'Less Equal' },
                { key: '<', label: 'Less Than' },
            ],
            filters: [
                {
                    filtertype: { key: "cpc", label: "CPC" },
                    condition: { key: '', label: 'None' },
                    value: ''
                }
            ],
            firstFilterData: {
                filtertype: { key: "cpc", label: "CPC" },
                condition: { key: '', label: 'Condition' },
                value: ''
            },
            secondFilterData: {
                filtertype: { key: "conversions", label: "Conversions" },
                condition: { key: '', label: 'Condition' },
                value: ''
            },
            keywordModalData: {
                header: [
                    { key: "headline", label: "Search Term" },
                    { key: "clicks", label: "Clicks" },
                    { key: "impressions", label: "Impressions" },
                    { key: "ctr", label: "CTR" },
                    { key: "cpc", label: "CPC" },
                    { key: "cost", label: "Cost (₹)" },
                    { key: "conversions", label: "Conversions" },
                    { key: "cost_conversion", label: "Cost/Conv (₹)" }
                ],
                data: []
            },
            secondFilterFlag: false,
            campaignModal: undefined,
            adgroupModal: undefined,
            keywordModal: undefined
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.formatDateRange();
            this.getSummaryData();
            this.initializeModal();
        },
        formatDateRange() {
            this.firstDateRange.startDate = moment(this.firstDateRange.startDate).format('YYYY-MM-DD');
            this.firstDateRange.endDate = moment(this.firstDateRange.endDate).format('YYYY-MM-DD');
            this.secondDateRange.startDate = moment(this.secondDateRange.startDate).format('YYYY-MM-DD');
            this.secondDateRange.endDate = moment(this.secondDateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates() {
            this.formatDateRange();
            this.destroyAllTables();
            this.getSummaryData();
        },
        destroyAllTables() {
            $('#adgroup-tbl').DataTable().destroy()
            $('#adgroupmodal-tbl').DataTable().destroy()
            $('#keywordmodal-tbl').DataTable().destroy()
            $('#campaignsummary-tbl').DataTable().destroy()
        },
        decodeHTML(html) {
            let txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value; // decoder for HTML-encoded strings.
        },
        roundNumber(num, dec) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },
        addFilter() {
            this.filters.push(
                {
                    filtertype: { key: "cpc", label: "CPC" },
                    condition: { key: '', label: 'None' },
                    value: ''
                }
            )
        },
        removeFilter(idx) {
            this.filters.splice(idx, 1);
            if(!this.filters[0].condition.key || !this.filters[0].value) return
            this.applyCampaignTableFilter();
        },
        resetFilter() {
            this.filters = [
                {
                    filtertype: { key: "cpc", label: "CPC" },
                    condition: { key: '', label: 'None' },
                    value: ''
                }
            ]

            $('#campaignsummary-tbl').DataTable().destroy()
            this.campaignTableData.data = this.campaignTableData.allData;

            this.$nextTick(() => {
                this.processSummaryTable();
            });
        },
        applyCampaignTableFilter() {
            $('#campaignsummary-tbl').DataTable().destroy()
            const comparisonOperatorHash = {
                "none": (a, b) => true,
                ">": (a, b) => a > b,
                ">=": (a, b) => a >= b,
                "==": (a, b) => a == b,
                "<=": (a, b) => a <= b,
                "<": (a, b) => a < b,
            }
            this.filters.forEach((filter, index) => {
                const comparisonFunc = comparisonOperatorHash[filter.condition.key];
                const compareType = filter.filtertype.key
                const compareVal = filter.value

                if (index === 0) {
                    this.campaignTableData.data = this.campaignTableData.allData.filter((data) => comparisonFunc(Number(data[compareType].value), compareVal));
                } else {
                    this.campaignTableData.data = this.campaignTableData.data.filter((data) => comparisonFunc(Number(data[compareType].value), compareVal));
                }
            })
            // }
            this.$nextTick(() => {
                this.processSummaryTable();
            });
        },
        initializeModal() {
            const $targetCampaign = document.getElementById('campaign-modal');
            const $targetAdgroup = document.getElementById('adgroup-modal');
            const $targetkeyword = document.getElementById('keyword-modal');
            // options with default values
            const campaignOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    $('#adgroup-tbl').DataTable().destroy();
                },
                onShow: () => {
                    console.log('modal is shown');
                }
            };
            const adgroupOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    this.processCampaignModalTable();
                    this.campaignModal.show();
                    $('#adgroupmodal-tbl').DataTable().destroy();
                },
                onShow: () => {
                    this.campaignModal.hide()
                }
            };
            const keywordOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                onHide: () => {
                    this.processAdgroupModalTable();
                    this.adgroupModal.show();
                    $('#keywordmodal-tbl').DataTable().destroy();
                },
                onShow: () => {
                    this.adgroupModal.hide()
                    this.campaignModal.hide()
                }
            };
            this.campaignModal = new Modal($targetCampaign, campaignOptions);
            this.adgroupModal = new Modal($targetAdgroup, adgroupOptions);
            this.keywordModal = new Modal($targetkeyword, keywordOptions);
        },
        processSummaryTable() {
            var search = require("@/assets/Icons_SVG/Search.svg");
            const campaignSummaryTable = $('#campaignsummary-tbl').DataTable({
                sorting: [[1, "desc"]],
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                },
                paging: true,
                searching: true,
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $('#campaignsummary-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            });
            $('#campaignsummary-tbl' + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            // $("#campaignsummary-tbl").wrap("<div class='scrolledDataTable'></div>");
        },
        processCampaignModalTable() {
            const campaignTable = $('#adgroup-tbl').DataTable({
                sorting: [[1, "desc"]],
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                },
                paging: false,
                searching: false
            });
        },
        processAdgroupModalTable() {
            const adgroupTable = $('#adgroupmodal-tbl').DataTable({
                sorting: [[1, "desc"]],
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                },
                searching: false,
                bLengthChange: false,
                pageLength: 6
            });
        },
        processKeywordModalTable() {
            const keywordTable = $('#keywordmodal-tbl').DataTable({
                sorting: [[1, "desc"]],
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                },
                searching: false,
                bLengthChange: false,
                pageLength: 6
            });
        },
        processAdgroupDropdown() {
            $('#adgroupmodal-tbl').DataTable().destroy();
            this.processAdgroupModalTable();
        },
        processCampaignModal(rowData, colData) {
            if (colData.key != 'campaign_name') return false;
            this.dataLoader = true;
            const campaignId = rowData.campaign_id
            const campaignName = rowData.campaign_name.value
            const formatter = new Intl.NumberFormat('en-US');
            let deviceUrl = `/api/adword/campaign_type_device?campaign_id=${campaignId}&start_date=2022-12-10&end_date=2023-02-09`
            const adgroupUrl = `/api/adword/adgroup_campaign?start_date=2023-01-10&end_date=2023-02-09&cstart_date=2022-12-10&cend_date=2023-01-09&campaign_id=${campaignId}`

            axios.get(deviceUrl).then((res) => {
                const resData = res.data.data;
                const deviceData = resData.device;
                const adNetwork = resData.type;
                deviceData.forEach((data) => {
                    data.clicks = formatter.format(data.clicks)
                    data.cost = formatter.format(data.cost)
                    data.conversions = formatter.format(data.conversions)
                    data.impressions = formatter.format(data.impressions)
                })
                adNetwork.forEach((data) => {
                    data.clicks = formatter.format(data.clicks)
                    data.cost = formatter.format(data.cost)
                    data.conversions = formatter.format(data.conversions)
                    data.impressions = formatter.format(data.impressions)
                })
                this.campaignModalData.device.data = deviceData;
                this.campaignModalData.adnetwork.data = adNetwork;
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            })

            axios.get(adgroupUrl).then((res) => {
                const resData = res.data.data;
                this.campaignModalData.adgroup.data = [];
                resData.forEach(data => {
                    const modifiedData = {}
                    modifiedData.adgroup_id = data.adgroup_id
                    modifiedData.adgroup_name = { value: data.adgroup_name, diff: null }
                    modifiedData.impressions = { value: formatter.format(data.impressions), diff: data.impressions_per }
                    modifiedData.clicks = { value: formatter.format(data.clicks), diff: data.clicks_per }
                    modifiedData.cost_conversion = { value: formatter.format(data.cost_conversion), diff: data.cost_conversion_per }
                    modifiedData.revenue = { value: formatter.format(data.revenue), diff: data.revenue_per }
                    modifiedData.conversions = { value: formatter.format(data.conversions), diff: data.conversions_per }
                    modifiedData.cost = { value: formatter.format(data.cost), diff: data.cost_per }
                    modifiedData.ctr = { value: formatter.format(this.roundNumber(data.ctr, 2)), diff: data.ctr_per }
                    modifiedData.cpc = { value: formatter.format(this.roundNumber(data.cpc, 2)), diff: data.cpc_per }
                    modifiedData.impression_share = { value: formatter.format(this.roundNumber(data.impression_share, 2)), diff: data.impression_share_per }
                    modifiedData.lost_is = { value: formatter.format(this.roundNumber(data.lost_is, 2)), diff: data.lost_is_per }
                    // modifiedData.revenue_cost = { value: formatter.format(this.roundNumber(data.revenue_cost, 2)), diff: data.revenue_cost_per }
                    this.campaignModalData.adgroup.data.push(modifiedData)
                });
                this.$nextTick(() => {
                    this.campaignModalData.title = campaignName;
                    this.campaignModal.show();
                    this.processCampaignModalTable();
                    this.dataLoader = false;
                });
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            })
        },
        processAdgroupModal(rowData, colData) {
            if (colData.key != 'adgroup_name') return false;
            this.dataLoader = true;
            const adgroupId = rowData.adgroup_id
            const adgroupName = rowData.adgroup_name.value
            const formatter = new Intl.NumberFormat('en-US');
            let deviceUrl = '/api/adword/adgroup_type_device?'
            deviceUrl += `adgroup_id=${adgroupId}&`
            deviceUrl += `start_date=${this.firstDateRange.startDate}&end_date=${this.firstDateRange.endDate}`
            let keywordUrl = '/api/adword/keyword_adgroup?'
            keywordUrl += `start_date=${this.firstDateRange.startDate}&end_date=${this.firstDateRange.endDate}&cstart_date=${this.secondDateRange.startDate}&cend_date=${this.secondDateRange.endDate}&`
            keywordUrl += `adgroup_id=${adgroupId}`
            let adseturl = '/api/adword/adset_adgroup?'
            adseturl += `start_date=${this.firstDateRange.startDate}&end_date=${this.firstDateRange.endDate}&cstart_date=${this.secondDateRange.startDate}&cend_date=${this.secondDateRange.endDate}&`
            adseturl += `adgroup_id=${adgroupId}`

            axios.get(deviceUrl).then((res) => {
                const resData = res.data.data;
                const deviceData = resData.device;
                const adNetwork = resData.type;
                deviceData.forEach((data) => {
                    data.clicks = formatter.format(data.clicks)
                    data.cost = formatter.format(data.cost)
                    data.conversions = formatter.format(data.conversions)
                    data.impressions = formatter.format(data.impressions)
                })
                adNetwork.forEach((data) => {
                    data.clicks = formatter.format(data.clicks)
                    data.cost = formatter.format(data.cost)
                    data.conversions = formatter.format(data.conversions)
                    data.impressions = formatter.format(data.impressions)
                })
                this.adgroupModalData.device.data = deviceData;
                this.adgroupModalData.adnetwork.data = adNetwork;
            }).catch((err) => {
                console.log(err)
                this.dataLoader = false;
            })

            axios.get(keywordUrl).then((res) => {
                const resData = res.data.data;
                this.adgroupModalData.keyword.data = [];
                resData.forEach(data => {
                    const modifiedData = {}
                    modifiedData.adgroup_id = adgroupId
                    modifiedData.keyword_name = { value: data.keyword_name, diff: null }
                    modifiedData.keyword_type = { value: data.keyword_type, diff: null }
                    modifiedData.impressions = { value: formatter.format(data.impressions), diff: data.impressions_per }
                    modifiedData.clicks = { value: formatter.format(data.clicks), diff: data.clicks_per }
                    modifiedData.cost_conversion = { value: formatter.format(data.cost_conversion), diff: data.cost_conversion_per }
                    modifiedData.conversions = { value: formatter.format(data.conversions), diff: data.conversions_per }
                    modifiedData.cost = { value: formatter.format(data.cost), diff: data.cost_per }
                    modifiedData.ctr = { value: formatter.format(this.roundNumber(data.ctr, 2)), diff: data.ctr_per }
                    modifiedData.cpc = { value: formatter.format(this.roundNumber(data.cpc, 2)), diff: data.cpc_per }
                    modifiedData.impression_share = { value: formatter.format(this.roundNumber(data.impression_share, 2)), diff: data.impression_share_per }
                    modifiedData.lost_is = { value: formatter.format(this.roundNumber(data.lost_is, 2)), diff: data.lost_is_per }
                    this.adgroupModalData.keyword.data.push(modifiedData)
                });
                console.log(this.adgroupModalData.keyword.data);
                this.$nextTick(() => {
                    this.adgroupModalData.title = adgroupName;
                    this.adgroupModal.show();
                    this.processAdgroupModalTable();
                    this.dataLoader = false;
                });
            }).catch((err) => {
                console.log(err)
                this.dataLoader = false;
            })

            axios.get(adseturl).then((res) => {
                const resData = res.data.data;
                this.adgroupModalData.adset.data = [];
                resData.forEach(data => {
                    const modifiedData = {}
                    modifiedData.adgroup_id = adgroupId
                    modifiedData.headline = { value: data.headline, diff: null }
                    modifiedData.impressions = { value: formatter.format(data.impressions), diff: data.impressions_per }
                    modifiedData.clicks = { value: formatter.format(data.clicks), diff: data.clicks_per }
                    modifiedData.cost_conversion = { value: formatter.format(data.cost_conversion), diff: data.cost_conversion_per }
                    modifiedData.conversions = { value: formatter.format(data.conversions), diff: data.conversions_per }
                    modifiedData.cost = { value: formatter.format(data.cost), diff: data.cost_per }
                    modifiedData.ctr = { value: formatter.format(this.roundNumber(data.ctr, 2)), diff: data.ctr_per }
                    modifiedData.cpc = { value: formatter.format(this.roundNumber(data.cpc, 2)), diff: data.cpc_per }
                    this.adgroupModalData.adset.data.push(modifiedData)
                });
            }).catch((err) => {
                console.log(err);

            })
        },
        processKeywordModal(rowData, colData) {
            if (colData.key != 'keyword_name') return false;
            this.dataLoader = true;
            const adgroupId = rowData.adgroup_id
            const keywordType = rowData.keyword_type.value
            const keyword = encodeURIComponent(rowData.keyword_name.value)
            const formatter = new Intl.NumberFormat('en-US');

            let deviceUrl = "/api/adword/searchterm_keyword?"
            deviceUrl += `start_date=2023-01-15&end_date=2023-02-14&cstart_date=2022-12-15&cend_date=2023-01-14&`
            deviceUrl += `keyword=${keyword}&keyword_type=${keywordType}&adgroup_id=${adgroupId}`

            axios.get(deviceUrl).then((res) => {
                const resData = res.data.data;
                this.keywordModalData.data = [];
                resData.forEach(data => {
                    const modifiedData = {}
                    modifiedData.headline = { value: data.headline, diff: null }
                    modifiedData.impressions = { value: formatter.format(data.impressions), diff: data.impressions_per }
                    modifiedData.clicks = { value: formatter.format(data.clicks), diff: data.clicks_per }
                    modifiedData.cost_conversion = { value: formatter.format(data.cost_conversion), diff: data.cost_conversion_per }
                    modifiedData.conversions = { value: formatter.format(data.conversions), diff: data.conversions_per }
                    modifiedData.cost = { value: formatter.format(data.cost), diff: data.cost_per }
                    modifiedData.ctr = { value: formatter.format(this.roundNumber(data.ctr, 2)), diff: data.ctr_per }
                    modifiedData.cpc = { value: formatter.format(this.roundNumber(data.cpc, 2)), diff: data.cpc_per }
                    this.keywordModalData.data.push(modifiedData)
                });
                this.$nextTick(() => {
                    this.keywordModal.show();
                    this.processKeywordModalTable();
                    this.dataLoader = false;
                });
            }).catch((err) => {
                console.log(err)
                this.dataLoader = false;
            })
        },
        getSummaryData() {
            this.dataLoader = true;
            let url = '/api/adword/summary?'
            url += `start_date=${this.firstDateRange.startDate}&end_date=${this.firstDateRange.endDate}&cstart_date=${this.secondDateRange.startDate}&cend_date=${this.secondDateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                const formatter = new Intl.NumberFormat('en-US');
                // formats a number string to iternational system.
                this.campaignTableData.header = resData.data.campaign.headers;
                const tableData = resData.data.campaign.data
                this.totalSummaryData = resData.data.total
                this.totalSummaryData.forEach(data => {
                    data.label = data.label.toUpperCase()
                    data.value = formatter.format(data.value)
                    if (data.prefix) data.value = this.decodeHTML(data.prefix) + " " + data.value;
                    if (data.suffix) data.value = data.value + data.suffix;
                });
                // tableData.forEach(data => {
                //     const modifiedData = {}
                //     modifiedData.status = data.campaign_status.value
                //     modifiedData.campaign_id = data.campaign_id.value
                //     modifiedData.campaign_name = { value: data.campaign_name.value, diff: null }
                //     modifiedData.impressions = { value: formatter.format(data.impressions.value), diff: data.impressions.percentage }
                //     modifiedData.clicks = { value: formatter.format(data.clicks.value), diff: data.clicks.percentage }
                //     modifiedData.cost_conversion = { value: formatter.format(data.cost_conversion.value), diff: data.cost_conversion.percentage }
                //     modifiedData.revenue = { value: formatter.format(data.revenue.value), diff: data.revenue.percentage }
                //     modifiedData.conversions = { value: formatter.format(data.conversions.value), diff: data.conversions.percentage }
                //     modifiedData.cost = { value: formatter.format(data.cost.value), diff: data.cost.percentage }
                //     modifiedData.ctr = { value: this.roundNumber(data.ctr.value, 2), diff: data.ctr.percentage }
                //     modifiedData.cpc = { value: this.roundNumber(data.cpc.value, 2), diff: data.cpc.percentage }
                //     modifiedData.impression_share = { value: this.roundNumber(data.impression_share.value, 2), diff: data.impression_share.percentage }
                //     modifiedData.lost_is = { value: this.roundNumber(data.lost_is, 2), diff: data.lost_is_per }
                //     modifiedData.revenue_cost = { value: this.roundNumber(data.revenue_cost, 2), diff: data.revenue_cost.percentage }
                // });
                this.campaignTableData.data = tableData;
                this.campaignTableData.allData = tableData;
                this.$nextTick(() => {
                    this.processSummaryTable();
                    this.dataLoader = false;
                });
            }).catch((err) => {
                console.log(err)
                this.dataLoader = false;
            })
        }
    },
}