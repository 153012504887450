import Loader from '@/components/Loader/Loader.vue';
import moment from 'moment';
import axios from 'axios';
export default {
    name: 'AmazonSilver',
    components: {
        Loader,
    },
    data() {
        return {
            dataLoader: false,
            dateRange: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment()
            },
            clientId: JSON.parse(localStorage.userData).data.client_id,
            current: JSON.parse(localStorage.userData).clients.current,
            tableData: [],

            adTypeHeader: [],
            adTypeData: [],

            targetingHeader: [],
            targetingData: [],

            eventHeader: [],
            eventData: [],
            selectedEventsData: [],
            eventTitle: [],


            displayTableheader: [],
            displayTableData: [],
            displayTitle: [],

            searchHeader: [],
            searchData: [],
            // previousSearchData:[],

            asinHeader: [],
            asinData: [],

            portfolioHeader: [],
            portfolioData: [],
            portfolioName: [],
            selectedPortfolioData: "Drill Kits_Blue (Corded)",

            creativeTableHeader: [],
            creativeTableData: [],
            creativeTitle: [],

            eventName: [],
            btn: "Search",
            isAmazonDisplayCheck: JSON.parse(localStorage.userData).menu.Amazon_Display,

        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.formatDates()
            this.targetingAd();
            this.displayTable();
            this.eventWise();
            this.searchDataTable();
            this.topAsins();
            this.portfolioTable();
            this.creativeTable();
            this.initilizeTabs();

        },
        btnClick(str) {
            this.btn = str
            this.processTable();
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },
        formatDateRange() {
            if (this.dateRange.startDate) this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
            if (this.dateRange.endDate) this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        initilizeTabs() {
            const tabElements = [
                {
                    id: 'search',
                    triggerEl: document.querySelector('#search'),
                    targetEl: document.querySelector('#search-section')
                },
                {
                    id: 'display',
                    triggerEl: document.querySelector('#display'),
                    targetEl: document.querySelector('#display-section')
                }
            ];
            const options = {
                defaultTabId: 'search',
                activeClasses: 'text-primary_brand hover:text-primary_brand border-primary_brand-80',
                inactiveClasses: 'text-gray-500 hover:text-gray-600 border-base_color-20 hover:border-gray-300 border-transparent',
            };
            this.tabObject = new Tabs(tabElements, options);
        },
        formatDates() {
            this.formatDateRange();
            this.targetingAd();
            this.displayTable();
            this.eventWise();
            this.searchDataTable();
            this.topAsins();
            this.initilizeTabs();
            this.portfolioTable();
            this.creativeTable();
        },
        targetingAd() {
            this.dataLoader = true;
            let url = '/api/amazon/targeting_adtype'
            let payload = {
                "account_id": this.clientId,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate,
            }
            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.tableData = resData;
                this.adTypeHeader = resData.data.ad_type_header;
                this.adTypeData = resData.data.ad_type

                this.targetingHeader = resData.data.targeting_header;
                this.targetingData = resData.data.targeting
                this.dataLoader = false

                this.$nextTick(() => {
                    this.processTable('#adType');

                })
                this.$nextTick(() => {
                    this.processTable('#targeting');

                })
            }).catch(err => {
                this.dataLoader = false;
                console.log(err)
            })
        },
        displayTable() {
            this.dataLoader = true;
            let url = '/api/amazon/display'
            let payload = {
                "account_id": this.clientId,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate,
            }
            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.displayTableheader = resData.data.display_data[0];
                this.displayTitle = (Object.keys(this.displayTableheader)).reverse();
                this.displayTableData = resData.data.display_data;
                this.dataLoader = false;
                this.$nextTick(() => {
                    this.processTable('#displayTable');
                })
            }).catch(err => {
                this.dataLoader = false;
                console.log(err)
            })
        },
        eventWise() {
            this.dataLoader = true;
            let url = '/api/amazon/event_wise'
            let payload = {
                "account_id": this.clientId,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate,
                "events": this.selectedEventsData
            }
            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.eventHeader = resData.data.event_data[0];
                this.eventTitle = resData.data.event_header;
                this.eventData = resData.data.event_data;
                this.eventName = resData.data.events;
                this.dataLoader = false;
                this.$nextTick(() => {
                    this.processTable('#eventWise');
                })
            }).catch(err => {
                this.dataLoader = false;
                console.log(err)
            })

        },
        searchDataTable() {
            this.dataLoader = true;
            let url = '/api/amazon/search_data_report'
            let payload = {
                "account_id": this.clientId,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate
            }
            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.searchHeader = resData.data.headers;
                this.searchData = resData.data.search_data;
                // this.previousSearchData=resData.data.previous_month_search_data;
                this.dataLoader = false;
                this.$nextTick(() => {
                    this.processTable('#searchDataTable');
                })
                // this.$nextTick(() => {
                //     this.processTable('#previousSearchDataTable');    
                // })
            }).catch(err => {
                this.dataLoader = false;
                console.log(err)
            })

        },

        topAsins() {
            this.dataLoader = true;
            let url = '/api/amazon/top_asins'
            let payload = {
                "account_id": this.clientId,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate
            }
            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.asinHeader = resData.data.headers;
                this.asinData = resData.data.top_asins_data;
                this.dataLoader = false;
                this.$nextTick(() => {
                    this.processTable('#topAsins');
                })
            }).catch(err => {
                this.dataLoader = false;
                console.log(err)
            })
        },

        portfolioTable() {
            this.dataLoader = true;
            let url = '/api/amazon/search_portfolio_report'
            let payload = {
                "account_id": this.clientId,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate,

            }
            axios.post(url, payload).then((res) => {
                const resData = res.data.data[this.selectedPortfolioData];
                this.portfolioName = res.data.data.portfolio_name;
                this.portfolioHeader = resData.headers;
                this.portfolioData = resData.portfolio_data;
                this.dataLoader = false;
                this.$nextTick(() => {
                    this.processTable('#portfolioTable');
                })
            }).catch(err => {
                this.dataLoader = false;
                console.log(err)
            })
        },

        creativeTable() {
            this.dataLoader = true;
            this.creativeTableHeader = [];
            this.creativeTableData = [];
            let url = '/api/amazon/creative_report'
            let payload = {
                "account_id": this.clientId,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate
            }

            axios.post(url, payload).then((res) => {
                const resData = res.data;
                this.creativeTitle = resData.data.title;
                this.creativeTableHeader = resData.data.header;
                this.creativeTableData = resData.data.creative_data;
                this.dataLoader = false;
                this.processTable('#creativeTable');
            }).catch(err => {
                this.dataLoader = false;
                console.log(err)
            })
        },


        processTable(id) {
            if (id === '#searchDataTable') {
                $(id).DataTable().destroy();
                $(id).DataTable({
                    searching: false,
                    info: false,
                    sorting: false,
                    lengthChange: false,
                    paging: false,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                })
            } else {
                $(id).DataTable().destroy();
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(id).DataTable({
                    searching: true,
                    info: false,
                    sorting: [[0, "desc"]],
                    initComplete: function (settings, json) {
                        $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    language: {
                        search: "",
                        searchPlaceholder: "Search...",
                        paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",

                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_",
                    },
                })
                $(id + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            }
        },
        eventsDropdown() {
            $('#eventWise').DataTable().destroy();
            this.eventWise();

        },
        portfolioDropdown() {
            $('#portfolioTable').DataTable().destroy();
            this.portfolioTable();

        }

    }
}