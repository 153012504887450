import moment from 'moment';
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';

export default {
    name: "CreativesReport",
    components: {
        Loader,
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment().subtract(6, 'days').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            filteredCreativesData: [],
            creativesDataList: [],
            filterMetrices: ['Campaign Name', 'Ad Name', 'Creative'],
            appliedFilterMetrices: [],
            filterQuery: "",
            metrices: [],
            selectedMetrices: [],
            isFilterOpen: false,
            reportName: localStorage.fb_creative_report_name,
            reportId: localStorage.fb_creative_report_id
        }
    },
    created() {
        window.addEventListener("click", this.close)
    },
    mounted() {
        this.getCreativesData();
       
        this.getFinalImageUrl();
    },
    beforeDestroy() {
        window.removeEventListener("click", this.close)
    },
    methods: {
        async getFinalImageUrl() {
            try {
                const response = await fetch("https://www.facebook.com/ads/image/?d=AQKZfxm0xDa3Kc-WU1rihLwMvMbnNG0GlrpFN9XGpP5QvWX_aB_heVXhzCdu87flfK5TdDRBgtUDUiEQ1OcQNVDWRT5eDw0BBKo3PXbolI_1T_M3sWB5ni3nynjxJMqU-ifhCXZWLjINmLCF40g1HwFz", {
                    method: 'GET', 
                    // We only want headers to follow the redirect
                    redirect: 'follow' // Follow the redirects
                });

                // If there were any redirects, this will be the final URL
                return response.url;
            } catch (error) {
                console.error('Error fetching the image URL:', error);
                return null;
            }
        },

        close(event) {
            if (document.getElementById('search-filter').contains(event.target)) {
                return
            } else {
                this.filterQuery = "";
                this.isFilterOpen = false;
            }
        },

        updateDate() {
            if (this.appliedFilterMetrices.length) {
                this.filterCreativeData()
            } else this.getCreativesData();
        },

        applyMetricFilter(metricName) {
            if (metricName && this.filterQuery) {
                this.appliedFilterMetrices.push({
                    metric: metricName,
                    contains: true,
                    query: this.filterQuery
                });
                this.filterCreativeData()
            }
        },
        removeSelectedMetric(idx,metric){
            this.selectedMetrices.filter((data)=>metric!=data)
            console.log(this.selectedMetrices)
        },
        getCreativesData() {
            this.loader = true;
            this.creativesDataList = [];

            let url = `/api/v1/meta/creativedata?start_date=${moment(this.dateRange.startDate).format("YYYY-MM-DD")}&end_date=${moment(this.dateRange.endDate).format("YYYY-MM-DD")}`;
            if (this.reportId) url += `&view_id=${this.reportId}`
            axios.get(url)
                .then(res => {
                    this.loader = false;
                    this.creativesDataList = res.data.data;
                    if (this.creativesDataList.length) {
                        Object.keys(this.creativesDataList[0].Metrics).forEach(key => {
                            this.metrices.push(key)
                        })
                        this.metrices.unshift("All")
                        this.selectedMetrices = structuredClone(this.metrices);
                    }
                })
                .catch(err => {
                    this.loader = false;
                    console.log("error", err)
                })
        },
        filterCreativeData() {
            this.loader = true;
            const url = "/api/v1/meta/creativedata";

            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                filters: this.appliedFilterMetrices,
                metrics: this.selectedMetrices
            }

            axios.post(url, payload).then(res => {
                this.loader = false;
                this.creativesDataList = res.data.data;
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting filtered data", err)
            })
        },
        removeAppliedMetricFilter(index) {
            this.appliedFilterMetrices.splice(index, 1);
            this.filterCreativeData();
        },
        selectAll(selectedOptions) {
            if (selectedOptions === 'All') {
                this.selectedMetrices = structuredClone(this.metrices)
            } else return;
        },
        removeAll(removedOptions) {
            if (removedOptions === 'All') {
                this.selectedMetrices = []
            } else {
                if (this.selectedMetrices[0] === 'All') this.selectedMetrices.shift();
                else return
            };
        },
        removeMetric(idx) {
            this.selectedMetrices.splice(idx, 1);
            if (this.selectedMetrices[0] === 'All') this.selectedMetrices.shift();
        },
        exportCreatives() {
            this.loader = true;
            const url = `/api/v1/meta/creativedata?start_date=${moment(this.dateRange.startDate).format("YYYY-MM-DD")}&end_date=${moment(this.dateRange.endDate).format("YYYY-MM-DD")}&type=csv`;

            axios.get(url).then(res => {
                this.loader = false;
                window.location = res.data.data;
            }).catch(err => {
                this.loader = false;
                console.log(`Error in exporting data::${err}`)
            })
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}