import axios from 'axios';
import moment from 'moment';

export default {
    name: 'AdwordMapping',
    components: {

    },

    data() {
        return {
            is_admin: false,
            ga_camps: [],
            acc_type: '',
            acc_type_list: ['GA', 'ADWORD'],
            dateRange: {
                startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            },
            camps: [],
            selectedCamp: null,
            field_list: [],
            camp_list: [],
            tag: '',
            new_value: '',
            old_value: '',
            remove_value: '',
            field_map: {},
            camp_drop: [],
            hideCamp: {
                enabled: false,
                paused: false,
                removed: false,
            },
        };
    },

    created() {
        const user = JSON.parse(localStorage.userData).data.username
        if (user === 'admin') {
            this.is_admin = true;
        }
        this.getAcc();
    },
    updated(){
        console.log(this.hideCamp)
    },
    mounted() {
        
    },

    methods: {
        getAcc() {
            const self = this;
            let url = '';
            if (self.acc_type === 'ADWORD') {
                url = '/api/adword/accounts';
            } else if (self.acc_type === 'GA') {
                url = '/api/ga/all-accounts';
            }

            axios
                .get(url)
                .then((response) => {
                    self.camps = response.data.data;
                    console.log(self.ga_camps);
                })
                .catch((error) => {
                    console.error('Error fetching accounts:', error);
                });
        },

        getCamp(index) {
            const self = this;
            self.tag = '';
            let url = '';
            if (self.acc_type === 'ADWORD') {
                url = `/api/adword/campaign_format?account_id=${self.acc}&start_date=${self.date.from}&end_date=${self.date.to}`;
            } else if (self.acc_type === 'GA') {
                url = `api/ga/ecommerce/fb_camp_list?account_id=${self.acc}`;
            }

            axios.get(url).then((response) => {
                self.field_list = response.data.field_list;
                self.camp_list = response.data.data;
                self.field_map = response.data.field_map;

                for (let i = 0; i < self.camp_list.length; i++) {
                    self.camp_list[i].text = 'Save';
                    if (index !== -1) {
                        self.camp_list[index].text = 'Saved';
                    }
                }

                // Need to create a copy of objects to duplicate
                for (const item of self.camp_list) {
                    const item_new = { ...item };
                    self.camp_drop.push(item_new);
                }
                this.$nextTick(() => {
                    this.processTable("#main-table", 1)
                })
            }).catch((error) => {
                console.error('Error fetching campaign:', error);
            });
        },

        saveItem(index) {
            const self = this;
            const values = {};
            for (const [k, v] of Object.entries(self.camp_list[index])) {
                if (
                    k !== 'campaign_name' &&
                    k !== 'campaign_id' &&
                    k !== 'text' &&
                    k !== 'status'
                ) {
                    values[k] = v;
                }
            }

            const data = {
                account_id: self.acc,
                value: values,
            };

            let url = '';
            if (self.acc_type === 'ADWORD') {
                url = '/api/adword/campaign_format';
                data.campaign_id = self.camp_list[index].campaign_id;
            } else if (self.acc_type === 'GA') {
                url = 'api/ga/ecommerce/fb_camp_list';
                data.campaign_name = self.camp_list[index].campaign_name;
            }

            axios
                .post(url, data)
                .then((response) => {
                    self.getCamp(index);
                })
                .catch((error) => {
                    console.error('Error saving item:', error);
                });
        },

        addTag() {
            const self = this;
            self.field_list.push(self.tag);
        },

        showEditTag(tag) {
            const self = this;
            self.old_value = tag;
            $('#myModal').modal('show');
        },

        showRemoveTag(tag) {
            const self = this;
            self.remove_value = tag;
            $('#myModal1').modal('show');
        },

        editTag() {
            const self = this;
            const url = '/api/adword/campaign_format';
            const data = {
                account_id: self.acc,
                value: self.old_value,
                new_value: self.new_value,
            };

            axios
                .put(url, data)
                .then((response) => {
                    self.getCamp(-1);
                })
                .catch((error) => {
                    console.error('Error editing tag:', error);
                });

            $('#myModal').modal('hide');
            self.new_value = '';
        },

        removeTag() {
            const self = this;
            let url = '';
            if (self.acc_type === 'ADWORD') {
                url = '/api/adword/campaign_format';
            } else if (self.acc_type === 'GA') {
                url = 'api/ga/ecommerce/fb_camp_list';
            }

            const data = {
                account_id: self.acc,
                value: self.remove_value,
            };

            axios
                .delete(url, { data })
                .then((response) => {
                    self.getCamp(-1);
                })
                .catch((error) => {
                    console.error('Error removing tag:', error);
                });

            $('#myModal1').modal('hide');
            self.remove_value = '';
        },

        hideModal() {
            $('#myModal1').modal('hide');
        },

        setValue(index, item) {
            this.camp_list[index][item] = this.camp_drop[index][item];
        },

        showThis(status) {
            if (!this.hideCamp[status]) {
                this.hideCamp[status] = true;
            } else {
                this.hideCamp[status] = false;
            }
        },

        checkStatus(index) {
            return !this.hideCamp[this.camp_list[index]['status']];
        },
        processTable(id, sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                lengthChange: true,
                sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            this.loader = false;
        },
    },
};
