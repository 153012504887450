import moment from 'moment';
import axios from 'axios';
import Loader from "@/components/Loader/Loader.vue";
import dualAxisChart from '@/components/charts/dualAxisChart.vue'

export default {
    name: "MyntraSummary",
    components: {
        Loader,
        dualAxisChart
    },
    data() {
        return {
            loader: false,
            loader1: false,
            accountsList: [],
            selectedAccount: null,
            dateRange: {
                startDate: moment().subtract(1, 'week').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            tabs: {
                summary: true,
                report: false,
            },
            selectedTab: 'campaign',
            bulkData: [],
            timelineIntervals: ["Day", "Month"],
            selectedInterval: "Day",
            timelineData: null,
            timelineChartFilterList: [],
            selectedTimelineBarKey: 'impressions',
            selectedTimelineLineKey: 'clicks',
            timelineChartData: {
                xAxis: [],
                seriesData: []
            },
            allChartData: {},
            viewAllTableData: [],
        }
    },
    mounted() {
        this.getAccounts();
    },
    methods: {
        dataAPIs() {
            this.getBulkData();
            this.getTimelineData();
            this.getCampAdProductTable();
        },
        getAccounts() {
            this.loader = true;
            axios.post('/api/v1/myntraaccounts').then(res => {
                this.accountsList = res.data.data;
                this.selectedAccount = this.accountsList[0]
                this.$nextTick(() => {
                    this.dataAPIs();
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getBulkData() {
            this.loader = true;
            this.bulkData = [];
            const url = "/api/v1/myntra/bulkdata";
            const payload = {
                account_name: this.selectedAccount,
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
            }

            axios.post(url, payload)
                .then(res => {
                    this.bulkData = res.data.data;
                    this.loader = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loader = false
                })
        },
        getTimelineData() {
            this.loader = true;
            $('#timeline-tbl').DataTable().destroy()
            this.timelineData = null;
            this.timelineChartFilterList = []
            this.allChartData = {}
            const url = "/api/v1/myntra/timelinebulkdata";
            const payload = {
                account_name: this.selectedAccount,
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                filter: this.selectedInterval
            }

            axios.post(url, payload).then(res => {
                this.timelineData = res.data;
                this.allChartData = res.data.body;
                this.timelineChartFilterList = Object.keys(res.data.body[0]).filter(key => key !== 'date');

                this.$nextTick(() => {
                    this.processTable('#timeline-tbl');
                    this.processTimelineGraph()
                    this.dataLoader = false;
                });
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getCampAdProductTable() {
            this.loader1 = true;
            this.viewAllTableData = [];
            $('#campAdProdTable').DataTable().destroy();
            const url = '/api/v1/myntra/tabledata';
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                account_name: this.selectedAccount,
                level: ""
            }

            if (this.selectedTab === 'campaign') {
                payload.level = "campaign_data"
            } else if (this.selectedTab === 'adGroup') {
                payload.level = "adgroup_data"
            } else {
                payload.level = "product_data"
            }

            axios.post(url, payload).then(res => {
                this.viewAllTableData = res.data.body;
                
                this.$nextTick(() => {
                    this.processFooterTable("#campAdProdTable");
                    this.loader1 = false;
                })
            }).catch(err => {
                console.log("error in camp ad product table", err);
                this.loader1 = false;
            })
        },
        processFooterTable(id) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            let self = this;
            $(id).DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: true,
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    search: "",
                    searchPlaceholder: "Search",
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },

                footerCallback: function (row, data, start, end, display) {
                    var api = this.api();
                    // Remove the formatting to get integer data for summation
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            parseFloat(i.replace(/,/g, '')) * 1 :
                            typeof i === 'number' ?
                                i : 0;

                    };

                    for (let i = 1; i < self.viewAllTableData.header.length; i++) {
                        let data = api.column(i, { search: 'applied' }).data();
                        if (data.length) {
                            var total = data.reduce(function (a, b) {
                                return intVal(a) + intVal(b);
                            });
                        } else {
                            total = 0;
                        }
                        total === 0 ?
                            $(api.column(i).footer()).children('div').html("-").addClass('text-center') :
                            $(api.column(i).footer()).html(self.formatNumber(total));
                    }
                }
            });
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },

        processTimelineGraph() {
            this.timelineChartData = {
                xAxis: [],
                seriesData: []
            }

            let xaxis = [];
            const barLabel = this.selectedTimelineBarKey.toUpperCase()
            const lineLabel = this.selectedTimelineLineKey.toUpperCase()
            let seriesData = [
                {
                    name: barLabel,
                    data: []
                },
                {
                    name: lineLabel,
                    data: [],
                    color: "#FCB116"
                }
            ];

            this.allChartData.forEach(series => {
                xaxis.push(series.date);
                seriesData[0].data.push(series[this.selectedTimelineBarKey]);
                seriesData[1].data.push(series[this.selectedTimelineLineKey]);
            })

            this.timelineChartData = {
                xAxis: xaxis,
                seriesData: seriesData,
            }
        },

        processTable(id) {
            $(id).DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    search: "",
                    searchPlaceholder: "Search",
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                }
            });
        },
        formatNumber(num) {
            if (isNaN(num)) {
                return num;
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        },
        toggleTab(tab) {
            this.selectedTab = tab;
            this.getCampAdProductTable();
        },
    }
}