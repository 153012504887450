<template lang="pug">
div
    div(v-if="loader")
        Loader
    div(v-if="chartData.seriesData.length")
        highcharts(:options="plotChartData")
    div(v-else) No data available in the chart!
</template>
<script>
import Loader from '@/components/Loader/Loader.vue'
export default {
    name: 'AreaChartPage',
    components: { Loader },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        xData: {
            type: Array,
            required: true
        },
        tickData: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            loader: false,
            plotChartData: {}
        }
    },
    watch: {
        chartData: {
            deep: true,
            handler() {
                this.loader = true;
                this.plotChart()
            }
        }
    },
    mounted() {
        this.loader = true;
        this.plotChart();
    },
    methods: {
        calcTitleFont() {
            if (window.screen.width < 1536) {
                return '14.5px'
            } else return '18px'
        },
        plotChart() {
            this.loader = true;
            this.plotChartData = {
                chart: {
                    height: 138,
                    marginLeft: 65, // Keep all charts left aligned
                    spacingTop: 10,
                    spacingBottom: 10,
                    borderColor: 'transparent',
                    type: 'area',
                    threshold: null,

                },
                title: {
                    text: this.chartData.title,
                    align: 'left',
                    style: {
                        fontSize: this.calcTitleFont(),
                        fontWeight: 500,
                        color: '#065559',
                        marginBottom: '20px',
                        fontFamily: 'Poppins, sans-serif'
                    },

                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        lineWidth: 0.3,
                        marker: {
                            radius: 1.5
                        }
                    },
                },
                xAxis: {
                    tickPositions: this.tickData,
                    tickmarkPlacement: 'on',
                    startOnTick: false,
                    tickWidth: this.chartData.tickWidth ? this.chartData.tickWidth : 0,
                    crosshair: true,
                    labels: {
                        enabled: this.chartData.xLabel,
                        align: 'center',
                        style: {
                            fontSize: '10px'
                        }
                    },
                    crosshair: {
                        width: 1,
                        color: 'silver'
                    },
                    categories: this.xData
                },
                yAxis: {
                    crosshair: true,
                    gridLineWidth: 0,
                    lineWidth: 1,
                    title: {
                        text: null
                    }
                },
                tooltip: {
                    formatter: function () {
                        return this.x + ' : <b>' + (this.y).toLocaleString("en-US") + '</b>';
                    },
                    shape: 'rect',
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                },
                series: [{
                    data: this.chartData.seriesData,
                    name: this.chartData.title,
                    color: this.chartData.color,
                    fillOpacity: 0.3,
                }]
            }
            this.loader = false;
        },
    }
}
</script>
<style></style>