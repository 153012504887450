import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name: "FacebookCreatives",
    components: {
        Loader,
    },
    data() {
        return {
            loader: false,
            savedCreatives: [],
        }
    },

    mounted() {
        this.getSavedCreatives();
    },

    methods: {
        getSavedCreatives() {
            this.loader = true;
            this.savedCreatives = [];
            const url = "/api/v1/meta/savecreative";

            axios.get(url).then(res => {
                this.loader = false;
                this.savedCreatives = res.data.data;
                this.$nextTick(() => {
                    this.processTable('#reports_table', 2)
                })
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })
        },
        goToDetailedReport(reportName, reportId) {
            localStorage.setItem('fb_creative_report_name', reportName);
            localStorage.setItem('fb_creative_report_id', reportId);
            window.location = `/${reportName.replaceAll(" ", "-")}`;
        },
        deleteReport(viewId) {
            this.loader = true;
            const url = "/api/v1/meta/savecreative";
            const payload = {
                view_id: viewId
            }

            axios.delete(url, { data: payload }).then(res => {
                this.loader = false;
                this.savedCreatives();
            }).catch(err => {
                this.loader = false;
                console.log(`Error in deleting report`, err);
            })
        },
        processTable(id, sortCol) {
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                lengthChange: true,
                sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}