import axios from 'axios';
import moment from 'moment';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'Programmetic',
  components: {
    Loader
  },
  data() {
    return {
      loader: false,
      breakdownLoader: false,
      dataReady: false,
      dateRange: {
        startDate: moment().subtract(90, 'days').format("YYYY-MM-DD"),
        endDate: moment().subtract(1, 'day').format("YYYY-MM-DD")
      },
      campaignList: [],
      selectedCampaign: {},
      lineItemsList: [],
      selectedLineItem: {},
      bulkData: {
        data: {},
        order: []
      },
      durationArr: [
        { value: 'day', label: 'Day' },
        { value: 'month', label: 'Month' }
      ],
      selectedDuration: null,
      timelineMetricList: [],
      selectedTimelineMetric1: null,
      selectedTimelineMetric2: null,
      selectedTimelineMetric3: null,
      timelineOrder: [],
      timelineData: [],
      timelineChartData: {},
      deviceData: {
        data: [],
        order: []
      },
      ageData: {
        data: [],
        order: []
      },
      genderData: {
        data: [],
        order: []
      },
      publisherData: {
        data: [],
        order: [],
        publisherList: []
      },
      ageMapper: {
        "age1824": "18-24",
        "age2534": "25-34",
        "age3544": "35-44",
        "age4554": "45-54",
        "age5564": "55-64",
        "age65plus": "65+"
      }
    };
  },
  computed:{
    currentClient(){
      return this.$store.state.userData.current;
    }
  },
  mounted() {
    this.getCampaignList();
    this.selectedDuration = this.durationArr[0];
    console.log(this.currentClient);
  },
  methods: {
    getAllData() {
      this.getBulkBoxData();
      this.getTimelineData();
      this.getDeviceData();
      this.getAgeData();
      this.getGenderData();
      this.getPublisherData();
    },
    downloadReport(){
      this.loader = true;
      const url = `/api/report/downloadreport`;
      let payload;
      let dataLoad = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
      };

      if (this.selectedCampaign.id === 'all') {
        let level = {
          level_type: 'account'
        }
        payload = {
          ...dataLoad, ...level
        };
      } else {
        if (this.selectedLineItem.id === 'all') {
          let level = {
            level_type: 'campaign',
            level_id: this.selectedCampaign.id
          }
          payload = { ...dataLoad, ...level };
        } else {
          let level = {
            level_type: 'lineitem',
            level_id: this.selectedLineItem.id
          }
          payload = { ...dataLoad, ...level }
        }
      };

      axios.get(url, { params: payload}).then(res => {
        window.location = res.data.url;
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })

    },
    getCampaignList() {
      const url = `/api/report/campaign`;
      this.loader = true;
      axios.get(url).then(res => {
        if(!res.data.data.length){
          this.loader = false;
          this.breakdownLoader = false;
          return;
        }
        this.campaignList = res.data.data;
        this.campaignList.unshift(
          {
            id: 'all',
            name: 'All Campaigns'
          }
        );
        this.selectedCampaign = this.campaignList[1];
        this.$nextTick(() => {
          this.getLineItems();
        })
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      });
    },
    getLineItems() {
      const url = `/api/report/lineitem?campaign_id=${this.selectedCampaign.id}`;
      this.loader = true;
      this.lineItemsList = [
        {
          id: 'all',
          name: "All Line Items"
        }
      ]
      axios.get(url).then(res => {
        let resData = res.data.data;
        if (Array.isArray(resData)) {
          resData.forEach(lineItem => {
            this.lineItemsList.push({ id: lineItem.id, name: lineItem.name })
          });
          this.selectedLineItem = this.lineItemsList[0];
        }
        this.$nextTick(() => {
          this.getAllData();
        });
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      });
    },
    getBulkBoxData() {
      this.loader = true;
      const url = `/api/report/bulk`;
      let payload;
      let dataLoad = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
      };
      if (this.selectedCampaign.id === 'all') {
        let level = {
          level_type: 'account'
        }
        payload = {
          ...dataLoad, ...level
        };
      } else {
        if (this.selectedLineItem.id === 'all') {
          let level = {
            level_type: 'campaign',
            level_id: this.selectedCampaign.id
          }
          payload = { ...dataLoad, ...level };
        } else {
          let level = {
            level_type: 'lineitem',
            level_id: this.selectedLineItem.id
          }
          payload = { ...dataLoad, ...level }
        }
      };
      axios.get(url, { params: payload }).then(res => {
        this.bulkData.data = res.data.data;
        this.bulkData.order = res.data.order;
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })
    },
    getDeviceData() {
      this.loader = true;
      const url = `/api/report/bulk`;
      let payload;
      let dataLoad = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        targeting_type: 'device'
      };

      $('#device_tbl').DataTable().destroy();

      if (this.selectedCampaign.id === 'all') {
        let level = {
          level_type: 'account'
        }
        payload = {
          ...dataLoad, ...level
        };
      } else {
        if (this.selectedLineItem.id === 'all') {
          let level = {
            level_type: 'campaign',
            level_id: this.selectedCampaign.id
          }
          payload = { ...dataLoad, ...level };
        } else {
          let level = {
            level_type: 'lineitem',
            level_id: this.selectedLineItem.id
          }
          payload = { ...dataLoad, ...level }
        }
      };

      this.deviceData = {
        data: [],
        order: []
      };

      axios.get(url, { params: payload }).then(res => {
        this.deviceData.data = res.data.data;
        this.deviceData.order = res.data.order;
        this.$nextTick(() => {
          this.processTable('#device_tbl')
        })
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })
    },
    getAgeData() {
      this.loader = true;
      const url = `/api/report/bulk`;
      let payload;
      let dataLoad = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        targeting_type: 'age'
      };

      $('#age_tbl').DataTable().destroy();

      if (this.selectedCampaign.id === 'all') {
        let level = {
          level_type: 'account'
        }
        payload = {
          ...dataLoad, ...level
        };
      } else {
        if (this.selectedLineItem.id === 'all') {
          let level = {
            level_type: 'campaign',
            level_id: this.selectedCampaign.id
          }
          payload = { ...dataLoad, ...level };
        } else {
          let level = {
            level_type: 'lineitem',
            level_id: this.selectedLineItem.id
          }
          payload = { ...dataLoad, ...level }
        }
      };

      this.ageData = {
        data: [],
        order: []
      };

      axios.get(url, { params: payload }).then(res => {
        this.ageData.data = res.data.data;
        this.ageData.order = res.data.order;
        this.$nextTick(() => {
          this.processTable('#age_tbl')
        })
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })
    },
    getGenderData() {
      this.loader = true;
      const url = `/api/report/bulk`;
      let payload;
      let dataLoad = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        targeting_type: 'gender'
      };

      $('#gender_tbl').DataTable().destroy();

      if (this.selectedCampaign.id === 'all') {
        let level = {
          level_type: 'account'
        }
        payload = {
          ...dataLoad, ...level
        };
      } else {
        if (this.selectedLineItem.id === 'all') {
          let level = {
            level_type: 'campaign',
            level_id: this.selectedCampaign.id
          }
          payload = { ...dataLoad, ...level };
        } else {
          let level = {
            level_type: 'lineitem',
            level_id: this.selectedLineItem.id
          }
          payload = { ...dataLoad, ...level }
        }
      };

      this.genderData = {
        data: [],
        order: []
      };

      axios.get(url, { params: payload }).then(res => {
        this.genderData.data = res.data.data;
        this.genderData.order = res.data.order;
        this.$nextTick(() => {
          this.processTable('#gender_tbl')
        })
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })
    },
    getPublisherData() {
      this.loader = true;
      if (this.selectedCampaign.id === 'all' || this.selectedLineItem.id === 'all') {
        return;
      }
      const url = `/api/report/bulk`;
      let payload;
      let dataLoad = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        targeting_type: 'publisher'
      };

      $('#publisher_tbl').DataTable().destroy();

      let level = {
        level_type: 'lineitem',
        level_id: this.selectedLineItem.id
      }
      payload = { ...dataLoad, ...level }

      this.publisherData = {
        data: [],
        order: [],
        publisherList: []
      };

      axios.get(url, { params: payload }).then(res => {
        this.publisherData.data = res.data.data;
        this.publisherData.order = res.data.order;
        this.publisherData.publisherList = res.data.publisher_list;

        this.$nextTick(() => {
          this.processTable('#publisher_tbl');
        })
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })
    },

    getTimelineData() {
      this.loader = true;
      const url = `/api/report/histogram`;
      $('#timeline_tbl').DataTable().destroy();
      let payload;
      let dataLoad = {
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        interval: this.selectedDuration.value
      }
      if (this.selectedCampaign.id === 'all') {
        let level = {
          level_type: 'account'
        }
        payload = {
          ...dataLoad, ...level
        };
      } else {
        if (this.selectedLineItem.id === 'all') {
          let level = {
            level_type: 'campaign',
            level_id: this.selectedCampaign.id
          }
          payload = { ...dataLoad, ...level };
        } else {
          let level = {
            level_type: 'lineitem',
            level_id: this.selectedLineItem.id
          }
          payload = { ...dataLoad, ...level }
        }
      };
      axios.get(url, { params: payload }).then(res => {
        this.timelineMetricList = res.data.order.slice(1);
        this.timelineOrder = res.data.order;
        this.timelineData = res.data.data;
        this.selectedTimelineMetric1 = this.timelineMetricList[0];
        this.selectedTimelineMetric2 = this.timelineMetricList[1];
        this.selectedTimelineMetric3 = this.timelineMetricList[2];
        this.timelineMetricList.forEach(order => {
          order.label = this.formatLabel(order.label);
        });
        this.plotTimelineChart();
        this.$nextTick(() => {
          this.processTable('#timeline_tbl');
        });
        this.loader = false;
      }).catch(err => {
        console.log(err);
        this.loader = false;
      })
    },
    processTable(id) {
      var search = require("@/assets/Icons_SVG/Search.svg");
      $(id).dataTable({
        searching: true,
        info: false,
        lengthChange: true,
        sorting: [[1, "desc"]],
        initComplete: function (settings, json) {
          $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
          $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
          $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
        },
        language: {
          search: "", searchPlaceholder: "Search...", paginate: {
            first: "",
            previous: "<",
            next: ">",
            last: "",
          },
          lengthMenu: "<h5>Show entries</h5> _MENU_"
        },
      })
      $(id + "_filter > label").append(
        `<div class='search_icon'><img src='${search}' /></div>`
      );
    },
    plotTimelineChart() {
      let xAxisData = [];
      let firstSeriesData = [];
      let secondSeriesData = [];
      let thirdSeriesData = [];

      //plotting data for chart
      this.timelineData.map(timelineDatapoint => {
        xAxisData.push(moment(timelineDatapoint.timestamp).format('YYYY-MM-DD'));
        firstSeriesData.push(Number(timelineDatapoint[this.selectedTimelineMetric1.key].toFixed(2)));
        secondSeriesData.push(Number(timelineDatapoint[this.selectedTimelineMetric2.key].toFixed(2)));
        thirdSeriesData.push(Number(timelineDatapoint[this.selectedTimelineMetric3.key].toFixed(2)));
      });

      //plotting chart options
      this.timelineChartData = {
        chart: {
          backgroundColor: 'transparent',
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        credits: {
          enabled: false
        },
        legend: {
          itemStyle: {
            fontSize: '0.8vw'
          },
          enabled: true,
          alignColumns: true,
          align: 'right',
          y: 0,
          x: 0,
          squareSymbol: true,
          verticalAlign: 'top',
          itemDistance: 35,
          margin: 20,
          padding: 0,
          symbolHeight: 14,
          symbolWidth: 14,
          symbolRadius: 4
        },
        xAxis: [{
          categories: xAxisData,
          crosshair: true
        }],
        yAxis: [
          {
            lineWidth: 0,
            crosshair: true,
            gridLineWidth: 0,
            title: {
              text: this.selectedTimelineMetric1.label,
              style: {
                color: "#47A694"
              }
            }
          },
          {
            lineWidth: 1,
            lineColor: '#8B8D94',
            crosshair: false,
            gridLineWidth: 1,
            title: {
              text: this.selectedTimelineMetric2.label,
              style: {
                color: "#8B8D94"
              }
            }
          },
          {
            lineWidth: 0,
            crosshair: false,
            gridLineWidth: 1,
            opposite: true,
            title: {
              text: this.selectedTimelineMetric3.label,
              style: {
                color: "#FFAB00"
              }
            }
          },
        ],
        tooltip: {
          shared: true,
          borderWidth: 0,
          shadow: {
            color: '#d6d6d6',
            opacity: 0.1,
            width: 10
          },
          borderRadius: 6,
          backgroundColor: '#ffffff'
        },
        series: [
          {
            name: this.selectedTimelineMetric1.label,
            type: 'column',
            yAxis: 0,
            color: '#47A694',
            borderWidth: 0,
            data: firstSeriesData
          },
          {
            name: this.selectedTimelineMetric2.label,
            type: 'column',
            yAxis: 1,
            color: '#8B8D94',
            borderWidth: 0,
            data: secondSeriesData
          },
          {
            name: this.selectedTimelineMetric3.label,
            type: 'line',
            yAxis: 2,
            color: '#FFAB00',
            borderWidth: 0,
            data: thirdSeriesData
          },
        ]

      }
    },
    formatNumber(num) {
      if (isNaN(num)) {
        return num
      }
      const num1 = Number(num).toFixed(2);
      const num2 = Number(num1).toLocaleString();
      return num2;
    },
    formatLabel(label) {
      if (label === 'Ctr' || label === 'Cpc' || label === 'Cpm') {
        return label.toUpperCase();
      } else return label
    }
  }
}