import moment from "moment"
import axios from 'axios'
import Loader from '@/components/Loader/Loader.vue'

export default {
  name:'PPT',
  components:{Loader},
  data(){
    return {
      moment:moment,
      dateRange:{
        startDate:moment().subtract(1,'months').format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      },
      selectedPlateform: "flipkart",
      selectedPlateformType: "display",
      selectedSegment:'pla',
      screenSection:false,
      po:'',
      account: 'amd',
      formData : new FormData(),
      loader1: false,
      uploadImageList:[],
      creativeImageList:[],
      progressBar:false,
      showloader:false,
      isdownloadPPT:false,
      POToggle: false,
      ROToggle: false,
      POAmount:'',
      PONumber:'',
      estimateNumber:'',
      estimatAmount:'',
      budgetSpend:'',
      objective:'',
      dealType:'',
      views: '',
      ROAmount:'',
      RONumber:'',
      intervalStatus:'',
      screenShotStatus:'',
      pptStatus:'',
      pptPost: false,
    }
  },
  methods:{
    formatDates(){
      this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    changeDate(){
      this.formatDates()
    },
    uploadLiveScreenshot(event){
    // # console.log(event)
      let self = this
      for (let File of event.target.files){
        let temp = {
          src: '',
          imgTitle: '',
          file:''
        }
        if (this.uploadImageList.length<8)
          if (File.size < 2000000){
            // # console.log("chekc syize of file",event.target.files[0].size)
            temp.src = URL.createObjectURL(File)
            temp.file = File
            temp.imgTitle = File.name
            this.uploadImageList.push(temp)
          }
          else
          {
            self.$toast.error("Image size more than 2MB", {
              position: "bottom-center",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            });     
          }
        else{
          self.$toast.error("Limit extented", {
            position: "bottom-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });     
        }
      }
    },

    uploadCreativeScreenshot(event){
      let self = this
      for ( let File of event.target.files){
        if (File.size < 2000000){ 
          let temp={
            src: '',
            imgTitle: '',
            file:''
          }
          temp.src = URL.createObjectURL(File)
          temp.file = File
          temp.imgTitle = File.name
          this.creativeImageList.push(temp)
        }
        else{
          self.$toast.error("Image size more than 2MB", {
            position: "bottom-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });     
        }
      }
    },
      
    deletIconFn(flag,idx){
      if (flag=='live')
        this.uploadImageList.splice(idx,1)
      if(flag=='creative')
        this.creativeImageList.splice(idx,1)
    },
    checkStatus(){
      let self = this
      let payload ={}
      // self.loader1 = true
      // payload={
      //   "Type": self.selectedPlateformType,
      //   "Account_name": self.account,
      //   "Platform": self.selectedPlateform
      // }
      let url = '/api/billing_automation/ppt/status'
      axios.get(url).then((respons)=>{
        // # console.log(data.data)
        // # window.location = data.data
        self.screenShotStatus = respons.data.data.status_screenshot
        self.pptStatus = respons.data.data.status_ppt
        if (respons.data.data.status_ppt=='finished'){
          clearInterval(self.intervalStatus)
          self.isdownloadPPT=true
        }
        console.log("console.log",respons.data.data.status_ppt)
      }).catch((error)=>{
        self.loader1 = false
        clearInterval(self.intervalStatus)
        self.$toast.error("Error!", {
          position: "bottom-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      })
    },
    getPPT(){
      self = this
      let payload ={}
      self.loader1 = true
      payload={
        "Type": self.selectedPlateformType,
        "Account_name": self.account,
        "Platform": self.selectedPlateform
      }
      let url = '/api/billing_automation/ppt/download'
      axios.post(url,payload).then((respons)=>{
        window.location= respons.data.data
        self.loader1 = false
      }).catch((error)=>{
        self.loader1 = false
        self.$toast.error("Error!", {
          position: "bottom-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });     
     })
    },

    postPPTData(){
      let self = this
      let formData = new FormData()
      if(self.PONumber.length>10)
        return false

      if(self.RONumber.length>10)
        return false
      
      self.progressBar = true
      for (let [k,data] of this.uploadImageList.entries()){
          formData.append("screenshot"+k,data.file)
      }
      
      for(let [k,data] of this.creativeImageList.entries()){
          formData.append("creative_screenshot"+k, data.file)
      }
      formData.append("start_date",this.dateRange.startDate)
      formData.append("end_date",this.dateRange.endDate)
      formData.append("Type",this.selectedPlateformType)
      formData.append("Account_name",self.account)
      formData.append("Platform",self.selectedPlateform)
      if(self.POToggle){
        formData.append("po_amount",this.POAmount)
        formData.append("po_number",this.PONumber)
        formData.append("estimate_number",this.estimateNumber)
        formData.append("estimate_amount",this.estimatAmount)
        formData.append("budget_to_spend",this.budgetSpend)
        formData.append("objective",this.objective)
        formData.append("deal_type",this.dealType)
        formData.append("views",this.views)
      }
      if(self.ROToggle){
        formData.append("ro_amount",this.ROAmount)
        formData.append("ro_number",this.RONumber)
      }
      // # console.log("check ?",Object.fromEntries(formData))

      // # console.log("payload",payload)
      let url = '/api/billing_automation/ppt'
      axios.post(url,formData).then((respons)=>{
        self.intervalStatus = setInterval(self.checkStatus, 5000);
        self.uploadImageList = []
        self.creativeImageList =[]
        self.po = ''
        self.progressBar = false
        self.pptPost = true
        document.getElementById('liveScreenshot').value = ""
        document.getElementById('createScreenshot').value=""
      }).catch((error)=>{
        self.progressBar = false
        self.$toast.error("Error!", {
          position: "bottom-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });     
      })
    },

    postScreenshotData(){
      let self = this
      self.showloader = true
      let formData = new FormData()
      formData.append("start_date",this.dateRange.startDate)
      formData.append("end_date",this.dateRange.endDate)
      formData.append("Type",this.selectedPlateformType)
      formData.append("Account_name",self.account)
      formData.append("Platform",self.selectedPlateform)
      if (this.selectedPlateform=='flipkart' && this.selectedPlateformType=='search')
        formData.append("segment",self.selectedSegment)
      // # console.log("check formdata",Object.fromEntries(formData))
      // # console.log("payload",payload)
      let url = "/api/billing_automation/screenshot"
      axios.post(url,formData).then((respons)=>{
        self.showloader = false
         self.$toast.success("Screenshot taken", {
            position: "bottom-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
        });
      }).catch(error=>{
        self.showloader = false
        self.$toast.error("Error!", {
          position: "bottom-center",
          timeout: false,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
      });
      })
    },
    toggleScreenPPT(){
      if(this.screenSection== true){
        this.screenSection = false
        this.selectedPlateform= "flipkart"
        this.selectedPlateformType= "display"
        this.account= 'amd'
      }
      else{
        this.screenSection = true
        this.selectedPlateform= "flipkart"
        this.selectedPlateformType= "display"
        this.account= 'amd'
      }
    }
  }
}



