import axios from 'axios'
import moment from 'moment'
import Vuex from 'vuex';
import Loader from "@/components/Loader/Loader.vue";

export default {
    name: "FacebookMapping",
    components: {
        Loader
    },
    data() {
        return {
            toggle_auto_dump: "",
            is_admin: false,
            accounts: [],
            selectedAccount: null,
            campType: [],
            selectedCampType: null,
            campName: [],
            selectedCampName: null,
            fullMetrics: [],
            metrics: [],
            actionType: "",
            loader1: false,
            loader2: false,
            markedMet: [],
            searchMet: "",
            campTextName: "",
            dateRange: {
                startDate: moment().subtract(31, 'days').format("YYYY-MM-DD"),
                endDate: moment().subtract(1, 'days').format("YYYY-MM-DD")
            },
            today: moment().format("YYYY-MM-DD"),
            acc: [],
            allDataAs: {},
            camp: "",
            loading: false,
            camp_id: "",
            file: "",
            account_id: "",
            final_data: [],
            header: [],
            data_submit: [],
            show_btn: false,
            start_date: "",
            end_date: "",
            data_cid: "",
            data_aid: "",
            show_table: true,
            tabs: {
                mapData: true,
                modifyData: false
            }
        }
    },
    computed: Vuex.mapState(['user', 'menu']),
    created() {
        const user = JSON.parse(localStorage.userData).data.username
        if (user === 'admin') {
            this.is_admin = true;
        }
    },
    // updated(){
    //     console.log("metric", Array.isArray(this.metrics))
    // },
    mounted() {
        // $('#mapmodifytab .item').tab()
        // this.date.to = moment().subtract(1, 'days').format("YYYY-MM-DD")
        this.getAccounts();
        this.getAllMetrics();
    },
    methods: {
        toggleAutoDump() {
            const url = "/api/fb/toggle_auto_dump";
            const payload = {
                account_name: this.selectedAccount.name,
                auto_dump: this.toggle_auto_dump
            }
            if (this.selectedAccount) {
                axios.post(url, payload).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })

            } else {
                alert("please select the account first")
            }

        },
        getData(val) {
            const url = `/api/fb/reports?camp_type=${this.selectedCampType}&campaign_id=${this.selectedCampName.id}&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&version=${val}`
            this.loading = true
            axios.get(url).then(res => {
                this.loading = false;
                window.location = res.data.data["data"]
            }).catch(err => {
                this.loading = false;
                console.log(err)
            })
        },
        upload() {
            const formData = new FormData();
            formData.append("file", document.querySelector('#file')[0].files[0]);

            const url = `/api/fb/upload?camp_id=${this.selectedCampName.id}&camp_name=${this.campTextName}&account_name=${this.selectedAccount.name}&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&campaign_type=${this.selectedCampType}`;

            axios.post(url, formData).then(response => {
                this.header = response.data["first_row"];
                this.final_data = [];
                this.data_submit = response.data["data"];
                this.allDataAs = response.data;

                for (const item of this.data_submit) {
                    const vals = [];
                    for (const key of this.header) {
                        vals.push(key === "Date" ? item["timestamp"] : item[key]);
                    }
                    this.final_data.push(vals);
                }
                this.show_btn = true;
            }).catch(error => {
                console.error("Error during upload:", error);
                // Handle errors as needed
            });
        },
        post_data() {
            const data = this.allDataAs;
            const url = "/api/fb/post";

            axios.post(url, data).then(response => {
                alert('saved');
            }).catch(error => {
                console.error("Error during post:", error);
                // Handle errors as needed
            });
        },
        searchAction1() {
            this.metrics = [];

            this.fullMetrics.forEach((dataset) => {
                this.$nextTick(() => {
                    const searchkey = this.searchMet.toLowerCase();
                    const title = dataset.title.toLowerCase();

                    if (title.includes(searchkey)) {
                        this.metrics.push(dataset);
                    }
                });
            });
        },
        getMarkedMet() {
            this.markedMet = [];

            setTimeout(() => {
                this.fullMetrics.forEach((dataset, idx) => {
                    if (dataset.check) {
                        this.markedMet.push(dataset.title);
                    }
                });
            }, 0);
        },
        getCampTypeMetrics() {
            this.actionType = "camp_type";
            this.markedMet = [];

            this.metrics.forEach((dataset) => {
                dataset.check = false;
            });

            this.loader2 = true;
            const url = `/api/fb/camp-type-keys?account_name=${this.selectedAccount.name}&campaign_type=${this.selectedCampType}`;

            axios.get(url).then(response => {
                this.loader2 = false;

                response.data.data.status.forEach(dataset => {
                    this.$nextTick(() => {
                        this.metrics.forEach((dataset2, idx2) => {
                            if (dataset2.title === dataset) {
                                this.metrics[idx2].check = true;
                                setTimeout(() => { 
                                    this.markedMet.push(dataset2.title);
                                }, 0);
                            }
                        });
                    });
                });
            }).catch(error => {
                alert("Error");
                this.loader2 = false;
            });
        },
        getAllMetrics() {
            this.loader2 = true;

            axios.get("/api/fb/metrics").then(response => {
                this.loader2 = false;

                response.data.data.data.forEach(dataset => {
                    const metricObj = {
                        title: dataset,
                        check: false
                    };
                    this.metrics.push(metricObj);
                    this.fullMetrics.push(metricObj);
                });
            }).catch(error => {
                this.loader2 = false;
                alert("Error");
            });
        },
        getAccounts() {
            this.loader1 = true;

            axios.get("/api/fb/all-accounts").then(response => {
                this.loader1 = false;
                this.accounts = response.data.data.data;
            }).catch(error => {
                this.loader1 = false;
                alert("Error");
            });
        },
        accountAction() {
            if (this.selectedAccount) {
                const toggleAutoDumpUrl = `/api/fb/toggle_auto_dump?account_name=${this.selectedAccount.name}`;

                axios.get(toggleAutoDumpUrl).then(response => {
                    console.log(response.data);
                    this.toggle_auto_dump = response.data.data.autodump_status;
                }).catch(error => {
                    console.error("Error during toggle auto dump:", error);
                });

                const campTypesUrl = `/api/fb/all-campaign-types-in-account?account_name=${this.selectedAccount.name}&start_date=2010-01-01&end_date=2038-09-01`;

                this.loader1 = true;

                axios.get(campTypesUrl).then(response => {
                    this.loader1 = false;
                    this.campType = response.data.data;
                    this.selectedCampType = "";
                    this.selectedCampName = "";
                }).catch(error => {
                    this.loader1 = false;
                    alert("Error");
                });
            } else {
                this.campType = "";
                this.campName = "";
                this.selectedCampType = "";
                this.selectedCampName = "";
                this.actionType = "";
            }
        },
        campTypeAction() {
            if (this.selectedCampType) {
                this.actionType = "camp_type";
                const url = `/api/fb/all-campaigns-in-campaign-type?start_date=2010-01-01&end_date=${this.today}&account_name=${this.selectedAccount.name}&camp_type=${this.selectedCampType}&version=original`;

                this.loader1 = true;

                axios.get(url).then(response => {
                    this.loader1 = false;
                    this.campName = response.data.data;
                    this.selectedCampName = "";
                    this.getCampTypeMetrics();
                }).catch(error => {
                    this.loader1 = false;
                    alert("Error");
                });
            } else {
                this.campName = "";
                this.selectedCampName = "";
                this.actionType = "";
            }
        },

        campNameAction() {
            this.campTextName = this.selectedCampName.campaign_name;
            this.markedMet = [];

            if (this.selectedCampName) {
                this.actionType = "camp_name";
                this.metrics.forEach(dataset => dataset.check = false);

                const url = `/api/fb/camp-keys?account_name=${this.selectedAccount.name}&campaign_id=${this.selectedCampName.id}`;

                this.loader1 = true;

                axios.get(url).then(response => {
                    this.loader1 = false;
                    response.data.data.status.forEach(dataset => {
                        this.metrics.forEach((dataset2,idx2) => {
                            if (dataset2.title === dataset) {
                                this.metrics[idx2].check = true;
                                this.markedMet.push(dataset2.title);
                            }
                        });
                    });
                }).catch(error => {
                    this.loader1 = false;
                    alert("Error");
                });
            } else {
                this.getCampTypeMetrics();
            }
        },
        saveMetric() {
            const saveUrl = this.actionType === "camp_type" ? "/api/fb/camp-type-keys" : "/api/fb/camp-keys";
            this.saveNow(saveUrl);
        },
        saveNow(geturl) {
            const readyMet = this.fullMetrics.filter(dataset => dataset.check).map(dataset => dataset.title);
            const data = {
                account_name: this.selectedAccount.name,
                keys: readyMet
            };

            if (this.actionType === "camp_type") {
                data.campaign_type = this.selectedCampType;
            } else if (this.actionType === "camp_name") {
                data.campaign_id = this.selectedCampName.id;
            }

            this.loader1 = true;

            axios.post(geturl, data).then(response => {
                alert('Success');
                this.loader1 = false;
            }).catch(error => {
                this.loader1 = false;
                alert("Error");
            });
        },

        toggleTab(variable) {
            for(let tabKey of Object.keys(this.tabs)){
                if(tabKey === variable){
                    this.tabs[variable] = true
                } else {
                    this.tabs[tabKey] = false;
                }
            }
        },
    },
}